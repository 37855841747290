import { Content, Header } from 'antd/es/layout/layout';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  Modal,
  Pagination,
  Space,
  theme,
} from 'antd';
import styled from 'styled-components';
import Color from '../../../../_common/view/style/Color';
import React, { useEffect, useState } from 'react';
import AdminLayout from '../AdminLayout';
import { SmallDashOutlined } from '@ant-design/icons';
import { User } from '../../../../user/domain/user';
import { deleteUser, findAdminPage, signUpAdmin } from '../../../repository/admin-repository';
import bcrypt from 'bcryptjs-react';
import { validateEmail } from '../../../../user/repository/user-repository';
import { AdminSignup } from '../../../domain/admin-signup';

const { Search } = Input;

const StyledTable = styled.table`
  /* Add other styles here */
  .common-table-row:hover {
    background-color: transparent !important;
  }
`;

const AdminAccount: React.FC = () => {
  const [contents, setContents] = useState<Array<User>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getContents = async (search: string | null, page?: number) => {
    let contentsPage = await findAdminPage((page ? page : pageNumber) - 1, 10, search);
    setContents(contentsPage?.contents as Array<User>);
    setTotalElements(contentsPage?.totalElements as number);
  };
  const [selectedItem, setSelectedItem] = useState<User | null>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  useEffect(() => {
    getContents(null);
  }, [pageNumber]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [AddAccount, setAddAccount] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal2 = () => {
    setIsModal2Open(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const AddModal = () => {
    setAddAccount(true);
  };

  const handleAddCancel = () => {
    setAddAccount(false);
  };

  const items = [
    {
      key: 'change',
      label: (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                textHoverBg: 'transparent',
              },
            },
          }}
        >
          <Button style={{ width: '100%', textAlign: 'left' }} type="text" onClick={showModal}>
            삭제하기
          </Button>
        </ConfigProvider>
      ),
    },
  ];

  const emailRegEx =
    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const onFinish = async () => {
    const securedPassword = bcrypt.hashSync(password, bcrypt.genSaltSync(10));

    if (await validateEmail(email)) {
      alert('이미 사용중인 이메일입니다.');
      return;
    }

    if (!emailRegEx.test(email)) {
      alert('이메일 형식이 올바르지 않습니다.');
      return;
    }
    if (name === '') {
      alert('이름을 입력해주세요.');
      return;
    }
    if (phone === '') {
      alert('연락처를 입력해주세요.');
      return;
    }

    const request = {
      email: email,
      passwordValue: securedPassword,
      name: name,
      phoneNumber: phone,
    } as AdminSignup;
    signUpAdmin(request).then((res) => {
      if (res) {
        alert('회원가입이 완료되었습니다.');
        window.location.reload();
      } else {
        alert('회원가입에 실패하였습니다.');
      }
    });
  };

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '1%',
                }}
              >
                관리자 계정 관리
              </div>
              <Input
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    await getContents(searchValue, 1);
                    setPageNumber(1);
                  }
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="검색어 입력 후 Enter 키를 엽력해주세요."
                style={{ border: '1px solid' + Color.gray600, height: '40px' }}
              />
            </div>
          </Header>
          <Content>
            <div
              style={{
                padding: 16,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <p style={{ fontSize: '16px', fontWeight: '600' }}>
                전체 관리자 <span style={{ color: Color.primary500 }}>{totalElements}</span>명
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                <Button
                  style={{
                    border: `2px solid ${Color.primary500}`,
                    color: Color.primary500,
                    padding: '12px 24px 34px  24px',
                    borderRadius: 8,
                  }}
                  onClick={AddModal}
                >
                  + 관리자 계정 만들기
                </Button>
                <Modal
                  width={350}
                  mask={false}
                  open={AddAccount}
                  closable={false}
                  maskClosable={true}
                  onCancel={handleAddCancel}
                  cancelText={'닫기'}
                  cancelButtonProps={{
                    style: { color: Color.primary500, backgroundColor: 'white' },
                  }}
                  footer={
                    <div
                      style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                    >
                      <Button
                        type={'primary'}
                        style={{
                          color: 'white',
                          backgroundColor: Color.primary500,
                          height: '40px',
                        }}
                        onClick={onFinish}
                      >
                        계정 생성
                      </Button>
                      <Button
                        type={'text'}
                        style={{
                          color: Color.primary500,
                          fontWeight: '700',
                          height: '40px',
                          marginInlineStart: '0',
                        }}
                        onClick={handleAddCancel}
                      >
                        돌아가기
                      </Button>
                    </div>
                  }
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: '700',
                        }}
                      >
                        관리자 계정 만들기
                      </p>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div style={{ width: '100%', marginBottom: '10px' }}>
                        <span style={{ fontWeight: '700' }}>아이디</span>
                        <Input
                          placeholder={'아이디를 입력해주세요'}
                          style={{ height: '40px', marginTop: '5px' }}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: '100%', marginBottom: '10px' }}>
                        <span style={{ fontWeight: '700' }}>비밀번호</span>
                        <Input.Password
                          placeholder={'비밀번호를 입력해주세요'}
                          style={{ height: '40px', marginTop: '5px' }}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: '100%', marginBottom: '10px' }}>
                        <span style={{ fontWeight: '700' }}>이름</span>
                        <Input
                          placeholder={'이름을 입력해주세요'}
                          style={{ height: '40px', marginTop: '5px' }}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: '100%', marginBottom: '10px' }}>
                        <span style={{ fontWeight: '700' }}>연락처</span>
                        <Input
                          placeholder={'연락처를 입력해주세요'}
                          style={{ height: '40px', marginTop: '5px' }}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              <StyledTable
                className="common-table"
                style={{
                  borderCollapse: 'collapse',
                  borderSpacing: '0 10px',
                  width: '100%',
                  marginBottom: '30px',
                }}
              >
                <thead>
                  <tr>
                    <td className="common-table-header-column">번호</td>
                    <td className="common-table-header-column">아이디</td>
                    <td className="common-table-header-column">생성일자 </td>
                    <td className="common-table-header-column">이름</td>
                    <td className="common-table-header-column">연락처 </td>
                    <td className={'common-table-header-column'}></td>
                  </tr>
                </thead>
                <tbody>
                  {contents ? (
                    contents.map((item, index) => (
                      <tr
                        className="common-table-row"
                        style={{ marginBottom: '5%', borderBottom: `1px solid ${Color.gray200}` }}
                        key={item.idx}
                      >
                        <td className="common-table-column">{item.idx}</td>
                        <td className="common-table-column">{item.email}</td>
                        <td className="common-table-column">{item.createdAt}</td>
                        <td className="common-table-column">{item.basicInfo.name}</td>
                        <td className="common-table-column">{item.basicInfo.phone.number}</td>
                        <td className="common-table-column">
                          <Dropdown menu={{ items }} trigger={['click']}>
                            <a
                              onClick={(e) => {
                                setSelectedItem(item);
                                e.preventDefault();
                              }}
                            >
                              <Space>
                                <SmallDashOutlined />
                              </Space>
                            </a>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>Loading...</td>
                    </tr>
                  )}
                </tbody>
              </StyledTable>

              <Modal
                open={isModalOpen}
                mask={false}
                closable={false}
                width={300}
                footer={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      type={'primary'}
                      style={{
                        color: 'white',
                        backgroundColor: Color.primary500,
                        marginTop: '10px',
                        fontWeight: '700',
                        marginInlineStart: '0',
                      }}
                      onClick={async () => {
                        if (selectedItem?.idx) {
                          if (await deleteUser(selectedItem.idx)) {
                            alert('삭제 처리가 완료되었습니다.');
                            await getContents(null);
                            handleCancel();
                            return;
                          }
                        }
                        alert('삭제 처리에 실패하였습니다.');
                        handleCancel();
                      }}
                    >
                      계정 삭제
                    </Button>
                    <Button
                      type={'text'}
                      style={{
                        color: Color.primary500,
                        fontWeight: '700',
                        textAlign: 'center',
                        marginLeft: '0px',
                      }}
                      onClick={handleCancel}
                    >
                      돌아 가기
                    </Button>
                  </div>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ fontWeight: '700' }}>정말로 삭제하나요?</p>
                  <span>삭제 후에는 되돌릴 수 없습니다.</span>
                </div>
              </Modal>

              {/*<Modal*/}
              {/*  width={350}*/}
              {/*  mask={false}*/}
              {/*  open={isModal2Open}*/}
              {/*  closable={false}*/}
              {/*  maskClosable={true}*/}
              {/*  onCancel={handleCancel2}*/}
              {/*  cancelText={"닫기"}*/}
              {/*  cancelButtonProps={{style:{color:Color.primary500,backgroundColor:"white"}}}*/}
              {/*  footer={*/}
              {/*      <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>*/}
              {/*          <Button type={"primary"} style={{*/}
              {/*              color: "white",*/}
              {/*              backgroundColor: Color.primary500,*/}
              {/*              height:"40px",*/}
              {/*          }}>비밀번호 변경</Button>*/}
              {/*          <Button type={"text"} style={{color: Color.primary500,fontWeight:"700",height:"40px",}}*/}
              {/*                  onClick={handleCancel2}>돌아가기</Button>*/}
              {/*      </div>*/}
              {/*  }*/}
              {/*>*/}
              {/*    <div>*/}

              {/*        <div style={{*/}
              {/*            display: "flex",*/}
              {/*            flexDirection: "column",*/}
              {/*            alignItems: "center"*/}
              {/*        }}>*/}
              {/*            <p style={{*/}
              {/*                fontWeight: "700",*/}
              {/*            }}>비밀번호 변경</p>*/}
              {/*        </div>*/}

              {/*        <div style={{*/}
              {/*            display: "flex",*/}
              {/*            flexDirection: "column",*/}
              {/*            alignItems: "flex-start"*/}
              {/*        }}>*/}
              {/*            <div style={{width:"100%",marginBottom:"10px"}}>*/}
              {/*                <span style={{fontWeight:"700"}}>기존 비밀번호</span>*/}
              {/*                <Input placeholder={'기존비밀번호를 입력해주세요'}*/}
              {/*                       style={{height: "40px",marginTop:"5px"}}/>*/}
              {/*            </div>*/}
              {/*            <div>*/}
              {/*                <span style={{fontWeight:"700"}}>새 비밀번호</span>*/}
              {/*                <Input placeholder={'새 비밀번호를 입력해주세요'}*/}
              {/*                       style={{height: "40px",marginBottom:"10px",marginTop:"5px"}}/>*/}
              {/*                <Input placeholder={'새 비밀번호를 다시 한 번 입력해주세요'}*/}
              {/*                       style={{height: "40px"}}/>*/}
              {/*            </div>*/}

              {/*        </div>*/}
              {/*    </div>*/}
              {/*</Modal>*/}

              <div
                className={'page'}
                style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
              >
                <Pagination
                  current={pageNumber}
                  defaultCurrent={1}
                  onChange={setPageNumber}
                  pageSize={10}
                  total={totalElements}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default AdminAccount;
