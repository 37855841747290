import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ConfigProvider, Input, Pagination } from 'antd';
import { useRecoilState } from 'recoil';
import { communityCommentsState, communitySinglePost } from '../../state/CommunityState'; // Update the path accordingly
import { EllipsisOutlined, LikeOutlined } from '@ant-design/icons';
import Color from '../../../_common/view/style/Color';
import {
  addLikeCount,
  deleteSinglePost,
  findPostComment,
  findSinglePost,
  uploadSingleComment,
} from '../../repository/community-repository';
import styled from 'styled-components';
import { Comment } from '../../domain/community';
import CommentBox from './Comment';
import silverClient from '../../../_common/client/silver-client';
import { useMediaQuery } from 'react-responsive';

const { TextArea } = Input;

function CommunityDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const [post, setPost] = useRecoilState(communitySinglePost);
  const getPostDetail = async () => {
    const postDetail = await findSinglePost(Number(id));
    setPost(postDetail);
    setLikeCount(Number(postDetail?.likeCount));
  };
  const [comments, setComments] = useRecoilState(communityCommentsState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getComments = async () => {
    const commentsPage = await findPostComment(pageNumber - 1, Number(id), 5);
    setComments(commentsPage?.contents as unknown as Array<Comment>);
    setTotalElements(commentsPage?.totalElements as number);

    console.log(commentsPage);
  };

  const [likeCount, setLikeCount] = useState<number>(0);
  const clickLikeBtn = async () => {
    const likeResponse = await addLikeCount(Number(id));
    if (likeResponse?.success) {
      setLikeCount(likeCount + 1);
    }
  };

  useEffect(() => {
    getPostDetail();
    getComments();
    checkIsWriter();
  }, []);

  const convertDateFormat = (datetime: any) => {
    const date = new Date(datetime);
    const year = String(date.getFullYear()).slice(2); // 년도에서 앞의 두 자리만 추출
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}.${month}.${day} ${hour}:${minute}`;
    return formattedDate;
  };

  const [modalShow, setModalShow] = useState(false);
  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const [isWriter, setIsWriter] = useState(false); // 현재 로그인한 계정이 글 작성자와 일치하면 true
  const checkIsWriter = () => {
    // 로그인계정 vs 글 작성자 이름으로 동일인물인지 확인
    const user = silverClient.getUser();

    if (user?.basicInfo.name === post?.writer.name) setIsWriter(true);
    else setIsWriter(false);
  };

  const deletePost = async () => {
    const deleteMsg = await deleteSinglePost(Number(id));
    if (deleteMsg) {
      alert('게시글이 성공적으로 삭제되었습니다.');
      navigate('/community');
    } else {
      alert('다시 시도해주세요.');
    }
  };

  const navigateToEditor = () => {
    navigate('/editor', { state: { modify: true, ...post } });
  };

  const [comment, setComment] = useState('');
  const changeComment = (e: any) => {
    setComment(e.target.value);
  };
  const uploadComment = async () => {
    const uploadSuccess = await uploadSingleComment(Number(post?.idx), comment, false);
    if (uploadSuccess) {
      window.location.reload();
    } else {
      alert('다시 시도해주세요.');
    }
  };

  const [isModifying, setIsModifying] = useState(false);
  const startModify = () => setIsModifying(true);

  return (
    <Container>
      <TitleContainer>
        <LeftTitle>
          <Title>{post?.title}</Title>
          <Subtitle>
            {post?.writer.name} {convertDateFormat(post?.createdAt)}
          </Subtitle>
        </LeftTitle>
        {
          <RightTitle onClick={toggleModal}>
            {isWriter && (
              <EllipsisOutlined width={24} height={24} style={{ color: Color.gray800 }} />
            )}
            {modalShow && isWriter && (
              <EditModal>
                <ModalText onClick={navigateToEditor}>수정하기</ModalText>
                <ModalText onClick={deletePost}>삭제하기</ModalText>
                {/* {
                                    !isWriter && <ModalText style={{color: Color.red1000}}>신고하기</ModalText>
                                } */}
              </EditModal>
            )}
          </RightTitle>
        }
      </TitleContainer>
      <Content dangerouslySetInnerHTML={{ __html: String(post?.content) }}></Content>
      <LikeContainer>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              borderRadius: 8,
              colorText: 'white',
              colorBgContainer: Color.primary500,
              controlHeight: 48,
              fontSize: 16,
            },
            components: {
              Button: {
                fontWeight: 700,
                paddingInline: 24,
              },
            },
          }}
        >
          <Button onClick={clickLikeBtn}>
            <LikeOutlined width={24} height={24} style={{ color: 'white', marginRight: 16 }} />
            좋아요
          </Button>
        </ConfigProvider>
        {likeCount}
      </LikeContainer>
      <CommentContainer>
        <CommentTitle>
          댓글 &nbsp;&nbsp;
          <CommentCount>{post?.commentCount}</CommentCount>
        </CommentTitle>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              borderRadius: 8,
              fontSize: 16,
              colorText: Color.gray800,
            },
            components: {
              Input: {
                paddingBlock: 12,
                paddingInline: 12,
                colorTextPlaceholder: Color.gray500,
              },
            },
          }}
        >
          <TextArea
            value={comment}
            rows={6}
            placeholder="댓글을 적어주세요."
            onChange={changeComment}
          />
        </ConfigProvider>
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 8,
              fontSize: Number(`${isMobile ? 13 : 16}`),
              colorText: 'white',
              colorBgContainer: Color.primary500,
              colorPrimary: Color.primary500,
              controlHeight: Number(`${isMobile ? 32 : 44}`),
              colorBgContainerDisabled: Color.gray,
              colorBorder: 'white',
            },
            components: {
              Button: {
                fontWeight: 500,
                paddingInline: Number(`${isMobile ? 8 : 24}`),
              },
            },
          }}
        >
          <Button
            disabled={comment.length === 0}
            style={{ alignSelf: 'flex-end' }}
            onClick={uploadComment}
          >
            댓글 작성
          </Button>
        </ConfigProvider>
        {post?.commentCount === 0 ? (
          <NoComment>
            아직 댓글이 없습니다. <br />
            첫번째 댓글을 남겨보세요.
          </NoComment>
        ) : (
          <>{comments?.map((comment) => <CommentBox {...comment} />)}</>
        )}
      </CommentContainer>
      {post?.commentCount !== 0 && (
        <PaginationWrapper>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: Color.primary500,
              },
            }}
          >
            <Pagination
              defaultCurrent={1}
              onChange={setPageNumber}
              pageSize={12}
              total={totalElements}
              showSizeChanger={false}
            />
          </ConfigProvider>
        </PaginationWrapper>
      )}
    </Container>
  );
}

export default CommunityDetail;

const Container = styled.div`
  padding: 56px 16px;
  width: calc(100% - 32px);
  gap: 16px;
  display: flex;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid ${Color.gray200};
`;
const Title = styled.div`
  font-size: 24px;
  color: ${Color.gray800};
  font-weight: 700;
`;
const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray600};
`;
const LeftTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const RightTitle = styled.div`
  padding: 12px;
  position: relative;
`;
const Content = styled.div`
  min-height: 300px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray800};
`;
const LikeContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: ${Color.gray500};
  align-self: center;
`;
const CommentContainer = styled.div`
  padding-top: 32px;
  border-top: 1px solid ${Color.gray200};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const CommentTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.gray800};
`;
const CommentCount = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.primary500};
`;
const NoComment = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${Color.gray400};
  text-align: center;
`;
const CommentWrapper = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${Color.gray600};
`;
const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const EditModal = styled.div`
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 121px;
  position: absolute;
  right: 0;
`;
const ModalText = styled.div`
  width: calc(100% - 16px);
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${Color.gray500};

  &:hover {
    background-color: ${Color.gray};
    border-radius: 8px;
    cursor: pointer;
  }
`;
