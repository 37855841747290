import silverClient from '../../_common/client/silver-client';
import { ListResponse, PageResponse } from '../../_common/domain/response';
import qs from 'qs';
import { User } from '../../user/domain/user';
import { USER_TYPE } from '../domain/admin-report';
import { Category } from '../../coachsignin/domain/category';
import { UserWithSettleInfo } from '../../user/domain/user-with-settleinfo';
import { SETTLE_STATUS } from '../../coach/domain/coach';
import { AdminSignup } from '../domain/admin-signup';

export const findLearnerPage = async (
  page: number,
  size: number,
  search: string | null,
): Promise<PageResponse<User> | null> => {
  return findUsersPage(page, size, 'LEARNER', search);
};

export const findTeacherPage = async (
  page: number,
  size: number,
  search: string | null,
): Promise<PageResponse<UserWithSettleInfo> | null> => {
  const params = qs.stringify({ page: page, size: size, searchKeyword: search });
  return silverClient.get('/v1/admin/users/teachers?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<UserWithSettleInfo>;
      return pageResponse;
    },
    () => {
      console.log('findTeacherPage error');
      return null;
    },
  );
};

export const findAdminPage = async (
  page: number,
  size: number,
  search: string | null,
): Promise<PageResponse<User> | null> => {
  return findUsersPage(page, size, 'ADMIN', search);
};

export const findUsersPage = async (
  page: number,
  size: number,
  type: USER_TYPE,
  search: string | null,
): Promise<PageResponse<User> | null> => {
  const params = qs.stringify({ page: page, size: size, type: type, searchKeyword: search });
  return silverClient.get('/v1/admin/users?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<User>;
      return pageResponse;
    },
    () => {
      console.log('findUsersPage error');
      return null;
    },
  );
};

export const findCategory = async (userIdx: number): Promise<Array<Category> | null> => {
  return silverClient.get('/v1/admin/categories?userIdx=' + userIdx).then(
    (response) => {
      const listResponse = response as ListResponse<Category>;
      return listResponse?.contents;
    },
    () => {
      console.log('findCategory error');
      return null;
    },
  );
};

export const terminateUser = async (userIdx: number): Promise<boolean> => {
  return silverClient.post('/v1/admin/users/terminate?userIdx=' + userIdx).then(
    () => {
      return true;
    },
    () => {
      console.log('terminateUser error');
      return false;
    },
  );
};

export const deleteUser = async (userIdx: number): Promise<boolean> => {
  return silverClient.delete('/v1/admin/users?userIdx=' + userIdx).then(
    () => {
      return true;
    },
    () => {
      console.log('deleteUser error');
      return false;
    },
  );
};

export const updateSettleStatus = async (
  userIdx: number,
  status: SETTLE_STATUS,
): Promise<boolean> => {
  const params = qs.stringify({ userIdx: userIdx, status: status });
  return silverClient.post('/v1/admin/users/teachers/settle-status?' + params).then(
    () => {
      return true;
    },
    () => {
      console.log('updateSettleStatus error');
      return false;
    },
  );
};

export const signUpAdmin = async (request: AdminSignup): Promise<boolean> => {
  return silverClient.post('/v1/admin/users', request).then(
    () => {
      return true;
    },
    () => {
      console.log('signUpAdmin error');
      return false;
    },
  );
};
