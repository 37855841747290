import { AxiosError } from 'axios';
import { Cookies } from 'react-cookie';
import SilverClient from '../silver-client';

const resourceClientErrorHandler = async (error: AxiosError) => {
  handle4xxError(error);
  handle5xxError(error);
  handleUnknownError(error);
  return Promise.reject(error);
};

const handle4xxError = (error: AxiosError) => {
  const statusCode = error.response?.status ?? 0;
  if (statusCode < 400 || statusCode >= 500) {
    return;
  }
  console.warn(`${statusCode} error:: ${error}`);
  switch (statusCode) {
    case 401:
      SilverClient.logout();
      alert('로그인 정보가 만료되었습니다. 다시 로그인해 주세요.');
      window.location.href = '/login?redirect=' + window.location.pathname + window.location.search;
      break;
    case 403:
      SilverClient.logout();
      alert('권한이 없습니다.');
      window.location.href = '/login?redirect=' + window.location.pathname + window.location.search;
      break;
    case 404:
      window.location.href = '/';
      break;
    default:
      break;
  }
};

const handle5xxError = (error: AxiosError) => {
  const statusCode = error.response?.status ?? 0;
  if (statusCode >= 500) {
    alert('일시적인 에러입니다. 잠시 후 다시 시도해 주세요.');
    console.warn(`${statusCode} error:: ${error}`);
  }
};

const handleUnknownError = (error: AxiosError) => {
  const statusCode = error.response?.status ?? 0;
  console.error(`${statusCode} unknown error:: ${error}`);
};

export default resourceClientErrorHandler;
