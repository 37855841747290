import React, { useEffect, useState } from 'react';
import { ConfigProvider, Input, Tabs, TabsProps } from 'antd';
import { SearchProps } from 'antd/es/input';
import Color from '../../_common/view/style/Color';
import Parking from '../../_common/view/component/parking';
import TotalSearch from './component/TotalSearch';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useRecoilState } from 'recoil';
import { searchKeyword } from '../state/SearchState';
import SearchTab from './component/SearchTab';

const { Search } = Input;

const SearchPage = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '전체',
      children: <TotalSearch />,
    },
    {
      key: '2',
      label: '강좌',
      children: <SearchTab searchIdx={1} />,
    },
    {
      key: '3',
      label: '프로그램/행사',
      children: <SearchTab searchIdx={2} />,
    },
    {
      key: '4',
      label: '소모임',
      children: <SearchTab searchIdx={3} />,
    },
    {
      key: '5',
      label: '커뮤니티',
      children: <SearchTab searchIdx={4} />,
    },
  ];
  //Tab바 크기
  const isMobile = useMediaQuery({
    query: '(max-width:400px)',
  });

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setKeyword(value);
  };

  const onChange = (e: any) => {
    setKeyword(e.target.value);
  };

  const [keyword, setKeyword] = useRecoilState(searchKeyword);
  useEffect(() => {
    setKeyword(keyword);
  }, [keyword]);

  return (
    <Container>
      <SearchBar>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              controlHeight: 48,
              colorBgContainer: Color.gray,
              borderRadius: 8,
              colorBorder: Color.gray,
              colorTextPlaceholder: Color.gray500,
              fontSize: 16,
            },
          }}
        >
          <Search
            placeholder="검색어를 입력하세요."
            onSearch={onSearch}
            onChange={(e) => onChange(e)}
            value={keyword}
            style={{
              width: 320,
            }}
          />
        </ConfigProvider>
      </SearchBar>
      <SearchTabConTainer>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemActiveColor: Color.primary500,
                itemHoverColor: Color.primary500,
                itemSelectedColor: Color.primary500,
                inkBarColor: Color.primary500,
                titleFontSize: 16,
                horizontalItemPadding: `${isMobile ? '8px' : '16px'}`,
                horizontalItemGutter: 0,
                horizontalMargin: '0 0 64px 0',
              },
            },
          }}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </ConfigProvider>
      </SearchTabConTainer>
    </Container>
  );
};

export default SearchPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 95px;

  @media screen and (max-width: 1279px) {
    padding: 56px max(2%, 16px);
  }
  @media screen and (max-width: 1023px) {
    padding: 56px 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 56px 16px;
  }
`;
const SearchBar = styled.div`
  margin-bottom: 32px;
`;
const SearchTabConTainer = styled.div`
  width: 100%;
  margin-bottom: 64px;
`;
