import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import { Button, ConfigProvider, Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { cancelCourse } from '../../repository/mypage-repository';
import { useNavigate } from 'react-router-dom';

export default function ApplyPreview(props: any) {
  const isPcTablet = useMediaQuery({
    query: '(min-width:768px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });
  const cancelText = {
    btn: '신청 취소',
    title: '정말로 신청을 취소하시나요?',
    detail:
      '신청 취소 후에는 되돌릴 수 없습니다.\n유료 배움의 경우 환불 규정에 따라 환불이 진행됩니다.',
  };
  const refundText = {
    btn: '환불 신청',
    title: '정말로 환불하시나요?',
    detail:
      '환불 신청 후에는 되돌릴 수 없습니다.\n유료 배움의 경우 환불 규정에 따라 환불이 진행됩니다.',
  };

  const getLabelComponent = () => {
    if (props.currentTimeInRecruitmentPeriod && props.currentTimeInOperatingPeriod) {
      return (
        <LabelContainer>
          <Label type={1}>모집 중</Label>
          <Label type={2}>진행 중</Label>
        </LabelContainer>
      );
    } else if (props.currentTimeInRecruitmentPeriod) {
      return <Label type={1}>모집 중</Label>;
    } else if (props.currentTimeInOperatingPeriod) {
      return <Label type={2}>진행 중</Label>;
    } else {
      return <Label type={3}>배움 종료</Label>;
    }
  };

  // 신청 취소 가능 - 1, 환불 가능 - 2, 모두 불가 - 3
  const [type, setType] = useState<number>(-1);
  const checkType = () => {
    if (props.currentTimeInRecruitmentPeriod) setType(1);
    else if (props.currentTimeInOperatingPeriod) setType(2);
    else setType(3);
  };

  useEffect(() => {
    checkType();
  }, []);

  const [isOpened, setIsOpened] = useState(false);

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  const onOk = async (event: any) => {
    event.stopPropagation();
    const success = await cancelCourse(props.idx, type);
    setIsOpened(false);
    console.log(props.idx);

    if (success) {
      window.alert(`성공적으로 ${type === 1 ? '신청 취소' : '환불 취소'} 처리되었습니다.`);
      window.location.reload();
    }
  };

  const navigate = useNavigate();
  const gotoLecture = () => {
    navigate(`/courses/${props.idx}`);
  };

  return (
    <>
      {isPcTablet && (
        <Container>
          <Left onClick={gotoLecture}>
            {getLabelComponent()}
            <Title>{props.title}</Title>
            <Detail>
              {props.teacher.name} |
              <span style={{ color: Color.primary500 }}>
                {' '}
                {props?.price > 0 ? `${props?.price.toLocaleString()}원` : '무료'}
              </span>
            </Detail>
            <Detail>
              {convertDateFormat(props?.operatingPeriod.start)} ~{' '}
              {convertDateFormat(props?.operatingPeriod.end)} | {props?.operatingDayOfWeekInfo} |{' '}
              {props?.operatingTimeInfo}
            </Detail>
          </Left>
          <Right>
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 42,
                  fontSize: 14,
                  colorPrimary: Color.primary500,
                  colorText: Color.primary500,
                  lineWidth: 2,
                  borderRadius: 8,
                  colorBorder: Color.primary500,
                  colorTextDisabled: Color.primary100,
                  colorBgContainerDisabled: 'white',
                },
                components: {
                  Button: {
                    borderColorDisabled: Color.primary100,
                  },
                },
              }}
            >
              <Button
                onClick={() => setIsOpened(true)}
                disabled={type === 3}
                style={{ width: 97, marginLeft: 36 }}
              >
                {type === 1 ? cancelText.btn : refundText.btn}
              </Button>
            </ConfigProvider>
          </Right>
        </Container>
      )}
      {isMobile && (
        <Container>
          {getLabelComponent()}
          <Title onClick={gotoLecture}>{props.title}</Title>
          <Detail onClick={gotoLecture}>2023.10.26 ~ 2023.11.30(화) | 매주 화요일</Detail>
          <Detail onClick={gotoLecture}>오후 2시 ~ 오후 4시</Detail>
          <ConfigProvider
            theme={{
              token: {
                controlHeight: 42,
                fontSize: 14,
                colorPrimary: Color.primary500,
                colorText: Color.primary500,
                lineWidth: 2,
                borderRadius: 8,
                colorBorder: Color.primary500,
                colorTextDisabled: Color.primary100,
                colorBgContainerDisabled: 'white',
              },
              components: {
                Button: {
                  borderColorDisabled: Color.primary100,
                },
              },
            }}
          >
            <Button
              onClick={() => setIsOpened(true)}
              disabled={type === 3}
              style={{ width: '100%', marginTop: 20 }}
            >
              {type === 1 ? cancelText.btn : refundText.btn}
            </Button>
          </ConfigProvider>
        </Container>
      )}
      <ConfigProvider
        theme={{
          token: {
            paddingLG: 16,
          },
        }}
      >
        <Modal
          centered={true}
          open={isOpened}
          onCancel={() => setIsOpened(false)}
          onOk={onOk}
          width={330}
          style={{ alignContent: 'center' }}
          maskClosable={true}
          closable={false}
          footer={
            <ModalBtnContainer>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    borderRadius: 8,
                    colorText: Color.primary500,
                    fontSize: 13,
                    colorBorder: 'white',
                    controlHeight: 32,
                  },
                  components: {
                    Button: {
                      fontWeight: 500,
                    },
                  },
                }}
              >
                <Button style={{ width: '100%' }} onClick={() => setIsOpened(false)}>
                  돌아가기
                </Button>
              </ConfigProvider>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    borderRadius: 8,
                    colorText: 'white',
                    fontSize: 13,
                    colorBorder: Color.primary500,
                    colorBgContainer: Color.primary500,
                    controlHeight: 32,
                  },
                  components: {
                    Button: {
                      fontWeight: 500,
                    },
                  },
                }}
              >
                <Button onClick={onOk} style={{ width: '100%', marginLeft: 0 }}>
                  {type === 1 ? cancelText.btn : refundText.btn}
                </Button>
              </ConfigProvider>
            </ModalBtnContainer>
          }
        >
          <ModalTitle>{type === 1 ? cancelText.title : refundText.title}</ModalTitle>
          <ModalDetail>{type === 1 ? cancelText.detail : refundText.detail}</ModalDetail>
        </Modal>
      </ConfigProvider>
    </>
  );
}

const Container = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${Color.gray200};
  border-radius: 8px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    gap: 4px;
  }
`;
const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
`;
const Label = styled.div<{ type: number }>`
  /* 모집 중 - 1, 진행중 - 2, 배움 종료 - 3 */
  border-radius: 16px;
  font-size: 13px;
  padding: 4px 12px;
  display: inline-flex;
  width: fit-content;
  color: ${(props) => {
    if (props.type === 1) return Color.blue500;
    else if (props.type === 2) return Color.primary500;
    else if (props.type === 3) return 'white';
  }};
  background-color: ${(props) => {
    if (props.type === 1) return Color.blue;
    else if (props.type === 2) return Color.primary;
    else if (props.type === 3) return Color.gray600;
  }};
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid ${Color.gray600};
  height: 100%;
`;
const Title = styled.div`
  font-size: 16px;
  color: ${Color.gray800};
  font-weight: 700;
`;
const Detail = styled.div`
  color: ${Color.gray600};
  font-weight: 500;
  font-size: 13px;
`;
const Btn = styled.div<{ type: number }>`
  border-radius: 8px;
  border: 2px solid;
  width: 112px;
  height: 42px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.type === 3 ? Color.primary100 : Color.primary500)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 36px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
`;
const ModalBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 8px;
`;
const ModalTitle = styled(Title)`
  text-align: center;
  margin-bottom: 16px;
`;
const ModalDetail = styled(Detail)`
  text-align: center;
  margin-bottom: 16px;
  white-space: pre-line;
`;
