import React from 'react';
import styled from 'styled-components';
import { ConfigProvider, Radio, Space } from 'antd';
import Color from '../../../_common/view/style/Color';
import { useRecoilState } from 'recoil';
import {
  periodDayOfWeekState,
  periodEndDateState,
  periodEndTimeState,
  periodStartDateState,
  periodStartTimeState,
  typeState,
} from '../../state/CourseSignInState';
import dayjs from 'dayjs';

const Type = () => {
  const [startDate, setStartDate] = useRecoilState(periodStartDateState);
  const [endDate, setEndDate] = useRecoilState(periodEndDateState);
  const [startTime, setStartTime] = useRecoilState(periodStartTimeState);
  const [endTime, setEndTime] = useRecoilState(periodEndTimeState);
  const [dayOfWeek, setDayOfWeek] = useRecoilState(periodDayOfWeekState);

  const [typeOption, setTypeOption] = useRecoilState(typeState);
  const handleTypeChange = (value: any) => {
    setStartDate(undefined);
    setEndDate(undefined);
    setStartTime(undefined);
    setEndTime(undefined);
    setDayOfWeek(undefined);
    setTypeOption(value.target.value);
  };

  return (
    <Container>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: Color.primary500,
          },
        }}
      >
        <Radio.Group onChange={handleTypeChange} value={typeOption} style={{ width: '100%' }}>
          <Space direction="vertical" style={{ rowGap: 40, width: '100%' }}>
            <Radio value={1} style={{ width: '100%' }}>
              <RadioText>
                <Name>원데이 클래스</Name>
                <Description>하루만 진행되는 배움입니다.</Description>
              </RadioText>
            </Radio>
            <Radio value={2}>
              <RadioText>
                <Name>정기 모임</Name>
                <Description>
                  일정 기간 동안 특정 요일과 시간대에 정기적으로 진행되는 배움입니다.
                </Description>
              </RadioText>
            </Radio>
          </Space>
        </Radio.Group>
      </ConfigProvider>
    </Container>
  );
};

export default Type;

const Container = styled.div`
  @media screen and (max-width: 330px) {
    margin-bottom: 20px;
  }
`;
const RadioText = styled.div`
  position: relative;
  width: 60vw;
`;
const Description = styled.div`
  color: ${Color.gray600};
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  width: 100%;

  @media screen and (max-width: 360px) {
    width: auto;
    display: inline-block;
  }
`;
const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray800};
`;
