import React, { useEffect, useState } from 'react';
import { ConfigProvider, Select } from 'antd';
import { useRecoilState } from 'recoil'; // Update the path accordingly
import { courseCategoryState, courseSelectedCategoriesState } from '../../state/CourseState';
import Color from '../../../_common/view/style/Color';
import { useMediaQuery } from 'react-responsive';
import { Category } from '../../../coachsignin/domain/category';
import { Option } from '../../domain/course';
import { findCategory } from '../../repository/course-repository';

const Dropdownchip = () => {
  const handleChange = (value: any) => {
    // if (value.length > 3) {
    //   value = value.slice(0, 3);
    // }
    setSelectedCategories(value);
  };

  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });
  const pcStyle = {
    width: 336,
  };
  const tabletMobileStyle = {
    width: '100%',
  };

  const [category, setCategory] = useRecoilState(courseCategoryState);
  const getCategory = async () => {
    const categories = await findCategory();
    setCategory(categories as Array<Category>);
    convertOptionFormat(categories as Array<Category>);
  };

  const [selectedCategories, setSelectedCategories] = useRecoilState(courseSelectedCategoriesState); // 서버에 보낼 선택된 관심사
  const [options, setOptions] = useState<Array<Option>>();
  const convertOptionFormat = (category: Array<Category>) => {
    const options = category.map((item) => ({ value: item.idx, label: item.title }));
    setOptions(options);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Color.primary500,
          borderRadius: 8,
          controlHeight: 48,
          fontSize: 16,
        },
      }}
    >
      <Select
        mode="multiple"
        style={isTabletMobile ? tabletMobileStyle : pcStyle}
        placeholder="관심사를 선택해주세요."
        onChange={handleChange}
        options={options}
      />
    </ConfigProvider>
  );
};

export default Dropdownchip;
