import React, { useEffect, useState } from 'react';
import {
  CloseOutlined,
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Flex, Input, Layout, Pagination, theme } from 'antd';
import Styled, { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import Color from '../../../../_common/view/style/Color';
import SideBar from '../SideBar';
import { courseListState } from '../../../../course/state/CourseState';
import { findCoachCoursePage } from '../../../../course/repository/course-repository';
import { Course } from '../../../../course/domain/course';
import { deleteCourse } from '../../../repository/coach-repository';
import { render } from 'react-dom';

const { Content, Sider } = Layout;

const CourseControl: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [course, setCourse] = useRecoilState(courseListState);
  const getCoursePages = async () => {
    const coursePages = await findCoachCoursePage(pageNumber - 1, 12);
    setCourse(coursePages?.contents as Array<Course>);
    setTotalElements(coursePages?.totalElements as number);
  };

  const onDelete = async (id: number) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      const deleteco = await deleteCourse(id);
      setCourse(course ? course.filter((co) => co.idx !== id) : null);
      if (deleteco === 'OK') alert('삭제되었습니다.');
    }
  };

  useEffect(() => {
    getCoursePages();
  }, []);

  console.log(
    course?.map((item: any, index: number) => (
      <div>
        <div>{item.title}</div>
        <div>{item.teacher.name}</div>
        <div>{item.price.toLocaleString()}</div>
        {/*<div>{item.OperatingPeriod.start}</div>*/}
        <div>{item.edate}</div>
      </div>
    )),
  );

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const pcInput: React.CSSProperties = {
    width: '30%',
    height: '40px',
  };

  const mobileInput: React.CSSProperties = {
    width: '100%',
    height: '40px',
  };

  const navigateCreate = () => {
    navigate('/coachsignin');
  };

  const navigate = useNavigate();
  const MoveToAddCourse = () => {
    navigate('/coachsignin');
  };

  const edit = (idx: number) => {
    navigate('/courses/' + idx + '/edit');
  };

  const searchStudent = (idx: number, title: string) => {
    navigate('/studentdetail/' + idx, { state: title });
  };

  return (
    <>
      {isPc && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <Sider style={{ backgroundColor: 'white' }} width={150}>
                  <SideBar />
                </Sider>
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>배움 관리</span>
                    <Button style={HeaderButton} onClick={MoveToAddCourse}>
                      배움 개설
                    </Button>
                  </div>
                  <div>
                    <div>
                      {course ? (
                        course.map((item: any, index: number) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              border: `1px solid ${Color.gray200}`,
                              padding: '24px 2px 24px 24px',
                              marginBottom: '2%',
                              borderRadius: '8px',
                            }}
                          >
                            <div
                              style={{ width: '90%', cursor: 'pointer' }}
                              onClick={() => searchStudent(item.idx, item.title)}
                            >
                              <a>{chipfun(item.status)}</a>
                              <h2>{item.title}</h2>
                              <p>
                                <Colorspan>{item.teacher.name}</Colorspan> |
                                <Spantitle>
                                  {' '}
                                  {item.price !== 0 ? item.price + '원' : '무료'}
                                </Spantitle>
                              </p>
                              <p>
                                <Colorspan>
                                  {convertDateFormat(item?.operatingPeriod?.start)} ~{' '}
                                  {convertDateFormat(item?.operatingPeriod?.end)} |{' '}
                                  {item?.operatingDayOfWeekInfo} | {item?.operatingTimeInfo}
                                </Colorspan>
                              </p>
                            </div>
                            {item.status === 'ACTIVE' ? (
                              <div
                                style={{
                                  justifyContent: 'end',
                                  padding: '4% 1% 0 3%',
                                  borderLeft: '1px solid black',
                                  width: '180px',
                                }}
                              >
                                <Button
                                  style={MainButton}
                                  onClick={() => searchStudent(item.idx, item.title)}
                                >
                                  수강생 확인
                                </Button>
                                <Button style={MainButton} onClick={() => edit(item.idx)}>
                                  강좌 수정
                                </Button>
                                <Button
                                  style={RedButton}
                                  onClick={() => onDelete(Number(item.idx))}
                                >
                                  강좌 삭제
                                </Button>
                              </div>
                            ) : item.status === 'CLOSE' ? (
                              <div
                                style={{
                                  justifyContent: 'end',
                                  padding: '4% 1% 0 3%',
                                  borderLeft: '1px solid black',
                                  width: '180px',
                                }}
                              >
                                <Button style={MainButton} disabled>
                                  수강생 확인
                                </Button>
                                <Button style={MainButton} disabled>
                                  강좌 수정
                                </Button>
                                <Button disabled style={RedButton}>
                                  강좌 삭제
                                </Button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  justifyContent: 'end',
                                  padding: '4% 1% 0 3%',
                                  borderLeft: '1px solid black',
                                  width: '180px',
                                }}
                              >
                                <Button
                                  style={MainButton}
                                  onClick={() => searchStudent(item.idx, item.title)}
                                >
                                  수강생 확인
                                </Button>
                                <Button style={MainButton} onClick={() => edit(item.idx)}>
                                  강좌 수정
                                </Button>
                                <Button
                                  style={RedButton}
                                  onClick={() => onDelete(Number(item.idx))}
                                >
                                  강좌 삭제
                                </Button>
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div>신청한 배움이 없습니다.</div>
                      )}
                    </div>
                    <div
                      className={'page'}
                      style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                    >
                      <Pagination
                        defaultCurrent={1}
                        onChange={setPageNumber}
                        pageSize={12}
                        total={totalElements}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isTablet && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>배움 관리</span>
                    <Button onClick={navigateCreate} style={HeaderButton}>
                      배움 개설
                    </Button>
                  </div>
                  <div>
                    <div>
                      {course ? (
                        course.map((item: any, index: number) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              border: `1px solid ${Color.gray200}`,
                              padding: '24px 2px 24px 24px',
                              marginBottom: '2%',
                              borderRadius: '8px',
                            }}
                          >
                            <div
                              style={{ width: '90%', cursor: 'pointer' }}
                              onClick={() => searchStudent(item.idx, item.title)}
                            >
                              <a>{chipfun(item?.status)}</a>
                              <h2>{item.title}</h2>
                              <p>
                                <Colorspan>{item.teacher.name}</Colorspan> |
                                <Spantitle>
                                  {' '}
                                  {item.price !== 0 ? item.price + '원' : '무료'}
                                </Spantitle>
                              </p>
                              <p>
                                <Colorspan>
                                  {convertDateFormat(item?.operatingPeriod?.start)} ~{' '}
                                  {convertDateFormat(item?.operatingPeriod?.end)} |{' '}
                                  {item?.operatingDayOfWeekInfo} | {item?.operatingTimeInfo}
                                </Colorspan>
                              </p>
                            </div>
                            {item.status === 'ACTIVE' ? (
                              <div
                                style={{
                                  justifyContent: 'end',
                                  padding: '4% 1% 0 3%',
                                  borderLeft: '1px solid black',
                                  width: '180px',
                                }}
                              >
                                <Button
                                  style={MainButton}
                                  onClick={() => searchStudent(item.idx, item.title)}
                                >
                                  수강생 확인
                                </Button>
                                <Button style={MainButton} onClick={() => edit(item.idx)}>
                                  강좌 수정
                                </Button>
                                <Button
                                  style={RedButton}
                                  onClick={() => onDelete(Number(item.idx))}
                                >
                                  강좌 삭제
                                </Button>
                              </div>
                            ) : item.status === 'CLOSE' ? (
                              <div
                                style={{
                                  justifyContent: 'end',
                                  padding: '4% 1% 0 3%',
                                  borderLeft: '1px solid black',
                                  width: '180px',
                                }}
                              >
                                <Button style={MainButton} disabled>
                                  수강생 확인
                                </Button>
                                <Button style={MainButton} disabled>
                                  강좌 수정
                                </Button>
                                <Button style={RedButton} disabled>
                                  강좌 삭제
                                </Button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  justifyContent: 'end',
                                  padding: '4% 1% 0 3%',
                                  borderLeft: '1px solid black',
                                  width: '180px',
                                }}
                              >
                                <Button
                                  style={MainButton}
                                  onClick={() => searchStudent(item.idx, item.title)}
                                >
                                  수강생 확인
                                </Button>
                                <Button style={MainButton} onClick={() => edit(item.idx)}>
                                  강좌 수정
                                </Button>
                                <Button
                                  style={RedButton}
                                  onClick={() => onDelete(Number(item.idx))}
                                >
                                  강좌 삭제
                                </Button>
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div>신청한 배움이 없습니다.</div>
                      )}
                    </div>
                    <div
                      className={'page'}
                      style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                    >
                      <Pagination
                        defaultCurrent={1}
                        onChange={setPageNumber}
                        pageSize={12}
                        total={totalElements}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isMobile && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>배움 관리</span>
                    <Button onClick={navigateCreate} style={HeaderButton}>
                      배움 개설
                    </Button>
                  </div>
                  <div>
                    <div>
                      {course ? (
                        course.map((item: any, index: number) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              border: `1px solid ${Color.gray200}`,
                              padding: '24px 2px 24px 24px',
                              marginBottom: '2%',
                              borderRadius: '8px',
                            }}
                          >
                            <div style={{ width: '90%' }}>
                              <a>{chipfun(item?.status)}</a>
                              <h2>{item.title}</h2>
                              <p>
                                <Colorspan>{item.teacher.name}</Colorspan> |
                                <Spantitle>
                                  {' '}
                                  {item.price !== 0 ? item.price + '원' : '무료'}
                                </Spantitle>
                              </p>
                              <p>
                                <Colorspan>
                                  {convertDateFormat(item?.operatingPeriod?.start)} ~{' '}
                                  {convertDateFormat(item?.operatingPeriod?.end)}
                                </Colorspan>
                              </p>
                              <p>
                                <Colorspan>
                                  {item?.operatingDayOfWeekInfo} | {item?.operatingTimeInfo}
                                </Colorspan>
                              </p>

                              {item.status === 'ACTIVE' ? (
                                <>
                                  <Button
                                    style={MediaButton}
                                    onClick={() => searchStudent(item.idx, item.title)}
                                  >
                                    수강생 확인
                                  </Button>
                                  <Button style={MediaButton} onClick={() => edit(item.idx)}>
                                    강좌 수정
                                  </Button>
                                  <Button
                                    style={MediaRedButton}
                                    onClick={() => onDelete(Number(item.idx))}
                                  >
                                    강좌 삭제
                                  </Button>
                                </>
                              ) : item.status === 'CLOSE' ? (
                                <>
                                  <Button style={MediaButton} disabled>
                                    수강생 확인
                                  </Button>
                                  <Button style={MediaButton} disabled>
                                    강좌 수정
                                  </Button>
                                  <Button style={MediaRedButton} disabled>
                                    강좌 삭제
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    style={MediaButton}
                                    onClick={() => searchStudent(item.idx, item.title)}
                                  >
                                    수강생 확인
                                  </Button>
                                  <Button style={MediaButton} onClick={() => edit(item.idx)}>
                                    강좌 수정
                                  </Button>
                                  <Button
                                    style={MediaRedButton}
                                    onClick={() => onDelete(Number(item.idx))}
                                  >
                                    강좌 삭제
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>신청한 배움이 없습니다.</div>
                      )}
                    </div>
                    <div
                      className={'page'}
                      style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                    >
                      <Pagination
                        defaultCurrent={1}
                        onChange={setPageNumber}
                        pageSize={12}
                        total={totalElements}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
    </>
  );
};

export default CourseControl;

const Infotext = Styled.div`
    font-weight: 700;
    font-size: 14px;
    color: gray;
    margin-bottom: 5px;
`;

const HeaderButton: React.CSSProperties = {
  height: '40px',
  backgroundColor: Color.primary500,
  color: 'white',
  borderRadius: '8px',
  fontSize: '16px',
  marginBottom: '10px',
};

const Spantitle = styled.span`
  color: ${Color.primary500};
`;

const Colorspan = styled.span`
  color: ${Color.gray600};
  font-weight: 500;
`;

const MainButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  width: '125px',
  fontSize: '16px',
  color: Color.primary500,
  border: `2px solid ${Color.primary500}`,
  marginBottom: '10px',
};

const MediaButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  fontSize: '16px',
  color: Color.primary500,
  border: `2px solid ${Color.primary500}`,
  marginBottom: '10px',
  width: '100%',
};

const RedButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  width: '125px',
  fontSize: '16px',
  color: 'red',
  border: `2px solid red`,
  marginBottom: '10px',
};

const MediaRedButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  fontSize: '16px',
  color: 'red',
  border: `2px solid red`,
  marginBottom: '10px',
  width: '100%',
};

const finish = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      width: '60px',
      backgroundColor: Color.gray600,
    }}
  >
    종 료
  </div>
);

const ing = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.primary500,
      borderRadius: '16px',
      padding: '4px 16px',
      width: '70px',
      backgroundColor: Color.primary,
    }}
  >
    진행중
  </div>
);

const cancel = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      width: '60px',
      backgroundColor: 'red',
    }}
  >
    취 소
  </div>
);

const gather = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.blue500,
      borderRadius: '16px',
      padding: '4px 16px',
      width: '70px',
      backgroundColor: Color.blue,
    }}
  >
    모집중
  </div>
);
const chipfun = (props: any) => {
  switch (props) {
    case 'CLOSE':
      return finish;
    case 'ACTIVE':
      return ing;
    case 'PAUSE':
      return gather;
    case '모집중':
      return cancel;
    default:
      return <h4>엥?.?</h4>;
  }
};

export const convertDateFormat = (dateString: string | undefined) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const convertedDate = `${year}.${month}.${day}`;
  return convertedDate;
};
