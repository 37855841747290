import { Select, ConfigProvider } from 'antd';
import React, { useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import Color from '../../../_common/view/style/Color';
import { RecoilState, useRecoilState } from 'recoil';
import { BankState } from '../../state/CoachState';
import { Bank } from '../../domain/coach';
import { findBanks } from '../../repository/coach-repository';
import { SettleInfoState } from '../../state/CoachSignUp-State';
import { SettleInfo } from '../../domain/CoachSignup';

const CoachFilterDropDown = (props: {
  name:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  const pcStyle = {
    width: '64%',
  };
  const tabletMobileStyle = {
    width: '100%',
  };

  const [bank, setBank] = useRecoilState(BankState);
  const fetchBank = async () => {
    const banklist = await findBanks();
    setBank(banklist);
  };

  useEffect(() => {
    fetchBank();
  }, []);
  const [settleInfo, setSettleInfo] = useRecoilState(SettleInfoState);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Color.primary500,
          fontSize: 16,
          controlHeight: 48,
        },
      }}
    >
      <Select
        placeholder={props.name}
        style={isTabletMobile ? tabletMobileStyle : pcStyle}
        onChange={(value) => {
          setSettleInfo(value);
        }}
        options={
          bank ? bank.map((banks) => ({ value: banks.bankCode, label: banks.bankName })) : []
        }
      />
    </ConfigProvider>
  );
};

export default CoachFilterDropDown;
