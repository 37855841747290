import React, { useEffect } from 'react';
import styled from 'styled-components';
import Color from '../../../../_common/view/style/Color';
import { Descriptions } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { convertDateFormat } from '../CourseControl/CourseControl';

export default function StudentPreview(props: any) {
  const isPcTablet = useMediaQuery({
    query: '(min-width:768px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const navigate = useNavigate();
  const navigateToDetail = () => {
    navigate('/studentdetail/' + props.data.idx, { state: props.data.title });
  };

  const LabelText = () => {
    /* 모집 중 - 1, 진행중 - 2, 배움 종료 - 3 */
    if (props.type === 1) return '모집 중';
    else if (props.type === 2) return '진행 중';
    return '배움 종료';
  };

  useEffect(() => {
    console.log('idx:' + props.data.status);
  }, []);

  return (
    <>
      {isPcTablet && (
        <Container onClick={navigateToDetail}>
          <Left>
            {/*<Label type={props.data.status}>{LabelText()}</Label>*/}
            <div>{chipfun(props.data.status)}</div>
            <Title>{props.data.title}</Title>
            <Detail>
              {convertDateFormat(props.data?.operatingPeriod?.start)} ~{' '}
              {convertDateFormat(props.data?.operatingPeriod?.end)} |{' '}
              {props.data?.operatingDayOfWeekInfo} | {props.data?.operatingTimeInfo}
            </Detail>
          </Left>
          <Btn>수강생 확인</Btn>
        </Container>
      )}
      {isMobile && (
        <Container onClick={navigateToDetail}>
          {/*<Label type={props.data.state}>{LabelText()}</Label>*/}
          <p>{chipfun(props.data.status)}</p>
          <Title>{props.data.title}</Title>
          <Detail>2023.10.26 ~ 2023.11.30(화) | 매주 화요일</Detail>
          <Detail>오후 2시 ~ 오후 4시</Detail>
          <Btn onClick={navigateToDetail}>수강생 확인</Btn>
        </Container>
      )}
    </>
  );
}
const Container = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${Color.gray200};
  border-radius: 8px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    gap: 4px;
  }
`;

const ing = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.primary500,
      borderRadius: '16px',
      padding: '6px 16px',
      width: '90px',
      backgroundColor: Color.primary,
    }}
  >
    정산 예정
  </div>
);

const cancel = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '6px 16px',
      width: '90px',
      backgroundColor: 'red',
    }}
  >
    정산 취소
  </div>
);

const gather = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.blue500,
      borderRadius: '16px',
      padding: '6px 16px',
      width: '90px',
      backgroundColor: Color.blue,
    }}
  >
    정산 완료
  </div>
);
const chipfun = (props: any) => {
  switch (props) {
    case 'ACTIVE':
      return ing;
    case 'CLOSE':
      return cancel;
    case 'PAUSE':
      return gather;
    default:
      return <h4>엥?.?</h4>;
  }
};

// const Label = styled.div<{type:string}>`
//     /* 모집 중 - 1, 진행중 - 2, 배움 종료 - 3 */
//     border-radius: 16px;
//     font-size: 13px;
//     padding: 4px 12px;
//     display: inline-flex;
//     width: fit-content;
//     color: ${(props) => {
//         if (props === 'ACTIVE') return Color.blue500
//         else if (props === 'PAUSE') return Color.primary500
//         else if (props.type === 3) return 'white'
//     }};
//     background-color: ${(props) => {
//         if (props.type === 1) return Color.blue
//         else if (props.type === 2) return Color.primary
//         else if (props.type === 3) return Color.gray600
//     }};
// `
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Title = styled.div`
  font-size: 16px;
  color: ${Color.gray800};
  font-weight: 700;
`;
const Detail = styled.div`
  color: ${Color.gray600};
  font-weight: 500;
  font-size: 13px;
`;
const Btn = styled.div`
  border-radius: 8px;
  border: 2px solid ${Color.primary500};
  width: 112px;
  height: 42px;
  font-size: 14px;
  font-weight: 500;
  color: ${Color.primary500};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 8px;
  }
`;
