import React, { useEffect } from 'react';
import Color from '../../../_common/view/style/Color';
import { useRecoilState } from 'recoil';
import { ConfigProvider, Select } from 'antd';
import { courseCategoriesState, keywordState } from '../../state/CourseSignInState';
import { findCategories } from '../../repository/category-repository';
import { Simulate } from 'react-dom/test-utils';
import paste = Simulate.paste;

const Keyword = () => {
  const [categories, setCategories] = useRecoilState(courseCategoriesState);
  useEffect(() => {
    findCategories().then((res) => {
      setCategories(res ?? []);
    });
  }, [setCategories]);

  const [keywords, setKeywords] = useRecoilState(keywordState);
  const handleKeywordsChange = (newKeywords: string[]) => {
    console.log('newKeywords :: ' + newKeywords);
    if (newKeywords.length <= 3) setKeywords(newKeywords);
    else {
      alert('최대 3개까지 선택 가능합니다.');
      return;
      // 3개 이상 선택시 가장 먼저 선택한 키워드가 삭제됨
      // const set = new Set(newKeywords)
      // if(set.size !== newKeywords.length) setKeywords(newKeywords)
      // else{
      //     const updatedKeywords = [...keywords.slice(1), newKeywords[3]]
      //     console.log(updatedKeywords)
      //     setKeywords(updatedKeywords)
      // }
    }
  };

  const getCategoryName = (idx: string) => {
    console.log('idx::' + idx);
    const category = categories.find((category) => category.idx === parseInt(idx));
    return category?.title ?? '';
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Color.primary500,
          colorBorder: Color.gray500,
          fontSize: 16,
          borderRadius: 8,
          colorTextPlaceholder: Color.gray500,
        },
      }}
    >
      <Select
        placeholder="수업에 맞는 키워드를 선택해주세요. (최대 3개)"
        style={{ width: '100%', height: 48 }}
        status=""
        options={categories.map((category) => ({
          value: category.idx,
          label: category.title,
        }))}
        mode="multiple"
        onChange={handleKeywordsChange}
        value={keywords}
      />
    </ConfigProvider>
  );
};

export default Keyword;
