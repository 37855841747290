import React from 'react';
import '../style/Card.css';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Color from '../style/Color';
import { Course } from '../../../course/domain/course';
const baseUrl = 'https://assets.bae-f.com/';
const Card = (props: Course) => {
  const navigate = useNavigate();
  const navigateToDetail = () => {
    navigate('/courses/' + props.idx);
  };

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  return (
    <div className="main_product_container">
      <div className="main_product" style={{ cursor: 'pointer' }} onClick={navigateToDetail}>
        <div className="main_product_img_div">
          <img src={baseUrl + props?.thumbnailImage?.pcUrl} className="product_img" />
        </div>
        <Detail>
          <Description>
            {props.price === 0 ? '무료' : '유료'} | {props?.region?.region1Name ?? '전국'}{' '}
            {props?.region?.region2Name ?? ''}
          </Description>
          <Title>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to={'/courses/' + props.idx}>
              {props.title}
            </Link>
          </Title>
          <StyleDate>
            {convertDateFormat(props?.recruitmentPeriod?.start)} ~{' '}
            {convertDateFormat(props?.recruitmentPeriod?.end)}
          </StyleDate>
        </Detail>
      </div>
    </div>
  );
};

export default Card;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;

  @media screen and (max-width: 767px) {
    gap: 0;
  }
`;
const Title = styled.div`
  font-size: 16px;
  color: ${Color.gray800};
  font-weight: 700;
`;
const Description = styled.div`
  font-weight: 700;
  font-size: 13px;
  color: ${Color.gray600};
`;
const StyleDate = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${Color.gray600};
`;
