import { Button, ConfigProvider } from 'antd';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import Color from '../style/Color';
import styled from 'styled-components';
import silverClient from '../../client/silver-client';

const Appbar = () => {
  const isPcTablet = useMediaQuery({
    query: '(min-width:768px)',
  });

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToCourse = () => {
    const path = location.pathname;
    navigate('/courses');
    if (path === '/gathering') window.location.reload();
  };

  const navigateToProgram = () => {
    navigate('/program');
  };

  const navigateToGather = () => {
    navigate('/coach');
  };

  const navigateToCommunity = () => {
    navigate('/community');
  };

  const navigateToParking = () => {
    window.open('https://forms.gle/KpFriMMWNe7b75pj9', '_blank', 'noopener, noreferrer');
  };
  const navigateToGathering = () => {
    const path = location.pathname;
    navigate('/gathering');
    if (path === '/courses') window.location.reload();
  };

  const navigateToHome = () => {
    navigate('/');
  };

  const navigatetoInfo = () => {
    navigate('/coachsignin');
  };

  const navigatetocontrol = () => {
    navigate('/coursecontrol');
  };

  const user = silverClient.getUser();
  const isUser = user?.userType === 'LEARNER';
  const isCoach = user?.userType === 'TEACHER' || user?.userType === 'ADMIN';

  return (
    <>
      {isPcTablet && (
        <>
          {location.pathname === '/coach' ||
          location.pathname === '/coachsignup' ||
          location.pathname === '/coachmypage' ||
          location.pathname === '/coachinfo' ||
          location.pathname === '/coachsignin' ||
          location.pathname === '/coursecontrol' ||
          location.pathname === '/studentcontrol' ||
          location.pathname === '/coachpayment' ? (
            <Container>
              <div style={{ display: 'flex', alignContent: 'center' }}>
                {isCoach ? (
                  <>
                    <ConfigProvider
                      theme={{
                        token: {
                          fontSize: 16,
                          colorPrimary: Color.primary500,
                        },
                        components: {
                          Button: {
                            fontWeight: 700,
                            textHoverBg: Color.primary500,
                          },
                        },
                      }}
                    >
                      <Button type="text" onClick={navigatetoInfo}>
                        배움 개설
                      </Button>
                      <Button type="text" onClick={navigatetocontrol}>
                        배움 관리
                      </Button>
                      <Button type="text" onClick={navigateToParking}>
                        프로그램/행사 문의
                      </Button>
                    </ConfigProvider>
                  </>
                ) : null}
              </div>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    fontSize: 16,
                    controlHeight: 44,
                    lineWidth: 2,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                      fontWeight: 500,
                    },
                  },
                }}
              >
                <Button onClick={navigateToHome} type={'primary'} ghost shape={'round'}>
                  배프 홈
                </Button>
              </ConfigProvider>
            </Container>
          ) : (
            <Container>
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <Button
                  style={{ fontSize: '16px', fontWeight: '700' }}
                  type="text"
                  onClick={navigateToHome}
                >
                  홈
                </Button>
                <Button
                  style={{ fontSize: '16px', fontWeight: '700' }}
                  type="text"
                  onClick={navigateToCourse}
                >
                  강좌
                </Button>
                <Button
                  style={{ fontSize: '16px', fontWeight: '700' }}
                  type="text"
                  onClick={navigateToProgram}
                >
                  프로그램/행사
                </Button>
                <Button
                  style={{ fontSize: '16px', fontWeight: '700' }}
                  type="text"
                  onClick={navigateToGathering}
                >
                  소모임
                </Button>
                <Button
                  style={{ fontSize: '16px', fontWeight: '700' }}
                  type="text"
                  onClick={navigateToCommunity}
                >
                  커뮤니티
                </Button>
              </div>
              {isUser ? (
                <></>
              ) : (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: Color.primary500,
                      fontSize: 16,
                      controlHeight: 44,
                      lineWidth: 2,
                    },
                    components: {
                      Button: {
                        paddingInline: 24,
                        fontWeight: 500,
                      },
                    },
                  }}
                >
                  <Button onClick={navigateToGather} type={'primary'} ghost shape={'round'}>
                    배코치 센터
                  </Button>
                </ConfigProvider>
              )}
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default Appbar;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    margin: 16px 24px;
  }
`;
