const ManagementData = [
  {
    id: 1,
    payno: 'adsgsdf-sgdfasd',
    title: '피그마 기초 교육 - 어플리케이션과 홈페이지 클론 디자인 해보기',
    date: '2020-10-25 14:50',
    fee: '80,000',
    state: '결제완료',
  },
  {
    id: 2,
    payno: 'adsgsdf-sgdfasd',
    title: '피그마 기초 교육 - 어플리케이션과 홈페이지 클론 디자인 해보기',
    date: '2020-10-25 14:50',
    fee: '80,000',
    state: '결제실패',
  },
  {
    id: 3,
    payno: 'adsgsdf-sgdfasd',
    title: '피그마 기초 교육 - 어플리케이션과 홈페이지 클론 디자인 해보기',
    date: '2020-10-25 14:50',
    fee: '80,000',
    state: '환불예정',
  },
  {
    id: 4,
    payno: 'adsgsdf-sgdfasd',
    title: '피그마 기초 교육 - 어플리케이션과 홈페이지 클론 디자인 해보기',
    date: '2020-10-25 14:50',
    fee: '80,000',
    state: '환불완료',
  },
  {
    id: 5,
    payno: 'adsgsdf-sgdfasd',
    title: '피그마 기초 교육 - 어플리케이션과 홈페이지 클론 디자인 해보기',
    date: '2020-10-25 14:50',
    fee: '80,000',
    state: '환불완료',
  },
  {
    id: 6,
    payno: 'adsgsdf-sgdfasd',
    title: '피그마 기초 교육 - 어플리케이션과 홈페이지 클론 디자인 해보기',
    date: '2020-10-25 14:50',
    fee: '80,000',
    state: '환불완료',
  },
];

export default ManagementData;
