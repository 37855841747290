import React, { useEffect, useState } from 'react';
import AdminLayout from '../AdminLayout';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  Modal,
  Pagination,
  Space,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Color from '../../../../_common/view/style/Color';
import { SmallDashOutlined } from '@ant-design/icons';
import { Course } from '../../../../course/domain/course';
import {
  deleteCourseByAdmin,
  searchAdminCoursePage,
} from '../../../../course/repository/course-repository';
import { Student } from '../../../../coach/domain/coach';
import { findCourseStudent } from '../../../../coach/repository/coach-repository';

const { Search } = Input;
const AdminCourse = () => {
  const [contents, setContents] = useState<Array<Course>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getContents = async (search: string | undefined, page?: number) => {
    let contentsPage = await searchAdminCoursePage({
      page: (page ? page : pageNumber) - 1,
      size: 10,
      type: 'A',
      keyword: search,
    });

    setContents(contentsPage?.contents as Array<Course>);
    setTotalElements(contentsPage?.totalElements as number);
  };
  const [selectedItem, setSelectedItem] = useState<Course | null>(null);

  const [studentContents, setStudentContents] = useState<Array<Student>>([]);
  const [studentPageNumber, setStudentPageNumber] = useState<number>(1);
  const [studentTotalElements, setStudentTotalElements] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    getContents(searchValue);
  }, [pageNumber]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [visible, setVisible] = useState(true);

  const getStudentContents = async (idx: number) => {
    let contentsPage = await findCourseStudent(idx, studentPageNumber - 1, 20);

    setStudentContents(contentsPage?.contents as Array<Student>);
    setStudentTotalElements(contentsPage?.totalElements as number);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal2 = async (idx: number) => {
    setIsModal2Open(true);
    await getStudentContents(idx);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const isVisible = () => {
    setVisible(!visible);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '3%',
                }}
              >
                강좌 관리
              </div>
              <div style={{ display: 'flex', marginBottom: '3%' }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: Color.primary500,
                      controlHeight: 48,
                      colorBorder: Color.gray500,
                      colorTextPlaceholder: Color.gray500,
                      fontSize: 16,
                      colorBorderBg: Color.gray800,
                    },
                  }}
                >
                  <Search
                    placeholder="검색어 입력 후 Enter 키를 엽력해주세요."
                    onSearch={async (value, e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      await getContents(searchValue, 1);
                      setPageNumber(1);
                    }}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </ConfigProvider>
                {/*<Select*/}
                {/*    defaultValue="전체"*/}
                {/*    style={{ width: "28%",marginLeft:"2%",height:"48px",borderRadius:"8px",border:`1px solid ${Color.gray500}` }}*/}
                {/*    onChange={handleChange}*/}
                {/*    options={[*/}
                {/*        { label: '전체', value: 'total' },*/}
                {/*        { label: '모집 중', value: 'gather' },*/}
                {/*        { label: '진행 중', value: 'ing' },*/}
                {/*        { label: '종료', value: 'finish',  },*/}
                {/*        { label: '취소', value: 'cancel',  },*/}
                {/*    ]}*/}
                {/*/>*/}
              </div>
            </div>
          </Header>
          <Content>
            <div
              style={{
                padding: 16,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <div>
                <p style={{ fontSize: '16px', fontWeight: '600' }}>
                  전체 강좌 <span style={{ color: Color.primary500 }}>{totalElements}</span>개
                </p>
                <hr />
                <table
                  className="common-table"
                  style={{
                    borderCollapse: 'collapse',
                    borderSpacing: '0 10px',
                    width: '100%',
                    marginBottom: '30px',
                  }}
                >
                  <thead>
                    <tr>
                      <td className="common-table-header-column">번호</td>
                      <td className="common-table-header-column">강좌명</td>
                      <td className="common-table-header-column">코치명</td>
                      <td className="common-table-header-column">일시</td>
                      <td className="common-table-header-column">지역</td>
                      <td className="common-table-header-column">수강인원</td>
                      <td className="common-table-header-column">최대인원</td>
                      <td className="common-table-header-column">모집상태</td>
                      <td className="common-table-header-column">진행상태</td>
                      <td className="common-table-header-column">강의상태</td>
                      <td className="common-table-header-column"> </td>
                    </tr>
                  </thead>
                  <tbody>
                    {contents ? (
                      contents.map((item, index) => (
                        <tr
                          className="common-table-row"
                          style={{
                            cursor: 'pointer',
                            marginBottom: '5%',
                            borderBottom: `1px solid ${Color.gray200}`,
                          }}
                          key={index}
                        >
                          <td className="common-table-column" onClick={() => showModal2(item.idx)}>
                            {item.idx}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item.idx)}>
                            {item.title}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item.idx)}>
                            {item.teacher.name}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item.idx)}>
                            {item.operatingTimeInfo}{' '}
                            {item.operatingDayOfWeekInfo && item.operatingDayOfWeekInfo.length > 0
                              ? '(' + item.operatingDayOfWeekInfo + ')'
                              : ''}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item.idx)}>
                            {item.region.region1Name} {item.region.region2Name}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item.idx)}>
                            {item.learnerCount}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item.idx)}>
                            {item.maxCapacity}
                          </td>
                          <td
                            className={'common-table-column'}
                            onClick={() => showModal2(item.idx)}
                          >
                            {recruitmentText(item)}
                          </td>
                          <td
                            className={'common-table-column'}
                            onClick={() => showModal2(item.idx)}
                          >
                            {operatingText(item)}
                          </td>
                          <td
                            className={'common-table-column'}
                            onClick={() => showModal2(item.idx)}
                            style={{ color: Color.primary500 }}
                          >
                            {chipStatus(item)}
                          </td>
                          <td className="common-table-column">
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          type="text"
                                          onClick={showModal}
                                          disabled={selectedItem?.status === 'DELETED'}
                                        >
                                          삭제하기
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                ],
                              }}
                              trigger={['click']}
                            >
                              <a
                                onClick={(e) => {
                                  setSelectedItem(item);
                                  e.preventDefault();
                                }}
                              >
                                <Space>
                                  <SmallDashOutlined />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <Modal
                  open={isModalOpen}
                  mask={false}
                  closable={false}
                  width={400}
                  footer={
                    <div
                      style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                    >
                      <Button
                        type={'primary'}
                        style={{
                          color: 'white',
                          backgroundColor: Color.primary500,
                        }}
                        onClick={async () => {
                          if (selectedItem?.idx && (await deleteCourseByAdmin(selectedItem.idx))) {
                            alert('삭제 처리가 완료되었습니다.');
                            await getContents(searchValue);
                            handleCancel();
                            return;
                          }
                        }}
                      >
                        강좌 삭제
                      </Button>
                      <Button
                        type={'text'}
                        style={{ color: Color.primary500, fontWeight: '700', marginLeft: '0px' }}
                        onClick={handleCancel}
                      >
                        돌아가기
                      </Button>
                    </div>
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ fontWeight: '700' }}>
                      {shortText(selectedItem?.title, 15)} 강의를 정말로 삭제하나요?
                    </p>
                    <span>삭제 처리 후에는 되돌릴 수 없습니다.</span>
                    <span style={{ marginBottom: '5%' }}>
                      정산,환불 등 남은 이슈가 없는지 꼭 확인해주세요.
                    </span>
                  </div>
                </Modal>

                <Modal
                  mask={false}
                  open={isModal2Open}
                  closable={false}
                  maskClosable={true}
                  onCancel={handleCancel2}
                  cancelText={'닫기'}
                  cancelButtonProps={{
                    style: { color: Color.primary500, backgroundColor: 'white' },
                  }}
                  footer={
                    <Button
                      onClick={handleCancel2}
                      style={{ color: Color.primary500, backgroundColor: 'white' }}
                    >
                      닫기
                    </Button>
                  }
                >
                  {studentContents && studentContents.length > 0 ? (
                    <div>
                      <p style={{ fontSize: '16px', fontWeight: '600' }}>
                        수강자{' '}
                        <span style={{ color: Color.primary500 }}>{studentTotalElements}</span>명
                      </p>
                      <hr />
                      <table
                        className="common-table"
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '0 10px',
                          width: '100%',
                          marginBottom: '30px',
                        }}
                      >
                        <thead>
                          <tr>
                            <td className="common-table-header-column">번호</td>
                            <td className="common-table-header-column">이메일</td>
                            <td className="common-table-header-column">이름</td>
                            <td className="common-table-header-column">성별</td>
                            <td className="common-table-header-column">연락처</td>
                          </tr>
                        </thead>
                        <tbody>
                          {studentContents.map((item, index) => (
                            <tr
                              className="common-table-row"
                              style={{
                                marginBottom: '5%',
                                borderBottom: `1px solid ${Color.gray200}`,
                              }}
                              key={index}
                            >
                              <td className="common-table-column">{item.idx}</td>
                              <td className="common-table-column">{item.email}</td>
                              <td className="common-table-column">{item.name}</td>
                              <td className="common-table-column">
                                {item.gender === 'MALE' ? '남성' : '여성'}
                              </td>
                              <td className="common-table-column">{item.phoneNumber}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div
                        className={'page'}
                        style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                      >
                        <Pagination
                          current={studentPageNumber}
                          defaultCurrent={1}
                          onChange={setStudentPageNumber}
                          pageSize={20}
                          total={studentTotalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                      }}
                    >
                      <span>수강자가 없습니다.</span>
                    </div>
                  )}
                </Modal>

                <div
                  className={'page'}
                  style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                >
                  <Pagination
                    current={pageNumber}
                    defaultCurrent={1}
                    onChange={setPageNumber}
                    pageSize={10}
                    total={totalElements}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default AdminCourse;

const close = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.gray600,
    }}
  >
    종료
  </div>
);

const pause = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: 'red',
    }}
  >
    일시정지
  </div>
);

const deleted = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: 'red',
    }}
  >
    삭제
  </div>
);

const active = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.blue500,
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.blue,
    }}
  >
    정상
  </div>
);

const chipStatus = (course: Course) => {
  let isOverRecruitmentPeriod = course.recruitmentPeriod?.end < new Date().toISOString();
  let remain = (course?.maxCapacity ?? 0) - (course?.learnerCount ?? 0);

  switch (course.status) {
    case 'CLOSE':
      return close;
    case 'ACTIVE':
      return active;
    case 'PAUSE':
      return pause;
    case 'DELETED':
      return deleted;
    default:
      return <h4>UNKNOWN</h4>;
  }
};

const recruitmentText = (course: Course) => {
  let isOverRecruitmentPeriod = course.recruitmentPeriod?.end < new Date().toISOString();
  let remain = (course?.maxCapacity ?? 0) - (course?.learnerCount ?? 0);

  if (course.isCurrentTimeInRecruitmentPeriod && remain > 0) {
    return '모집중';
  } else {
    return isOverRecruitmentPeriod ? '모집마감' : '모집대기';
  }
};

const operatingText = (course: Course) => {
  let isOverOperatingPeriod = course.operatingPeriod?.end < new Date().toISOString();
  if (course.isCurrentTimeInOperatingPeriod) {
    return '수강진행중';
  } else {
    return isOverOperatingPeriod ? '수강종료' : '수강대기';
  }
};

const shortText = (text?: string, length?: number) => {
  if (!text) return '';
  let lengthValue = length ? length : 10;
  return text.length > lengthValue ? text.substr(0, lengthValue) + '...' : text;
};
