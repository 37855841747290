import React, { useEffect, useState } from 'react';
import {
  CloseOutlined,
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, ConfigProvider, Flex, Input, Layout, Pagination, theme } from 'antd';
import Styled, { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import Color from '../../../../_common/view/style/Color';
import StudentPreview from './StudentPreview';
import SideBar from '../SideBar';
import { courseListState } from '../../../../course/state/CourseState';
import { findCoachCoursePage } from '../../../../course/repository/course-repository';
import { Course } from '../../../../course/domain/course';

const { Content, Sider } = Layout;

const StudentControl: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const MoveToStudentDetail = (params: any) => {
    navigate('/studentdetail', { state: { id: params } });
  };

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [course, setCourse] = useRecoilState(courseListState);
  const getCoursePages = async () => {
    const coursePages = await findCoachCoursePage(pageNumber - 1, 12);
    setCourse(coursePages?.contents as Array<Course>);
    setTotalElements(coursePages?.totalElements as number);
  };
  useEffect(() => {
    getCoursePages();
  }, []);

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const pcInput: React.CSSProperties = {
    width: '30%',
    height: '40px',
  };

  const mobileInput: React.CSSProperties = {
    width: '100%',
    height: '40px',
  };

  return (
    <>
      {isPc && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <Sider style={{ backgroundColor: 'white' }} width={150}>
                  <SideBar />
                </Sider>
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <MainTitle>수강생 관리</MainTitle>
                  <PreviewContainer>
                    {course ? (
                      course.map((item: any, index: number) => <StudentPreview data={item} />)
                    ) : (
                      <div>신청한 배움이 없습니다.</div>
                    )}
                  </PreviewContainer>
                  <div
                    className={'page'}
                    style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                  >
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: Color.primary500,
                        },
                      }}
                    >
                      <div
                        className={'page'}
                        style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                      >
                        <Pagination
                          defaultCurrent={1}
                          onChange={setPageNumber}
                          pageSize={12}
                          total={totalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </ConfigProvider>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isTablet && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <MainTitle>수강생 관리</MainTitle>
                  <PreviewContainer>
                    {course ? (
                      course.map((item: any, index: number) => <StudentPreview data={item} />)
                    ) : (
                      <div>신청한 배움이 없습니다.</div>
                    )}
                  </PreviewContainer>
                  <div
                    className={'page'}
                    style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                  >
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: Color.primary500,
                        },
                      }}
                    >
                      <div
                        className={'page'}
                        style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                      >
                        <Pagination
                          defaultCurrent={1}
                          onChange={setPageNumber}
                          pageSize={12}
                          total={totalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </ConfigProvider>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isMobile && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <MainTitle>수강생 관리</MainTitle>
                  <div>
                    <PreviewContainer>
                      {course ? (
                        course.map((item: any, index: number) => <StudentPreview data={item} />)
                      ) : (
                        <div>신청한 배움이 없습니다.</div>
                      )}
                    </PreviewContainer>
                    <div
                      className={'page'}
                      style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                    >
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: Color.primary500,
                          },
                        }}
                      >
                        <div
                          className={'page'}
                          style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                        >
                          <Pagination
                            defaultCurrent={1}
                            onChange={setPageNumber}
                            pageSize={12}
                            total={totalElements}
                            showSizeChanger={false}
                          />
                        </div>
                      </ConfigProvider>
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
    </>
  );
};

export default StudentControl;

const MainTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${Color.gray800};
  margin-bottom: 16px;
`;
const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;
const CenterSpan = styled.span`
  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Spantitle = styled.span`
  color: ${Color.primary500};
`;

const Colorspan = styled.span`
  color: ${Color.gray600};
  font-weight: 500;
`;

const MainButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  fontSize: '16px',
  color: Color.primary500,
  fontWeight: '700',
  border: `2px solid ${Color.primary500}`,
  marginBottom: '10px',
};

const MediaMain: React.CSSProperties = {
  fontSize: '16px',
  textAlign: 'center',
  marginBottom: '20px',
  color: Color.primary500,
  border: `2px solid ${Color.primary500}`,
  width: '100%',
};

const RedButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  fontSize: '16px',
  color: 'red',
  fontWeight: '700',
  border: `2px solid red`,
  marginBottom: '10px',
};

const data = [
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 2,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 3,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 1,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 1,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 2,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 3,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 3,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 2,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 3,
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: 1,
  },
];
