import React, { useEffect } from 'react';
import Header from './header';
import Appbar from './Appbar';
import { Divider } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import styled from 'styled-components';
import SilverClient from '../../client/silver-client';

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (SilverClient.checkLoginTokenExpired()) {
      console.log('Token expired, logging out');
      alert('로그인 정보가 만료되었습니다. 다시 로그인해 주세요.');
      SilverClient.logout();
      navigate('/login');
    }
  }, [location, navigate]);

  return (
    <Container>
      <ContentWrapper>
        <Header />
        <Appbar />
        <Divider style={{ marginTop: '0', marginBottom: '0' }} />
        <Outlet />
      </ContentWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Container>
  );
};

export default MainLayout;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const ContentWrapper = styled.div`
  flex: 1;
`;
const FooterWrapper = styled.div`
  flex-shrink: 0;
`;
