import React, { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ConfigProvider, Input } from 'antd';
import Color from '../style/Color';
import DrawerMenu from './DrawerMenu';
import { useMediaQuery } from 'react-responsive';
import { SearchProps } from 'antd/es/input';
import styled from 'styled-components';
import silverClient from '../../client/silver-client';
import { useRecoilState } from 'recoil';
import { searchKeyword } from '../../../search/state/SearchState';

const { Search } = Input;

function Header() {
  const navigate = useNavigate();

  const user = silverClient.getUser();

  const navigateToLogin = () => {
    navigate('/login');
  };

  const navigateToSign = () => {
    navigate('/signup');
  };

  const navigateToCoachSign = () => {
    navigate('/coachsignup');
  };

  const navigateToMain = () => {
    navigate('/');
  };
  const navigateToMyPage = () => {
    navigate('/mypage');
  };
  const logout = () => {
    silverClient.logout();
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  const [keyword, setKeyword] = useRecoilState(searchKeyword);
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setKeyword(value);
    navigate('/search');
  };
  const onChangeSearchKeyword = (e: any) => {
    setKeyword(e.target.value);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/search') setKeyword('');
  }, [location.pathname]);

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  return (
    <>
      {isPc && (
        <Container>
          <Left>
            <Logo onClick={navigateToMain}>
              <img
                alt="logo"
                src={require('../img/Logo.png')}
                style={{ objectFit: 'fill', cursor: 'pointer' }}
                onClick={navigateToMain}
              />
            </Logo>
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    controlHeight: 48,
                    colorBgContainer: Color.gray,
                    borderRadius: 8,
                    colorBorder: Color.gray,
                    colorTextPlaceholder: Color.gray500,
                    fontSize: 16,
                  },
                }}
              >
                <Search
                  placeholder="검색어를 입력하세요."
                  onSearch={onSearch}
                  value={keyword}
                  onChange={(e) => onChangeSearchKeyword(e)}
                  style={{
                    width: 320,
                  }}
                />
              </ConfigProvider>
            </div>
          </Left>
          {user ? (
            <Right>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    colorBgContainer: Color.primary500,
                    colorText: 'white',
                    borderRadius: 8,
                    controlHeight: 42,
                    fontSize: 16,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                    },
                  },
                }}
              >
                <Button type="default" onClick={navigateToMyPage}>
                  마이페이지
                </Button>
              </ConfigProvider>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    colorBgContainer: 'white',
                    colorText: Color.primary500,
                    borderRadius: 8,
                    controlHeight: 42,
                    fontSize: 16,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                    },
                  },
                }}
              >
                <Button type="default" onClick={logout}>
                  로그아웃
                </Button>
              </ConfigProvider>
            </Right>
          ) : (
            <Right>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    colorBgContainer: Color.primary500,
                    colorText: 'white',
                    borderRadius: 8,
                    controlHeight: 42,
                    fontSize: 16,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                    },
                  },
                }}
              >
                <Button type="default" onClick={navigateToLogin}>
                  로그인
                </Button>
              </ConfigProvider>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    colorBgContainer: Color.primary500,
                    colorText: 'white',
                    borderRadius: 8,
                    controlHeight: 42,
                    fontSize: 16,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                    },
                  },
                }}
              >
                {window.location.pathname === '/coach' ? (
                  <Button type="default" onClick={navigateToCoachSign}>
                    배코치 회원가입
                  </Button>
                ) : (
                  <Button type="default" onClick={navigateToSign}>
                    회원가입
                  </Button>
                )}
              </ConfigProvider>
            </Right>
          )}
        </Container>
      )}
      {isTablet && (
        <Container>
          <Left>
            <Logo onClick={navigateToMain}>
              <img
                alt="logo"
                src={require('../img/Logo.png')}
                style={{ objectFit: 'fill', cursor: 'pointer' }}
                onClick={navigateToMain}
              />
            </Logo>
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    controlHeight: 48,
                    colorBgContainer: Color.gray,
                    borderRadius: 8,
                    colorBorder: Color.gray,
                    colorTextPlaceholder: Color.gray500,
                    fontSize: 16,
                  },
                }}
              >
                <Search
                  placeholder="검색어를 입력하세요."
                  onSearch={onSearch}
                  value={keyword}
                  onChange={(e) => onChangeSearchKeyword(e)}
                  style={{
                    width: 320,
                  }}
                />
              </ConfigProvider>
            </div>
          </Left>
          <DrawerMenu />
        </Container>
      )}
      {isMobile && (
        <Container>
          <Top>
            <Logo onClick={navigateToMain}>
              <img
                alt="logo"
                src={require('../img/Logo.png')}
                style={{ objectFit: 'fill', cursor: 'pointer' }}
                onClick={navigateToMain}
              />
            </Logo>
            <DrawerMenu />
          </Top>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: Color.primary500,
                controlHeight: 48,
                colorBgContainer: Color.gray,
                borderRadius: 8,
                colorBorder: Color.gray,
                colorTextPlaceholder: Color.gray500,
                fontSize: 16,
              },
            }}
          >
            <Search
              placeholder="검색어를 입력하세요."
              onSearch={onSearch}
              value={keyword}
              onChange={(e) => onChangeSearchKeyword(e)}
              style={{
                width: '100%',
              }}
            />
          </ConfigProvider>
        </Container>
      )}
    </>
  );
}

export default Header;

const Container = styled.div`
  margin: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    margin: 24px;
  }
  @media screen and (max-width: 767px) {
    margin: 24px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Logo = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-right: 10%;
`;
const Right = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;
