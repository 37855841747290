import axios from 'axios';
import resourceClientErrorHandler from './handler/silver-resource-client-error-handler';
import { Cookies } from 'react-cookie';

const defaultAxiosCreate = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_SILVER_BACKEND_API_URL,
    withCredentials: process.env.REACT_APP_SILVER_BACKEND_API_WITH_CREDENTIALS === 'true',
    timeout: parseInt(process.env.REACT_APP_SILVER_BACKEND_API_TIMEOUT ?? '5000'),
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const createAuthAxios = () => {
  const authInstance = defaultAxiosCreate();
  authInstance.interceptors.request.use(
    (config) => {
      if (config.headers) {
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        config.headers['Accept'] = 'application/json';
        config.withCredentials =
          process.env.REACT_APP_SILVER_BACKEND_API_WITH_CREDENTIALS === 'true';
      }
      return config;
    },
    (error) => {
      alert('인증 요청이 잘못되었습니다.');
      window.location.href = '/login';
    },
  );
  authInstance.interceptors.response.use((response) => {
    return response;
  });
  return authInstance;
};

export const createResourceAxios = () => {
  const resourceInstance = defaultAxiosCreate();
  resourceInstance.interceptors.request.use(
    (config) => {
      if (config.headers) {
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        config.headers['Accept'] = 'application/json';
        config.withCredentials =
          process.env.REACT_APP_SILVER_BACKEND_API_WITH_CREDENTIALS === 'true';
        config.headers['Authorization'] = 'Bearer ' + new Cookies().get('bearerToken');
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  resourceInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => resourceClientErrorHandler(error),
  );
  return resourceInstance;
};
