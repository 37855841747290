import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../course/view/style/CourseCard.css';
import styled from 'styled-components';

const baseUrl = 'https://assets.bae-f.com/';

const ProgramCard = (props: any) => {
  const navigate = useNavigate();
  const navigateToDetail = () => {
    navigate('/program/' + props.idx);
  };

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  return (
    <Container onClick={navigateToDetail}>
      <ImageWrapper>
        <img
          style={{ objectFit: 'contain', borderRadius: 4 }}
          src={baseUrl + props.thumbnailImage.pcUrl}
          className="product_img"
          alt="thumbnail for program"
        />
      </ImageWrapper>
      <Detail>
        <div className="product_title">
          {props.price > 0 ? '유료' : '무료'} | {props.region.region1Name}{' '}
          {props.region.region2Name}
        </div>
        <div className="product_des">{props.title}</div>
        <div className="product_mon">
          {convertDateFormat(props?.operatingPeriod.start)} ~{' '}
          {convertDateFormat(props?.operatingPeriod.end)}
        </div>
      </Detail>
    </Container>
  );
};

export default ProgramCard;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const ImageWrapper = styled.div`
  width: min(196px, 100%);
  aspect-ratio: 1/1.4;

  @media screen and (max-width: 1023px) {
    align-self: center;
  }
`;
const Detail = styled.div`
  width: min(196px, 100%);

  @media screen and (max-width: 1023px) {
    align-self: center;
  }
`;
