import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import { StoragePath, upload } from '../../../_common/repository/storage-repository';
import { useRecoilState } from 'recoil';
import { communityPostContentState } from '../../state/CommunityState';
import 'react-quill/dist/quill.snow.css';

const CustomEditor = (props: any) => {
  const quillRef = useRef<ReactQuill>();
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    document.body.appendChild(input);
    input.click();
    input.onchange = async () => {
      const file = input.files?.[0];
      if (file) {
        const path: StoragePath = {
          bucket: 'DOMAIN_IMAGE',
          domain: 'lecture',
          category: 'contents',
        };
        const url =
          'https://assets.bae-f.com/' +
          (await upload(file, path)) +
          '?timestamp=' +
          new Date().getTime();
        const editor = quillRef.current?.getEditor();
        const range: number = quillRef.current?.getEditor().getSelection()?.index ?? 0;
        editor?.clipboard.dangerouslyPasteHTML(range, `<img src="${url}"/>`);
        editor?.setSelection(range, 1);
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: { image: imageHandler },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [],
  );

  const [content, setContent] = useRecoilState(communityPostContentState);
  const handleChange = (value: any) => {
    setContent(value);
  };
  useEffect(() => {
    setContent('');
    props.modify && setContent(props.content);
  }, []);

  return (
    <CustomQuill
      ref={(element) => {
        if (element !== null) {
          quillRef.current = element;
        }
      }}
      modules={modules}
      style={{ height: '500px' }}
      value={content}
      theme="snow"
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

export default CustomEditor;

const placeholder = `* 배움에 대한 상세정보를 입력해주세요. (강좌 상세 소개, 커리큘럼, 사진 등)
* 이미지 형태로 업로드 시 권장 가로 사이즈는 945px 입니다.
* 준비물이 필요한 경우 꼭 적어주세요. (ex. 운동화, 엑셀이 설치된 노트북 등)
`;

const CustomQuill = styled(ReactQuill)`
  height: 500px;
  @media screen and (max-width: 417px) {
    margin-bottom: 20px;
  }
`;
