import { atom } from 'recoil';
import { Comment } from '../domain/community';
import { Post } from '../domain/community';

export const communityCommentsState = atom<Array<Comment> | null>({
  key: 'communityCommentsState',
  default: null,
});

export const likeState = atom({
  key: 'likeState',
  default: 0,
});

export const communityPostContentState = atom<string | undefined>({
  key: 'communityPostContentState',
  default: '',
});

export const communityListState = atom<Array<Post> | null>({
  key: 'communityListState',
  default: null,
});
export const communitySinglePost = atom<Post | null>({
  key: 'communitySinglePost',
  default: null,
});
