import React, { useEffect, useState } from 'react';
import AdminLayout from '../AdminLayout';
import { Button, Input, Layout, Modal, Switch, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Color from '../../../../_common/view/style/Color';
import styled from 'styled-components';
import AdminUpload from './AdminUpload';
import { useRecoilState } from 'recoil';
import { adminMain2BannerCreateRequest, adminMain2BannersList } from '../../../state/AdminState';
import {
  createBanner,
  deleteBanners,
  findBanners,
  updateBanner,
} from '../../../repository/banners-repository';
import { AdminBannersCURequest } from '../../../domain/admin-banners';
import { StoragePath, upload } from '../../../../_common/repository/storage-repository';

const { Search } = Input;
const SubBanner = () => {
  const baseUrl = 'https://assets.bae-f.com/';
  const bannerType = 'MAIN_2';

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModal2Open, setIsModal2Open] = useState(false);
  const [visible, setVisible] = useState(true);
  const [pcPreview, setPcPreview] = useState('');
  const [mobilePreview, setMobilePreview] = useState('');

  const [subBanners, setSubBanners] = useRecoilState(adminMain2BannersList);
  const [bannerCreateRequest, setBannerCreateRequest] = useRecoilState(
    adminMain2BannerCreateRequest,
  );

  const fetchBanners = async () => {
    const banners = await findBanners(bannerType);
    setSubBanners(banners);
    console.log(subBanners);
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const showModal2 = () => {
    setPcPreview('');
    setMobilePreview('');
    setBannerCreateRequest({} as AdminBannersCURequest);
    setIsModal2Open(true);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const onchange = (e: React.MouseEvent<HTMLElement, MouseEvent>, idx: number) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      deleteBanners(idx).then(async (res) => {
        await fetchBanners();
      });
    }
  };

  const onChangeActivated = (checked: boolean, bannerIdx: number) => {
    const request = {
      activated: checked,
    } as AdminBannersCURequest;
    updateBanner(bannerIdx, request).then(async (res) => {
      await fetchBanners();
    });
  };

  const onUploadPcFile = async (file: File) => {
    const url = await uploadFile(file);
    setPcPreview(url);
    setBannerCreateRequest({
      ...bannerCreateRequest,
      image: {
        ...bannerCreateRequest,
        pcUrl: url,
      },
    } as AdminBannersCURequest);
    return true;
  };
  const onUploadMobileFile = async (file: File) => {
    const url = await uploadFile(file);
    setMobilePreview(url);
    setBannerCreateRequest({
      ...bannerCreateRequest,
      image: {
        ...bannerCreateRequest?.image,
        mobileUrl: url,
      },
    } as AdminBannersCURequest);
    return true;
  };

  const uploadFile = async (file: File) => {
    const path: StoragePath = { bucket: 'DOMAIN_IMAGE', domain: 'banner', category: 'contents' };
    return (await upload(file, path)) + '?timestamp=' + new Date().getTime();
  };

  const onBannerCreateSubmit = async () => {
    if (bannerCreateRequest?.title === undefined || bannerCreateRequest.title.length === 0) {
      alert('타이틀을 입력해주세요');
      return;
    }
    if (bannerCreateRequest?.sequence === undefined || bannerCreateRequest.sequence === 0) {
      alert('순서를 입력해주세요');
      return;
    }
    if (
      bannerCreateRequest?.image?.pcUrl === undefined ||
      bannerCreateRequest?.image?.pcUrl.length === 0
    ) {
      alert('PC 이미지를 등록해주세요');
      return;
    }
    if (
      bannerCreateRequest?.image?.mobileUrl === undefined ||
      bannerCreateRequest?.image?.mobileUrl.length === 0
    ) {
      alert('MOBILE 이미지를 등록해주세요');
      return;
    }

    const request = {
      ...bannerCreateRequest,
      type: bannerType,
      activated: true,
    } as AdminBannersCURequest;
    console.log(request);

    createBanner(request).then(async (res) => {
      fetchBanners();
      alert('배너가 등록되었습니다.');
      setIsModal2Open(false);
    });
  };

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header
            style={{ background: 'transparent', padding: '0', height: 'auto', width: '100%' }}
          >
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '3%',
                }}
              >
                배너 등록
              </div>
              <div style={{ marginBottom: '3%', width: '100%', textAlign: 'right' }}>
                <Button
                  type="primary"
                  style={{
                    marginLeft: '2%',
                    backgroundColor: 'white',
                    color: Color.primary500,
                    border: `2px solid ${Color.primary500}`,
                    height: '48px',
                    display: 'inline-block',
                  }}
                  onClick={showModal2}
                >
                  + 배너 등록
                </Button>
                <Modal
                  width={400}
                  mask={true}
                  open={isModal2Open}
                  closable={false}
                  maskClosable={true}
                  onCancel={handleCancel2}
                  destroyOnClose={true}
                  cancelText={'닫기'}
                  cancelButtonProps={{
                    style: { color: Color.primary500, backgroundColor: 'white' },
                  }}
                  footer={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Button
                        type={'primary'}
                        style={{
                          color: 'white',
                          backgroundColor: Color.primary500,
                          height: '40px',
                        }}
                        onClick={onBannerCreateSubmit}
                      >
                        배너 등록
                      </Button>
                      <Button
                        type={'text'}
                        style={{
                          color: Color.primary500,
                          fontWeight: '700',
                          height: '40px',
                          marginLeft: '0',
                        }}
                        onClick={handleCancel2}
                      >
                        돌아가기
                      </Button>
                    </div>
                  }
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: '700',
                        }}
                      >
                        배너 등록
                      </p>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>타이틀</span>
                        <Input
                          placeholder={'타이틀을 입력해주세요'}
                          style={{ height: '40px', marginTop: '5px' }}
                          onChange={(e) =>
                            setBannerCreateRequest({
                              ...bannerCreateRequest,
                              title: e.target.value,
                            } as AdminBannersCURequest)
                          }
                        />
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>순서</span>
                        <Input
                          placeholder={'순서를 입력해주세요'}
                          type={'number'}
                          style={{ height: '40px', marginTop: '5px' }}
                          onChange={(e) =>
                            setBannerCreateRequest({
                              ...bannerCreateRequest,
                              sequence: Number(e.target.value),
                            } as AdminBannersCURequest)
                          }
                        />
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>PC 이미지</span>
                        <div
                          style={{
                            border: `1px solid ${Color.gray200}`,
                            borderRadius: '8px',
                            marginTop: '7px',
                          }}
                        >
                          <AdminUpload
                            size={'PC 이미지를 등록해주세요.'}
                            onChange={onUploadPcFile}
                            preview={pcPreview}
                          />
                        </div>
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>MOBILE 이미지</span>
                        <div
                          style={{
                            border: `1px solid ${Color.gray200}`,
                            borderRadius: '8px',
                            marginTop: '7px',
                          }}
                        >
                          <AdminUpload
                            size={'MOBILE 이미지를 등록해주세요.'}
                            onChange={onUploadMobileFile}
                            preview={mobilePreview}
                          />
                        </div>
                      </Stlyediv>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </Header>
          <Content>
            <div
              style={{
                padding: 16,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <div>
                <hr />
                <StyledTable
                  className="common-table"
                  style={{
                    borderCollapse: 'collapse',
                    borderSpacing: '0 10px',
                    width: '100%',
                    marginBottom: '30px',
                  }}
                >
                  <thead>
                    <tr>
                      <td className="common-table-header-column">순서</td>
                      <td className="common-table-header-column">타이틀</td>
                      <td className="common-table-header-column">타입</td>
                      <td className="common-table-header-column">PC 이미지</td>
                      <td className="common-table-header-column">Mobile 이미지</td>
                      <td className="common-table-header-column">활성화</td>
                      <td className="common-table-header-column">관리 </td>
                    </tr>
                  </thead>
                  <tbody>
                    {subBanners ? (
                      subBanners.map((item, index) => (
                        <tr
                          className="common-table-row"
                          style={{ marginBottom: '5%', borderBottom: `1px solid ${Color.gray200}` }}
                          key={index}
                        >
                          {item.type === bannerType ? (
                            <>
                              <td className="common-table-column">{item.sequence}</td>
                              <td className="common-table-column" style={bordertd}>
                                {item.title ?? ''}
                              </td>
                              <td className="common-table-column" style={bordertd}>
                                {item.type ?? ''}
                              </td>
                              <td className="common-table-column" style={bordertd}>
                                {item?.image?.pcUrl ? (
                                  <img
                                    src={`${baseUrl + item.image.pcUrl}`}
                                    style={{ width: '100%' }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      justifyContent: 'center',
                                      display: 'flex',
                                      width: '100%',
                                    }}
                                  >
                                    <AdminUpload size={'1280*260 px'} />
                                  </div>
                                )}
                              </td>
                              <td className="common-table-column" style={bordertd}>
                                {item?.image?.mobileUrl ? (
                                  <img
                                    src={`${baseUrl + item.image.mobileUrl}`}
                                    style={{ width: '100%' }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      justifyContent: 'center',
                                      display: 'flex',
                                      width: '100%',
                                    }}
                                  >
                                    <AdminUpload size={'1280*260 px'} />
                                  </div>
                                )}
                              </td>
                              {/*<td className="common-table-column" style={bordertd}>*/}
                              {/*    {!item.image.pcUrl ? (*/}
                              {/*        <p>URL을 입력해주세요</p>) : (*/}
                              {/*        <p>{item.image.pcUrl}</p>*/}
                              {/*    )}*/}
                              {/*</td>*/}
                              <td className="common-table-column" style={bordertd}>
                                <Switch
                                  checked={item.activated}
                                  onChange={(tf) => onChangeActivated(tf, item.idx)}
                                />
                              </td>
                              <td className="common-table-column" style={bordertd}>
                                <Button
                                  onClick={(event) => onchange(event, item.idx)}
                                  style={{
                                    backgroundColor: Color.primary500,
                                    color: 'white',
                                    padding: '4px 24px',
                                    borderRadius: 8,
                                  }}
                                >
                                  삭제
                                </Button>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </StyledTable>
              </div>
            </div>
          </Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default SubBanner;

const data = [
  {
    no: '1',
    banner1: [require('../../../../_common/view/img/samplebanner.png')],
    banner2: '../../../_common/view/img/Logo.png',
    url: 'https://www.bae-f.com',
    isactive: true,
  },
  {
    no: '1',
    banner1: [require('../../../../_common/view/img/samplebanner.png')],
    banner2: '../../../_common/view/img/Logo.png',
    url: 'https://www.bae-f.com',
    isactive: false,
  },
  {
    no: '1',
    banner1: '',
    banner2: '../../../_common/view/img/Logo.png',
    url: '',
    isactive: true,
  },
];

const bordertd = {
  borderLeft: `1px solid ${Color.gray200}`,
};

const StyledTable = styled.table`
  /* Add other styles here */
  .common-table-row:hover {
    background-color: transparent !important;
  }
`;

const Stlyediv = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
