import React, { useState } from 'react';
import styled from 'styled-components';
import Color from '../../_common/view/style/Color';
import Board from './component/Board';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import silverClient from '../../_common/client/silver-client';

const CommunityPage = () => {
  const navigate = useNavigate();

  const menuArr = [{ name: '자유게시판' }, { name: '강좌 개설 요청' }, { name: '모임 자랑' }];
  const [currentTab, setCurrentTab] = useState(0);
  const user = silverClient.getUser();
  const navigateToEditor = () => {
    if (!user) {
      alert('로그인이 필요합니다.');
      navigate('/login');
    } else {
      navigate('/editor', { state: { modify: false, boardIdx: currentTab + 1 } });
    }
  };

  return (
    <CommunityContainer>
      <Container>
        <TextContainer>
          <Title>커뮤니티</Title>
          <SubTitle>
            강좌 개설 요청부터 참여 자랑까지 다양한 의견을 나눌 수 있는 공간입니다.
          </SubTitle>
        </TextContainer>
        <TabContainer>
          {menuArr.map((menu, i) => (
            <TabMenu active={currentTab === i} onClick={() => setCurrentTab(i)}>
              {menu.name}
            </TabMenu>
          ))}
        </TabContainer>
        <WriteBtn onClick={navigateToEditor}>
          <EditOutlined />
          글쓰기
        </WriteBtn>
        <Board activeIdx={currentTab + 1} />
      </Container>
    </CommunityContainer>
  );
};

export default CommunityPage;

const CommunityContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0;
  width: 1080px;
  gap: 40px;

  @media screen and (max-width: 1280px) {
    padding: 56px 16px;
    width: calc(100% - 32px);
  }
  @media screen and (max-width: 1023px) {
    padding: 56px 24px;
    width: calc(100% - 48px);
  }
  @media screen and (max-width: 767px) {
    padding: 56px 16px;
    width: calc(100% - 32px);
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: ${Color.gray800};
  margin-bottom: 16px;
`;
const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray800};

  @media only screen and (max-width: 767px) {
    font-size: 15px;
  }
`;
const TabContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  border-radius: 58px;
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);

  & > :first-child {
    border-radius: 58px 0 0 58px;
  }

  & > :last-child {
    border-radius: 0 58px 58px 0;
  }
`;
const TabMenu = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? 'white' : Color.gray400)};
  background-color: ${(props) => (props.active ? Color.primary500 : 'white')};
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
`;
const WriteBtn = styled.div`
  border-radius: 8px;
  padding: 12px 24px;
  background-color: ${Color.primary500};
  color: white;
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  align-self: flex-end;
  cursor: pointer;
`;
