import React, { useEffect } from 'react';
import {
  CreditCardOutlined,
  FileTextOutlined,
  FolderAddOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Layout, Menu } from 'antd';
import Logo from '../../../_common/view/img/Logo.png';
import Color from '../../../_common/view/style/Color';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { adminMenuCurrentState, adminOpenKeyState } from '../../state/AdminState';
import SilverClient from '../../../_common/client/silver-client';

const { Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('사용자 관리', 'sub1', <TeamOutlined />, [
    getItem('배퍼 목록', '/admin/learner'),
    getItem('배코치 목록', '/admin/coach'),
  ]),
  getItem('컨텐츠 관리', 'sub2', <FileTextOutlined />, [
    getItem('강의 관리', '/admin/course'),
    getItem('소모임 관리', '/admin/gathering'),
    getItem('프로그램 행사 관리', '/admin/program'),
    getItem('게시글 관리', '/admin/article'),
    // getItem('댓글 관리', '/admin/comment'),
    // getItem('신고 관리', '/admin/report'),
  ]),
  getItem('배너 등록', 'sub3', <FolderAddOutlined />, [
    getItem('메인 배너', '/admin/banner/main'),
    getItem('하단 배너', '/admin/banner/sub'),
  ]),
  getItem('정산 관리', '/admin/payment', <CreditCardOutlined />),
  getItem('관리자 계정 관리', '/admin/account', <SettingOutlined />),
];

interface Props {
  children: React.ReactNode;
}

const AdminLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useRecoilState(adminMenuCurrentState);
  const [openKeys, setOpenKeys] = useRecoilState(adminOpenKeyState);

  useEffect(() => {
    if (children === undefined) {
      setCurrent('');
      setOpenKeys(['']);
    }

    // 토큰 체크
    if (SilverClient.checkLoginTokenExpired()) {
      console.log('Token expired, logging out');
      alert('로그인 정보가 만료되었습니다. 다시 로그인해 주세요.');
      SilverClient.logout();
      navigate('/admin');
      return;
    }

    // 어드민 권한 체크
    let isAdmin = SilverClient.getUser()?.userType === 'ADMIN';
    if (!isAdmin) {
      alert('관리자만 접근 가능합니다.');
      navigate('/admin');
      return;
    }
  }, [navigate]);

  const onClick = (e: any) => {
    setCurrent(e.key);
    navigate(`${e.key}`);
  };

  const MoveToHome = () => {
    navigate('/');
  };

  const MoveToAdminMain = () => {
    navigate('/admin/main');
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider style={{ backgroundColor: 'white', width: '250px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10%',
            marginTop: '10%',
          }}
        >
          <img
            src={Logo}
            style={{ width: '80px', height: 'auto', cursor: 'pointer' }}
            onClick={MoveToAdminMain}
          />
        </div>
        <Button
          type="primary"
          style={{
            width: '80%',
            marginLeft: '10%',
            marginBottom: '2%',
            backgroundColor: 'white',
            border: `2px solid ${Color.primary500}`,
            color: Color.primary500,
          }}
          onClick={MoveToHome}
        >
          홈페이지 바로가기
        </Button>
        <div className="demo-logo-vertical" />
        <Menu
          defaultSelectedKeys={['sub1']}
          mode="inline"
          items={items}
          onClick={onClick}
          selectedKeys={[current]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      </Sider>
      <Layout>
        <Content style={{ padding: '32px', flexDirection: 'column', alignItems: 'flex-start' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
