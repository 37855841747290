import React, { useEffect, useState } from 'react';
import { Pagination, ConfigProvider } from 'antd';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import { useRecoilState } from 'recoil';
import { ApplyDataListState, ApplyStudyState } from '../../state/MyPageState';
import CourseData from '../../../_common/view/component/CourseData';
import SideBar from './SideBar';
import ApplyPreview from './ApplyPreview';
import { findCoursePage } from '../../repository/mypage-repository';
import { Course } from '../../../course/domain/course';

const ApplyStudy: React.FC = () => {
  const [data, setData] = useRecoilState(ApplyDataListState);

  useEffect(() => {
    setData(CourseData);
  }, [setData]);

  const [course, setCourse] = useRecoilState(ApplyStudyState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getCoursePages = async () => {
    const coursePages = await findCoursePage(pageNumber - 1, 12);
    setCourse(coursePages?.contents as Array<Course>);
    setTotalElements(coursePages?.totalElements as number);
    console.log(coursePages?.contents);
  };

  useEffect(() => {
    getCoursePages();
  }, []);

  return (
    <Container>
      <SideBar activeindex={1} />
      <ContentContainer>
        <MainTitle>신청 배움</MainTitle>
        {course?.length === 0 ? (
          <div>신청한 배움이 없습니다.</div>
        ) : (
          <PreviewContainer>
            {course?.map((info, index) => <ApplyPreview {...info} />)}
          </PreviewContainer>
        )}
        <div className={'page'} style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: Color.primary500,
              },
            }}
          >
            <Pagination
              defaultCurrent={1}
              onChange={setPageNumber}
              pageSize={3}
              total={totalElements}
              showSizeChanger={false}
              hideOnSinglePage={true}
            />
          </ConfigProvider>
        </div>
      </ContentContainer>
    </Container>
  );
};

export default ApplyStudy;

const Container = styled.div`
  padding: 48px 16px;
  display: flex;
  gap: 120px;
  width: calc(100% - 32px);

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
`;
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const MainTitle = styled.div`
  font-weight: 700;
  color: ${Color.gray800};
  font-size: 24px;
  margin-bottom: 8px;

  @media screen and (max-width: 1023px) {
    align-self: center;
  }
`;
const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;
