import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  courseContentsState,
  courseNameState,
  keywordState,
  thumbnailUrlState,
} from '../../../../coachsignin/state/CourseSignInState';
import React, { useEffect } from 'react';
import { CourseRequest } from '../../../../coachsignin/domain/course-request';
import { findCourse, updateCourse } from '../../../../coachsignin/repository/course-repository';
import { Button, ConfigProvider } from 'antd';
import Color from '../../../../_common/view/style/Color';
import styled from 'styled-components';
import CourseEditInfo from './CouseEditInfo';

const CourseEditPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [courseNameOption, setCourseNameOption] = useRecoilState(courseNameState);
  const [keywordOption, setKeywordOption] = useRecoilState(keywordState);
  const [thumbnailUrlOption, setThumbnailUrlOption] = useRecoilState(thumbnailUrlState);
  const [courseContentsOption, setCourseContentsOption] = useRecoilState(courseContentsState);

  useEffect(() => {
    findCourse(parseInt(id!!)).then((course) => {
      const cate = course?.categoryIdxes?.map((c) => c.toString()) ?? [];
      console.log(cate);
      setCourseNameOption(course?.title!!);
      setKeywordOption(cate);
      setThumbnailUrlOption(course?.thumbnailImage?.pcUrl!!);
      setCourseContentsOption(course?.contents!!);
    });
  }, []);

  const isValidField = () => {
    if (!courseNameOption || courseNameOption.trim().length === 0) {
      alert('배움명을 입력해주세요.');
      return false;
    }
    // if (!keywordOption || keywordOption.length === 0) {
    //   alert('키워드를 선택해주세요.')
    //   return false
    // }
    if (!thumbnailUrlOption || thumbnailUrlOption.trim().length === 0) {
      alert('썸네일을 등록해주세요.');
      return false;
    }
    if (!courseContentsOption || courseContentsOption.trim().length === 0) {
      alert('배움 상세 내용을 입력해주세요.');
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (!isValidField()) {
      return;
    }
    const request: CourseRequest = {
      title: courseNameOption,
      thumbnailUrl: thumbnailUrlOption!!,
      contents: courseContentsOption!!,
      //"categoryIdxes": keywordOption.map((keyword) => Number.parseInt(keyword))
    };
    updateCourse(parseInt(id!!), request)
      .then(() => {
        alert('강좌가 정상적으로 수정되었습니다.');
        navigate('/coursecontrol');
      })
      .catch((error) => {
        alert('강좌 수정에 실패하였습니다.');
        console.log(error);
      });
  };

  return (
    <>
      <Container>
        <Title>배움 수정</Title>
        <CourseEditInfo />

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              fontSize: 16,
              borderRadius: 8,
            },
            components: {
              Button: {
                defaultBg: Color.primary500,
                defaultColor: 'white',
                fontWeight: 500,
              },
            },
          }}
        >
          <Button block={true} style={{ height: 44, width: 300 }} onClick={submit}>
            배움 수정
          </Button>
        </ConfigProvider>
      </Container>
    </>
  );
};

export default CourseEditPage;

const Container = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 48px 0px;

  @media screen and (max-width: 1024px) {
    padding: 48px 62px;
  }
  @media screen and (max-width: 768px) {
    padding: 48px max(24px, 10%);
  }
  @media screen and (max-width: 360px) {
    padding: 48px 16px;
  }
`;
const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
`;
