import React, { useEffect, useState } from 'react';
import { Input, Modal } from 'antd';
import { Button } from 'antd/es/radio';
import Color from '../../../../_common/view/style/Color';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { SettleInfoState } from '../../../state/CoachState';
import { findSettleInfo } from '../../../repository/coach-repository';

const CoachSettleInfoModal: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const [settle, setSettle] = useRecoilState(SettleInfoState);
  const fetchSettle = async () => {
    const settle = await findSettleInfo();
    setSettle(settle);
  };

  useEffect(() => {
    fetchSettle();
  }, []);

  return (
    <div>
      <Button
        type="primary"
        style={{
          backgroundColor: 'white',
          color: Color.primary500,
          fontWeight: '700',
          border: `2px solid ${Color.primary500}`,
          display: 'inline-block',
        }}
        onClick={showModal}
      >
        정산계좌
      </Button>
      <Modal
        width={400}
        mask={true}
        open={isModalOpen}
        closable={false}
        maskClosable={true}
        onCancel={handleCancel}
        destroyOnClose={true}
        cancelText={'닫기'}
        cancelButtonProps={{ style: { color: Color.primary500, backgroundColor: 'white' } }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Button
              type={'primary'}
              style={{
                color: Color.primary500,
                fontWeight: '700',
                height: '40px',
                marginLeft: '0',
                textAlign: 'center',
                flexBasis: 'content',
              }}
              onClick={handleCancel}
            >
              확인
            </Button>
          </div>
        }
      >
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontWeight: '700',
              }}
            >
              정산계좌
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Stlyediv>
              <span style={{ fontWeight: '700' }}>예금주</span>
              <Input
                value={
                  settle?.accountHolder + ' ' ??
                  '' +
                    (settle?.accountType === undefined
                      ? ''
                      : settle.accountType === 'PERSONAL'
                        ? '(개인)'
                        : '(법인)')
                }
                readOnly={true}
                style={{ height: '40px', marginTop: '5px', cursor: 'not-allowed' }}
              />
            </Stlyediv>
            <Stlyediv>
              <span style={{ fontWeight: '700' }}>은행</span>
              <Input
                value={settle?.accountBank ?? ''}
                readOnly={true}
                style={{ height: '40px', marginTop: '5px', cursor: 'not-allowed' }}
              />
            </Stlyediv>
            <Stlyediv>
              <span style={{ fontWeight: '700' }}>계좌</span>
              <Input
                value={settle?.accountNumber ?? ''}
                readOnly={true}
                style={{ height: '40px', marginTop: '5px', cursor: 'not-allowed' }}
              />
            </Stlyediv>
            <Stlyediv>
              <span style={{ fontWeight: '700' }}>계좌 이미지</span>
              <img
                src={settle?.accountImageUrl ?? ''}
                style={{ width: '100%', height: 'auto', marginTop: '7px' }}
              />
            </Stlyediv>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Stlyediv = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export default CoachSettleInfoModal;
