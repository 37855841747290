import silverClient from '../../_common/client/silver-client';
import { PageResponse, SingleResponse } from '../../_common/domain/response';
import qs from 'qs';
import { Post, STATUS } from '../domain/community';
import { Comment } from '../domain/community';

// 게시글 필터 적용 가능 검색
export const findPostPage = async (
  isAdmin: boolean = false,
  page: number,
  boardIdx: number | null,
  size: number,
  searchKeyword?: string,
): Promise<PageResponse<Post> | null> => {
  const params = qs.stringify({
    page: page,
    boardIdx: boardIdx,
    size: size,
    searchKeyword: searchKeyword,
  });

  return silverClient.get(`/v1${isAdmin ? '/admin' : ''}/posts?${params}`).then(
    (response) => {
      const pageResponse = response as PageResponse<Post>;
      return pageResponse;
    },
    () => {
      console.log('community findCommunityPage error');
      return null;
    },
  );
};

export const findSinglePost = async (postIdx: number): Promise<Post | null> => {
  try {
    const response = await silverClient.get('/v1/posts/' + postIdx);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse?.content;
  } catch (error) {
    console.log('community findSinglePost error');
    return null;
  }
};

export const findPostComment = async (
  page: number,
  postIdx: number,
  size: number,
): Promise<PageResponse<Comment> | null> => {
  const params = qs.stringify({
    page: page,
    postIdx: postIdx,
    sortType: 'CREATED_AT_DESC',
    size: size,
  });

  return silverClient.get(`/v1/comments?${params}`).then(
    (response) => {
      const pageResponse = response as PageResponse<Comment>;
      return pageResponse;
    },
    () => {
      console.log('community findPostComment error');
      return null;
    },
  );
};

export const deleteSinglePost = async (postIdx: number): Promise<boolean | null> => {
  try {
    const response = await silverClient.delete('/v1/posts/' + postIdx);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse?.success;
  } catch (error) {
    console.log('community deleteSinglePost error');
    return null;
  }
};

export const modifySinglePost = async (
  postIdx: number,
  title: string,
  content: string,
  isAnonymous: boolean,
): Promise<boolean | null> => {
  try {
    const requestBody = {
      title: title,
      content: content,
      isAnonymous: isAnonymous,
    };

    const response = await silverClient.put('/v1/posts/' + postIdx, requestBody);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse?.success;
  } catch (error) {
    console.log('community modifySinglePost error');
    return null;
  }
};

export const modifyPostStatus = async (
  postIdx: number,
  status: STATUS,
): Promise<boolean | undefined> => {
  try {
    const response = await silverClient.put('/v1/admin/posts/' + postIdx + '?status=' + status, {});
    return response?.success;
  } catch (error) {
    console.log('community modifyPostStatus error');
    return undefined;
  }
};

export const modifyCommentStatus = async (
  commentIdx: number,
  status: STATUS,
): Promise<boolean | undefined> => {
  try {
    const response = await silverClient.put(
      '/v1/admin/comments/' + commentIdx + '?status=' + status,
      {},
    );
    return response?.success;
  } catch (error) {
    console.log('community modifyCommentStatus error');
    return undefined;
  }
};

export const uploadSinglePost = async (
  boardIdx: number,
  title: string,
  content: string,
  isAnonymous: boolean,
): Promise<boolean | null> => {
  try {
    const requestBody = {
      boardIdx: boardIdx,
      title: title,
      content: content,
      isAnonymous: isAnonymous,
    };

    const response = await silverClient.post('/v1/posts', requestBody);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse?.success;
  } catch (error) {
    console.log('community uploadSinglePost error');
    return null;
  }
};

export const addLikeCount = async (postIdx: number): Promise<SingleResponse<Post> | null> => {
  try {
    const response = await silverClient.post(`/v1/posts/${postIdx}/like`);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse;
  } catch (error) {
    console.log('community addLikeCount error');
    alert('이미 좋아요를 누른 게시물입니다.');
    return null;
  }
};

export const deleteSingleComment = async (postIdx: number): Promise<boolean | null> => {
  try {
    const response = await silverClient.delete('/v1/comments/' + postIdx);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse?.success;
  } catch (error) {
    console.log('community deleteSingleComment error');
    return null;
  }
};

export const uploadSingleComment = async (
  postIdx: number,
  content: string,
  isAnonymous: boolean,
): Promise<boolean | null> => {
  try {
    const requestBody = {
      postIdx: postIdx,
      content: content,
      isAnonymous: isAnonymous,
    };

    const response = await silverClient.post('/v1/comments', requestBody);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse?.success;
  } catch (error) {
    console.log('community uploadSingleComment error');
    return null;
  }
};

export const modifySingleComment = async (
  commentIdx: number,
  content: string,
  isAnonymous: boolean,
): Promise<boolean | null> => {
  try {
    const requestBody = {
      content: content,
      isAnonymous: isAnonymous,
    };

    const response = await silverClient.put('/v1/comments/' + commentIdx, requestBody);
    const SingleResponse = response as SingleResponse<Post>;
    return SingleResponse?.success;
  } catch (error) {
    console.log('community modifySingleComment error');
    return null;
  }
};
