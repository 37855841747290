import silverClient from '../../_common/client/silver-client';
import { ListResponse, SingleResponse } from '../../_common/domain/response';
import { AdminBanners, AdminBannersCURequest, TYPE } from '../domain/admin-banners';

export const findBanners = async (type: TYPE): Promise<AdminBanners[] | null> => {
  return silverClient.get('/v1/admin/banners?type=' + type).then(
    (response) => {
      const BannersResponse = response as ListResponse<AdminBanners>;
      return BannersResponse?.contents;
    },
    () => {
      console.log('findBanners error');
      return null;
    },
  );
};

export const findActiveBanners = async (): Promise<AdminBanners[] | null> => {
  return silverClient.get('/v1/banners').then(
    (response) => {
      const BannersResponse = response as ListResponse<AdminBanners>;
      return BannersResponse?.contents;
    },
    () => {
      console.log('findActiveBanners error');
      return null;
    },
  );
};

export const deleteBanners = async (bannerIdx: number): Promise<string | null> => {
  return silverClient.delete('/v1/admin/banners/' + bannerIdx).then(
    (response) => {
      console.log('deleteeteete');
      const DeleteResponse = response as SingleResponse<string>;
      return DeleteResponse?.content;
    },
    () => {
      console.log('deleteBanners error');
      return null;
    },
  );
};

export const createBanner = async (createRequest: AdminBannersCURequest) => {
  return silverClient.post('/v1/admin/banners/', createRequest);
};

export const updateBanner = async (bannerIdx: number, updateRequest: AdminBannersCURequest) => {
  return silverClient.put('/v1/admin/banners/' + bannerIdx, updateRequest);
};
