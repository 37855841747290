import silverClient from '../../_common/client/silver-client';
import { ListResponse } from '../../_common/domain/response';
import { Category } from '../domain/category';

export const findCategories = async (): Promise<Array<Category> | null> => {
  return silverClient.get('/v1/categories').then(
    (response) => {
      const listResponse = response as ListResponse<Category>;
      return listResponse?.contents;
    },
    () => {
      console.log('findCategories error');
      return null;
    },
  );
};
