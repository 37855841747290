import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { Button, ConfigProvider } from 'antd';
import Color from '../../../_common/view/style/Color';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { courseCategoryState } from '../../state/CourseState';
import { findCategory } from '../../repository/course-repository';
import silverClient from '../../../_common/client/silver-client';
import { freePayment } from '../../../payment/repository/payment-repository';

const Scroll = (props: any) => {
  const navigate = useNavigate();
  const user = silverClient.getUser();
  const navigateToPayment = () => {
    if (!user) {
      alert('로그인이 필요합니다.');
      navigate('/login');
    } else {
      if (!props?.currentTimeInRecruitmentPeriod) {
        alert('모집 기간이 아닙니다.');
        return;
      }
      if (props?.price === 0) {
        freePayment(props?.idx)
          .then(() => {
            navigate('/applystudy');
          })
          .catch((err) => {
            console.log('결제 에러', err);
          });
      } else {
        navigate('/payment', {
          state: { title: props.title, author: props.teacher.name, lectureIdx: props.idx },
        });
      }
    }
  };

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  const [category, setCategory] = useRecoilState(courseCategoryState);
  useEffect(() => {
    console.log(props?.learnerCount);
    findCategory().then(setCategory);
  }, [setCategory]);

  const getCategoryNameByIdx = (idx: number) => {
    return category?.find((c) => c.idx === idx)?.title ?? '';
  };

  let isOverRecruitmentPeriod = props.recruitmentPeriod?.start < new Date().toISOString();
  let remain = (props?.maxCapacity ?? 0) - (props?.learnerCount ?? 0);

  return (
    <>
      {isPc && (
        <PCContainer>
          <DetailContainer>
            <Label>강좌</Label>
            <Title>{props?.title}</Title>
            <HashtagContainer>
              {props?.categoryIdxes?.map((idx: any, index: number) => {
                return <Hashtag key={index}>#{getCategoryNameByIdx(idx)}</Hashtag>;
              })}
            </HashtagContainer>
            <Schedule>
              {convertDateFormat(props?.operatingPeriod?.start)} ~{' '}
              {convertDateFormat(props?.operatingPeriod?.end)} | {props?.operatingDayOfWeekInfo} |{' '}
              {props?.operatingTimeInfo}
            </Schedule>
            <Schedule></Schedule>
          </DetailContainer>
          <BottomContainer>
            <Bottom>
              <BottomText>
                {props?.price === 0 ? '무료' : props?.price?.toLocaleString() + '원'}
              </BottomText>
              <BottomText>남은 자리 {remain < 0 ? 0 : remain}석</BottomText>
            </Bottom>
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 44,
                  fontSize: 16,
                },
                components: {
                  Button: {
                    fontWeight: 700,
                  },
                },
              }}
            >
              <Button
                onClick={navigateToPayment}
                disabled={!props?.currentTimeInRecruitmentPeriod || remain <= 0}
                type="primary"
                style={{ width: '100%', backgroundColor: Color.primary500 }}
              >
                {props.currentTimeInRecruitmentPeriod && remain > 0
                  ? '신청하기'
                  : isOverRecruitmentPeriod
                    ? '모집마감'
                    : '모집예정'}
              </Button>
            </ConfigProvider>
          </BottomContainer>
        </PCContainer>
      )}
      {isTabletMobile && (
        <TabletContainer>
          <Title>{props?.title}</Title>
          <Bottom>
            <BottomText>
              {props?.price === 0 ? '무료' : props?.price?.toLocaleString() + '원'}
            </BottomText>
            <BottomText>
              남은 자리 {(props?.maxCapacity ?? 0) - (props?.learnerCount ?? 0)}석
            </BottomText>
          </Bottom>
          <ConfigProvider
            theme={{
              token: {
                controlHeight: 44,
                fontSize: 16,
              },
              components: {
                Button: {
                  fontWeight: 700,
                },
              },
            }}
          >
            <Button
              onClick={navigateToPayment}
              disabled={!props?.currentTimeInRecruitmentPeriod || remain <= 0}
              type="primary"
              style={{ width: '100%', backgroundColor: Color.primary500 }}
            >
              {props.currentTimeInRecruitmentPeriod && remain > 0
                ? '신청하기'
                : isOverRecruitmentPeriod
                  ? '모집마감'
                  : '모집예정'}
            </Button>
          </ConfigProvider>
        </TabletContainer>
      )}
    </>
  );
};

export default Scroll;

const PCContainer = styled.div`
  position: fixed;
  width: 336px;
  height: 336px;
  top: 220px;
  right: 16px;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid ${Color.gray200};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
`;
const TabletContainer = styled.div`
  position: fixed;
  width: calc(100% - 48px);
  bottom: 0;
  padding: 24px;
  margin-left: -24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1);
  background-color: white;

  @media screen and (max-width: 767px) {
    margin-left: -16px;
    padding: 24px 16px;
    width: calc(100% - 32px);
  }
`;
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Label = styled.div`
  color: ${Color.gray500};
  font-size: 13px;
  font-weight: 700;
`;
const Title = styled.div`
  color: ${Color.gray800};
  font-size: 20px;
  font-weight: 700;
`;
const HashtagContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
const Hashtag = styled.div`
  color: ${Color.primary500};
  font-size: 13px;
  font-weight: 700;
`;
const Schedule = styled.div`
  font-size: 14px;
  color: ${Color.gray800};
  font-weight: 700;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BottomText = styled.div`
  color: ${Color.primary700};
  font-size: 16px;
  font-weight: 700;
`;
