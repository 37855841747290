import { atom } from 'recoil';
import ManagementData from '../view/Component/ManagementData';
import CourseData from '../../_common/view/component/CourseData';
import { Course } from '../../course/domain/course';
export const ManagementDataListState = atom({
  key: 'ManagementDataListState',
  default: ManagementData,
});

export const ApplyDataListState = atom({
  key: 'ApplyDataListState',
  default: CourseData,
});

export const ApplyStudyState = atom<Array<Course> | null>({
  key: 'ApplyStudyState',
  default: null,
});
