import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import Color from '../../../_common/view/style/Color';
import { Button, ConfigProvider, Input } from 'antd';
import '../style/Editor.css';
import styled from 'styled-components';
import { StoragePath, upload } from '../../../_common/repository/storage-repository';
import CustomEditor from './CustomEditor';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { communityPostContentState } from '../../state/CommunityState';
import { modifySinglePost, uploadSinglePost } from '../../repository/community-repository';

const EditorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  useEffect(() => {
    location.state.modify && setTitle(location.state.title);
  }, []);
  const onChangeTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const [content, setContent] = useRecoilState(communityPostContentState);
  const uploadPost = async () => {
    if (location.state.modify) {
      // 수정하기 일 때
      const uploadSuccess = await modifySinglePost(
        location.state.idx,
        title,
        String(content),
        false,
      );
      if (uploadSuccess) {
        alert('게시글이 성공적으로 수정되었습니다.');
        navigate(`/community/${location.state.idx}`);
      } else {
        alert('다시 시도해주세요.');
      }
    } else {
      // 게시글 작성일 때
      const uploadSuccess = await uploadSinglePost(
        location.state.boardIdx,
        title,
        String(content),
        false,
      );
      if (uploadSuccess) {
        alert('게시글이 성공적으로 게시되었습니다.');
        navigate(`/community`);
      } else {
        alert('다시 시도해주세요.');
      }
    }
  };

  return (
    <Container>
      <EditorContainer>
        <div className="Input">
          <ConfigProvider
            theme={{
              token: {
                borderRadius: 8,
                fontSize: 16,
                colorText: Color.gray800,
                colorPrimary: Color.primary500,
              },
              components: {
                Input: {
                  colorTextPlaceholder: Color.gray500,
                },
              },
            }}
          >
            <Input
              placeholder="제목을 입력해주세요."
              maxLength={40}
              style={{ height: '50px' }}
              value={title}
              onChange={onChangeTitle}
            />
          </ConfigProvider>
        </div>
        <CustomEditor {...location.state} />
        <BtnContainer>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: Color.primary500,
                controlHeight: 44,
                borderRadius: 8,
                fontSize: 16,
                colorBorder: 'white',
                colorText: Color.primary500,
              },
              components: {
                Button: {
                  fontWeight: 700,
                },
              },
            }}
          >
            <Button style={{ width: '100%' }}>취소하기</Button>
          </ConfigProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: Color.primary500,
                controlHeight: 44,
                borderRadius: 8,
                fontSize: 16,
                colorText: 'white',
                colorBgContainer: Color.primary500,
              },
              components: {
                Button: {
                  fontWeight: 700,
                },
              },
            }}
          >
            <Button onClick={uploadPost} style={{ width: '100%' }}>
              {location.state.modify ? '수정하기' : '등록하기'}
            </Button>
          </ConfigProvider>
        </BtnContainer>
      </EditorContainer>
    </Container>
  );
};

export default EditorPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 16px;
  width: calc(100% - 32px);

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    padding: 56px 24px;
    width: calc(100% - 48px);
  }
`;
const EditorContainer = styled.div`
  max-width: 900px;
  width: 100%;
`;
const BtnContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 200px;
  gap: 40px;
  justify-content: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    display: flex;
    row-gap: 8px;
  }
`;
