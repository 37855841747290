import { atom } from 'recoil';
import { Course, Region } from '../domain/course';
import { Category } from '../../coachsignin/domain/category';
import { Careers, Experience } from '../../coach/domain/coach';

export const courseSortState = atom({
  key: 'courseSortState',
  default: 'RECOMMEND',
});

export const coursePriceState = atom({
  key: 'coursePriceState',
  default: 'ALL',
});

// 선택한 관심사
export const courseSelectedCategoriesState = atom<Array<number>>({
  key: 'courseSelectedCategoriesState',
  default: [],
});

// 강좌 배열
export const courseListState = atom<Array<Course> | null>({
  key: 'courseListState',
  default: null,
});

// 선택한 강좌 1개
export const courseState = atom<Course | null>({
  key: 'courseState',
  default: null,
});

// 선택 가능한 카테고리
export const courseCategoryState = atom<Array<Category> | null>({
  key: 'courseCategoryState',
  default: null,
});

// 선택 가능한 지역
export const courseRegionState = atom<Array<Region> | null>({
  key: 'courseRegionState',
  default: null,
});

// 선택한 지역
export const courseSelectedRegionState = atom<number | undefined>({
  key: 'courseSelectedRegionState',
  default: undefined,
});

export const courseCareerState = atom<Careers[] | null>({
  key: 'courseCareerState',
  default: null,
});

export const courseExperienceState = atom<Experience[] | null>({
  key: 'courseExperienceState',
  default: null,
});
