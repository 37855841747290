import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Input, Pagination, theme } from 'antd';
import styled from 'styled-components';
import Color from '../../../../_common/view/style/Color';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  adminCurrentSettleStatusState,
  adminPaymentSearchState,
  adminSettleState,
  adminSettleTotalState,
} from '../../../state/AdminState';
import {
  findSettlePage,
  updateAdminSettleStatus,
  updateAdminSettleStatusBulk,
} from '../../../repository/payment-repostory';
import { AdminSettle } from '../../../../payment/domain/settle';
import { STATUS } from '../../../../coach/domain/coach';

const { Search } = Input;

const StyledTable = styled.table`
  /* Add other styles here */
  .common-table-row:hover {
    background-color: transparent !important;
  }
`;
const PaymentControl = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [settle, setSettle] = useRecoilState(adminSettleState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useRecoilState(adminSettleTotalState);
  const getPaymentPages = async (search: string | undefined) => {
    const pages = await findSettlePage(pageNumber - 1, 10, search, currentStatus);
    setSettle(pages?.contents as Array<AdminSettle>);
    setTotalElements(pages?.totalElements as number);
  };

  const [checkItems, setCheckItems] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useRecoilState(adminPaymentSearchState);
  const currentStatus = useRecoilValue(adminCurrentSettleStatusState);

  useEffect(() => {
    setCheckItems([]);
    setSettle([]);
    getPaymentPages(searchValue);
  }, [pageNumber]);

  useEffect(() => {
    setTotalElements(0);
    setPageNumber(1);
    setCheckItems([]);
    setSettle([]);
    getPaymentPages(searchValue);
  }, [searchValue, currentStatus]);

  const handleCheck = (checked: boolean, id: number) => {
    setCheckItems((prevCheckItems) => {
      if (checked) {
        return [...prevCheckItems, id];
      } else {
        return prevCheckItems.filter((el) => el !== id);
      }
    });
  };

  const handleCheckToggle = (id: number) => {
    setCheckItems((prevCheckItems) => {
      if (prevCheckItems.includes(id)) {
        return prevCheckItems.filter((el) => el !== id);
      } else {
        return [...prevCheckItems, id];
      }
    });
  };

  const handleAllCheck = (checked: boolean) => {
    if (checked) {
      const idArray = settle?.map((el) => el.idx) ?? [];
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  const setSettleDone = async () => {
    await updateAdminSettleStatusBulk(checkItems, 'SUCCESS');
    setCheckItems([]);
    setSettle([]);
    getPaymentPages(searchValue);
  };

  const setSettleCancel = async () => {
    await updateAdminSettleStatusBulk(checkItems, 'CANCEL');
    setCheckItems([]);
    setSettle([]);
    getPaymentPages(searchValue);
  };

  return (
    <Content>
      <div
        style={{
          padding: 16,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        <div>
          <div className={'ButtonGroup'} style={{ marginBottom: '20px', display: 'flex' }}>
            <Button
              style={{
                marginRight: '10px',
                color: Color.primary500,
                border: `2px solid ${Color.primary500}`,
                padding: '10px 24px 30px 24px',
              }}
              onClick={setSettleDone}
            >
              정산 완료 처리
            </Button>
            <Button
              style={{
                marginRight: '10px',
                color: 'red',
                border: `2px solid red`,
                padding: '10px 24px 30px 24px',
              }}
              onClick={setSettleCancel}
            >
              정산 취소 처리
            </Button>
            <div style={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                    controlHeight: 48,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    fontSize: 16,
                    colorBorderBg: Color.gray800,
                  },
                }}
              >
                <Search
                  placeholder="검색어를 입력하세요."
                  onSearch={async (value, e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    setSearchValue(value);
                  }}
                  style={{
                    width: '320px',
                    borderRadius: '8px',
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div>
          <StyledTable
            className="common-table"
            style={{
              borderCollapse: 'collapse',
              borderSpacing: '0 10px',
              width: '100%',
              marginBottom: '30px',
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="select-all"
                    style={{ width: '20px', height: '20px' }}
                    onChange={(e) => handleAllCheck(e.target.checked)}
                    checked={
                      settle?.length === 0
                        ? false
                        : checkItems.length === settle?.length
                          ? true
                          : false
                    }
                  />
                </td>
                <td className="common-table-header-column">정산번호</td>
                <td className="common-table-header-column">배움번호</td>
                <td className="common-table-header-column">배움명</td>
                <td className="common-table-header-column">강사명</td>
                <td className="common-table-header-column">판매금액</td>
                <td className="common-table-header-column">수수료금액</td>
                <td className="common-table-header-column">정산금액</td>
                <td className="common-table-header-column">계좌정보</td>
                <td className="common-table-header-column">상태</td>
              </tr>
            </thead>
            <tbody>
              {settle && settle.length > 0 ? (
                settle.map((item, index) => {
                  return (
                    <tr
                      className="common-table-row"
                      style={{
                        cursor: 'pointer',
                        marginBottom: '5%',
                        borderBottom: `1px solid ${Color.gray200}`,
                        height: '45px',
                      }}
                      key={index}
                    >
                      <td className="common-table-content-column">
                        <input
                          type="checkbox"
                          name="select-all"
                          style={{ width: '20px', height: '20px' }}
                          onChange={(e) => handleCheck(e.target.checked, item.idx)}
                          checked={checkItems.includes(item.idx)}
                        />
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {item.idx}
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {item.lectureIdx}
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {shortText(item.lecture, 20)}
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {item.settleInfo.accountHolder}
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {item.salesAmount}
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {item.commissionAmount} ({item.commissionRate}%)
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {item.settleAmount}
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                      >
                        {item.settleInfo.accountBank} {item.settleInfo.accountNumber} (
                        {item.settleInfo.accountHolder})
                      </td>
                      <td
                        className="common-table-content-column"
                        onClick={(e) => handleCheckToggle(item.idx)}
                        style={{ width: 90 }}
                      >
                        {chipStatus(item.status)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </StyledTable>

          {totalElements > 0 && (
            <div className={'page'} style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}>
              <Pagination
                current={pageNumber}
                defaultCurrent={1}
                onChange={setPageNumber}
                pageSize={10}
                total={totalElements}
                showSizeChanger={false}
              />
            </div>
          )}
        </div>
      </div>
    </Content>
  );
};

export default PaymentControl;

const chipStatus = (status: STATUS) => {
  switch (status) {
    case 'READY':
      return ready;
    case 'PROCESSING':
      return processing;
    case 'FAIL':
      return fail;
    case 'HOLD':
      return hold;
    case 'CARRYOVER':
      return carryover;
    case 'CANCEL':
      return cancel;
    case 'SUCCESS':
      return success;
    default:
      return <h4>UNKNOWN</h4>;
  }
};

const ready = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.gray400,
    }}
  >
    정산예정
  </div>
);

const processing = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.primary400,
    }}
  >
    처리중
  </div>
);

const fail = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.red1000,
    }}
  >
    실패
  </div>
);

const hold = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.red1000,
    }}
  >
    지급보류
  </div>
);

const carryover = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.red1000,
    }}
  >
    지급이월
  </div>
);

const cancel = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.gray600,
    }}
  >
    취소
  </div>
);

const success = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.primary500,
    }}
  >
    완료
  </div>
);

const shortText = (text?: string, length?: number) => {
  if (!text) return '';
  let lengthValue = length ? length : 10;
  return text.length > lengthValue ? text.substr(0, lengthValue) + '...' : text;
};
