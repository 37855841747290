import { atom } from 'recoil';
import { Course } from '../../course/domain/course';

export const mainRecommendListState = atom<Course[]>({
  key: 'mainRecommendListState',
  default: [],
});

export const mainNewListState = atom<Course[]>({
  key: 'mainNewListState',
  default: [],
});

export const mainClosestListState = atom<Course[]>({
  key: 'mainClosestListState',
  default: [],
});
