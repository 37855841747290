import { Button, Divider } from 'antd';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const SideBar = () => {
  const navigate = useNavigate();

  const MoveToEditInfo = () => {
    navigate('/coachmypage');
  };

  const MoveToCourseManage = () => {
    navigate('/coursecontrol');
  };

  const MoveToPayManage = () => {
    navigate('/coachpayment');
  };

  const MoveToStudentControl = () => {
    navigate('/studentcontrol');
  };

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  return (
    <>
      {isPc && (
        <>
          <div>
            <Button style={ButtonStyle} type={'text'} onClick={MoveToEditInfo}>
              경력정보 수정
            </Button>
            <Button style={ButtonStyle} type={'text'} onClick={MoveToCourseManage}>
              배움 관리
            </Button>
            {/*<Button style={ButtonStyle} type={'text'} onClick={MoveToStudentControl}>*/}
            {/*  수강생 관리*/}
            {/*</Button>*/}
            <Button style={ButtonStyle} type={'text'} onClick={MoveToPayManage}>
              정산 관리
            </Button>
          </div>
        </>
      )}
      {isTablet && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button type={'text'} style={ButtonStyle} onClick={MoveToEditInfo}>
              경력정보 수정
            </Button>
            <Button type={'text'} style={ButtonStyle} onClick={MoveToCourseManage}>
              배움 관리
            </Button>
            {/*<Button type={'text'} style={ButtonStyle} onClick={MoveToStudentControl}>*/}
            {/*  수강생 관리*/}
            {/*</Button>*/}
            <Button type={'text'} style={ButtonStyle} onClick={MoveToPayManage}>
              정산 관리
            </Button>
          </div>
          <Divider />
        </div>
      )}
      {isMobile && (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'inline-block' }}>
              <Button type={'text'} style={ButtonStyle} onClick={MoveToEditInfo}>
                경력정보 수정
              </Button>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Button type={'text'} style={ButtonStyle} onClick={MoveToCourseManage}>
                배움 관리
              </Button>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Button type={'text'} style={ButtonStyle} onClick={MoveToPayManage}>
                정산 관리
              </Button>
            </div>
          </div>
          <Divider />
        </div>
      )}
    </>
  );
};

export default SideBar;

const ButtonStyle: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: 'bold',
};
