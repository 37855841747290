import { Axios, AxiosError, AxiosResponse } from 'axios';
import { ApiResponse, SingleResponse } from '../domain/response';
import { Cookies } from 'react-cookie';
import { AuthToken } from '../domain/auth';
import { createAuthAxios, createResourceAxios } from './axios-provider';
import { User } from '../../user/domain/user';
import { findMy } from '../../user/repository/user-repository';
import silverClient from './silver-client';

export const BEARER_TOKEN_COOKIE_NAME = 'bearerToken';

class SilverClient {
  private static instance: SilverClient;
  private static authInstance: Axios;
  private static resourceInstance: Axios;

  private constructor() {}

  // 싱글톤 패턴
  public static getInstance(): SilverClient {
    if (!SilverClient.instance) {
      SilverClient.instance = new SilverClient();
    }
    SilverClient.authInstance = createAuthAxios();
    SilverClient.resourceInstance = createResourceAxios();
    return SilverClient.instance;
  }

  public async authenticate(email: string, password: string): Promise<User> {
    const form = new FormData();
    form.append('email', email);
    form.append('password', password);
    return await SilverClient.authInstance
      .post<SingleResponse<AuthToken>>('/v1/auth/token', form)
      .then(this.handleAuthenticateSuccess)
      .catch(this.handleAuthenticateError);
  }

  public logout() {
    new Cookies().remove('bearerToken');
    localStorage.removeItem('user');
    SilverClient.resourceInstance.defaults.headers.common['Authorization'] = '';
  }

  public async get<T extends ApiResponse>(url: string): Promise<T | null> {
    const response = await SilverClient.resourceInstance.get<T>(url);
    return response?.data;
  }

  public async post<T, R extends ApiResponse>(url: string, data?: T): Promise<R | null> {
    const response = await SilverClient.resourceInstance.post<R>(url, data);
    return response?.data;
  }

  public async put<T, R extends ApiResponse>(url: string, data: T): Promise<R | null> {
    const response = await SilverClient.resourceInstance.put<R>(url, data);
    return response?.data;
  }

  public async delete<T extends ApiResponse>(url: string): Promise<T | null> {
    const response = await SilverClient.resourceInstance.delete<T>(url);
    return response?.data;
  }

  public async patch<T, R extends ApiResponse>(url: string, data: T): Promise<R | null> {
    const response = await SilverClient.resourceInstance.patch<R>(url, data);
    return response?.data;
  }

  public getResourceInstance(): Axios {
    return SilverClient.resourceInstance;
  }

  public getUser(): User | null {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      return JSON.parse(userJson);
    } else {
      return null;
    }
  }

  public async updateMy() {
    return findMy().then((user) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        return user;
      } else {
        throw new AxiosError('user is null');
      }
    });
  }

  public checkLoginTokenExpired(): Boolean {
    let hasToken = new Cookies().get('bearerToken');
    let hasUser = this.getUser()?.idx;

    // 유저정보는 있지만 토큰이 없을 경우 (토큰이 만료된 상태)
    if (!hasToken && hasUser) {
      return true;
    }
    return false;
  }

  private async handleAuthenticateSuccess(response: AxiosResponse<SingleResponse<AuthToken>>) {
    if (response?.data?.content) {
      let expiredTimeMillis = 1000 * 60 * 60 * 6; // 6시간
      new Cookies().set(BEARER_TOKEN_COOKIE_NAME, response.data.content.accessToken, {
        path: '/',
        secure: true,
        expires: new Date(Date.now() + expiredTimeMillis),
      });
      return await silverClient.updateMy();
    } else {
      throw new AxiosError('response data content is null');
    }
  }

  private handleAuthenticateError(error: AxiosError<ApiResponse>) {
    console.log('authenticate catch:: ' + error);
    switch (error.response?.status) {
      case 400:
        alert('유효하지 않은 이메일 형식입니다.');
        break;
      case 401:
        alert(error.response?.data?.message);
        break;
    }

    return Promise.reject(error);
  }
}

export default SilverClient.getInstance();
