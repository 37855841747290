import React, { useEffect, useState } from 'react';
import Color from '../../../_common/view/style/Color';
import styled from 'styled-components';
import { ConfigProvider, DatePicker, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  endDateState,
  periodDayOfWeekState,
  periodEndDateState,
  periodEndTimeState,
  periodStartDateState,
  periodStartTimeState,
  typeState,
} from '../../state/CourseSignInState';
import { useMediaQuery } from 'react-responsive';

const timeOptions = Array.from({ length: 49 }).map((_, index) => {
  const hour = Math.floor(index / 2)
    .toString()
    .padStart(2, '0');
  const minute = index % 2 === 0 ? '00' : '30';
  const value = `${hour}:${minute}`;
  const label = value;

  return { value, label };
});

const Period = () => {
  const typeOption = useRecoilValue(typeState);

  const [startDate, setStartDate] = useRecoilState(periodStartDateState);
  const handleStartDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setStartDate(date?.format('YYYY-MM-DD'));
  };
  const [endDate, setEndDate] = useRecoilState(periodEndDateState);
  const handleEndDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setEndDate(date?.format('YYYY-MM-DD'));
  };
  const [startTime, setStartTime] = useRecoilState(periodStartTimeState);
  const handleStartTimeChange = (value: string) => {
    setStartTime(value);
  };
  const [endTime, setEndTime] = useRecoilState(periodEndTimeState);
  const handleEndTimeChange = (value: string) => {
    setEndTime(value);
  };

  const recruitEndDate = useRecoilValue(endDateState);

  const [dayOfWeek, setDayOfWeek] = useRecoilState(periodDayOfWeekState);

  const [timePlaceholder, setTimePlaceholder] = useState('시간을 선택해주세요');
  const [datePlaceholder, setDatePlaceholder] = useState('날짜를 선택해주세요');
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });
  useEffect(() => {
    if (isMobile) {
      setTimePlaceholder('시간');
      setDatePlaceholder('날짜');
    } else {
      setTimePlaceholder('시간을 선택해주세요');
      setDatePlaceholder('날짜를 선택해주세요');
    }
  }, [isMobile]);

  const dayOfWeekToString = (dayOfWeek: number) => {
    switch (dayOfWeek) {
      case 1:
        return '월요일';
      case 2:
        return '화요일';
      case 3:
        return '수요일';
      case 4:
        return '목요일';
      case 5:
        return '금요일';
      case 6:
        return '토요일';
      case 7:
        return '일요일';
      default:
        return null;
    }
  };

  const handleStartTimeOptions = () => {
    const startTimeOptions = timeOptions.slice(0, timeOptions.length - 1);
    if (endTime) {
      // 원데이 클래스
      const filteredOptions = startTimeOptions.filter((option) => {
        return option.value < endTime;
      });
      return filteredOptions;
    }
    return startTimeOptions;
  };
  const handleEndTimeOptions = () => {
    if (startTime) {
      // 원데이 클래스
      const filteredOptions = timeOptions.filter((option) => {
        return option.value > startTime;
      });
      return filteredOptions;
    }
    return timeOptions.slice(1);
  };

  useEffect(() => {
    setDayOfWeek(String(dayjs(startDate).day()));
    if (startDate === undefined) setDayOfWeek(undefined);
  }, [startDate]);

  return (
    <>
      {typeOption === 1 ? (
        <Container typeOption={typeOption}>
          <Wrapper>
            <Description>수업 날짜</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                },
              }}
            >
              <CustomDatePicker
                value={startDate ? dayjs(startDate) : null}
                placeholder={datePlaceholder}
                locale={locale}
                onChange={handleStartDateChange}
                disabledDate={(current) => {
                  return current && current < dayjs(recruitEndDate).endOf('day');
                }}
                disabled={recruitEndDate?.length === 0 || recruitEndDate === undefined}
              />
            </ConfigProvider>
          </Wrapper>
          <Wrapper>
            <Description>수업 시작</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                },
                components: {
                  Select: {
                    optionHeight: 48,
                    optionPadding: 12,
                    optionFontSize: 16,
                    optionSelectedFontWeight: 700,
                  },
                },
              }}
            >
              <CustomSelect
                value={startTime}
                placeholder={timePlaceholder}
                options={handleStartTimeOptions()}
                onChange={handleStartTimeChange}
                disabled={startDate?.length === 0 || startDate === undefined}
              />
            </ConfigProvider>
          </Wrapper>
          <Wrapper>
            <Description>수업 종료</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                },
                components: {
                  Select: {
                    optionHeight: 48,
                    optionPadding: 12,
                    optionFontSize: 16,
                    optionSelectedFontWeight: 700,
                  },
                },
              }}
            >
              <CustomSelect
                value={endTime}
                placeholder={timePlaceholder}
                options={handleEndTimeOptions()}
                onChange={handleEndTimeChange}
                disabled={startDate?.length === 0 || startDate === undefined}
              />
            </ConfigProvider>
          </Wrapper>
        </Container>
      ) : (
        <Container typeOption={typeOption}>
          <Wrapper>
            <Description>수업 시작일</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                },
              }}
            >
              <CustomDatePicker
                placeholder="날짜 선택"
                locale={locale}
                value={startDate ? dayjs(startDate) : null}
                onChange={handleStartDateChange}
                disabledDate={(current) => {
                  if (!endDate) {
                    return (
                      current &&
                      (current < dayjs().endOf('day') ||
                        current < dayjs(recruitEndDate).endOf('day'))
                    );
                  }
                  return (
                    current &&
                    (current > dayjs(endDate).endOf('day') ||
                      current < dayjs().endOf('day') ||
                      current < dayjs(recruitEndDate).endOf('day'))
                  );
                }}
                disabled={recruitEndDate?.length === 0 || recruitEndDate === undefined}
              />
            </ConfigProvider>
          </Wrapper>
          <Wrapper>
            <Description>수업 종료일</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                  colorText: Color.gray800,
                },
              }}
            >
              <CustomDatePicker
                placeholder="날짜 선택"
                locale={locale}
                value={endDate ? dayjs(endDate) : null}
                onChange={handleEndDateChange}
                disabledDate={(current) => {
                  if (!startDate) {
                    return current && current < dayjs().add(8, 'day').endOf('day');
                  }
                  return (
                    current &&
                    current < dayjs(startDate).add(7, 'day').subtract(1, 'day').endOf('day')
                  );
                }}
                disabled={recruitEndDate?.length === 0 || recruitEndDate === undefined}
              />
            </ConfigProvider>
          </Wrapper>
          <Wrapper>
            <Description>매주 수업 진행 요일</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                },
                components: {
                  Select: {
                    optionHeight: 48,
                    optionPadding: 12,
                    optionFontSize: 16,
                    optionSelectedFontWeight: 700,
                    colorTextDisabled: Color.gray800,
                    colorBgContainerDisabled: 'white',
                  },
                },
              }}
            >
              <CustomSelect
                placeholder="요일 선택"
                value={dayOfWeekToString(parseInt(dayOfWeek!!))}
                disabled
              />
            </ConfigProvider>
          </Wrapper>
          <Wrapper>
            <Description>수업 시작 시간</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                },
                components: {
                  Select: {
                    optionHeight: 48,
                    optionPadding: 12,
                    optionFontSize: 16,
                    optionSelectedFontWeight: 700,
                  },
                },
              }}
            >
              <CustomSelect
                placeholder="시간 선택"
                options={handleStartTimeOptions()}
                onChange={handleStartTimeChange}
                disabled={
                  startDate?.length === 0 ||
                  startDate === undefined ||
                  endDate?.length === 0 ||
                  endDate === undefined
                }
              />
            </ConfigProvider>
          </Wrapper>
          <Wrapper>
            <Description>수업 종료 시간</Description>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  colorTextPlaceholder: Color.gray500,
                  borderRadius: 8,
                },
                components: {
                  Select: {
                    optionHeight: 48,
                    optionPadding: 12,
                    optionFontSize: 16,
                    optionSelectedFontWeight: 700,
                  },
                },
              }}
            >
              <CustomSelect
                placeholder="시간 선택"
                options={handleEndTimeOptions()}
                onChange={handleEndTimeChange}
                disabled={
                  startDate?.length === 0 ||
                  startDate === undefined ||
                  endDate?.length === 0 ||
                  endDate === undefined
                }
              />
            </ConfigProvider>
          </Wrapper>
        </Container>
      )}
    </>
  );
};
export default Period;

const Container = styled.div<{ typeOption: number }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.typeOption === 1 ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)'};
  gap: 8px;
  @media screen and (max-width: 850px) and (min-width: 501px) {
    grid-template-columns: ${(props) =>
      props.typeOption === 1 ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)'};
    & > div:nth-child(1),
    & > div:nth-child(2) {
      grid-column: span 3;
    }
    & > div:nth-child(3) {
      grid-column: ${(props) => (props.typeOption === 1 ? 'span 3' : 'span 2')};
    }
    & > div:nth-child(4),
    & > div:nth-child(5) {
      grid-column: span 2;
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: ${(props) =>
      props.typeOption === 1 ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'};
  }
`;
const Wrapper = styled.div`
  width: 100%;
`;
const Description = styled.div`
  color: ${Color.gray600};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;
const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  height: 48px;
`;
const CustomSelect = styled(Select)`
  width: 100%;
  height: 48px;
`;
