import { InboxOutlined } from '@ant-design/icons';
import React from 'react';
import { Upload } from 'antd';
import Color from '../../../../_common/view/style/Color';

export default function AdminUpload(props: any) {
  const baseUrl = 'https://assets.bae-f.com/';

  return (
    <Upload.Dragger
      maxCount={1}
      multiple={false}
      name="files"
      accept={'image/*'}
      customRequest={() => {}}
      style={{ backgroundColor: 'white', border: 'none' }}
      showUploadList={false}
      beforeUpload={props.onChange}
    >
      {props.preview ? (
        <img src={baseUrl + props.preview} style={{ width: '100%', height: '100%' }} />
      ) : (
        <div>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text" style={{ color: Color.gray300 }}>
            {props.size} (png,jpg)
          </p>
        </div>
      )}
    </Upload.Dragger>
  );
}
