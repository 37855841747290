import React from 'react';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import { useNavigate } from 'react-router-dom';

export default function SideBar(props: any) {
  const navigate = useNavigate();

  const tabInfo = [
    {
      title: '회원정보 수정',
      path: '/mypage',
    },
    {
      title: '신청 배움',
      path: '/applystudy',
    },
    {
      title: '결제 관리',
      path: '/managepayment',
    },
  ];
  const navigation = (path: string) => {
    navigate(`${path}`);
  };

  return (
    <Container>
      {tabInfo.map((info, i) => (
        <Tab key={i} activeindex={i === props.activeindex} onClick={() => navigation(info.path)}>
          {info.title}
        </Tab>
      ))}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: fit-content;

  @media screen and (max-width: 1023px) {
    flex-direction: row;
    padding-bottom: 36px;
    border-bottom: 1px solid ${Color.gray200};
    width: 100%;
    justify-content: space-around;
  }
`;
const Tab = styled.div<{ activeindex: boolean }>`
  color: ${(props) => (props.activeindex ? Color.primary500 : Color.gray800)};
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;

  &:hover {
    color: ${Color.primary500};
    cursor: pointer;
  }
`;
