import React, { useEffect, useState } from 'react';
import AdminLayout from '../AdminLayout';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  Modal,
  Pagination,
  Select,
  Space,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Color from '../../../../_common/view/style/Color';
import { SmallDashOutlined } from '@ant-design/icons';
import { Comment, Post, STATUS } from '../../../../community/domain/community';
import {
  deleteSinglePost,
  findPostComment,
  findPostPage,
  modifyCommentStatus,
  modifyPostStatus,
} from '../../../../community/repository/community-repository';

const { Search } = Input;
const AdminArticle = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [contents, setContents] = useState<Array<Post>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [selectedBoardIdx, setSelectedBoardIdx] = useState<number | null>(null);
  const getContents = async (search: string | undefined, page?: number) => {
    const contentsPage = await findPostPage(true, pageNumber - 1, selectedBoardIdx, 10, search);

    setContents(contentsPage?.contents as Array<Post>);
    setTotalElements(contentsPage?.totalElements as number);
  };
  const [selectedItem, setSelectedItem] = useState<Post | null>(null);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [commentContents, setCommentContents] = useState<Array<Comment>>([]);
  const [commentPageNumber, setCommentPageNumber] = useState<number>(1);
  const [commentTotalElements, setCommentTotalElements] = useState<number>(0);

  useEffect(() => {
    getContents(searchValue);
  }, [pageNumber, selectedBoardIdx]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const getCommentContents = async (postIdx: number) => {
    const commentsPage = await findPostComment(pageNumber - 1, postIdx, 10);
    setCommentContents(commentsPage?.contents as Array<Comment>);
    setCommentTotalElements(commentsPage?.totalElements as number);
  };

  const showModal2 = async (postIdx: number) => {
    await getCommentContents(postIdx);
    setIsModal2Open(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedBoardIdx(value ? parseInt(value) : null);
  };

  const convertFormat = (date: string) => {
    return date.split('T')[0] + ' ' + date.split('T')[1].split('.')[0];
  };

  const openNewPage = (item: Post) => {
    if (item.status === 'INACTIVE') {
      alert('비활성화된 게시글입니다.');
      return;
    }
    if (item.status === 'VIOLATION') {
      alert('정책 위반된 게시글입니다.');
      return;
    }
    window.open('/community/' + item.idx, '_blank');
  };

  const onModifyPostStatus = async (idx: number, status: STATUS) => {
    const result = await modifyPostStatus(idx, status);
    if (result) {
      alert('게시글 상태가 변경되었습니다.');
      await getContents(searchValue);
    } else {
      alert('게시글 상태 변경에 실패했습니다.');
    }
  };

  const onModifyCommentStatus = async (idx: number, status: STATUS) => {
    const result = await modifyCommentStatus(idx, status);
    if (result) {
      alert('댓글 상태가 변경되었습니다.');
      if (selectedItem) {
        await getCommentContents(selectedItem.idx);
      } else {
        setIsModal2Open(false);
      }
    } else {
      alert('댓글 상태 변경에 실패했습니다.');
    }
  };

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '3%',
                }}
              >
                강좌 관리
              </div>
              <div style={{ display: 'flex', marginBottom: '3%' }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: Color.primary500,
                      controlHeight: 48,
                      colorBorder: Color.gray500,
                      colorTextPlaceholder: Color.gray500,
                      fontSize: 16,
                      colorBorderBg: Color.gray800,
                    },
                  }}
                >
                  <Search
                    placeholder="검색어 입력 후 Enter 키를 엽력해주세요."
                    onSearch={async (value, e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      await getContents(searchValue, 1);
                      setPageNumber(1);
                    }}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </ConfigProvider>
                <Select
                  defaultValue="카테고리"
                  style={{
                    width: '28%',
                    marginLeft: '2%',
                    height: '48px',
                    borderRadius: '8px',
                    border: `1px solid ${Color.gray500}`,
                  }}
                  onChange={handleChange}
                  options={[
                    { label: '전체', value: null },
                    { label: '자유게시판', value: '1' },
                    { label: '강좌 개설 요청', value: '2' },
                    { label: '모임 자랑', value: '3' },
                  ]}
                />
              </div>
            </div>
          </Header>
          <Content>
            <div
              style={{
                padding: 16,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <div>
                <table
                  className="common-table"
                  style={{
                    borderCollapse: 'collapse',
                    borderSpacing: '0 10px',
                    width: '100%',
                    marginBottom: '30px',
                  }}
                >
                  <thead>
                    <tr>
                      <td className="common-table-header-column">번호</td>
                      <td className="common-table-header-column">작성일</td>
                      <td className="common-table-header-column">게시판</td>
                      <td className="common-table-header-column">제목</td>
                      <td className="common-table-header-column">익명여부</td>
                      <td className="common-table-header-column">작성자</td>
                      <td className="common-table-header-column">조회수</td>
                      <td className="common-table-header-column">좋아요수</td>
                      <td className="common-table-header-column">댓글수</td>
                      <td className="common-table-header-column" style={{ width: '90px' }}>
                        상태
                      </td>
                      <td className="common-table-header-column"> </td>
                    </tr>
                  </thead>
                  <tbody>
                    {contents ? (
                      contents.map((item, index) => (
                        <tr
                          className="common-table-row"
                          style={{
                            marginBottom: '5%',
                            borderBottom: `1px solid ${Color.gray200}`,
                            cursor: 'pointer',
                          }}
                          key={index}
                        >
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {item.idx}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {' '}
                            {convertFormat(item.createdAt)}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {item.boardTitle}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {item.title}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {item.anonymous ? '익명' : '실명'}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {' '}
                            {item.writer.name}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {' '}
                            {item.hitCount}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {' '}
                            {item.likeCount}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {' '}
                            {item.commentCount}
                          </td>
                          <td className="common-table-column" onClick={() => openNewPage(item)}>
                            {' '}
                            {chipStatus(item.status)}
                          </td>
                          <td className="common-table-column">
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: '0',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          type="text"
                                          disabled={item.commentCount === 0}
                                          onClick={() => showModal2(item.idx)}
                                        >
                                          댓글보기
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                  {
                                    key: '1',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          type="text"
                                          disabled={item.status === 'ACTIVE'}
                                          onClick={() => onModifyPostStatus(item.idx, 'ACTIVE')}
                                        >
                                          활성화
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                  {
                                    key: '2',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          type="text"
                                          disabled={item.status === 'INACTIVE'}
                                          onClick={() => onModifyPostStatus(item.idx, 'INACTIVE')}
                                        >
                                          비활성화
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                  {
                                    key: '3',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          type="text"
                                          disabled={item.status === 'VIOLATION'}
                                          onClick={() => onModifyPostStatus(item.idx, 'VIOLATION')}
                                        >
                                          정책 위반 처리
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                  {
                                    key: '4',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button type="text" onClick={showModal}>
                                          삭제하기
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                ],
                              }}
                              trigger={['click']}
                            >
                              <a
                                onClick={(e) => {
                                  setSelectedItem(item);
                                  e.preventDefault();
                                }}
                              >
                                <Space>
                                  <SmallDashOutlined />
                                </Space>
                              </a>
                            </Dropdown>

                            <Modal
                              open={isModalOpen}
                              mask={false}
                              closable={false}
                              width={400}
                              footer={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Button
                                    type={'primary'}
                                    style={{
                                      color: 'white',
                                      backgroundColor: Color.primary500,
                                    }}
                                    onClick={async () => {
                                      if (
                                        selectedItem?.idx &&
                                        (await deleteSinglePost(selectedItem.idx))
                                      ) {
                                        alert('삭제 처리가 완료되었습니다.');
                                        await getContents(searchValue);
                                        handleCancel();
                                        return;
                                      }
                                    }}
                                  >
                                    게시글 삭제
                                  </Button>
                                  <Button
                                    type={'text'}
                                    style={{
                                      color: Color.primary500,
                                      fontWeight: '700',
                                      marginLeft: '0px',
                                    }}
                                    onClick={handleCancel}
                                  >
                                    돌아가기
                                  </Button>
                                </div>
                              }
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <p style={{ fontWeight: '700' }}>정말로 삭제하나요?</p>
                                <span>게시글의 모든 정보가 삭제됩니다.</span>
                                <span style={{ marginBottom: '5%' }}>
                                  삭제 처리 후에는 되돌릴 수 없습니다.
                                </span>
                              </div>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <Modal
                  mask={false}
                  open={isModal2Open}
                  closable={false}
                  maskClosable={true}
                  onCancel={handleCancel2}
                  cancelText={'닫기'}
                  cancelButtonProps={{
                    style: { color: Color.primary500, backgroundColor: 'white' },
                  }}
                  footer={
                    <Button
                      onClick={handleCancel2}
                      style={{ color: Color.primary500, backgroundColor: 'white' }}
                    >
                      닫기
                    </Button>
                  }
                  width={1200}
                >
                  {commentContents && commentContents.length > 0 ? (
                    <div>
                      <p style={{ fontSize: '16px', fontWeight: '600' }}>
                        총 댓글{' '}
                        <span style={{ color: Color.primary500 }}>{commentTotalElements}</span>개
                      </p>
                      <hr />
                      <table
                        className="common-table"
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '0 10px',
                          width: '100%',
                          marginBottom: '30px',
                        }}
                      >
                        <thead>
                          <tr>
                            <td className="common-table-header-column">번호</td>
                            <td className="common-table-header-column">내용</td>
                            <td className="common-table-header-column">작성자</td>
                            <td className="common-table-header-column">작성시간</td>
                            <td className="common-table-header-column">수정시간</td>
                            <td className="common-table-header-column" style={{ width: '90px' }}>
                              상태
                            </td>
                            <td className="common-table-header-column"> </td>
                          </tr>
                        </thead>
                        <tbody>
                          {commentContents.map((item, index) => (
                            <tr
                              className="common-table-row"
                              style={{
                                marginBottom: '5%',
                                borderBottom: `1px solid ${Color.gray200}`,
                                cursor: 'pointer',
                              }}
                              key={index}
                            >
                              <td
                                className="common-table-column"
                                onClick={() => {
                                  if (selectedItem) {
                                    openNewPage(selectedItem);
                                  }
                                }}
                              >
                                {item.idx}
                              </td>
                              <td
                                className="common-table-column"
                                onClick={() => {
                                  if (selectedItem) {
                                    openNewPage(selectedItem);
                                  }
                                }}
                              >
                                {shortText(item.content, 20)}
                              </td>
                              <td
                                className="common-table-column"
                                onClick={() => {
                                  if (selectedItem) {
                                    openNewPage(selectedItem);
                                  }
                                }}
                              >
                                {item.writer.email}
                              </td>
                              <td
                                className="common-table-column"
                                onClick={() => {
                                  if (selectedItem) {
                                    openNewPage(selectedItem);
                                  }
                                }}
                              >
                                {convertFormat(item.createdAt)}
                              </td>
                              <td
                                className="common-table-column"
                                onClick={() => {
                                  if (selectedItem) {
                                    openNewPage(selectedItem);
                                  }
                                }}
                              >
                                {item.modifiedAt ? convertFormat(item.modifiedAt) : ''}
                              </td>
                              <td className="common-table-column">{chipStatus(item.status)}</td>
                              <td className="common-table-column">
                                <Dropdown
                                  menu={{
                                    items: [
                                      {
                                        key: '1',
                                        label: (
                                          <ConfigProvider
                                            theme={{
                                              components: {
                                                Button: {
                                                  textHoverBg: 'transparent',
                                                },
                                              },
                                            }}
                                          >
                                            <Button
                                              type="text"
                                              disabled={item.status === 'ACTIVE'}
                                              onClick={() =>
                                                onModifyCommentStatus(item.idx, 'ACTIVE')
                                              }
                                            >
                                              활성화
                                            </Button>
                                          </ConfigProvider>
                                        ),
                                      },
                                      {
                                        key: '2',
                                        label: (
                                          <ConfigProvider
                                            theme={{
                                              components: {
                                                Button: {
                                                  textHoverBg: 'transparent',
                                                },
                                              },
                                            }}
                                          >
                                            <Button
                                              type="text"
                                              disabled={item.status === 'INACTIVE'}
                                              onClick={() =>
                                                onModifyCommentStatus(item.idx, 'INACTIVE')
                                              }
                                            >
                                              비활성화
                                            </Button>
                                          </ConfigProvider>
                                        ),
                                      },
                                      {
                                        key: '3',
                                        label: (
                                          <ConfigProvider
                                            theme={{
                                              components: {
                                                Button: {
                                                  textHoverBg: 'transparent',
                                                },
                                              },
                                            }}
                                          >
                                            <Button
                                              type="text"
                                              disabled={item.status === 'VIOLATION'}
                                              onClick={() =>
                                                onModifyCommentStatus(item.idx, 'VIOLATION')
                                              }
                                            >
                                              정책 위반 처리
                                            </Button>
                                          </ConfigProvider>
                                        ),
                                      },
                                    ],
                                  }}
                                  trigger={['click']}
                                >
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Space>
                                      <SmallDashOutlined />
                                    </Space>
                                  </a>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div
                        className={'page'}
                        style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                      >
                        <Pagination
                          current={commentPageNumber}
                          defaultCurrent={1}
                          onChange={setCommentPageNumber}
                          pageSize={10}
                          total={commentTotalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                      }}
                    >
                      <span>수강자가 없습니다.</span>
                    </div>
                  )}
                </Modal>

                <div
                  className={'page'}
                  style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                >
                  <Pagination
                    current={pageNumber}
                    defaultCurrent={1}
                    onChange={setPageNumber}
                    pageSize={10}
                    total={totalElements}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default AdminArticle;

const violation = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.gray400,
      width: '90px',
    }}
  >
    정책 위반
  </div>
);

const underReport = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: 'red',
      width: '90px',
    }}
  >
    신고 접수
  </div>
);

const inactive = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.gray400,
      width: '90px',
    }}
  >
    비활성화
  </div>
);

const active = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.blue500,
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.blue,
      width: '90px',
    }}
  >
    정상
  </div>
);

const chipStatus = (status: STATUS) => {
  switch (status) {
    case 'ACTIVE':
      return active;
    case 'INACTIVE':
      return inactive;
    case 'UNDER_REPORT':
      return underReport;
    case 'VIOLATION':
      return violation;
    default:
      return <h4>UNKNOWN</h4>;
  }
};

const shortText = (text?: string, length?: number) => {
  if (!text) return '';
  let lengthValue = length ? length : 10;
  return text.length > lengthValue ? text.substr(0, lengthValue) + '...' : text;
};
