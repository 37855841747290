import React from 'react';
import styled from 'styled-components';
import Name from '../../../../coachsignin/component/Info/Name';
import Keyword from '../../../../coachsignin/component/Info/Keyword';
import Thumbnail from '../../../../coachsignin/component/Info/Thumbnail';
import Editor from '../../../../coachsignin/component/Info/Editor';
import Color from '../../../../_common/view/style/Color';

const CourseEditInfo = (value: any) => {
  return (
    <Container>
      <Title>배움 정보</Title>
      <Content>
        <SubTitle>배움명</SubTitle>
        <Name />
      </Content>

      {/*<Content>*/}
      {/*    <SubTitle>배움 키워드</SubTitle>*/}
      {/*    <Keyword/>*/}
      {/*</Content>*/}

      <Content>
        <SubTitle>썸네일 등록</SubTitle>
        <Thumbnail />
      </Content>

      <Content>
        <SubTitle>배움 상세 내용</SubTitle>
        <Editor />
      </Content>
    </Container>
  );
};

export default CourseEditInfo;

const Container = styled.div`
  padding: 56px 72px;
  border-radius: 16px;
  border: 1px solid ${Color.gray200};
  width: 756px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 124px);
  }
  @media screen and (max-width: 768px) {
    width: calc(100% - 48px);
    padding: 56px min(72px, 10%);
  }
  @media screen and (max-width: 360px) {
    width: calc(100% - 32px);
    padding: 56px 16px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${Color.gray800};
  margin-bottom: 40px;
`;
const SubTitle = styled.div`
  font-size: 20px;
  color: ${Color.gray800};
  font-weight: 700;
  margin-bottom: 16px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
