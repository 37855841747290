import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import '../style/CoachInfo.css';
import Styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import CoachFilterDropDown from './CoachFilterDropDown';
import {
  AccountHolderState,
  AccountNumberState,
  SettleInfoState,
} from '../../state/CoachSignUp-State';
import { CoachSignUpRequest } from '../../domain/coach-request';
import { signUpRequestState } from '../../../signup/state/SignupState';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { createCoach } from '../../repository/coach-repository';
import { thumbnailUrlState } from '../../../coachsignin/state/CourseSignInState';
import { StoragePath, upload } from '../../../_common/repository/storage-repository';
import styled from 'styled-components';

const CoachInfo = () => {
  const navigate = useNavigate();
  const request = useRecoilValue(signUpRequestState);

  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankCode, setBankCode] = useRecoilState(SettleInfoState);

  const [career, setCareer] = useState('');
  const [experience, setExperience] = useState('');

  useEffect(() => {
    if (!request) {
      navigate('/coachsignup');
    }
  }, []);

  const submit = async () => {
    if (!request) {
      navigate('/coachsignup');
    }
    if (!career || career === '') {
      alert('경력을 입력해주세요.');
      return;
    }
    if (!experience || experience === '') {
      alert('강의 이력을 입력해주세요.');
      return;
    }
    if (!accountHolder || accountHolder === '') {
      alert('예금주명을 입력해주세요.');
      return;
    }
    if (!accountNumber || accountNumber === '') {
      alert('계좌번호를 입력해주세요.');
      return;
    }
    if (!bankCode || bankCode === '') {
      alert('은행을 선택해주세요.');
      return;
    }
    if (!accountImageUrl || accountImageUrl === '') {
      alert('계좌 사본을 첨부해주세요.');
      return;
    }
    const postData: CoachSignUpRequest = {
      user: {
        email: request!!.user.email,
        passwordValue: request!!.user.passwordValue,
        basicInfo: {
          name: request!!.user.basicInfo.name,
          gender: request!!.user.basicInfo.gender,
          birth: request!!.user.basicInfo.birth,
          phoneNumber: request!!.user.basicInfo.phoneNumber,
          address: request!!.user.basicInfo.address,
        },
        regionIdx: request!!.user.regionIdx,
      },
      categoryIdxes: request!!.categoryIdxes ?? [],
      agreements: request!!.agreements ?? [],
      career: career,
      educationHistory: experience,
      settleInfo: {
        accountType: 'PERSONAL',
        personalNumber: request!!.user.basicInfo.birth.replace(/-/gi, ''),
        accountBank: bankCode,
        accountHolder: accountHolder,
        accountNumber: accountNumber,
        accountImageUrl: accountImageUrl!!,
      },
    };

    console.log('postData :: ' + JSON.stringify(postData));

    await createCoach(postData)
      .then(() => {
        alert('강사 신청이 완료되었습니다.');
        window.location.href = '/login?email=' + request!!.user.email;
      })
      .catch((error) => {
        alert('강사 신청에 실패하였습니다.');
        console.log(error);
      });
  };

  const [accountImageUrl, setAccountImageUrl] = useRecoilState(thumbnailUrlState);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onClickUploadBtn = (): void => {
    inputRef.current?.click();
  };
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = e.currentTarget;
    const selectedFile = (target.files as FileList)[0];
    if (selectedFile) {
      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);
      img.onload = () => {
        setFileName(selectedFile.name);
        // 추가로 필요한 작업 수행
        const path: StoragePath = {
          bucket: 'DOMAIN_IMAGE',
          domain: 'account',
          category: 'contents',
        };
        upload(selectedFile, path).then((url) => {
          setAccountImageUrl('https://assets.bae-f.com/' + url);
        });
      };
    }
  };

  const [fileName, setFileName] = useState(
    '중요한 개인정보를 마스킹해주세요 (주민등록번호 뒷자리)',
  );

  return (
    <div className={'CoachInfo'}>
      <div className="TeacherInfo">
        <div className="Head">
          <h2>강사 정보 입력</h2>
        </div>
        <div className="Carrer-1">
          <h2>경력 사항</h2>
          <div>
            <div className="coursetext">
              <div className="coursetextarea">
                <TextArea
                  placeholder="경력을 적어주세요 (ex. 2023.01 - 2023.01 / 회사명 / 직책)"
                  style={{ width: '100%', height: '100px' }}
                  value={career}
                  onChange={(e) => setCareer(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>{}</div>
          <h2>강의 이력</h2>
          <div>
            <div className="coursetext">
              <div className="coursetextarea">
                <TextArea
                  placeholder="강의 이력을 적어주세요 (ex. 기업 임직원 대상 엑셀 교육 진행)"
                  style={{ width: '100%', height: '100px' }}
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="AccountInfo">
          <h2>계좌 정보</h2>
          <CoachFilterDropDown name="은행을 입력해주세요" />
          <Margindiv>
            <Input
              className={'AccountInput'}
              onChange={(e) => setAccountHolder(e.target.value)}
              placeholder="예금주명을 입력해주세요."
            />
          </Margindiv>
          <Margindiv>
            <Input
              className={'AccountInput'}
              onChange={(e) => setAccountNumber(e.target.value)}
              placeholder="계좌번호를 숫지로 입력해주세요."
            />
          </Margindiv>

          <Container onClick={onClickUploadBtn}>
            <img width={24} height={24} src={require('../../../_common/view/img/attachment.png')} />
            <Description fileName={fileName}>{fileName}</Description>
            <input
              ref={inputRef}
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </Container>

          <Button
            className={'next'}
            style={{ backgroundColor: Color.primary500 }}
            type="primary"
            onClick={submit}
          >
            강사 신청 완료{' '}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CoachInfo;

const Margindiv = Styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 64%;
        @media screen and (max-width: 1023px){
        width: 100%;
        margin-right: 10px;
        margin-left: 10px;
    }
    `;

const Container = styled.button`
  width: 64%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${Color.gray200};
  padding: 28px 12px;
  background-color: white;
`;
const Description = styled.div<{ fileName: string }>`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) =>
    props.fileName === '중요한 개인정보를 마스킹해주세요 (주민등록번호 뒷자리)'
      ? Color.gray400
      : Color.gray800};
`;
