import React, { useEffect } from 'react';
import styled from 'styled-components';
import BasicInfo from '../component/BasicInfo';
import Info from '../component/Info';
import { Button, ConfigProvider } from 'antd';
import Color from '../../_common/view/style/Color';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  categoryState,
  courseContentsState,
  courseNameState,
  endDateState,
  keywordState,
  maxCapacityState,
  periodDayOfWeekState,
  periodEndDateState,
  periodEndTimeState,
  periodStartDateState,
  periodStartTimeState,
  priceState,
  priceValueState,
  startDateState,
  thumbnailUrlState,
  typeState,
} from '../state/CourseSignInState';

import { CourseRequest } from '../domain/course-request';
import { createCourse } from '../repository/course-repository';
import { useNavigate } from 'react-router-dom';
import silverClient from '../../_common/client/silver-client';

const CoachSignIn = () => {
  const navigate = useNavigate();

  const user = silverClient.getUser();
  const [categoryOption, setCategoryOption] = useRecoilState(categoryState);
  const [rqStartDateOption, setRqStartDateOption] = useRecoilState(startDateState);
  const [rqEndDateOption, setRqEndDateOption] = useRecoilState(endDateState);
  const [typeOption, setTypeOption] = useRecoilState(typeState);
  const [opStartDateOption, setOpStartDateOption] = useRecoilState(periodStartDateState);
  const [opEndDateOption, setOpEndDateOption] = useRecoilState(periodEndDateState);
  const [opStartTimeOption, setOpStartTimeOption] = useRecoilState(periodStartTimeState);
  const [opEndTimeOption, setOpEndTimeOption] = useRecoilState(periodEndTimeState);
  const [dayOfWeekOption, setDayOfWeekOption] = useRecoilState(periodDayOfWeekState);
  const [priceOption, setPriceOption] = useRecoilState(priceState);
  const [priceValueOption, setPriceValueOption] = useRecoilState(priceValueState);
  const [maxCapacityOption, setMaxCapacityOption] = useRecoilState(maxCapacityState);
  const [courseNameOption, setCourseNameOption] = useRecoilState(courseNameState);
  const [keywordOption, setKeywordOption] = useRecoilState(keywordState);
  const [thumbnailUrlOption, setThumbnailUrlOption] = useRecoilState(thumbnailUrlState);
  const [courseContentsOption, setCourseContentsOption] = useRecoilState(courseContentsState);

  useEffect(() => {
    setCategoryOption(1);
    setRqStartDateOption('');
    setRqEndDateOption('');
    setTypeOption(1);
    setOpStartDateOption('');
    setOpEndDateOption('');
    setOpStartTimeOption('');
    setOpEndTimeOption('');
    setDayOfWeekOption('');
    setPriceOption(1);
    setPriceValueOption(0);
    setMaxCapacityOption(0);
    setCourseNameOption('');
    setKeywordOption([]);
    setThumbnailUrlOption('');
    setCourseContentsOption('');
  }, []);

  const opStartDateTime = opStartDateOption + ' ' + opStartTimeOption + ':00';
  const opEndDateTime =
    typeOption === 1
      ? opStartDateOption + ' ' + opEndTimeOption + ':00'
      : opEndDateOption + ' ' + opEndTimeOption + ':00';

  const isValidField = () => {
    if (!rqStartDateOption || !rqEndDateOption) {
      alert('모집 기간을 입력해주세요.');
      return false;
    }
    console.log('typeOption :: ' + typeOption);
    if (typeOption === 1) {
      console.log('opStartDateOption :: ' + opStartDateOption);
      console.log('opStartTimeOption :: ' + opStartTimeOption);
      console.log('opEndTimeOption :: ' + opEndTimeOption);
      if (!opStartDateOption || !opStartTimeOption || !opEndTimeOption) {
        alert('배움 기간을 입력해주세요.');
        return false;
      }
    } else {
      if (
        !opStartDateOption ||
        !opStartTimeOption ||
        !opEndDateOption ||
        !opEndTimeOption ||
        !dayOfWeekOption
      ) {
        alert('배움 기간을 입력해주세요.');
        return false;
      }
    }
    if (!priceValueOption || priceValueOption < 0) {
      if (priceOption === 2) {
        alert('배움 가격을 입력해주세요.');
        return false;
      }
    }
    if (!maxCapacityOption || maxCapacityOption < 0) {
      alert('배움 인원을 입력해주세요.');
      return false;
    }
    if (!courseNameOption || courseNameOption.trim().length === 0) {
      alert('배움명을 입력해주세요.');
      return false;
    }
    if (!keywordOption || keywordOption.length === 0) {
      alert('키워드를 선택해주세요.');
      return false;
    }
    if (!thumbnailUrlOption || thumbnailUrlOption.trim().length === 0) {
      alert('썸네일을 등록해주세요.');
      return false;
    }
    if (!courseContentsOption || courseContentsOption.trim().length === 0) {
      alert('배움 상세 내용을 입력해주세요.');
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (!isValidField()) {
      return;
    }
    const request: CourseRequest = {
      title: courseNameOption,
      thumbnailUrl: thumbnailUrlOption!!,
      contents: courseContentsOption!!,
      maxCapacity: maxCapacityOption,
      price: priceValueOption,
      meetingType: 'OFFLINE',
      regionIdx: user?.regionIdx!!,
      operatingPeriod: {
        start: opStartDateTime,
        end: opEndDateTime,
      },
      operatingDayOfWeekInfo: dayOfWeekToString(parseInt(dayOfWeekOption!!)),
      operatingTimeInfo: opStartTimeOption + ' ~ ' + opEndTimeOption,
      recruitmentPeriod: {
        start: rqStartDateOption + ' 00:00:00',
        end: rqEndDateOption + ' 23:59:59',
      },
      teacherIdx: user?.idx!!,
      type: categoryOption === 1 ? 'A' : 'B',
      categoryIdxes: keywordOption.map((keyword) => Number.parseInt(keyword)),
    };
    const created = await createCourse(request);
    if (created) {
      console.log('created :: ' + JSON.stringify(created));
      alert('강좌가 정상적으로 등록되었습니다.');
      navigate('/coursecontrol');
    }
  };

  const dayOfWeekToString = (dayOfWeek: number) => {
    switch (dayOfWeek) {
      case 1:
        return '매주 월요일';
      case 2:
        return '매주 화요일';
      case 3:
        return '매주 수요일';
      case 4:
        return '매주 목요일';
      case 5:
        return '매주 금요일';
      case 6:
        return '매주 토요일';
      case 7:
        return '매주 일요일';
      default:
        return '';
    }
  };

  return (
    <>
      <Container>
        <Title>배움 개설</Title>
        <BasicInfo />
        <Info />
        {/*<Ask/>*/}

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              fontSize: 16,
              borderRadius: 8,
            },
            components: {
              Button: {
                defaultBg: Color.primary500,
                defaultColor: 'white',
                fontWeight: 500,
              },
            },
          }}
        >
          <Button block={true} style={{ height: 44, width: 300 }} onClick={submit}>
            배움 개설
          </Button>
        </ConfigProvider>
      </Container>
    </>
  );
};

export default CoachSignIn;

const Container = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 48px 0px;

  @media screen and (max-width: 1024px) {
    padding: 48px 62px;
  }
  @media screen and (max-width: 768px) {
    padding: 48px max(24px, 10%);
  }
  @media screen and (max-width: 360px) {
    padding: 48px 16px;
  }
`;
const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
`;
const LaunchBtn = styled.div`
  width: 300px;
  height: 44px;
`;
