import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import Color from '../../../_common/view/style/Color';
import styled from 'styled-components';
import { userCategoryState } from '../../../user/state/user-state';
import { findCategories } from '../../../coachsignin/repository/category-repository';
import { findMyCategories } from '../../../user/repository/user-repository';
import { Category } from '../../../coachsignin/domain/category';
import silverClient from '../../../_common/client/silver-client';

const Chip = (props: any) => {
  const [categories, setCategories] = useRecoilState(userCategoryState);
  const user = silverClient.getUser();

  useEffect(() => {
    if (props.init) {
      findCategories().then((res) => {
        const categories = res ?? [];
        if (user) {
          findMyCategories().then((res) => {
            const newCategories = categories.map((c) => {
              c.selected = hasCategory(c.idx, res);
              return c;
            });
            setCategories(newCategories);
          });
        } else {
          setCategories(categories);
        }
      });
    }
  }, []);

  const toggleCategory = (idx: number) => {
    let alreadySelected = categories?.find((c) => c?.idx === idx && c.selected);
    let selectedCategories = categories?.filter((c) => c.selected);
    if (!alreadySelected && selectedCategories.length >= 3) {
      alert('최대 3개까지 선택 가능합니다.');
      return;
    }
    const newCategories = categories?.map((c: Category) => {
      let newCategory = { ...c };
      if (c?.idx === idx) {
        newCategory.selected = !c.selected;
      }
      return newCategory;
    });
    setCategories(newCategories);
  };

  const hasCategory = (idx: number, categories: Category[]): boolean => {
    return categories.some((c) => c?.idx === idx);
  };

  return (
    <Container>
      {categories?.map((c) => (
        <ChipWrapper key={c?.idx} onClick={() => toggleCategory(c?.idx)} selected={c?.selected}>
          {c?.title}
        </ChipWrapper>
      ))}
    </Container>
  );
};

export default Chip;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
const ChipWrapper = styled.div<{ selected: boolean }>`
  font-size: 14px;
  color: ${Color.primary500};
  font-weight: 500;
  border-radius: 8px;
  border: 2px solid ${Color.primary500};
  padding: 8px 24px;
  background-color: ${(props) => (props.selected ? Color.primary : 'white')};
  cursor: pointer;

  @media screen and (max-width: 767px) {
    padding: 8px 16px;
    font-size: 13px;
  }
`;
