import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import Color from '../style/Color';
import styled from 'styled-components';
import silverClient from '../../client/silver-client';

const DrawerMenu: React.FC = () => {
  const [open, setOpen] = useState(false);

  const user = silverClient.getUser();
  const navigate = useNavigate();

  const navigateToCourse = () => {
    onClose();
    navigate('/courses');
  };

  const navigateToProgram = () => {
    onClose();
    navigate('/program');
  };

  const navigateToSomoim = () => {
    onClose();
    navigate('/gathering');
  };

  const navigateToCommunity = () => {
    onClose();
    navigate('/community');
  };
  const navigateToCoach = () => {
    onClose();
    navigate('/coach');
  };
  const navigateToLogin = () => {
    onClose();
    navigate('/login');
  };
  const navigateToSignup = () => {
    onClose();
    navigate('/signup');
  };

  const navigateToCoachSignup = () => {
    onClose();
    navigate('/coachsignup');
  };

  const navigateToMyPage = () => {
    onClose();
    navigate('/mypage');
  };

  const navigatetoInfo = () => {
    onClose();
    navigate('/coachsignin');
  };

  const navigatetocontrol = () => {
    onClose();
    navigate('/coursecontrol');
  };
  const navigateToParking = () => {
    onClose();
    window.open('https://forms.gle/KpFriMMWNe7b75pj9', '_blank', 'noopener, noreferrer');
  };

  const divstyle = {
    marginBottom: '2%',
    marginLeft: '2%',
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const logout = () => {
    silverClient.logout();
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  const isLearnerUser = user?.userType === 'LEARNER';

  return (
    <>
      <Button type="text" onClick={showDrawer} style={{ backgroundColor: 'transparent' }}>
        <MenuOutlined style={{ color: Color.gray800 }} />
      </Button>

      <Drawer title="메뉴" placement="right" onClose={onClose} open={open} width="100%">
        <Container>
          <DefaultBtn onClick={navigateToCourse}>강의</DefaultBtn>
          <DefaultBtn onClick={navigateToProgram}>프로그램/행사</DefaultBtn>
          <DefaultBtn onClick={navigateToSomoim}>소모임</DefaultBtn>
          <DefaultBtn onClick={navigateToCommunity}>커뮤니티</DefaultBtn>

          {isLearnerUser ? (
            <></>
          ) : (
            <div style={divstyle}>
              <hr />
              <DefaultBtn onClick={navigatetoInfo}>배움 개설</DefaultBtn>
              <DefaultBtn onClick={navigatetocontrol}>배움 관리</DefaultBtn>
              <DefaultBtn onClick={navigateToParking}>프로그램/행사 문의</DefaultBtn>
            </div>
          )}

          {isLearnerUser ? <></> : <BorderBtn onClick={navigateToCoach}>배코치 센터</BorderBtn>}

          {user ? (
            <>
              <FilledBtn onClick={navigateToMyPage}>마이페이지</FilledBtn>
              <LogoutBtn onClick={logout}>로그아웃</LogoutBtn>
            </>
          ) : (
            <>
              <FilledBtn onClick={navigateToLogin}>로그인</FilledBtn>
              {window.location.pathname === '/coach' ? (
                <FilledBtn onClick={navigateToCoachSignup}>배코치 회원가입</FilledBtn>
              ) : (
                <FilledBtn onClick={navigateToSignup}>회원가입</FilledBtn>
              )}
            </>
          )}
        </Container>
      </Drawer>
    </>
  );
};

export default DrawerMenu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const DefaultBtn = styled.button`
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  width: 100%;
  font-weight: 500;
  color: ${Color.gray800};
  background-color: white;
  font-size: 16px;
`;
const BorderBtn = styled(DefaultBtn)`
  color: ${Color.primary500};
  border: 2px solid ${Color.primary500};
`;
const FilledBtn = styled(DefaultBtn)`
  color: white;
  background-color: ${Color.primary500};
`;
const LogoutBtn = styled(DefaultBtn)`
  color: ${Color.primary500};
`;
