import React from 'react';
import { Flex, Button } from 'antd';
import Styled from 'styled-components';
import Color from '../style/Color';
import styled from 'styled-components';

const Footer = () => {
  return (
    <Container>
      <Infotext>배프 | 사업자등록번호 262-37-01281 </Infotext>
      <Infotext>통신판매업 신고번호 2023-경남밀양-2508</Infotext>
      <Infotext>대표 손 정 민 | 개인정보처리책임자 손 정 민</Infotext>
      <Infotext>경상남도 밀양시 시청로3길 4</Infotext>
      <Infotext>배프는 통신판매 중개자이며 강좌에 대한 당사자 및 주최자가 아닙니다.</Infotext>
      <Infotext>고객센터 번호 : 070-8098-7165</Infotext>
      <BtnContainer>
        <Btn>서비스 이용 약관</Btn>
        <Btn>개인정보처리방침 </Btn>
        <Btn>취소 및 환불 약관 </Btn>
      </BtnContainer>
      <Infotext>Copyright ⓒ Beaf Co.,Ltd. All Rights Reserved</Infotext>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background-color: ${Color.gray};
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Infotext = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${Color.gray500};
`;
const BtnContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  row-gap: 0;
`;
const Btn = styled.button`
  border: none;
  background-color: ${Color.gray};
  font-size: 13px;
  font-weight: 500;
  color: ${Color.gray800};
  padding: 8px 16px;
`;
