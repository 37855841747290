import { atom } from 'recoil';
import { Program, Region } from '../domain/program';
import { Category } from '../../coachsignin/domain/category';

export const programSortState = atom({
  key: 'programSortState',
  default: 'RECOMMEND',
});

export const programPriceState = atom({
  key: 'programPriceState',
  default: 'ALL',
});

// 프로그램 배열
export const programListState = atom<Array<Program> | null>({
  key: 'programListState',
  default: null,
});

// 선택한 프로그램 1개
export const programState = atom<Program | null>({
  key: 'programState',
  default: null,
});

// 선택 가능한 관심사
export const programCategoryState = atom<Array<Category> | null>({
  key: 'programCategoryState',
  default: null,
});

// 선택한 관심사
export const programSelectedCategoryState = atom<Array<number>>({
  key: 'programSelectedCategoryState',
  default: [],
});

// 선택 가능한 지역
export const programRegionState = atom<Array<Region> | null>({
  key: 'programRegionState',
  default: null,
});

// 선택한 지역
export const programSelectedRegionState = atom<number | undefined>({
  key: 'programSelectedRegionState',
  default: undefined,
});
