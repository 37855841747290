import React, { useEffect, useState } from 'react';
import ProgramCard from './component/ProgramCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  programListState,
  programSelectedCategoryState,
  programSelectedRegionState,
  programPriceState,
  programSortState,
} from '../state/ProgramState';
import SideBar from './component/SideBar';
import { Pagination, ConfigProvider, Drawer } from 'antd';
import '../view/style/Program.css';
import styled from 'styled-components';
import Color from '../../_common/view/style/Color';
import { useMediaQuery } from 'react-responsive';
import { FilterOutlined } from '@ant-design/icons';
import { findProgramPage } from '../repository/program-repository';
import { Program } from '../domain/program';

const ProgramPage = () => {
  const [isOpened, setIsOpened] = useState(false);

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  const [program, setProgram] = useRecoilState(programListState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const selectedSort = useRecoilValue(programSortState);
  const selectedPrice = useRecoilValue(programPriceState);
  const selectedRegion = useRecoilValue(programSelectedRegionState);
  const selectedCategories = useRecoilValue(programSelectedCategoryState);
  const getProgramPages = async () => {
    const programPages = await findProgramPage(
      pageNumber - 1,
      12,
      selectedRegion,
      selectedCategories,
      selectedPrice,
      selectedSort,
    );
    setProgram(programPages?.contents as Array<Program>);
    setTotalElements(programPages?.totalElements as number);
  };

  useEffect(() => {
    getProgramPages();
  }, [pageNumber, selectedCategories, selectedSort, selectedPrice]);

  useEffect(() => {
    setPageNumber(1);
    getProgramPages();
  }, [selectedRegion]);

  return (
    <>
      {isTabletMobile && (
        <>
          <ButtonWrapper>
            <FilterButton onClick={() => setIsOpened(true)}>
              <FilterOutlined style={{ marginRight: 4 }} />
              필터
            </FilterButton>
          </ButtonWrapper>
          <ConfigProvider
            theme={{
              token: {
                paddingLG: 16,
              },
            }}
          >
            <Drawer open={isOpened} onClose={() => setIsOpened(false)} width="100%" title="필터">
              <SideBar />
            </Drawer>
          </ConfigProvider>
        </>
      )}
      <Container>
        {isPc && <SideBar />}
        <ContentContainer>
          {program?.length === 0 ? (
            <NoResult>등록된 프로그램 및 행사가 없습니다.</NoResult>
          ) : (
            <CardContainer>
              {program?.map((info, index) => <ProgramCard key={index} {...info} />)}
            </CardContainer>
          )}
          <PaginationWrapper>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: Color.primary500,
                },
              }}
            >
              <Pagination
                defaultCurrent={1}
                current={pageNumber}
                onChange={setPageNumber}
                pageSize={12}
                total={totalElements}
                showSizeChanger={false}
              />
            </ConfigProvider>
          </PaginationWrapper>
        </ContentContainer>
      </Container>
    </>
  );
};

export default ProgramPage;

const Container = styled.div`
  display: flex;
  padding: 56px 16px;
  width: calc(100% - 32px);
  @media screen and (max-width: 1023px) {
    padding-top: 0;
  }
`;
const ContentContainer = styled.div`
  width: 100%;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 2%;
  padding-right: 2%;

  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1023px) {
    padding: 0 2%;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 5%;
    margin-bottom: 10%;
  }
`;
const PaginationWrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 36px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 10%;
    margin-top: 200px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const FilterButton = styled.button`
  border: none;
  background-color: white;
  padding: 12px 24px;
  margin-right: 2%;
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray800};

  @media screen and (max-width: 1023px) {
    margin-top: 32px;
  }
`;
const NoResult = styled.div`
  font-size: 16px;
  color: ${Color.gray500};
  font-weight: 500;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
