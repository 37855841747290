import qs from 'qs';
import { Post } from '../../community/domain/community';
import silverClient from '../../_common/client/silver-client';
import { PageResponse } from '../../_common/domain/response';
import { Course } from '../../course/domain/course';
import { Program } from '../../program/domain/program';

export const findCoursePage = async (
  page: number,
  type: string,
  size: number,
  keyword: string,
): Promise<PageResponse<Course> | null> => {
  const params = qs.stringify({ page: page, type: type, size: size, keyword: keyword });

  return silverClient.get(`/v1/lectures/search?${params}`).then(
    (response) => {
      const pageResponse = response as PageResponse<Course>;
      return pageResponse;
    },
    () => {
      console.log('search findCoursePage error');
      return null;
    },
  );
};

export const findProgramPage = async (
  page: number,
  size: number,
  keyword: string,
): Promise<PageResponse<Program> | null> => {
  const params = qs.stringify({ page: page, size: size, keyword: keyword });

  return silverClient.get('/v1/programs/search?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<Program>;
      return pageResponse;
    },
    () => {
      console.log('search findProgramPage error');
      return null;
    },
  );
};

export const findPostPage = async (
  page: number,
  size: number,
  keyword: string,
): Promise<PageResponse<Post> | null> => {
  const params = qs.stringify({ page: page, size: size, searchKeyword: keyword });

  return silverClient.get(`/v1/posts?${params}`).then(
    (response) => {
      const pageResponse = response as PageResponse<Post>;
      return pageResponse;
    },
    () => {
      console.log('search findCommunityPage error');
      return null;
    },
  );
};
