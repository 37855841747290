import { Button, Checkbox, ConfigProvider, Input } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './component/style/Login.css';
import Color from '../../_common/view/style/Color';
import Logo from '../../_common/view/img/Logo.png';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import silverClient from '../../_common/client/silver-client';
import SilverClient from '../../_common/client/silver-client';

const AdminLoginPage = () => {
  useEffect(() => {
    let isAdmin = SilverClient.getUser()?.userType === 'ADMIN';
    if (isAdmin) {
      navigate('/admin/main');
    }
  }, []);

  const navigate = useNavigate();
  const [idValue, setId] = useState(localStorage.getItem('id') || '');
  const [passwordValue, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const login = async () => {
    try {
      if (isRemember) {
        localStorage.setItem('id', idValue);
      }
      await silverClient.authenticate(idValue, passwordValue).then((res) => {
        navigate('/admin/main');
      });
    } catch (error) {
      setPassword('');
      //navigate('/admin');
    }
  };
  const onChange = (e: CheckboxChangeEvent) => {
    setIsRemember(e.target.checked);
  };
  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') login();
  };
  const saveId = (e: any) => {
    setId(e.target.value);
  };

  const savePassword = (e: any) => {
    setPassword(e.target.value);
  };

  return (
    <div className="Login" style={{ marginTop: '8%' }}>
      <img src={Logo} style={{ width: '100px', height: 'auto', marginBottom: '2%' }} />
      <div className="Input">
        <Input
          className="id"
          placeholder="아이디를 입력해 주세요"
          style={{ marginBottom: '6%', width: '300px', border: '1px solid' + Color.gray600 }}
          onChange={saveId}
          value={idValue}
        />
        <br />
        <Input
          style={{ border: '1px solid' + Color.gray600, marginBottom: '6%' }}
          className="password"
          placeholder="비밀번호를 입력해주세요"
          type="password"
          onChange={savePassword}
          value={passwordValue}
          onKeyDown={(e) => onPressEnter(e)}
        />
        <br />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
            },
          }}
        >
          <Checkbox onChange={onChange}>아이디 기억하기</Checkbox>
          <br />
        </ConfigProvider>

        <Button
          className="LoginButton"
          onClick={login}
          disabled={idValue.length === 0 || passwordValue.length === 0}
          style={{ backgroundColor: Color.primary500 }}
        >
          로그인{' '}
        </Button>
      </div>
    </div>
  );
};

export default AdminLoginPage;
