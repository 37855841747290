import React, { useEffect } from 'react';
import Color from '../../_common/view/style/Color';
import styled from 'styled-components';
import { Button, ConfigProvider, Divider, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreditCardOutlined, FileTextOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { paymentReady } from '../repository/payment-repository';
import { isMobile, isTablet } from 'react-device-detect';
import { useRecoilState } from 'recoil';
import { courseState } from '../../course/state/CourseState';
import { findSingleCourse } from '../../course/repository/course-repository';
import { Region } from '../../course/domain/course';
import silverClient from '../../_common/client/silver-client';

const PaymentPage = (props: any) => {
  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  const loc = useLocation();

  const { state } = useLocation();
  const lectureIdx = state?.lectureIdx;

  const onPayment = () => {
    paymentReady(lectureIdx)
      .then((res) => {
        window.location.href =
          isMobile || isTablet ? res?.nextMobileUrl ?? '' : res?.nextPcUrl ?? '';
      })
      .catch((err) => {
        console.log('결제 에러', err);
      });
  };

  const user = silverClient.getUser();
  const [course, setCourse] = useRecoilState(courseState);

  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(loc.search);
    const param = queryParams.get('success');
    const isSuccess = param === 'true';

    if (isSuccess) {
      navigate('/applystudy');
      return;
    }

    if (lectureIdx) {
      findSingleCourse(Number(lectureIdx)).then(setCourse);
    } else {
      navigate(-1);
      alert('잘못된 접근입니다.');
      return;
    }
  }, [lectureIdx]);

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  const regionFullName = (region?: Region) => {
    return (
      (region?.region1Name ?? '') +
      (region?.region2Name ? ' ' + region?.region2Name : '') +
      (region?.region3Name ? ' ' + region?.region3Name : '')
    );
  };

  return (
    <Container>
      <Info>
        {isTabletMobile && (
          <>
            <Content style={{ gap: 16 }}>
              <Title>{state?.title}</Title>
              <CoachName>{state?.author}</CoachName>
            </Content>
            <ConfigProvider
              theme={{
                token: {
                  marginLG: 32,
                },
              }}
            >
              <Divider style={{ borderColor: Color.gray200 }} />
            </ConfigProvider>
          </>
        )}
        <Content>
          <Title>강의 정보</Title>
          <CourseInfo>
            <Label>일시</Label>
            <Colorspan>
              {convertDateFormat(course?.operatingPeriod?.start)} ~{' '}
              {convertDateFormat(course?.operatingPeriod?.end)} | {course?.operatingDayOfWeekInfo} |{' '}
              {course?.operatingTimeInfo}
            </Colorspan>
            <Label>장소</Label>
            <Colorspan>
              {regionFullName(course?.region)} {course?.addressDetail}
            </Colorspan>
          </CourseInfo>
        </Content>
        <ConfigProvider
          theme={{
            token: {
              marginLG: 32,
            },
          }}
        >
          <Divider style={{ borderColor: Color.gray200 }} />
        </ConfigProvider>
        <Content>
          <Title>신청자 정보</Title>
          <InfoWrapper>
            <Label>성함</Label>
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 48,
                  colorBorder: Color.gray500,
                  borderRadius: 8,
                  colorBgContainerDisabled: Color.gray,
                  colorTextDisabled: Color.gray800,
                  fontSize: 16,
                  colorPrimary: Color.primary500,
                },
                components: {
                  Input: {
                    paddingInline: 12,
                  },
                },
              }}
            >
              <Input value={user?.basicInfo?.name} disabled={true} />
            </ConfigProvider>
          </InfoWrapper>
          <InfoWrapper>
            <Label>번호</Label>
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 48,
                  colorBorder: Color.gray500,
                  borderRadius: 8,
                  colorBgContainerDisabled: Color.gray,
                  colorTextDisabled: Color.gray800,
                  fontSize: 16,
                  colorPrimary: Color.primary500,
                },
                components: {
                  Input: {
                    paddingInline: 12,
                  },
                },
              }}
            >
              <Input value={user?.basicInfo?.phone?.number} disabled={true} />
            </ConfigProvider>
          </InfoWrapper>
        </Content>
        <ConfigProvider
          theme={{
            token: {
              marginLG: 32,
            },
          }}
        >
          <Divider style={{ borderColor: Color.gray200 }} />
        </ConfigProvider>
        <Content>
          <Title>결제 방법</Title>
          <Choose>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: Color.primary500,
                  colorBgContainer: 'white',
                  lineWidth: 2,
                  colorText: Color.primary500,
                  borderRadius: 8,
                  controlHeight: 48,
                  fontSize: 16,
                  colorBorder: Color.primary500,
                },
                components: {
                  Button: {
                    fontWeight: 700,
                  },
                },
              }}
            >
              <Button onClick={onPayment} style={{ width: '100%' }}>
                카드 결제 <CreditCardOutlined />
              </Button>
            </ConfigProvider>
            <ConfigProvider
              theme={{
                token: {
                  lineWidth: 2,
                  colorPrimary: Color.primary500,
                  colorText: Color.primary500,
                  borderRadius: 8,
                  controlHeight: 48,
                  fontSize: 16,
                  colorBorder: Color.primary500,
                },
                components: {
                  Button: {
                    fontWeight: 700,
                  },
                },
              }}
            >
              <Button onClick={onPayment} style={{ width: '100%' }}>
                가상 계좌 <FileTextOutlined />
              </Button>
            </ConfigProvider>
          </Choose>
        </Content>
      </Info>
      <Side>
        <Title>{course?.title}</Title>
        <CoachName style={{ marginTop: 16 }}>{course?.teacher?.name}</CoachName>
        <ConfigProvider
          theme={{
            token: {
              marginLG: 32,
            },
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                marginLG: 32,
              },
            }}
          >
            <Divider style={{ borderColor: Color.gray200 }} />
          </ConfigProvider>
        </ConfigProvider>
        <div className="paymentside_content_data">
          <PriceTitle>금액 상세 정보</PriceTitle>
          <Price>
            <Colorspan>총 결제 금액</Colorspan>
            <PriceTitle style={{ color: Color.primary500 }}>
              {course?.price?.toLocaleString()}원
            </PriceTitle>
          </Price>
        </div>
        <ConfigProvider
          theme={{
            token: {
              marginLG: 32,
            },
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                marginLG: 32,
              },
            }}
          >
            <Divider style={{ borderColor: Color.gray200 }} />
          </ConfigProvider>
        </ConfigProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              controlHeight: 44,
              fontSize: 16,
              colorBgContainer: Color.primary500,
              colorBorder: Color.primary500,
              colorText: 'white',
            },
            components: {
              Button: {
                borderColorDisabled: Color.gray100,
                colorTextDisabled: Color.gray400,
                colorBgContainerDisabled: Color.gray100,
              },
            },
          }}
        >
          <Button disabled={true} style={{ width: '100%' }}>
            신청하기
          </Button>
        </ConfigProvider>
      </Side>
    </Container>
  );
};

export default PaymentPage;

const Container = styled.div`
  padding: 48px 16px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 32px;
  }
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.gray800};
`;
const Label = styled.div`
  color: ${Color.primary500};
  font-size: 16px;
  font-weight: 500;
`;

const Colorspan = styled.span`
  color: ${Color.gray600};
  font-weight: 500;
  font-size: 14px;
`;
const Info = styled.div`
  width: calc(92% - 400px);
  padding: 40px;
  border: 1px solid ${Color.gray200};
  border-radius: 16px;
  margin-right: 2%;

  @media screen and (max-width: 1023px) {
    width: calc(100% - 80px);
    margin-right: 0;
  }
  @media screen and (max-width: 767px) {
    border: none;
    padding: 24px;
    width: calc(100% - 48px);
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;
const Side = styled.div`
  padding: 32px;
  width: 336px;
  height: fit-content;
  border: 1px solid ${Color.gray200};
  border-radius: 16px;

  @media screen and (max-width: 1023px) {
    width: calc(100% - 64px);
  }
`;
const Choose = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const CoachName = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${Color.gray800};
`;
const PriceTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${Color.gray800};
`;
const Price = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const CourseInfo = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  row-gap: 12px;
  column-gap: 24px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;
