import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import { findActiveBanners } from '../../admin/repository/banners-repository';
import { AdminBanners, TYPE } from '../../admin/domain/admin-banners';
import { useMediaQuery } from 'react-responsive';
import { useRecoilState } from 'recoil';
import { bannerState } from '../state/BannerState';

const contentStyle: React.CSSProperties = {
  aspectRatio: 5 / 1,
  width: '100%',
  objectFit: 'fill',
};

const Banner = (props: any) => {
  const bannerList = props.banners as AdminBanners[];
  const isPcTablet = useMediaQuery({ query: '(min-width:768px)' });
  return (
    <Carousel
      autoplay={true}
      autoplaySpeed={2000}
      dots={false}
      draggable={true}
      pauseOnHover={true}
      swipeToSlide={true}
    >
      {bannerList.map((banner, index) => {
        let imagePath =
          'https://assets.bae-f.com/' +
          (isPcTablet ? banner?.image?.pcUrl : banner?.image?.mobileUrl);
        return (
          <div key={index}>
            <img src={banner ? imagePath : ''} style={contentStyle} />
          </div>
        );
      })}
    </Carousel>
  );
};

export default Banner;
