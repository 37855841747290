import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  Modal,
  Pagination,
  Popconfirm,
  Space,
  theme,
} from 'antd';
import AdminLayout from '../AdminLayout';
import Color from '../../../../_common/view/style/Color';
import { SmallDashOutlined } from '@ant-design/icons';
import SilverClient from '../../../../_common/client/silver-client';
import {
  findCategory,
  findLearnerPage,
  findUsersPage,
  terminateUser,
} from '../../../repository/admin-repository';
import { findCoursePage } from '../../../../course/repository/course-repository';
import { Course } from '../../../../course/domain/course';
import { useRecoilState } from 'recoil';
import { courseListState } from '../../../../course/state/CourseState';
import { User } from '../../../../user/domain/user';
import { Category } from '../../../../coachsignin/domain/category';
const { Header, Content, Footer, Sider } = Layout;

const BaeferList: React.FC = () => {
  const [contents, setContents] = useState<Array<User>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getContents = async (search: string | null, page?: number) => {
    let contentsPage = await findLearnerPage((page ? page : pageNumber) - 1, 10, search);
    setContents(contentsPage?.contents as Array<User>);
    setTotalElements(contentsPage?.totalElements as number);
  };
  const [selectedItem, setSelectedItem] = useState<User | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<Array<Category>>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  useEffect(() => {
    getContents(null);
  }, [pageNumber]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal2 = () => {
    setIsModal2Open(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const setSelectedItemAndCategory = async (item: User) => {
    setSelectedItem(item);
    const categories = (await findCategory(item.idx)) ?? [];
    setSelectedCategories(categories);
  };

  const items = [
    {
      key: '1',
      label: (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                textHoverBg: 'transparent',
              },
            },
          }}
        >
          <Button style={{ width: '100%' }} type="text" onClick={showModal}>
            탈퇴시키기
          </Button>
        </ConfigProvider>
      ),
    },
  ];

  return (
    <AdminLayout>
      <Layout>
        <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
          <div>
            <div
              style={{ lineHeight: '0px', fontSize: '20px', fontWeight: '700', marginBottom: '1%' }}
            >
              배퍼 목록
            </div>
            <Input
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  await getContents(searchValue, 1);
                  setPageNumber(1);
                }
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="검색어 입력 후 Enter 키를 엽력해주세요."
              style={{ border: '1px solid' + Color.gray600, height: '40px' }}
            />
          </div>
        </Header>
        <Content>
          <div
            style={{
              padding: 16,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <p style={{ fontSize: '16px', fontWeight: '600' }}>
              전체 배퍼 <span style={{ color: Color.primary500 }}>{totalElements}</span>명
            </p>
            <div>
              <hr />
              <table
                className="common-table"
                style={{
                  borderCollapse: 'collapse',
                  borderSpacing: '0 10px',
                  width: '100%',
                  marginBottom: '30px',
                }}
              >
                <thead>
                  <tr>
                    <td className="common-table-header-column">번호</td>
                    <td className="common-table-header-column">성함</td>
                    <td className="common-table-header-column">아이디</td>
                    <td className="common-table-header-column">가입일자</td>
                    <td className="common-table-header-column">연락처</td>
                    <td className="common-table-header-column"> </td>
                  </tr>
                </thead>
                <tbody>
                  {contents ? (
                    contents.map((item, index) => (
                      <tr
                        className="common-table-row"
                        style={{
                          marginBottom: '5%',
                          borderBottom: `1px solid ${Color.gray200}`,
                          cursor: 'pointer',
                        }}
                        key={index}
                      >
                        <td
                          className="common-table-column"
                          onClick={async (event) => {
                            await setSelectedItemAndCategory(item);
                            showModal2();
                          }}
                        >
                          {item.idx}
                        </td>
                        <td
                          className="common-table-column"
                          onClick={async (event) => {
                            await setSelectedItemAndCategory(item);
                            showModal2();
                          }}
                        >
                          {item.basicInfo.name}
                        </td>
                        <td
                          className="common-table-column"
                          onClick={async (event) => {
                            await setSelectedItemAndCategory(item);
                            showModal2();
                          }}
                        >
                          {item.email}
                        </td>
                        <td
                          className="common-table-column"
                          onClick={async (event) => {
                            await setSelectedItemAndCategory(item);
                            showModal2();
                          }}
                        >
                          {item.createdAt}
                        </td>
                        <td
                          className="common-table-column"
                          onClick={async (event) => {
                            await setSelectedItemAndCategory(item);
                            showModal2();
                          }}
                        >
                          {item.basicInfo.phone.number}
                        </td>
                        <td className="common-table-column">
                          <Dropdown menu={{ items }} trigger={['click']}>
                            <a
                              onClick={async (e) => {
                                e.preventDefault();
                                await setSelectedItemAndCategory(item);
                              }}
                            >
                              <Space>
                                <SmallDashOutlined />
                              </Space>
                            </a>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>Loading...</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Modal
                open={isModalOpen}
                mask={false}
                closable={false}
                width={350}
                footer={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      type={'primary'}
                      style={{
                        color: 'white',
                        backgroundColor: Color.primary500,
                        marginTop: '10px',
                        fontWeight: '700',
                        marginInlineStart: '0',
                      }}
                      onClick={async () => {
                        if (selectedItem?.idx) {
                          if (await terminateUser(selectedItem.idx)) {
                            alert('탈퇴 처리가 완료되었습니다.');
                            await getContents(null);
                            handleCancel();
                            return;
                          }
                        }
                        alert('탈퇴 처리에 실패하였습니다.');
                        handleCancel();
                      }}
                    >
                      탈퇴시키기
                    </Button>
                    <Button
                      type={'text'}
                      style={{
                        color: Color.primary500,
                        fontWeight: '700',
                        textAlign: 'center',
                        marginLeft: '0px',
                      }}
                      onClick={handleCancel}
                    >
                      돌아 가기
                    </Button>
                  </div>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ fontWeight: '700' }}>정말로 탈퇴시키나요??</p>
                  <span>탈퇴 처리 후에는 되돌릴 수 없습니다.</span>
                  <span>정산,환불 등 남은 이슈가 없는지 꼭 확인해주세요.</span>
                </div>
              </Modal>
              <Modal
                title="배퍼 정보"
                mask={false}
                open={isModal2Open}
                closable={false}
                maskClosable={true}
                onCancel={handleCancel2}
                cancelText={'닫기'}
                cancelButtonProps={{ style: { color: Color.primary500, backgroundColor: 'white' } }}
                footer={
                  <Button
                    onClick={handleCancel2}
                    style={{ color: Color.primary500, backgroundColor: 'white' }}
                  >
                    닫기
                  </Button>
                }
              >
                <div style={{ marginBottom: '10px' }}>
                  <table
                    style={{
                      borderCollapse: 'collapse',
                      borderSpacing: '0 10px',
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <thead>
                      <tr style={{ color: Color.primary500, fontWeight: '700' }}>
                        <td>성함</td>
                        <td>아이디</td>
                        <td>생년월일</td>
                        <td>성별</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ marginBottom: '5%' }}>
                        <td>{selectedItem?.basicInfo?.name ?? ''}</td>
                        <td>{selectedItem?.email ?? ''}</td>
                        <td>{selectedItem?.basicInfo?.birth ?? ''}</td>
                        <td>{selectedItem?.basicInfo?.gender === 'MALE' ? '남성' : '여성'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <span style={{ color: Color.primary500, fontWeight: '700' }}>주소</span>
                  <br />
                  <span>{selectedItem?.basicInfo?.address ?? ''}</span>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <span style={{ color: Color.primary500, fontWeight: '700' }}>관심사</span>
                  <br />
                  <span>{selectedCategories?.map((item, index) => item.title).join(', ')}</span>
                </div>
              </Modal>

              <div
                className={'page'}
                style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
              >
                <Pagination
                  current={pageNumber}
                  defaultCurrent={1}
                  onChange={setPageNumber}
                  pageSize={10}
                  total={totalElements}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </AdminLayout>
  );
};

export default BaeferList;
