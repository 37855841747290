import { Region } from '../../course/domain/course';
import silverClient from '../../_common/client/silver-client';
import { SingleResponse } from '../../_common/domain/response';
import { SignupRequestState } from '../domain/signup';

export const findRegionByCode = async (regionCode: string): Promise<Region | null> => {
  return silverClient.get('/v1/regions/search-code?regionCode=' + regionCode).then(
    (response) => {
      const singleResponse = response as SingleResponse<Region>;
      return singleResponse?.content;
    },
    () => {
      console.log('findRegionByCode error');
      return null;
    },
  );
};

export const signUpLearner = async (signUpRequest: SignupRequestState): Promise<boolean> => {
  return silverClient.post('/v1/learners/without-imp', signUpRequest).then(
    () => {
      return true;
    },
    () => {
      console.log('signUpLearner error');
      return false;
    },
  );
};
