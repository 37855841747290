let data = [
  {
    id: '0',
    img: 'src/_common/view/img/sampleimg.png',
    kind: '라면',
    title: '엑셀 기초 교육',
    content: '엑셀의 기초를 다룹니다.',
    location: '서울특별시 강남구',
    author: '김코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '1',
    url: '/share-application/one-serving/1',
    img: '/public-assets/one-serving/stew.jpg',
    kind: '찌개',
    title: '포토샵의 이해',
    content: '포토샵을 쉽고 빠르게',
    location: '서울특별시 강남구',
    author: '배코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '2',
    url: '/share-application/one-serving/2',
    img: '/public-assets/one-serving/noodle.jpg',
    img_alt: 'noodle',
    kind: '국수',
    title: '영상편집이란?',
    content: '누구나 쉽게 따라할 수 있는 영상편집',
    location: '서울특별시 강남구',
    author: '한코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '3',
    url: '/share-application/one-serving/0',
    img: '/public-assets/one-serving/ramen.jpg',
    img_alt: 'ramen',
    kind: '라면',
    title: '코딩 할 수 있다',
    content: '간단한 코딩으로 게임 만들기',
    location: '서울특별시 강남구',
    author: '박코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '4',
    url: '/share-application/one-serving/1',
    img: '/public-assets/one-serving/stew.jpg',
    img_alt: 'stew',
    kind: '찌개',
    title: 'Chat GTP',
    content: '최신 기술을 사용해보자',
    location: '서울특별시 강남구',
    author: '구코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '5',
    url: '/share-application/one-serving/2',
    img: '/public-assets/one-serving/noodle.jpg',
    img_alt: 'noodle',
    kind: '국수',
    title: '컴퓨터 보안',
    content: '해킹당하지 말자구요',
    location: '서울특별시 강남구',
    author: '최코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '6',
    url: '/share-application/one-serving/0',
    img: '/public-assets/one-serving/ramen.jpg',
    img_alt: 'ramen',
    title: '백엔드 기초',
    content: '백엔드 기초',
    location: '서울특별시 강남구',
    author: '감코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '7',
    url: '/share-application/one-serving/1',
    img: '/public-assets/one-serving/stew.jpg',
    img_alt: 'stew',
    title: '프론트앤드 기초',
    content: '프론트앤드 기초',
    location: '서울특별시 강남구',
    author: '남궁코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '8',
    url: '/share-application/one-serving/2',
    img: '/public-assets/one-serving/noodle.jpg',
    img_alt: 'noodle',
    title: '파워포인트 기초 교육',
    content: '파워포인트 기초',
    location: '서울특별시 강남구',
    author: '박코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '9',
    url: '/share-application/one-serving/2',
    img: '/public-assets/one-serving/noodle.jpg',
    img_alt: 'noodle',
    title: '워드를 이용한 문서작성',
    content: '워드를 이용한 문서작성',
    location: '서울특별시 강남구',
    author: '김코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '10',
    url: '/share-application/one-serving/0',
    img: '/public-assets/one-serving/ramen.jpg',
    img_alt: 'ramen',
    title: 'zoom을 이용한 화상회의',
    content: 'zoom을 이용한 화상회의',
    location: '서울특별시 강남구',
    author: '김코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '11',
    url: '/share-application/one-serving/1',
    img: '/public-assets/one-serving/stew.jpg',
    img_alt: 'stew',
    title: 'Google meet을 이용한 화상회의',
    content: 'Google meet을 이용한 화상회의',
    location: '서울특별시 강남구',
    author: '김코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '12',
    url: '/share-application/one-serving/2',
    img: '/public-assets/one-serving/noodle.jpg',
    img_alt: 'noodle',
    title: '엑셀 기초 교육',
    content: '차슈라면이 너무 먹고싶어요.. 혹시 차슈라면 할 수 있는 분',
    location: '서울특별시 강남구',
    author: '김코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '13',
    url: '/share-application/one-serving/0',
    img: '/public-assets/one-serving/ramen.jpg',
    img_alt: 'ramen',
    title: '엑셀 기초 교육',
    content: '차슈라면이 너무 먹고싶어요.. 혹시 차슈라면 할 수 있는 분',
    location: '서울특별시 강남구',
    author: '김코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
  {
    id: '14',
    url: '/share-application/one-serving/1',
    img: '/public-assets/one-serving/stew.jpg',
    img_alt: 'stew',
    title: '엑셀 기초 교육',
    content: '차슈라면이 너무 먹고싶어요.. 혹시 차슈라면 할 수 있는 분',
    location: '서울특별시 강남구',
    author: '김코치',
    sdate: '2023.11.07',
    edate: '2023.12.13',
    date: '2023.12.26 (금)',
    user_img: '/public-assets/one-serving/ramen.jpg',
  },
];

export default data;
