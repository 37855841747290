import React, { useEffect, useState } from 'react';
import CourseCard from './component/CourseCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  courseListState,
  coursePriceState,
  courseSelectedCategoriesState,
  courseSelectedRegionState,
  courseSortState,
} from '../state/CourseState';
import SideBar from './component/SideBar';
import { ConfigProvider, Drawer, Pagination } from 'antd';
import styled from 'styled-components';
import Color from '../../_common/view/style/Color';
import { FilterOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { findCoursePage } from '../repository/course-repository';
import { Course } from '../domain/course';
import { useLocation } from 'react-router-dom';

const CoursePage = () => {
  const location = useLocation();

  const [isOpened, setIsOpened] = useState(false);

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  const [course, setCourse] = useRecoilState(courseListState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const selectedSort = useRecoilValue(courseSortState);
  const selectedPrice = useRecoilValue(coursePriceState);
  const selectedCategories = useRecoilValue(courseSelectedCategoriesState);
  const selectedRegion = useRecoilValue(courseSelectedRegionState);
  const getCoursePages = async () => {
    let coursePages = await findCoursePage({
      page: pageNumber - 1,
      size: 12,
      type: location.pathname === '/courses' ? 'A' : 'B',
      categoryIdxes: selectedCategories,
      regionIdx: selectedRegion,
      sort: selectedSort,
      priceFilter: selectedPrice,
    });

    setCourse(coursePages?.contents as Array<Course>);
    setTotalElements(coursePages?.totalElements as number);
  };

  useEffect(() => {
    getCoursePages();
  }, [pageNumber, selectedCategories, , selectedSort, selectedPrice]);

  useEffect(() => {
    setPageNumber(1);
    getCoursePages();
  }, [selectedRegion]);

  return (
    <>
      {isTabletMobile && (
        <>
          <ButtonWrapper>
            <FilterButton onClick={() => setIsOpened(true)}>
              <FilterOutlined style={{ marginRight: 4 }} />
              필터
            </FilterButton>
          </ButtonWrapper>
          <ConfigProvider
            theme={{
              token: {
                paddingLG: 16,
              },
            }}
          >
            <Drawer open={isOpened} onClose={() => setIsOpened(false)} width="100%" title="필터">
              <SideBar />
            </Drawer>
          </ConfigProvider>
        </>
      )}
      <Container>
        {isPc && <SideBar />}
        <ContentContainer>
          {course?.length === 0 ? (
            <NoResult>등록된 강좌가 없습니다.</NoResult>
          ) : (
            <CardContainer>
              {course?.map((info, index) => <CourseCard key={index} {...info} />)}
            </CardContainer>
          )}
          <PaginationWrapper>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: Color.primary500,
                },
              }}
            >
              <Pagination
                defaultCurrent={1}
                current={pageNumber}
                onChange={setPageNumber}
                pageSize={12}
                total={totalElements}
                showSizeChanger={false}
              />
            </ConfigProvider>
          </PaginationWrapper>
        </ContentContainer>
      </Container>
    </>
  );
};

export default CoursePage;

const Container = styled.div`
  display: flex;
  padding: 56px 16px;
  width: calc(100% - 32px);
  @media screen and (max-width: 1023px) {
    padding-top: 0;
  }
`;
const ContentContainer = styled.div`
  width: 100%;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 2%;

  @media screen and (max-width: 1279px) {
    padding-right: 2%;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 2%;
    width: 96%;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 5%;
    width: 90%;
    margin-bottom: 10%;
  }
`;
const PaginationWrapper = styled.div`
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 25%;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const FilterButton = styled.button`
  border: none;
  background-color: white;
  padding: 12px 24px;
  margin-right: 2%;
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray800};

  @media screen and (max-width: 1023px) {
    margin-top: 32px;
  }
`;
const NoResult = styled.div`
  font-size: 16px;
  color: ${Color.gray500};
  font-weight: 500;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
