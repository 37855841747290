import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Scroll from './Scroll';
import Color from '../../../_common/view/style/Color';
import styled from 'styled-components';
import 'react-quill/dist/quill.bubble.css';
import { Divider } from 'antd';
import { findSingleCourse } from '../../repository/course-repository';
import { useRecoilState } from 'recoil';
import { courseState } from '../../state/CourseState';
import ReactQuill from 'react-quill';

const baseUrl = 'https://assets.bae-f.com/';

const CourseDetail: React.FC = () => {
  const { id } = useParams();

  const [course, setCourse] = useRecoilState(courseState);
  const getCourseDetail = async () => {
    const courseDetail = await findSingleCourse(Number(id));
    setCourse(courseDetail);
    // const teacherIdx = courseDetail?.teacher?.idx
    // if (teacherIdx) {
    //     findCareer(teacherIdx).then(setCareer)
    //     findHistory(teacherIdx).then(setExperience)
    // }
  };

  // const [career, setCareer] = useRecoilState(courseCareerState)
  // const [experience, setExperience] = useRecoilState(courseExperienceState)
  useEffect(() => {
    getCourseDetail();
  }, []);

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  return (
    <Container>
      <ContentContainer>
        <ImgContainer>
          <img
            src={baseUrl + course?.thumbnailImage.pcUrl}
            style={{ objectFit: 'contain', width: '100%' }}
          />
        </ImgContainer>
        <div>
          <Title>강좌 정보</Title>
          <LineContainer>
            <Line>
              <Label>일시</Label>
              <Detail>
                {convertDateFormat(course?.operatingPeriod.start)} ~{' '}
                {convertDateFormat(course?.operatingPeriod.end)} | {course?.operatingDayOfWeekInfo}{' '}
                | {course?.operatingTimeInfo}
              </Detail>
            </Line>
            <Line>
              <Label>모집</Label>
              <Detail>
                {convertDateFormat(course?.recruitmentPeriod.start)} ~{' '}
                {convertDateFormat(course?.recruitmentPeriod.end)}{' '}
              </Detail>
            </Line>
            <Line>
              <Label>장소</Label>
              <Detail>{course?.addressDetail}</Detail>
            </Line>
            <Line>
              <Label>인원</Label>
              <Detail>{course?.maxCapacity}명</Detail>
            </Line>
            <Line>
              <Label>비용</Label>
              <Detail>{course?.price.toLocaleString()}원</Detail>
            </Line>
          </LineContainer>
        </div>
        <Divider style={{ borderColor: Color.gray200 }} />
        <div>
          <Title>배코치 정보</Title>
          <SubTitle style={{ marginBottom: 12 }}>{course?.teacher.name}</SubTitle>
          <LineContainer>
            <Line style={{ alignItems: 'flex-start' }}>
              <Label>경력</Label>
              <DetailContainer>
                {/*{*/}
                {/*    career?.map((ca, index) => {*/}
                {/*        return <Detail key={index}>{convertDateFormat(ca?.startDate)} ~ {convertDateFormat(ca?.endDate)} {ca?.companyName} {ca?.position}</Detail>*/}
                {/*    })*/}
                {/*}*/}
                <Detail>{course?.teacher?.career}</Detail>
              </DetailContainer>
            </Line>
            <Line style={{ alignItems: 'flex-start' }}>
              <Label>이력</Label>
              <DetailContainer>
                {/*{*/}
                {/*    experience?.map((exp, index) => {*/}
                {/*        return <Detail key={index}>{convertDateFormat(exp?.startDate)} {exp.description}</Detail>*/}
                {/*    })*/}
                {/*}*/}
                <Detail>{course?.teacher?.educationHistory}</Detail>
              </DetailContainer>
            </Line>
          </LineContainer>
        </div>
        <Divider style={{ borderColor: Color.gray200 }} />
        <div>
          <Title>상세 정보</Title>
          <ReactQuill value={course?.contents ?? ''} readOnly={true} theme={'bubble'} />
        </div>
        <Divider style={{ borderColor: Color.gray200 }} />
        <div>
          <Title>환불 규정</Title>
          <Rules>
            <li>결제 당일 환불 신청 시 전체 금액 환불</li>
            <li>첫 번째 모임일 8일 전까지 환불 신청 시 전체 금액 환불</li>
            <li>모임 수강기간 또는 수강진도 1/4 경과 전: 결제 대금의 3/4 환불</li>
            <li>모임 수강기간 또는 수강진도 2/4 경과 전: 결제 대금의 2/4 환불</li>
            <li>모임 수강기간 또는 수강진도 3/4 경과 전: 결제 대금의 1/4 환불</li>
            <li>
              모임 수강기간 또는 수강진도 3/4 경과 후: 환불 불가 (3/4지점에 해당하는 모임 당일 포함)
            </li>
          </Rules>
        </div>
        <Divider style={{ borderColor: Color.gray200 }} />
        <div>
          <Title>문의</Title>
          <LineContainer>
            <Line>
              <SubTitle style={{ marginRight: 16 }}>담당자</SubTitle>
              <Detail>{course?.teacher.name}</Detail>
            </Line>
            <Line>
              <SubTitle style={{ marginRight: 16 }}>연락처</SubTitle>
              <Detail>{course?.teacher.phoneNumber}</Detail>
            </Line>
            <Line>
              <SubTitle style={{ marginRight: 16 }}>이메일</SubTitle>
              <Detail>{course?.teacher.email}</Detail>
            </Line>
          </LineContainer>
        </div>
      </ContentContainer>
      <Scroll {...course} />
    </Container>
  );
};

export default CourseDetail;

const Container = styled.div`
  padding: 48px 16px;

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    padding: 48px 24px;
  }
`;
const ContentContainer = styled.div`
  width: calc(97% - 400px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;
const ImgContainer = styled.div`
  //background-color: ${Color.gray};
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;
const CourseInfo = styled.div``;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.gray800};
  margin-bottom: 24px;
`;
const Label = styled.span`
  color: ${Color.primary500};
  font-size: 16px;
  font-weight: 500;
  margin-right: 24px;
`;
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Detail = styled.span`
  color: ${Color.gray600};
  font-weight: 500;
  font-size: 14px;
`;
const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
`;
const SubTitle = styled.div`
  color: ${Color.gray800};
  font-size: 16px;
  font-weight: 700;
`;
const Rules = styled.ul`
  padding: 0 16px;
  color: ${Color.gray800};
  font-size: 16px;
  font-weight: 500;
`;
