import React from 'react';
import MainBody from './component/MainBody';
import Banner from '../../banner/view/Banner';
import '../view/style/Main.css';

const MainPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <MainBody />
    </div>
  );
};

export default MainPage;
