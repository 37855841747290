import { atom } from 'recoil';
import { Aggrements, Carrers, Experience } from '../domain/CoachSignup';

export const EmailState = atom<string>({
  key: 'SignUpEmailState',
  default: '',
});

export const PasswordState = atom<string>({
  key: 'SignUpPasswordState',
  default: '',
});

export const AgreeState = atom<Aggrements[] | null>({
  key: 'SignUpAgreeState',
  default: [],
});

export const DateState = atom<string>({
  key: 'SignUpDateState',
  default: '',
});

export const endDateState = atom<string>({
  key: 'SignUpendDateState',
  default: '',
});

export const companyNameState = atom<string>({
  key: 'SignUpcompanyNameState',
  default: '',
});

export const positionState = atom<string>({
  key: 'SignUppositionState',
  default: '',
});

export const descriptionState = atom<string | undefined>({
  key: 'SignUpdescriptionState',
  default: undefined,
});

export const CarrerState = atom<Carrers[] | null>({
  key: 'SignUpCarrerState',
  default: null,
});

export const ExperienceState = atom<Experience[] | null>({
  key: 'SignUpExperienceState',
  default: null,
});

export const SettleInfoState = atom<string>({
  key: 'SignupSettleInfoState',
  default: '',
});

export const AccountHolderState = atom<string>({
  key: 'AccountHolderState',
  default: '',
});

export const AccountNumberState = atom<string>({
  key: 'AccountNumberState',
  default: '',
});
