import React from 'react';
import { ConfigProvider, Select } from 'antd';
import { useRecoilState } from 'recoil';
import { birthDateState } from '../../state/SignupState'; // Adjust the path based on your project structure
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';

interface Form {
  year: string;
  month: string;
  day: string;
}

function BirthDropdown() {
  const [form, setForm] = useRecoilState<Form | null>(birthDateState);
  const now = new Date();

  let years = [];
  for (let y = now.getFullYear(); y >= 1930; y -= 1) {
    years.push(y.toString());
  }

  let month = [];
  for (let m = 1; m <= 12; m += 1) {
    if (m < 10) {
      month.push('0' + m.toString());
    } else {
      month.push(m.toString());
    }
  }

  let days = [];
  let date = new Date(Number(form?.year), Number(form?.month), 0).getDate();
  for (let d = 1; d <= date; d += 1) {
    if (d < 10) {
      days.push('0' + d.toString());
    } else {
      days.push(d.toString());
    }
  }

  const handleYearChange = (value: string) => {
    setForm((prevForm) => ({ ...(prevForm as Form), year: value }));
  };

  const handleMonthChange = (value: string) => {
    setForm((prevForm) => ({ ...(prevForm as Form), month: value }));
  };

  const handleDayChange = (value: string) => {
    setForm((prevForm) => ({ ...(prevForm as Form), day: value }));
  };
  console.log(form);
  return (
    <ConfigProvider
      theme={{
        token: {
          controlHeight: 48,
          colorBorder: Color.gray500,
          colorText: Color.gray800,
          colorTextPlaceholder: Color.gray500,
          fontSize: 16,
          colorPrimary: Color.primary500,
        },
      }}
    >
      <Container>
        <Select value={form?.year} placeholder={'년도'} onChange={handleYearChange}>
          {years.map((item) => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
        <Select value={form?.month} placeholder={'월'} onChange={handleMonthChange}>
          {month.map((item) => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
        <Select value={form?.day} placeholder={'일'} onChange={handleDayChange}>
          {days.map((item) => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Container>
    </ConfigProvider>
  );
}

export default BirthDropdown;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;
