import { atom, useRecoilState } from 'recoil';
import { Adminpayment } from '../domain/admin-payment';
import { AdminBanners, AdminBannersCURequest } from '../domain/admin-banners';
import {
  AdminDate,
  OperatingPeriod,
  ProgramAddress,
  RecuritmentPeriod,
} from '../domain/admin-contents';
import { AdminReport } from '../domain/admin-report';
import { AdminSettle } from '../../payment/domain/settle';
import { STATUS } from '../../coach/domain/coach';

export const adminpaymentListState = atom<Array<Adminpayment> | null>({
  key: 'adminpaymentListState',
  default: null,
});

export const adminPaymentSearchState = atom<string | undefined>({
  key: 'adminPaymentSearchState',
  default: undefined,
});

export const adminSettleState = atom<Array<AdminSettle> | null>({
  key: 'adminSettleState',
  default: null,
});

export const adminSettleTotalState = atom<number>({
  key: 'adminSettleTotalState',
  default: 0,
});

export const adminCurrentSettleStatusState = atom<Array<STATUS>>({
  key: 'adminCurrentSettleStatusState',
  default: ['READY', 'PROCESSING', 'HOLD', 'CARRYOVER'],
});

export const adminMain1BannersList = atom<AdminBanners[] | null>({
  key: 'adminMain1BannersList',
  default: null,
});

export const adminMain1BannerCreateRequest = atom<AdminBannersCURequest | null>({
  key: 'adminMain1BannerCreateRequest',
  default: null,
});

export const adminMain2BannersList = atom<AdminBanners[] | null>({
  key: 'adminSubBannersList',
  default: null,
});

export const adminMain2BannerCreateRequest = atom<AdminBannersCURequest | null>({
  key: 'adminSubBannerCreateRequest',
  default: null,
});

//-----------------admin-program-add----------------

export const adminProgramNameState = atom<string>({
  key: 'adminProgramNameState',
  default: '',
});

export const adminProgramThumbnailUrlState = atom<string>({
  key: 'adminProgramThumbnailUrlState',
  default: '',
});

export const adminProgramAddressState = atom<ProgramAddress | null>({
  key: 'adminProgramAddressState',
  default: null,
});

export const adminProgramOperatingPeriodState = atom<OperatingPeriod | null>({
  key: 'adminProgramOperatingPeriodState',
  default: null,
});

export const adminProgramRecuritmentPeriodState = atom<RecuritmentPeriod | null>({
  key: 'adminProgramRecuritmentPeriodState',
  default: null,
});

export const adminProgramDescriptionState = atom<string>({
  key: 'adminProgramDescriptionState',
  default: '',
});

export const adminProgramPriceState = atom<number>({
  key: 'adminProgramPriceState',
  default: 0,
});

export const adminDateState = atom<AdminDate | null>({
  key: 'adminDateState',
  default: null,
});

export const adminStartDateState = atom<AdminDate | null>({
  key: 'adminStartDateState',
  default: null,
});

export const adminEndDateState = atom<AdminDate | null>({
  key: 'adminEndDateState',
  default: null,
});

export const adminstart = atom<string>({
  key: 'adminstart',
  default: '',
});

export const adminend = atom<string>({
  key: 'adminend',
  default: '',
});

export const adminProgramOrganizationState = atom<string>({
  key: 'adminProgramOrganizationState',
  default: '',
});

export const adminCategoryState = atom<number[]>({
  key: 'adminCategoryState',
  default: [],
});

//------------------------------------------

export const adminReportListState = atom<Array<AdminReport> | null>({
  key: 'adminReportListState',
  default: null,
});

export const adminMenuCurrentState = atom<string>({
  key: 'adminMenuCurrentState',
  default: '',
});

export const adminOpenKeyState = atom<string[]>({
  key: 'adminOpenKeyState',
  default: [''],
});
