import { atom } from 'recoil';
import { Category } from '../domain/category';

export const categoryState = atom({
  key: 'categoryState',
  default: 1,
});

export const startDateState = atom<string | undefined>({
  key: 'startDateState',
  default: undefined,
});

export const endDateState = atom<string | undefined>({
  key: 'endDateState',
  default: undefined,
});

export const typeState = atom<number>({
  key: 'typeState',
  default: 1,
});

export const dateState = atom({
  key: 'dateState',
  default: 1,
});

export const periodStartDateState = atom<string | undefined>({
  key: 'periodStartDateState',
  default: undefined,
});

export const periodEndDateState = atom<string | undefined>({
  key: 'periodEndDateState',
  default: undefined,
});

export const periodStartTimeState = atom<string | undefined>({
  key: 'periodStartTimeState',
  default: '00:00',
});

export const periodEndTimeState = atom<string | undefined>({
  key: 'periodEndTimeState',
  default: '00:00',
});

export const periodDayOfWeekState = atom<string | undefined>({
  key: 'periodDayOfWeekState',
  default: undefined,
});

export const priceState = atom<number>({
  key: 'priceState',
  default: 1,
});

export const priceValueState = atom<number>({
  key: 'priceValueState',
  default: 0,
});

export const maxCapacityState = atom<number>({
  key: 'maxCapacityState',
  default: 0,
});

export const courseNameState = atom<string>({
  key: 'courseNameState',
  default: '',
});

export const dayState = atom({
  key: 'dayState',
  default: 1,
});

export const keywordState = atom<string[]>({
  key: 'keywordState',
  default: [],
});

export const courseCategoriesState = atom<Array<Category>>({
  key: 'categoriesState',
  default: [],
});

export const thumbnailUrlState = atom<string | null>({
  key: 'thumbnailUrlState',
  default: undefined,
});

export const courseContentsState = atom<string | undefined>({
  key: 'courseContentsState',
  default: '',
});
