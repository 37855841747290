import React from 'react';
import styled from 'styled-components';
import Color from '../../_common/view/style/Color';
import { Radio, ConfigProvider, Space, DatePicker } from 'antd';
import { useRecoilState } from 'recoil';
import {
  categoryState,
  startDateState,
  endDateState,
  maxCapacityState,
} from '../state/CourseSignInState';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

const BasicInfo = (value: any) => {
  const [capacity, setCapacity] = useRecoilState<number>(maxCapacityState);
  const [categoryOption, setCategoryOption] = useRecoilState(categoryState);
  const handleCategoryChange = (value: any) => {
    setCategoryOption(value.target.value);
    if (value.target.value === 2 && capacity > 5) {
      setCapacity(5);
    }
  };

  const [startDate, setStartDate] = useRecoilState(startDateState);
  const handleStartDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setStartDate(date?.format('YYYY-MM-DD'));
  };
  const [endDate, setEndDate] = useRecoilState(endDateState);
  const handleEndDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setEndDate(date?.format('YYYY-MM-DD'));
  };

  return (
    <Container>
      <Title>배움 기본 정보</Title>
      <Category>
        <SubTitle>배움 종류</SubTitle>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
            },
          }}
        >
          <Radio.Group
            onChange={handleCategoryChange}
            value={categoryOption}
            style={{ width: '100%' }}
          >
            <Space direction="vertical" style={{ rowGap: 40, width: '100%' }}>
              <Radio value={1} className="id" style={{ width: '100%' }}>
                <RadioText>
                  <Name>강좌</Name>
                  <CategoryDescription>수강 최대 인원 제한이 없는 배움입니다.</CategoryDescription>
                </RadioText>
              </Radio>
              <Radio value={2}>
                <RadioText>
                  <Name>소모임</Name>
                  <CategoryDescription>
                    수강 최대 인원이 10명 넘지 않는 배움입니다.
                  </CategoryDescription>
                </RadioText>
              </Radio>
            </Space>
          </Radio.Group>
        </ConfigProvider>
      </Category>
      <Period>
        <SubTitle>모집 기간</SubTitle>
        <PeriodContainer>
          <PeriodWrapper>
            <PeriodDescription>모집 시작</PeriodDescription>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  borderRadius: 8,
                  colorTextPlaceholder: Color.gray500,
                },
              }}
            >
              <CustomDatePicker
                placeholder="날짜를 선택해주세요"
                locale={locale}
                onChange={handleStartDateChange}
                disabledDate={(current) => {
                  if (!endDate) {
                    return current && current < dayjs().endOf('day');
                  }
                  return (
                    current &&
                    (current > dayjs(endDate).endOf('day') || current < dayjs().endOf('day'))
                  );
                }}
              />
            </ConfigProvider>
          </PeriodWrapper>
          <PeriodWrapper>
            <PeriodDescription>모집 마감</PeriodDescription>
            <ConfigProvider
              theme={{
                token: {
                  colorBorder: Color.gray500,
                  colorPrimary: Color.primary500,
                  fontSize: 16,
                  borderRadius: 8,
                  colorTextPlaceholder: Color.gray500,
                },
              }}
            >
              <CustomDatePicker
                placeholder="날짜를 선택해주세요"
                locale={locale}
                onChange={handleEndDateChange}
                disabledDate={(current) => {
                  if (!startDate) {
                    return current && current < dayjs().endOf('day');
                  }
                  return current && current < dayjs(startDate).subtract(1, 'day').endOf('day');
                }}
              />
            </ConfigProvider>
          </PeriodWrapper>
        </PeriodContainer>
      </Period>
    </Container>
  );
};

export default BasicInfo;

const Container = styled.div`
  padding: 56px 72px;
  border-radius: 16px;
  border: 1px solid ${Color.gray200};
  width: 756px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 124px);
  }
  @media screen and (max-width: 768px) {
    width: calc(100% - 48px);
    padding: 56px min(72px, 10%);
  }
  @media screen and (max-width: 360px) {
    width: calc(100% - 32px);
    padding: 56px 16px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${Color.gray800};
  margin-bottom: 40px;
`;
const SubTitle = styled.div`
  font-size: 20px;
  color: ${Color.gray800};
  font-weight: 700;
  margin-bottom: 16px;
`;
const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray800};
`;
const DescriptionStyle = styled.div`
  color: ${Color.gray600};
  font-size: 14px;
  font-weight: 500;
`;
const CategoryDescription = styled(DescriptionStyle)`
  position: absolute;
  width: 100%;
  @media screen and (max-width: 360px) {
    width: auto;
    display: inline-block;
  }
`;
const PeriodDescription = styled(DescriptionStyle)`
  margin-bottom: 8px;
`;
const Category = styled.div`
  margin-bottom: 60px;
`;
const Period = styled.div``;
const PeriodContainer = styled.div`
  display: flex;
  column-gap: 8px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    column-gap: 0px;
    row-gap: 8px;
  }
`;
const PeriodWrapper = styled.div``;
const RadioText = styled.div`
  position: relative;
  width: 70vw;
`;
const CustomDatePicker = styled(DatePicker)`
  width: 196px;
  height: 48px;
  @media screen and (max-width: 600px) {
    width: min(196px, 100%);
  }
  @media screen and (max-width: 360px) {
    width: 100%;
  }
`;
