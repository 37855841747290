import React from 'react';
import './style/App.css';
import { RecoilRoot } from 'recoil';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from '../../main/view/MainPage';
import CoursePage from '../../course/view/CoursePage';
import CourseDetail from '../../course/view/component/CourseDetail';
import CoachInfo from '../../coach/view/component/CoachInfo';
import ProgramPage from '../../program/view/ProgramPage';
import EditorPage from '../../community/view/component/EditorPage';
import CommunityDetail from '../../community/view/component/CommunityDetail';
import NotFoundPage from '../../_common/view/component/NotFoundPage';
import CoachPage from '../../coach/view/CoachPage';
import CommunityPage from '../../community/view/CommunityPage';
import Parking from '../../_common/view/component/parking';
import SignUpPage from '../../signup/view/SignUpPage';
import SearchPage from '../../search/view/SearchPage';
import PaymentPage from '../../payment/view/PaymentPage';
import Mypage from '../../mypage/view/Mypage';
import ManagePayment from '../../mypage/view/Component/ManagePayment';
import ApplyStudy from '../../mypage/view/Component/ApplyStudy';
import CoachMyPage from '../../coach/view/component/CoachMyPage';
import AdminLoginPage from '../../admin/view/AdminLoginPage';
import MainLayout from '../../_common/view/component/MainLayout';
import AdminLayout from '../../admin/view/component/AdminLayout';
import BaeferList from '../../admin/view/component/UserMenu/BaeferList';
import AdminCourse from '../../admin/view/component/ContentsMenu/AdminCourse';
import AdminProgram from '../../admin/view/component/ContentsMenu/AdminProgram';
import MainBanner from '../../admin/view/component/BannerMenu/MainBanner';
import AdminPayment from '../../admin/view/component/PaymentMenu/AdminPayment';
import AdminAccount from '../../admin/view/component/AdminMenu/AdminAccount';
import BaecoachList from '../../admin/view/component/UserMenu/BaecoachList';
import AdminGather from '../../admin/view/component/ContentsMenu/AdminGather';
import AdminArticle from '../../admin/view/component/ContentsMenu/AdminArticle';
import AdminComment from '../../admin/view/component/ContentsMenu/AdminComment';
import AdminReportPage from '../../admin/view/component/ContentsMenu/AdminReportPage';
import SubBanner from '../../admin/view/component/BannerMenu/SubBanner';
import LoginPage from '../../login/view/LoginPage';
import ProgramDetail from '../../program/view/component/ProgramDetail';
import CoachSignIn from '../../coachsignin/view/CoachSignIn';
import CourseControl from '../../coach/view/component/CourseControl/CourseControl';
import StudentControl from '../../coach/view/component/StudentControl/StudentControl';
import StudentDetail from '../../coach/view/component/StudentControl/StudentDetail';
import CoachPayment from '../../coach/view/component/PaymentControl/CoachPayment';
import CoachSignUpV2Page from '../../coach/view/component/CoachSignUpV2Page';
import CourseEditPage from '../../coach/view/component/CourseControl/CourseEditPage';
import ScrollToTop from '../../_common/util/ScrollToTop';

function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<MainPage />} />
              <Route path="/signup" element={<SignUpPage />}></Route>
              <Route path="/login" element={<LoginPage />}></Route>
              <Route path="/courses" element={<CoursePage />}></Route>
              <Route path="/gathering" element={<CoursePage />}></Route>
              <Route path="/courses/:id" element={<CourseDetail />}></Route>
              <Route path="/gathering/:id" element={<CourseDetail />}></Route>
              <Route path="/payment" element={<PaymentPage />}></Route>
              <Route path="/coach" element={<CoachPage />}></Route>
              <Route path="/coachsignup" element={<CoachSignUpV2Page />}></Route>
              <Route path="/coachinfo" element={<CoachInfo />}></Route>
              <Route path="/coachsignin" element={<CoachSignIn />}></Route>
              <Route path="/coursecontrol" element={<CourseControl />}></Route>
              <Route path="/courses/:id/edit" element={<CourseEditPage />}></Route>
              <Route path="/studentcontrol" element={<StudentControl />}></Route>
              <Route path="/studentdetail/:id" element={<StudentDetail />}></Route>
              <Route path="/coachpayment" element={<CoachPayment />}></Route>
              <Route path="/coachmypage" element={<CoachMyPage />}></Route>
              <Route path="/program" element={<ProgramPage />}></Route>
              <Route path="/program/:id" element={<ProgramDetail />}></Route>
              <Route path="/community" element={<CommunityPage />}></Route>
              <Route path="/editor" element={<EditorPage />}></Route>
              <Route path="/community/:id" element={<CommunityDetail />} />
              <Route path="/search" element={<SearchPage />}></Route>
              <Route path="/mypage" element={<Mypage />}></Route>
              <Route path="/managepayment" element={<ManagePayment />}></Route>
              <Route path="/applystudy" element={<ApplyStudy />}></Route>
              <Route path="/parking" element={<Parking />} />
              <Route path="*" element={<NotFoundPage />}></Route>
            </Route>

            {/* admin */}
            <Route path="/admin" element={<AdminLoginPage />} />
            <Route path="/admin/main" element={<AdminLayout children={undefined} />} />
            <Route path="/admin/learner" element={<BaeferList />} />
            <Route path="/admin/coach" element={<BaecoachList />} />
            <Route path="/admin/course" element={<AdminCourse />}></Route>
            <Route path="/admin/gathering" element={<AdminGather />}></Route>
            <Route path="/admin/program" element={<AdminProgram />}></Route>
            <Route path="/admin/payment" element={<AdminPayment />}></Route>
            <Route path="/admin/account" element={<AdminAccount />}></Route>
            <Route path="/admin/article" element={<AdminArticle />}></Route>
            <Route path="/admin/comment" element={<AdminComment />}></Route>
            <Route path="/admin/report" element={<AdminReportPage />}></Route>
            <Route path="/admin/banner/main" element={<MainBanner />}></Route>
            <Route path="/admin/banner/sub" element={<SubBanner />}></Route>
          </Routes>
          <ScrollToTop />
        </BrowserRouter>
      </div>
    </RecoilRoot>
  );
}

export default App;
