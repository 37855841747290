import qs from 'qs';
import silverClient from '../../_common/client/silver-client';
import { PageResponse, SingleResponse } from '../../_common/domain/response';
import { Adminprogram } from '../domain/admin-contents';
import { Program } from '../../program/domain/program';
import { AdminSettle } from '../../payment/domain/settle';
import { STATUS } from '../../coach/domain/coach';

export const findSettlePage = async (
  page: number,
  size: number,
  search?: string,
  status?: Array<STATUS>,
): Promise<PageResponse<AdminSettle> | null> => {
  const params = qs.stringify({
    page: page,
    size: size,
    keyword: search,
    status: status?.join(','),
  });
  return silverClient.get('/v1/admin/settles?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<AdminSettle>;
      return pageResponse;
    },
    () => {
      console.log('payment findSettlePage error');
      return null;
    },
  );
};

export const findAdminProgramPage = async (
  page: number,
  size: number,
): Promise<PageResponse<Program> | null> => {
  const params = qs.stringify({ page: page, size: size });
  return silverClient.get('/v1/programs?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<Program>;
      return pageResponse;
    },
    () => {
      console.log('course findProgramPage error');
      return null;
    },
  );
};

export const insertAdminProgram = async (request: Adminprogram): Promise<Program | null> => {
  return silverClient.post('/v1/admin/programs', JSON.stringify(request)).then(
    (response) => {
      const singleResponse = response as SingleResponse<Program>;
      return singleResponse?.content;
    },
    () => {
      alert('강좌 생성에 실패하였습니다.');
      return null;
    },
  );
};

export const updateAdminSettleStatus = async (idx: number, status: STATUS) => {
  return silverClient.put('/v1/admin/settles/' + idx, JSON.stringify(status));
};

export const updateAdminSettleStatusBulk = async (idxes: Array<number>, status: STATUS) => {
  return silverClient.put('/v1/admin/settles/status/bulk?idxes=' + idxes + '&status=' + status, '');
};
