import React from 'react';
import Styled from 'styled-components';
import { ConfigProvider, Divider } from 'antd';
import Color from '../../../_common/view/style/Color';

const MainText = Styled.div`
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 12px;
    color: ${Color.gray800};
`;
const SubText = Styled.div`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 12px;
    color: ${Color.gray800};
`;
const Detail = Styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${Color.gray500};
`;

const SearchBoard = (props: any) => {
  const convertDateFormat = (dateString: string) => {
    const date = dateString.slice(0, 10);
    const replacedStr = date.replace(/-/g, '.');
    return replacedStr;
  };

  return (
    <div style={{ width: '100%' }}>
      <SubText>자유게시판</SubText>
      <MainText>{props.title}</MainText>
      <Detail>
        {props.writer.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{convertDateFormat(props.createdAt)}
      </Detail>
      <ConfigProvider
        theme={{
          token: {
            marginLG: 16,
          },
        }}
      >
        <Divider />
      </ConfigProvider>
    </div>
  );
};

export default SearchBoard;
