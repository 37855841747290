import { Payment, PaymentUrlResponse } from '../domain/payment';
import silverClient from '../../_common/client/silver-client';
import { PageResponse, SingleResponse } from '../../_common/domain/response';
import qs from 'qs';

export const paymentReady = async (lectureIdx: number): Promise<PaymentUrlResponse | null> => {
  try {
    const response = await silverClient.post('/v1/payments/ready?lectureIdx=' + lectureIdx);
    const singleResponse = response as SingleResponse<PaymentUrlResponse>;
    return singleResponse?.content;
  } catch (error) {
    console.log('course paymentReady error');
    return null;
  }
};
export const findPayment = async (
  page: number,
  size: number,
): Promise<PageResponse<Payment> | null> => {
  try {
    const params = qs.stringify({ page: page, size: size });
    const response = await silverClient.get('/v1/payments/learner?' + params);
    const singleResponse = response as PageResponse<Payment>;
    return singleResponse;
  } catch (error) {
    console.log(error);
    console.log('payment findPayment error');
    return null;
  }
};

export const freePayment = async (lectureIdx: number): Promise<any> => {
  return await silverClient.post('/v1/lectures/' + lectureIdx + '/apply');
};
