import React, { useEffect, useState } from 'react';
import Color from '../../../_common/view/style/Color';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import silverClient from '../../../_common/client/silver-client';
import { deleteSingleComment, modifySingleComment } from '../../repository/community-repository';
import { ConfigProvider, Input, Button } from 'antd';
import { useMediaQuery } from 'react-responsive';

const { TextArea } = Input;

const CommentApp = (props: any) => {
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const convertDateFormat = (datetime: string) => {
    const date = new Date(datetime);
    const year = String(date.getFullYear()).slice(2); // 년도에서 앞의 두 자리만 추출
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}.${month}.${day} ${hour}:${minute}`;
    return formattedDate;
  };
  const [modalShow, setModalShow] = useState(false);
  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const [isWriter, setIsWriter] = useState(false); // 현재 로그인한 계정이 글 작성자와 일치하면 true
  const checkIsWriter = () => {
    // 로그인계정 vs 글 작성자 이름으로 동일인물인지 확인
    const user = silverClient.getUser();

    if (user?.basicInfo.name === props.writer.name) setIsWriter(true);
    else setIsWriter(false);
  };

  useEffect(() => {
    checkIsWriter();
  }, []);

  const deleteComment = async () => {
    const deleteMsg = await deleteSingleComment(Number(props.idx));
    if (deleteMsg) {
      alert('댓글이 성공적으로 삭제되었습니다.');
      window.location.reload();
    } else {
      alert('다시 시도해주세요.');
    }
  };

  const [isModifying, setIsModifying] = useState(false);
  const [comment, setComment] = useState('');
  const startModify = () => {
    setIsModifying(true);
    setModalShow(false);
    setComment(props.content);
  };
  const onChangeComment = (e: any) => {
    setComment(e.target.value);
  };
  const modifyComment = async () => {
    const uploadSuccess = await modifySingleComment(Number(props.idx), comment, false);
    if (uploadSuccess) {
      window.location.reload();
    } else {
      alert('다시 시도해주세요.');
    }
  };

  return (
    <Container>
      <Left>
        <Top>
          <Writer>{props.writer.name}</Writer>
          <Time>{convertDateFormat(props.createdAt)}</Time>
        </Top>
        {isModifying ? (
          <>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: Color.primary500,
                  borderRadius: 8,
                  fontSize: 16,
                  colorText: Color.gray800,
                },
                components: {
                  Input: {
                    paddingBlock: 12,
                    paddingInline: 12,
                    colorTextPlaceholder: Color.gray500,
                  },
                },
              }}
            >
              <TextArea
                value={comment}
                rows={3}
                onChange={onChangeComment}
                style={{ width: '100%' }}
              />
            </ConfigProvider>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  fontSize: Number(`${isMobile ? 13 : 16}`),
                  colorText: 'white',
                  colorBgContainer: Color.primary500,
                  colorPrimary: Color.primary500,
                  controlHeight: Number(`${isMobile ? 32 : 44}`),
                  colorBgContainerDisabled: Color.gray,
                  colorBorder: 'white',
                },
                components: {
                  Button: {
                    fontWeight: 500,
                    paddingInline: Number(`${isMobile ? 8 : 24}`),
                  },
                },
              }}
            >
              <Button
                disabled={comment.length === 0}
                style={{ alignSelf: 'flex-end' }}
                onClick={modifyComment}
              >
                댓글 수정
              </Button>
            </ConfigProvider>
          </>
        ) : (
          <Content>{props.content}</Content>
        )}
      </Left>
      <Right onClick={toggleModal}>
        {isWriter && !isModifying && (
          <EllipsisOutlined width={24} height={24} style={{ color: Color.gray800 }} />
        )}
        {modalShow && isWriter && (
          <EditModal>
            <ModalText onClick={startModify}>수정하기</ModalText>
            <ModalText onClick={deleteComment}>삭제하기</ModalText>
          </EditModal>
        )}
      </Right>
    </Container>
  );
};

export default CommentApp;

const Container = styled.div`
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid ${Color.gray200};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
const Right = styled.div`
  padding: 12px;
  position: relative;
  cursor: pointer;
`;
const Top = styled.div`
  display: flex;
  gap: 8px;
`;
const Writer = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${Color.primary500};
`;
const Time = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${Color.gray600};
`;
const Content = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray600};
`;
const EditModal = styled.div`
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 121px;
  position: absolute;
  right: 0;
`;
const ModalText = styled.div`
  width: calc(100% - 16px);
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${Color.gray500};

  &:hover {
    background-color: ${Color.gray};
    border-radius: 8px;
    cursor: pointer;
  }
`;
