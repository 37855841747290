import { ConfigProvider, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Color from '../../../_common/view/style/Color';
import { useMediaQuery } from 'react-responsive';
import { useRecoilState } from 'recoil';
import { findRegion } from '../../../course/repository/course-repository';
import { Option, Region } from '../../../course/domain/course';
import { programRegionState, programSelectedRegionState } from '../../state/ProgramState';
import { courseSelectedRegionState } from '../../../course/state/CourseState';

const FilterDropDown = (props: {
  name:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  wid: any;
}) => {
  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  const pcStyle = {
    width: 336,
  };
  const tabletMobileStyle = {
    width: '100%',
  };

  const handleChange = (value: any) => {
    setProgramSelectedRegionOptions(value);
  };

  const [courseSelectedRegionOptions, setCourseSelectedRegionOptions] =
    useRecoilState(courseSelectedRegionState);
  const [programSelectedRegionOptions, setProgramSelectedRegionOptions] = useRecoilState(
    programSelectedRegionState,
  );
  const [regions, setRegions] = useRecoilState(programRegionState);
  const getRegions = async () => {
    const regionPages = await findRegion();
    setRegions(regionPages);
    convertOptionFormat(regionPages as Array<Region>);
  };
  const [regionOptions, setRegionOptions] = useState<Array<Option>>();
  const convertOptionFormat = (region: Array<Region>) => {
    const options = region.map((item) => ({ value: item.idx, label: item.region1Name }));
    setRegionOptions(options);
  };

  useEffect(() => {
    setCourseSelectedRegionOptions(undefined);
    setProgramSelectedRegionOptions(undefined);
    getRegions();
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Color.primary500,
          fontSize: 16,
          controlHeight: 48,
        },
      }}
    >
      <Select
        placeholder={props.name}
        style={isTabletMobile ? tabletMobileStyle : pcStyle}
        onChange={handleChange}
        options={regionOptions}
      />
    </ConfigProvider>
  );
};

export default FilterDropDown;
