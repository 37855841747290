import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';

const baseUrl = 'https://assets.bae-f.com/';

const SearchCard = (props: any) => {
  const navigate = useNavigate();
  const navigateToDetail = () => {
    if (props.type === 'A') navigate('/courses/' + props.idx);
    else if (props.type === 'B') navigate('/gathering/' + props.idx);
  };

  const convertDateFormat = (dateString: string) => {
    const date = dateString.slice(0, 10);
    const replacedStr = date.replace(/-/g, '.');
    return replacedStr;
  };

  return (
    <Container>
      <ImgWrapper program={props.program}>
        <Link to={'/courses/' + props.idx}>
          <img
            src={baseUrl + props.thumbnailImage.pcUrl}
            style={{ width: '100%', borderRadius: 4 }}
          />
        </Link>
      </ImgWrapper>
      <Detail>
        <Description>
          {props.price > 0 ? '유료' : '무료'} | {props.region.region1Name}
        </Description>
        <Title>
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={'/courses/' + props.id}>
            {props.title}
          </Link>
        </Title>
        <Date>
          {convertDateFormat(props?.operatingPeriod.start)} ~{' '}
          {convertDateFormat(props?.operatingPeriod.end)}
        </Date>
      </Detail>
    </Container>
  );
};

export default SearchCard;

const Container = styled.div`
  width: 100%;
`;
const ImgWrapper = styled.div<{ program: boolean }>`
  width: 100%;
  aspect-ratio: ${(props) => (props.program ? '1/1.4' : '16/9')};
  margin-bottom: 8px;
`;
const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (max-width: 767px) {
    gap: 0;
  }
`;
const Title = styled.div`
  font-size: 16px;
  color: ${Color.gray800};
  font-weight: 700;
`;
const Description = styled.div`
  font-weight: 700;
  font-size: 13px;
  color: ${Color.gray600};
`;
const Date = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${Color.gray600};
`;
