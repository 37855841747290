import React, { useEffect } from 'react';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import { Divider } from 'antd';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { programCategoryState, programState } from '../../state/ProgramState';
import { findSingleProgram } from '../../repository/program-repository';
import { findCategories } from '../../../coachsignin/repository/category-repository';
import ReactQuill from 'react-quill';

const baseUrl = 'https://assets.bae-f.com/';

const ProgramDetail: React.FC = () => {
  const { id } = useParams();

  const [program, setProgram] = useRecoilState(programState);
  const getProgramDetail = async () => {
    const programDetail = await findSingleProgram(Number(id));
    setProgram(programDetail);
  };

  const [categories, setCategories] = useRecoilState(programCategoryState);
  useEffect(() => {
    getProgramDetail();
    findCategories().then((res) => {
      setCategories(res ?? []);
    });
  }, [setCategories]);

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  const getCategoryNameByIdx = (idx: number) => {
    return categories?.find((c) => c.idx === idx)?.title ?? '';
  };

  return (
    <Container>
      <ImgWrapper>
        <img
          style={{ objectFit: 'contain', width: '100%' }}
          src={baseUrl + program?.thumbnailImage?.pcUrl}
        />
      </ImgWrapper>
      <Content>
        <Title>프로그램/행사 정보</Title>
        <InfoContainer>
          <Label>프로그램/행사명</Label>
          <Detail>{program?.title}</Detail>
          <Label>일시</Label>
          <Detail>
            {convertDateFormat(program?.operatingPeriod?.start)} ~{' '}
            {convertDateFormat(program?.operatingPeriod?.end)} | {program?.operatingDayOfWeekInfo} |{' '}
            {program?.operatingTimeInfo}
          </Detail>
          <Label>장소</Label>
          <Detail>
            {program?.region?.region1Name} {program?.region?.region2Name}{' '}
            {program?.region?.region3Name} {program?.addressDetail}
          </Detail>
          <Label>비용</Label>
          <Detail>{program?.price === 0 ? '무료' : program?.price?.toLocaleString() + '원'}</Detail>
          <Label>행사소개</Label>
          <Detail>
            <ReactQuill value={program?.contents ?? ''} readOnly={true} theme={'bubble'} />
          </Detail>
          <Label style={{ padding: '8px 0' }}>키워드</Label>
          <KeywordContainer>
            {program?.categoryIdxes?.map((idx: any, index: number) => {
              return <Keyword key={index}>{getCategoryNameByIdx(idx)}</Keyword>;
            })}
          </KeywordContainer>
        </InfoContainer>
      </Content>
      <Divider style={{ borderColor: Color.gray200 }} />
      <Content>
        <Title>주최자</Title>
        <LineContainer>
          <Line>
            <SubTitle style={{ marginRight: 16 }}>주최자</SubTitle>
            <Detail>{program?.organization}</Detail>
          </Line>
          <Line>
            <SubTitle style={{ marginRight: 16 }}>연락처</SubTitle>
            <Detail>{program?.organizationContact}</Detail>
          </Line>
        </LineContainer>
      </Content>
      <Divider style={{ borderColor: Color.gray200 }} />
      <Content>
        <Title>모집 링크</Title>
        <LinkWrapper>
          <Link href={program?.link ?? ''} target="_blank" rel="noopener noreferrer">
            {program?.link ?? ''}
          </Link>
        </LinkWrapper>
      </Content>
      <Divider style={{ borderColor: Color.gray200 }} />
      <Content>
        <Title>환불 규정</Title>
        <Rules>
          <li>프로그램/행사의 모집처는 배프가 아닙니다. 위 행사 담당자에게 문의해주세요.</li>
        </Rules>
      </Content>
    </Container>
  );
};

export default ProgramDetail;

const explation = `행정각부의 설치·조직과 직무범위는 법률로 정한다. 대통령은 제1항과 제2항의 처분 또는 명령을 한 때에는 지체없이 국회에 보고하여 그 승인을 얻어야 한다. 대통령은 제3항과 제4항의 사유를 지체없이 공포하여야 한다. 법률이 정하는 주요방위산업체에 종사하는 근로자의 단체행동권은 법률이 정하는 바에 의하여 이를 제한하거나 인정하지 아니할 수 있다. 국군은 국가의 안전보장과 국토방위의 신성한 의무를 수행함을 사명으로 하며, 그 정치적 중립성은 준수된다. 모든 국민은 인간으로서의 존엄과 가치를 가지며, 행복을 추구할 권리를 가진다. 국가는 개인이 가지는 불가침의 기본적 인권을 확인하고 이를 보장할 의무를 진다.`;

const Container = styled.div`
  padding: 48px max(90px, 12%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1023px) {
    padding: 48px 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 48px 16px;
  }
`;
const ImgWrapper = styled.div`
  width: min(498px, 100%);
  aspect-ratio: 1/1.4;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
const Content = styled.div`
  width: 100%;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.gray800};
  margin-bottom: 24px;
`;
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 106px auto;
  column-gap: 24px;
  row-gap: 12px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;
const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.div`
  color: ${Color.primary500};
  font-size: 16px;
  font-weight: 500;
`;
const Detail = styled.div`
  color: ${Color.gray600};
  font-weight: 500;
  font-size: 14px;
`;
const KeywordContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
const Keyword = styled.div`
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 500;
  color: ${Color.primary500};
`;
const SubTitle = styled.div`
  color: ${Color.gray800};
  font-size: 16px;
  font-weight: 700;
`;
const LinkWrapper = styled.div`
  border-radius: 8px;
  background-color: ${Color.primary};
  padding: 12px 24px;
  display: flex;
  justify-content: center;
`;
const Link = styled.a`
  text-decoration: underline;
  color: ${Color.gray800};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;
const Rules = styled.ul`
  padding: 0 16px;
  color: ${Color.gray800};
  font-size: 16px;
  font-weight: 500;
`;
