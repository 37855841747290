import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  searchCommunityState,
  searchCourseListState,
  searchGatheringState,
  searchKeyword,
  searchProgramListState,
} from '../../state/SearchState';
import SearchCard from './SearchCard';
import { Button, ConfigProvider } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Color from '../../../_common/view/style/Color';
import SearchBoard from './SearchBoard';
import styled from 'styled-components';
import { Course } from '../../../course/domain/course';
import { Program } from '../../../program/domain/program';
import { useNavigate } from 'react-router-dom';
import { findPostPage, findCoursePage, findProgramPage } from '../../repository/search-repository';
import { Post } from '../../../community/domain/community';

const SearchTab = (props: any) => {
  const navigate = useNavigate();

  const keyword = useRecoilValue(searchKeyword);

  const [course, setCourse] = useRecoilState(searchCourseListState);
  const [gathering, setGathering] = useRecoilState(searchGatheringState);
  const [program, setProgram] = useRecoilState(searchProgramListState);
  const [community, setCommunity] = useRecoilState(searchCommunityState);

  const [courseElementCount, setCourseElementcount] = useState(0);
  const [programElementCount, setProgramElementcount] = useState(0);
  const [gatheringElementCount, setGatheringElementcount] = useState(0);
  const [communityElementCount, setCommunityElementcount] = useState(0);
  const getCoursePages = async () => {
    const coursePages = await findCoursePage(0, 'A', 8, keyword);
    const gatheringPages = await findCoursePage(0, 'B', 8, keyword);

    setCourse(coursePages?.contents as Array<Course>);
    setCourseElementcount(coursePages?.totalElements as number);

    setGathering(gatheringPages?.contents as Array<Course>);
    setGatheringElementcount(gatheringPages?.totalElements as number);
  };
  const getProgramPages = async () => {
    const programPages = await findProgramPage(0, 10, keyword);

    setProgram(programPages?.contents as Array<Program>);
    setProgramElementcount(programPages?.totalElements as number);
  };
  const getCommunityPages = async () => {
    const communityPages = await findPostPage(0, 10, keyword);

    setCommunity(communityPages?.contents as Array<Post>);
    setCommunityElementcount(communityPages?.totalElements as number);
  };

  useEffect(() => {
    (props.searchIdx === 1 || props.searchIdx === 3) && getCoursePages();
    props.searchIdx === 2 && getProgramPages();
    props.searchIdx === 4 && getCommunityPages();
  }, [keyword, props.searchIdx]);

  const navigateToCourse = () => {
    navigate('/courses');
  };

  const navigateToProgram = () => {
    navigate('/program');
  };

  const navigateToGathering = () => {
    navigate('/gathering');
  };

  const navigateToCommunity = () => {
    navigate('/community');
  };

  return (
    <Container>
      {props.searchIdx === 1 && (
        <div>
          <Title>
            강좌
            <Count>{courseElementCount}</Count>
          </Title>
          <CourseContainer>
            {course?.slice(0, 8).map((item, i) => <SearchCard key={i} {...item} program={false} />)}
          </CourseContainer>
          {courseElementCount > 8 ? (
            <div style={{ textAlign: 'center' }}>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    colorBgContainer: Color.primary500,
                    colorText: 'white',
                    fontSize: 16,
                    controlHeight: 48,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                      fontWeight: 700,
                    },
                  },
                }}
              >
                <Button onClick={navigateToCourse}>
                  강좌 더보기
                  <RightOutlined />
                </Button>
              </ConfigProvider>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {props.searchIdx === 2 && (
        <div>
          <Title>
            프로그램/행사
            <Count>{programElementCount}</Count>
          </Title>
          <ProgramContainer>
            {program
              ?.slice(0, 10)
              .map((item: any, index: number) => (
                <SearchCard key={index} {...item} program={true} />
              ))}
          </ProgramContainer>
          {programElementCount > 10 ? (
            <div style={{ textAlign: 'center' }}>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    colorBgContainer: Color.primary500,
                    colorText: 'white',
                    fontSize: 16,
                    controlHeight: 48,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                      fontWeight: 700,
                    },
                  },
                }}
              >
                <Button onClick={navigateToProgram}>
                  프로그램/행사 더보기
                  <RightOutlined />
                </Button>
              </ConfigProvider>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {props.searchIdx === 3 && (
        <div>
          <Title>
            소모임
            <Count>{gatheringElementCount}</Count>
          </Title>
          <CourseContainer>
            {gathering
              ?.slice(0, 8)
              .map((item: any, index: number) => (
                <SearchCard key={index} {...item} program={false} />
              ))}
          </CourseContainer>
          {gatheringElementCount > 8 ? (
            <div style={{ textAlign: 'center' }}>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    colorBgContainer: Color.primary500,
                    colorText: 'white',
                    fontSize: 16,
                    controlHeight: 48,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                      fontWeight: 700,
                    },
                  },
                }}
              >
                <Button onClick={navigateToGathering}>
                  소모임 더보기
                  <RightOutlined />
                </Button>
              </ConfigProvider>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {props.searchIdx === 4 && (
        <div>
          <Title>
            커뮤니티
            <Count>{communityElementCount}</Count>
          </Title>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {community
              ?.slice(0, 5)
              .map((item: any, index: number) => <SearchBoard key={index} {...item} />)}
          </div>
          {communityElementCount > 5 ? (
            <div style={{ textAlign: 'center', marginTop: 8 }}>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    colorBgContainer: Color.primary500,
                    colorText: 'white',
                    fontSize: 16,
                    controlHeight: 48,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Button: {
                      paddingInline: 24,
                      fontWeight: 700,
                    },
                  },
                }}
              >
                <Button onClick={navigateToCommunity}>
                  커뮤니티 글 더보기
                  <RightOutlined />
                </Button>
              </ConfigProvider>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </Container>
  );
};

export default SearchTab;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${Color.gray800};
  margin-bottom: 24px;
`;
const CourseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 12px;
  margin-bottom: 24px;

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const ProgramContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Count = styled.span`
  font-size: 20px;
  color: ${Color.gray500};
  font-weight: 700;
  margin-left: 8px;
`;
