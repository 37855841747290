import { Button } from 'antd';
import React from 'react';
import Color from '../../_common/view/style/Color';
import { useNavigate } from 'react-router-dom';
import '../view/style/Coach.css';
import styled from 'styled-components';
import {
  SelectOutlined,
  SoundFilled,
  UploadOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import silverClient from '../../_common/client/silver-client';

const CoachPage = () => {
  const navigate = useNavigate();
  const user = silverClient.getUser();
  const navigateToSignIn = () => {
    console.log(user);
    if (user?.userType === 'TEACHER' || user?.userType === 'ADMIN') {
      navigate('/coachsignin');
    } else {
      navigate('/coachsignup', { state: { value: '다음', route: '/login' } });
    }
  };

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  return (
    <>
      {isPc && (
        <div>
          <div className="coach-container">
            <MainText>시니어를 위한</MainText>
            <MainText>배움을 개설해 보세요</MainText>
            <span style={{ margin: '10px 0 20px 0', color: Color.gray600 }}>
              50~65 액티브 시니어들에게 여러분의 전문성과 열정을 전달해주세요
            </span>
            <Button
              style={{
                backgroundColor: Color.primary500,
                fontSize: '20px',
                height: 'auto',
                color: 'white',
                borderRadius: '32px',
                padding: '10px 20px 10px 20px',
              }}
              onClick={navigateToSignIn}
            >
              배움 개설하러 가기
            </Button>
          </div>
          <div className="coach-about">
            <div className="coach-about-title">
              <p style={{ fontSize: '24px', fontWeight: '700', color: Color.gray800 }}>
                배프에서 수업을 하면 뭐가 다른가요?
              </p>
              <p style={{ fontSize: '16px', fontWeight: '500', color: Color.gray600 }}>
                배프에서 수업을 시작해야 하는 이유
              </p>
            </div>
            <div className="coach-about-content" style={{ marginTop: '3%' }}>
              <div
                className="coach-about-content1"
                style={{ border: `1px solid ${Color.gray200}` }}
              >
                <Colorh4>
                  <SelectOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />{' '}
                  시니어 전문 플랫폼
                </Colorh4>
                <SubText>
                  배프는 시니어 오프라인 교육 전문 플랫폼으로 보다 명확한 타겟을 대상으로 수업을
                  진행하고, 준비할 수 있습니다.
                </SubText>
              </div>
              <div
                className="coach-about-content3"
                style={{ border: `1px solid ${Color.gray200}` }}
              >
                <Colorh4>
                  {' '}
                  <UploadOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  강의 노출도 증가
                </Colorh4>
                <SubText>
                  배프는 시니어분들의 관심 키워드와 일치하는 강의를 추천 시스템을 통해 강의 노출도를
                  증대시킵니다.
                </SubText>
              </div>
            </div>
            <div className="coach-about-content2">
              <div
                className="coach-about-content1"
                style={{ border: `1px solid ${Color.gray200}` }}
              >
                <Colorh4>
                  <SoundFilled
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  편리한 수업 생성부터 홍보까지
                </Colorh4>
                <SubText>
                  배프는 온라인을 통해 쉽고 간편하게 수업 생성부터 관리까지 가능하고 배너를 통해
                  수업 홍보가 가능합니다.
                </SubText>
              </div>
              <div
                className="coach-about-content3"
                style={{ border: `1px solid ${Color.gray200}` }}
              >
                <Colorh4>
                  <UsergroupDeleteOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  전문 배코치들간의 네트워킹
                </Colorh4>
                <SubText>
                  배프는 심사를 통해 선발된 우수한 강사(배코치)들을 대상으로 네트워킹 기회를
                  제공합니다.
                </SubText>
              </div>
            </div>
          </div>
          <div className="coach-bottom">
            <BottomText>시니어분들에게 여러분의</BottomText>
            <BottomText>지식을 전달해주세요</BottomText>
            <span style={{ margin: '10px 0 20px 0', color: 'white' }}>
              빠른 배움 개설은 빠른 수강생 모집으로 이어집니다!
            </span>
            <Button
              style={{
                backgroundColor: Color.primary500,
                fontSize: '20px',
                height: 'auto',
                color: 'white',
                borderRadius: '32px',
                padding: '10px 20px 10px 20px',
              }}
              onClick={navigateToSignIn}
            >
              배움 개설하러 가기
            </Button>
          </div>
        </div>
      )}
      {isTablet && (
        <div>
          <div className="coach-container">
            <MediaMainText>시니어를 위한</MediaMainText>
            <MediaMainText>배움을 개설해 보세요</MediaMainText>
            <span style={{ marginTop: '10px', color: Color.gray600 }}>
              50~65 액티브 시니어들에게
            </span>
            <span style={{ marginBottom: '10px', color: Color.gray600 }}>
              여러분의 전문성과 열정을 전달해주세요
            </span>
            <Button
              style={{
                backgroundColor: Color.primary500,
                fontSize: '20px',
                height: 'auto',
                color: 'white',
                borderRadius: '32px',
                padding: '10px 20px 10px 20px',
              }}
              onClick={navigateToSignIn}
            >
              배움 개설하러 가기
            </Button>
          </div>
          <div className="coach-about">
            <div className="coach-about-title">
              <p style={{ fontSize: '24px', fontWeight: '700', color: Color.gray800 }}>
                배프에서 수업을 하면 뭐가 다른가요?
              </p>
              <p style={{ fontSize: '16px', fontWeight: '500', color: Color.gray600 }}>
                배프에서 수업을 시작해야 하는 이유
              </p>
            </div>
            <div className="coach-about-content" style={{ marginTop: '3%' }}>
              <div
                className="coach-about-content1"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  <SelectOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />{' '}
                  시니어 전문 플랫폼
                </Colorh4>
                <SubText>
                  배프는 시니어 오프라인 교육 전문 플랫폼으로 보다 명확한 타겟을 대상으로 수업을
                  진행하고, 준비할 수 있습니다.
                </SubText>
              </div>
              <div
                className="coach-about-content3"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  {' '}
                  <UploadOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  강의 노출도 증가
                </Colorh4>
                <SubText>
                  배프는 시니어분들의 관심 키워드와 일치하는 강의를 추천 시스템을 통해 강의 노출도를
                  증대시킵니다.
                </SubText>
              </div>
            </div>
            <div className="coach-about-content2">
              <div
                className="coach-about-content1"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  <SoundFilled
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  편리한 수업 생성부터 홍보까지
                </Colorh4>
                <SubText>
                  배프는 온라인을 통해 쉽고 간편하게 수업 생성부터 관리까지 가능하고 배너를 통해
                  수업 홍보가 가능합니다.
                </SubText>
              </div>
              <div
                className="coach-about-content3"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  <UsergroupDeleteOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  전문 배코치들간의 네트워킹
                </Colorh4>
                <SubText>
                  배프는 심사를 통해 선발된 우수한 강사(배코치)들을 대상으로 네트워킹 기회를
                  제공합니다.
                </SubText>
              </div>
            </div>
          </div>
          <div className="coach-bottom">
            <BottomText>시니어분들에게 여러분의</BottomText>
            <BottomText>지식을 전달해주세요</BottomText>
            <span style={{ margin: '10px 0 20px 0', color: 'white' }}>
              빠른 배움 개설은 빠른 수강생 모집으로 이어집니다!
            </span>
            <Button
              style={{
                backgroundColor: Color.primary500,
                fontSize: '20px',
                height: 'auto',
                color: 'white',
                borderRadius: '32px',
                padding: '10px 20px 10px 20px',
              }}
              onClick={navigateToSignIn}
            >
              배움 개설하러 가기
            </Button>
          </div>
        </div>
      )}
      {isMobile && (
        <div>
          <div className="coach-container">
            <MediaMainText>시니어를 위한</MediaMainText>
            <MediaMainText>배움을 개설해 보세요</MediaMainText>
            <span style={{ marginTop: '10px', color: Color.gray600 }}>
              50~65 액티브 시니어들에게
            </span>
            <span style={{ marginBottom: '10px', color: Color.gray600 }}>
              여러분의 전문성과 열정을 전달해주세요
            </span>
            <Button
              style={{
                backgroundColor: Color.primary500,
                fontSize: '20px',
                height: 'auto',
                color: 'white',
                borderRadius: '32px',
                padding: '10px 20px 10px 20px',
              }}
              onClick={navigateToSignIn}
            >
              배움 개설하러 가기
            </Button>
          </div>
          <div className="coach-about">
            <div className="coach-about-title">
              <p style={{ fontSize: '24px', fontWeight: '700', color: Color.gray800 }}>
                배프에서 수업을 하면 뭐가 다른가요?
              </p>
              <p style={{ fontSize: '16px', fontWeight: '500', color: Color.gray600 }}>
                배프에서 수업을 시작해야 하는 이유
              </p>
            </div>
            <div className="coach-about-content" style={{ marginTop: '3%' }}>
              <div
                className="coach-about-content1"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  <SelectOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />{' '}
                  시니어 전문 플랫폼
                </Colorh4>
                <SubText>
                  배프는 시니어 오프라인 교육 전문 플랫폼으로 보다 명확한 타겟을 대상으로 수업을
                  진행하고, 준비할 수 있습니다.
                </SubText>
              </div>
              <div
                className="coach-about-content3"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  {' '}
                  <UploadOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  강의 노출도 증가
                </Colorh4>
                <SubText>
                  배프는 시니어분들의 관심 키워드와 일치하는 강의를 추천 시스템을 통해 강의 노출도를
                  증대시킵니다.
                </SubText>
              </div>
            </div>
            <div className="coach-about-content2">
              <div
                className="coach-about-content1"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  <SoundFilled
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  편리한 수업 생성부터 홍보까지
                </Colorh4>
                <SubText>
                  배프는 온라인을 통해 쉽고 간편하게 수업 생성부터 관리까지 가능하고 배너를 통해
                  수업 홍보가 가능합니다.
                </SubText>
              </div>
              <div
                className="coach-about-content3"
                style={{ border: `1px solid ${Color.gray200}`, width: '100%' }}
              >
                <Colorh4>
                  <UsergroupDeleteOutlined
                    style={{ marginRight: '10px', fontSize: '25px', color: Color.primary500 }}
                  />
                  전문 배코치들간의 네트워킹
                </Colorh4>
                <SubText>
                  배프는 심사를 통해 선발된 우수한 강사(배코치)들을 대상으로 네트워킹 기회를
                  제공합니다.
                </SubText>
              </div>
            </div>
          </div>
          <div className="coach-bottom">
            <BottomText>시니어분들에게 여러분의</BottomText>
            <BottomText>지식을 전달해주세요</BottomText>
            <span style={{ margin: '10px 0 20px 0', color: 'white' }}>
              빠른 배움 개설은 빠른 수강생 모집으로 이어집니다!
            </span>
            <Button
              style={{
                backgroundColor: Color.primary500,
                fontSize: '20px',
                height: 'auto',
                color: 'white',
                borderRadius: '32px',
                padding: '10px 20px 10px 20px',
              }}
              onClick={navigateToSignIn}
            >
              배움 개설하러 가기
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CoachPage;

const MainText = styled.span`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 5px;
  color: ${Color.gray800};
`;

const MediaMainText = styled.span`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 5px;
  color: ${Color.gray800};
`;

const SubText = styled.p`
  font-size: 16px;
  padding-left: 30px;
  color: ${Color.gray500};
`;

const MediaSubText = styled.p`
  font-size: 13px;
  padding-left: 30px;
  color: ${Color.gray500};
`;

const Colorh4 = styled.h4`
  color: ${Color.primary500};
`;

const BottomText = styled.span`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  color: white;
`;
