import React, { useEffect, useState } from 'react';
import AdminLayout from '../AdminLayout';
import {
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Input,
  Layout,
  Modal,
  Pagination,
  Radio,
  RadioChangeEvent,
  Space,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Color from '../../../../_common/view/style/Color';
import { SmallDashOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import AdminUpload from '../BannerMenu/AdminUpload';
import Chip from '../../../../signup/view/component/Chip';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  adminend,
  adminProgramAddressState,
  adminProgramNameState,
  adminProgramOrganizationState,
  adminProgramPriceState,
  adminProgramThumbnailUrlState,
  adminstart,
} from '../../../state/AdminState';
import {
  Adminprogram,
  BOTH,
  OFFLINE,
  ONLINE,
  ProgramAddress,
} from '../../../domain/admin-contents';
import { Program, STATUS } from '../../../../program/domain/program';
import {
  searchAdminProgramPage,
  updateAdminProgram,
} from '../../../../program/repository/program-repository';
import Editor from '../../../../coachsignin/component/Info/Editor';
import { StoragePath, upload } from '../../../../_common/repository/storage-repository';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import { courseContentsState } from '../../../../coachsignin/state/CourseSignInState';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { findRegionByCode } from '../../../../signup/repository/signupRepository';
import { userCategoryState } from '../../../../user/state/user-state';
import { insertAdminProgram } from '../../../repository/payment-repostory';
import { Category } from '../../../../coachsignin/domain/category';
import { findCategories } from '../../../../coachsignin/repository/category-repository';

const { Search } = Input;
const { TextArea } = Input;

const Stlyediv = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

type INSERT_TYPE = INSERT | UPDATE;
type INSERT = 'INSERT';
type UPDATE = 'UPDATE';

const AdminProgram = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [priceType, setPriceType] = useState(1);
  const [contents, setContents] = useState<Array<Program>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [selectedItem, setSelectedItem] = useState<Program | null>(null);

  const [name, setName] = useRecoilState(adminProgramNameState);
  const [thumbnail, setThumbnail] = useRecoilState(adminProgramThumbnailUrlState);
  const [address, setAddress] = useRecoilState(adminProgramAddressState);
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [description, setDescription] = useRecoilState(courseContentsState);
  const [price, setPrice] = useRecoilState(adminProgramPriceState);
  const [organization, setOrganization] = useRecoilState(adminProgramOrganizationState);
  const [contact, setContact] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [categories, setCategories] = useRecoilState(userCategoryState);
  const [insertType, setInsertType] = useState<INSERT_TYPE>('INSERT');

  const getContents = async (search: string | undefined, page?: number) => {
    const programPages = await searchAdminProgramPage({
      page: (page ? page : pageNumber) - 1,
      size: 10,
      keyword: search,
    });
    setContents(programPages?.contents as Array<Program>);
    setTotalElements(programPages?.totalElements as number);
  };

  useEffect(() => {
    getContents(searchValue);
    findCategories().then((res) => {
      const categories = res ?? [];
      setCategories(categories);
    });
  }, [pageNumber]);

  const onChange = (e: RadioChangeEvent) => {
    let value = e.target.value;
    setPriceType(value);
    if (value === 1) {
      setPrice(0);
    }
  };

  const submit = async () => {
    if (name === null || name.trim().length === 0) {
      alert('프로그램/행사명을 입력해주세요.');
      return;
    }
    if (thumbnail === null || thumbnail.trim().length === 0) {
      alert('썸네일을 등록해주세요.');
      return;
    }
    if (description === undefined || description.trim().length === 0) {
      alert('프로그램/행사 설명을 입력해주세요.');
      return;
    }
    if (link === null || link.trim().length === 0) {
      alert('링크를 입력해주세요.');
      return;
    }
    if (address === null || detailAddress === null || detailAddress.trim().length === 0) {
      alert('주소를 입력해주세요.');
      return;
    }
    if (startDate === null) {
      alert('시작일을 입력해주세요.');
      return;
    }
    if (endDate === null) {
      alert('종료일을 입력해주세요.');
      return;
    }
    if (organization === null || organization.trim().length === 0) {
      alert('주최기관명을 입력해주세요.');
      return;
    }
    if (contact === null || contact.trim().length === 0) {
      alert('연락처를 입력해주세요.');
      return;
    }
    let selectedCategoryIdxes = categories.filter((c) => c.selected).map((c) => c.idx);
    const postData: Adminprogram = {
      title: name,
      thumbnailUrl: thumbnail,
      contents: description ?? '',
      price: Number(price),
      link: link,
      regionIdx: Number(address.regionIdx),
      regionFullName: address.addressText,
      addressDetail: detailAddress,
      operatingPeriod: {
        start: startDate + ' 00:00:00',
        end: endDate + ' 23:59:59',
      },
      recruitmentPeriod: {
        start: startDate + ' 00:00:00',
        end: endDate + ' 23:59:59',
      },
      organization: organization,
      organizationContact: contact,
      categoryIdxes: selectedCategoryIdxes,
    };
    if (insertType === 'INSERT') {
      const push = await insertAdminProgram(postData);
      if (push) {
        alert('프로그램 등록이 완료되었습니다.');
        window.location.href = '/admin/program';
      }
    } else {
      if (selectedItem === null) {
        alert('수정할 프로그램을 선택해주세요.');
        return;
      }
      const push = await updateAdminProgram(selectedItem.idx, postData);
      if (push) {
        alert('프로그램 수정이 완료되었습니다.');
        window.location.href = '/admin/program';
      }
    }
  };

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal2 = (item: Program | null) => {
    if (item) {
      setSelectedItem(item);
      setInsertType('UPDATE');
      setThumbnail(item.thumbnailImage.pcUrl);
      setName(item.title);
      setAddress({ regionIdx: item.region.idx, addressText: item.regionFullName });
      setDetailAddress(item.addressDetail);
      setDescription(item.contents);
      setPrice(item.price);
      setOrganization(item.organization);
      setStartDate(removeTime(item.operatingPeriod.start));
      setEndDate(removeTime(item.operatingPeriod.end));
      setPriceType(item.price === 0 ? 1 : 2);
      setLink(item.link);
      setContact(item.organizationContact);

      const newCategories = categories?.map((c: Category) => {
        let newCategory = { ...c };
        newCategory.selected = item.categoryIdxes.includes(c.idx);
        return newCategory;
      });
      setCategories(newCategories);
    } else {
      setInsertType('INSERT');
      setThumbnail('');
      setName('');
      setAddress(null);
      setDetailAddress('');
      setDescription('');
      setPrice(0);
      setOrganization('');
      setStartDate('');
      setEndDate('');
      setPriceType(1);
      setLink('');
      setContact('');

      const initCategories = categories?.map((c: Category) => {
        let newCategory = { ...c };
        newCategory.selected = false;
        return newCategory;
      });
      setCategories(initCategories);
    }
    setIsModal2Open(true);
  };

  const removeTime = (date: string) => {
    return date.split('T')[0];
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const setStatus = async (programIdx: number, status: STATUS) => {
    await updateAdminProgram(programIdx, {
      status: status,
    });
    await getContents(searchValue, pageNumber);
  };

  const onUploadFile = async (file: File) => {
    const url = await uploadFile(file);
    setThumbnail(url);
    return true;
  };

  const uploadFile = async (file: File) => {
    const path: StoragePath = { bucket: 'DOMAIN_IMAGE', domain: 'program', category: 'contents' };
    return (await upload(file, path)) + '?timestamp=' + new Date().getTime();
  };

  const [startDate, setStartDate] = useState<string | undefined>();
  const handleStartDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setStartDate(date?.format('YYYY-MM-DD'));
  };
  const [endDate, setEndDate] = useState<string | undefined>();
  const handleEndDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setEndDate(date?.format('YYYY-MM-DD'));
  };

  const open = useDaumPostcodePopup(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  );
  const searchAddress = () => {
    open({
      onComplete: handleComplete,
      popupTitle: '주소 검색',
      popupKey: 'popupKey',
      showMoreHName: true,
    });
  };
  const handleComplete = (data: any) => {
    findRegionByCode(data?.bcode).then((res) => {
      const regionIdx = res?.idx;
      if (regionIdx) {
        setAddress({
          regionIdx: regionIdx,
          addressText: data?.roadAddress ?? '',
        } as ProgramAddress);
      } else {
        alert('해당 지역은 등록/수정이 불가능합니다.');
      }
    });
  };

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '3%',
                }}
              >
                프로그램/행사 관리
              </div>
              <div style={{ display: 'flex', marginBottom: '3%' }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: Color.primary500,
                      controlHeight: 48,
                      colorBorder: Color.gray500,
                      colorTextPlaceholder: Color.gray500,
                      fontSize: 16,
                      colorBorderBg: Color.gray800,
                    },
                  }}
                >
                  <Search
                    placeholder="검색어 입력 후 Enter 키를 엽력해주세요."
                    onSearch={async (value, e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      await getContents(searchValue, 1);
                      setPageNumber(1);
                    }}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </ConfigProvider>
                <Button
                  type="primary"
                  style={{
                    width: '25%',
                    marginLeft: '2%',
                    backgroundColor: 'white',
                    color: Color.primary500,
                    border: `2px solid ${Color.primary500}`,
                    height: '48px',
                  }}
                  onClick={() => showModal2(null)}
                >
                  + 프로그램/행사 등록
                </Button>
              </div>
            </div>
          </Header>
          <Content>
            <div
              style={{
                padding: 16,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <div>
                <p style={{ fontSize: '16px', fontWeight: '600' }}>
                  전체 프로그램 <span style={{ color: Color.primary500 }}>{totalElements}</span>개
                </p>
                <hr />
                <table
                  className="common-table"
                  style={{
                    borderCollapse: 'collapse',
                    borderSpacing: '0 10px',
                    width: '100%',
                    marginBottom: '30px',
                  }}
                >
                  <thead>
                    <tr>
                      <td className="common-table-header-column">번호</td>
                      <td className="common-table-header-column">타이틀</td>
                      <td className="common-table-header-column">주최기관명</td>
                      <td className="common-table-header-column">연락처</td>
                      <td className="common-table-header-column">일시</td>
                      <td className="common-table-header-column">지역</td>
                      <td className="common-table-header-column">가격</td>
                      <td className="common-table-header-column">생성자</td>
                      <td className="common-table-header-column">상태</td>
                      <td className="common-table-header-column"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {contents ? (
                      contents.map((item, index) => (
                        <tr
                          className="common-table-row"
                          style={{
                            marginBottom: '5%',
                            borderBottom: `1px solid ${Color.gray200}`,
                            cursor: 'pointer',
                          }}
                          key={index}
                        >
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {item.idx}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {item.title}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {item.organization}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {item.organizationContact}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {convertDateFormat(item.operatingPeriod.start)} ~{' '}
                            {convertDateFormat(item.operatingPeriod.end)}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {item.region.region1Name} {item.region.region2Name}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {item.price === 0 ? '무료' : item.price}
                          </td>
                          <td className="common-table-column" onClick={() => showModal2(item)}>
                            {item.creator}
                          </td>
                          <td
                            className="common-table-column"
                            onClick={() => showModal2(item)}
                            style={{ color: Color.primary500, width: '100px' }}
                          >
                            {chipStatus(item.status)}
                          </td>
                          <td className="common-table-column">
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          type="text"
                                          onClick={() =>
                                            setStatus(
                                              item.idx,
                                              item.status === 'ACTIVE' ? 'PAUSE' : 'ACTIVE',
                                            )
                                          }
                                          disabled={item.status === 'CLOSE'}
                                        >
                                          {item.status === 'ACTIVE' ? '비공개하기' : '공개하기'}
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                  {
                                    key: '2',
                                    label: (
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              textHoverBg: 'transparent',
                                            },
                                          },
                                        }}
                                      >
                                        <Button
                                          type="text"
                                          onClick={showModal}
                                          disabled={item?.status === 'CLOSE'}
                                        >
                                          종료하기
                                        </Button>
                                      </ConfigProvider>
                                    ),
                                  },
                                ],
                              }}
                              trigger={['click']}
                            >
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedItem(item);
                                }}
                              >
                                <Space>
                                  <SmallDashOutlined />
                                </Space>
                              </a>
                            </Dropdown>

                            <Modal
                              open={isModalOpen}
                              mask={false}
                              closable={false}
                              width={400}
                              footer={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Button
                                    type={'primary'}
                                    style={{
                                      color: 'white',
                                      backgroundColor: Color.primary500,
                                      marginLeft: '0px',
                                    }}
                                    onClick={async () => {
                                      if (selectedItem) {
                                        await setStatus(selectedItem.idx, 'CLOSE');
                                        handleCancel();
                                      }
                                    }}
                                  >
                                    프로그램 종료
                                  </Button>
                                  <Button
                                    type={'text'}
                                    style={{
                                      color: Color.primary500,
                                      fontWeight: '700',
                                      marginLeft: '0px',
                                    }}
                                    onClick={handleCancel}
                                  >
                                    돌아가기
                                  </Button>
                                </div>
                              }
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <p style={{ fontWeight: '700' }}>정말로 종료하시겠습니까?</p>
                                <span>종료 처리 후에는 되돌릴 수 없습니다.</span>
                              </div>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <Modal
                  width={600}
                  mask={true}
                  open={isModal2Open}
                  closable={false}
                  maskClosable={true}
                  onCancel={handleCancel2}
                  destroyOnClose={true}
                  cancelText={'닫기'}
                  // style={{overflow:"scroll"}}
                  cancelButtonProps={{
                    style: { color: Color.primary500, backgroundColor: 'white' },
                  }}
                  footer={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Button
                        type={'primary'}
                        onClick={submit}
                        style={{
                          color: 'white',
                          backgroundColor: Color.primary500,
                          height: '40px',
                        }}
                      >
                        프로그램/행사 등록
                      </Button>
                      <Button
                        type={'text'}
                        style={{
                          color: Color.primary500,
                          fontWeight: '700',
                          height: '40px',
                          marginLeft: '0px',
                        }}
                        onClick={handleCancel2}
                      >
                        돌아가기
                      </Button>
                    </div>
                  }
                >
                  <div style={{ paddingBottom: '50px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: '700',
                        }}
                      >
                        프로그램/행사 등록
                      </p>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>프로그램/행사명</span>
                        <Input
                          value={name}
                          placeholder={'프로그램/행사명을 입력해주세요'}
                          style={{ height: '40px', marginTop: '5px' }}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>주소</span>
                        <ConfigProvider
                          theme={{
                            token: {
                              borderRadius: 6,
                              fontSize: 14,
                              colorBorder: Color.gray200,
                              colorTextPlaceholder: Color.gray200,
                              colorText: Color.gray800,
                              colorPrimary: Color.primary500,
                              controlHeight: 38,
                            },
                          }}
                        >
                          <Search
                            placeholder={'주소를 검색해주세요'}
                            value={address?.addressText ?? ''}
                            readOnly
                            onClick={searchAddress}
                            onSearch={searchAddress}
                          />
                          <Input
                            value={detailAddress}
                            placeholder={'자세한 주소를 입력해주세요'}
                            style={
                              address ? { height: '40px', marginTop: '5px' } : { display: 'none' }
                            }
                            onChange={(e) => setDetailAddress(e.target.value)}
                          />
                        </ConfigProvider>
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>날짜</span>
                        <PeriodContainer>
                          <PeriodWrapper>
                            <PeriodDescription>프로그램/행사 시작</PeriodDescription>
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorBorder: Color.gray500,
                                  colorPrimary: Color.primary500,
                                  fontSize: 16,
                                  borderRadius: 8,
                                  colorTextPlaceholder: Color.gray500,
                                },
                              }}
                            >
                              <CustomDatePicker
                                placeholder={startDate ? startDate : '날짜를 선택해주세요'}
                                locale={locale}
                                onChange={handleStartDateChange}
                                disabledDate={(current) => {
                                  if (!endDate) {
                                    return current && current < dayjs().endOf('day');
                                  }
                                  return (
                                    current &&
                                    (current > dayjs(endDate).endOf('day') ||
                                      current < dayjs().endOf('day'))
                                  );
                                }}
                              />
                            </ConfigProvider>
                          </PeriodWrapper>
                          <PeriodWrapper>
                            <PeriodDescription>프로그램/행사 마감</PeriodDescription>
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorBorder: Color.gray500,
                                  colorPrimary: Color.primary500,
                                  fontSize: 16,
                                  borderRadius: 8,
                                  colorTextPlaceholder: Color.gray500,
                                },
                              }}
                            >
                              <CustomDatePicker
                                placeholder={endDate ? endDate : '날짜를 선택해주세요'}
                                locale={locale}
                                onChange={handleEndDateChange}
                                disabledDate={(current) => {
                                  if (!startDate) {
                                    return current && current < dayjs().endOf('day');
                                  }
                                  return (
                                    current &&
                                    current < dayjs(startDate).subtract(1, 'day').endOf('day')
                                  );
                                }}
                              />
                            </ConfigProvider>
                          </PeriodWrapper>
                        </PeriodContainer>
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>배움 가격</span>
                        <br />
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: Color.primary500,
                              borderRadius: 12,
                              colorBgContainer: '#f6ffed',
                            },
                          }}
                        >
                          <Radio.Group onChange={onChange} value={priceType}>
                            <Space direction="vertical">
                              <Radio value={1}>무료</Radio>
                              <Radio value={2}>유료</Radio>
                            </Space>
                          </Radio.Group>
                        </ConfigProvider>
                        <div>
                          <Input
                            value={priceType === 1 && price === 0 ? '' : price}
                            type={'number'}
                            min={0}
                            disabled={priceType === 1 ? true : false}
                            onChange={(e) => setPrice(Number(e.target.value))}
                            placeholder={'가격을 입력해주세요.'}
                            style={{ height: '40px', marginTop: '5px', width: '70%' }}
                          />
                        </div>
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>주최</span>
                        <Input
                          value={organization}
                          placeholder={'주최좌 혹은 주최 기관 및 단체를 입력해주세요.'}
                          onChange={(e) => setOrganization(e.target.value)}
                          style={{ height: '40px', marginTop: '5px' }}
                        />
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>연락처</span>
                        <Input
                          value={contact}
                          type={'tel'}
                          placeholder={'주최자 연락처를 입력해주세요.'}
                          onChange={(e) => setContact(e.target.value)}
                          style={{ height: '40px', marginTop: '5px' }}
                        />
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>프로그램/행사 키워드 (최대 3개)</span>
                        <div style={{ marginTop: '8px' }}>
                          <Chip init={false} />
                        </div>
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>링크</span>
                        <Input
                          value={link}
                          placeholder={'모집을 진행하는 URL을 입력해주세요'}
                          onChange={(e) => setLink(e.target.value)}
                          style={{ height: '40px', marginTop: '5px' }}
                        />
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>대표 이미지</span>
                        <div
                          style={{
                            border: `1px solid ${Color.gray200}`,
                            borderRadius: '8px',
                            marginTop: '7px',
                          }}
                        >
                          <AdminUpload
                            size={'대표이미지를 등록해주세요.'}
                            onChange={onUploadFile}
                            preview={thumbnail}
                          />
                        </div>
                      </Stlyediv>
                      <Stlyediv>
                        <span style={{ fontWeight: '700' }}>프로그램/행사 상세 내용</span>
                        <Editor placeholder={placeholder} />
                      </Stlyediv>
                    </div>
                  </div>
                </Modal>

                <div
                  className={'page'}
                  style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                >
                  <Pagination
                    current={pageNumber}
                    defaultCurrent={1}
                    onChange={setPageNumber}
                    pageSize={10}
                    total={totalElements}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default AdminProgram;

const close = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.gray600,
    }}
  >
    종료
  </div>
);

const pause = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: 'red',
    }}
  >
    일시정지
  </div>
);

const active = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.blue500,
      borderRadius: '16px',
      padding: '4px 16px',
      backgroundColor: Color.blue,
    }}
  >
    진행중
  </div>
);

const chipStatus = (status: STATUS) => {
  switch (status) {
    case 'CLOSE':
      return close;
    case 'ACTIVE':
      return active;
    case 'PAUSE':
      return pause;
    default:
      return <h4>UNKNOWN</h4>;
  }
};

const shortText = (text?: string, length?: number) => {
  if (!text) return '';
  let lengthValue = length ? length : 10;
  return text.length > lengthValue ? text.substr(0, lengthValue) + '...' : text;
};

const PeriodContainer = styled.div`
  display: flex;
  column-gap: 8px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    column-gap: 0px;
    row-gap: 8px;
  }
`;
const PeriodWrapper = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
`;
const DescriptionStyle = styled.div`
  color: ${Color.gray600};
  font-size: 14px;
  font-weight: 500;
`;
const PeriodDescription = styled(DescriptionStyle)`
  margin-bottom: 3px;
`;
const CustomDatePicker = styled(DatePicker)`
  width: 196px;
  height: 38px;
  border-radius: 6px;
  border: 1px solid ${Color.gray200};
  @media screen and (max-width: 600px) {
    width: min(196px, 100%);
  }
  @media screen and (max-width: 360px) {
    width: 100%;
  }
  ::placeholder {
    font-size: 14px;
  }
`;

const placeholder = `* 프로그램에 대한 상세정보를 입력해주세요. (프로그램 상세 소개, 커리큘럼, 강사진, 사진 등)
* 이미지 형태로 업로드 시 권장 가로 사이즈는 945px 입니다.
* 준비물이 필요한 경우 꼭 적어주세요. (ex. 운동화, 엑셀이 설치된 노트북 등)
`;
