import { Course } from '../../course/domain/course';
import silverClient from '../../_common/client/silver-client';
import { ApiResponse, PageResponse } from '../../_common/domain/response';
import qs from 'qs';
import { AxiosError } from 'axios';

export const findCoursePage = async (
  page: number,
  size: number,
): Promise<PageResponse<Course> | null> => {
  const params = qs.stringify({ page: page, size: size });
  return silverClient.get('/v1/lectures/learner?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<Course>;
      return pageResponse;
    },
    () => {
      console.log('mypage findCoursePage error');
      return null;
    },
  );
};

export const cancelCourse = async (lectureIdx: number, type: number): Promise<boolean | null> => {
  return silverClient
    .post(`/v1/lectures/${lectureIdx}/cancel`)
    .then((response) => {
      return response?.success ?? false;
    })
    .catch((error: AxiosError) => {
      console.log('course cancelCourse error');
      let apiResponse = error.response?.data as ApiResponse;
      if (apiResponse) {
        window.alert(`${apiResponse.message}`);
      } else {
        window.alert(`${type === 1 ? '신청 취소에' : '환불 취소에'} 실패하였습니다.}`);
      }
      return null;
    });
};

export const updateCategories = async (idxes: number[]) => {
  const categories = { categoryIdxes: idxes };
  return silverClient.put('/v1/users/categories', categories);
};

export const updateRegion = async (regionIdx: string, address: string) => {
  const region = {
    regionIdx: regionIdx,
    address: address,
  };
  return silverClient.put('/v1/users/region', region);
};
