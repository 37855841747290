import { Button, ConfigProvider, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Color from '../../_common/view/style/Color';
import styled from 'styled-components';
import SilverClient from '../../_common/client/silver-client';
import silverClient from '../../_common/client/silver-client';

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const emailRegEx =
    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;

  const [idValue, setId] = useState('');
  const [passwordValue, setPassword] = useState('');

  const saveId = (e: any) => {
    setId(e.target.value);
  };

  const savePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const login = async () => {
    if (!emailRegEx.test(idValue)) {
      alert('이메일 형식이 올바르지 않습니다.');
      return;
    }
    try {
      await SilverClient.authenticate(idValue, passwordValue).then((res) => {
        navigate(redirect ? redirect : '/');
      });
    } catch (error) {
      setPassword('');
    }
  };

  const navigate = useNavigate();

  const navigateToSignup = () => {
    navigate('/signup');
  };
  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') login();
  };

  const loc = useLocation();
  useEffect(() => {
    if (silverClient.getUser()) {
      navigate('/');
    }
    const queryParams = new URLSearchParams(loc.search);
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setId(emailParam);
    }
  }, []);

  return (
    <Container>
      <Content>
        <Title>로그인</Title>
        <InputContainer>
          <ConfigProvider
            theme={{
              token: {
                colorBorder: Color.gray500,
                fontSize: 16,
                controlHeight: 48,
                colorTextPlaceholder: Color.gray500,
                borderRadius: 8,
                colorPrimary: Color.primary500,
              },
              components: {
                Input: {
                  paddingInline: 12,
                },
              },
            }}
          >
            <Input
              placeholder="아이디를 입력해주세요"
              allowClear={true}
              type="email"
              style={{ width: '100%' }}
              onChange={saveId}
              value={idValue}
            />
            <Input
              placeholder="비밀번호를 입력해주세요"
              allowClear={true}
              type="password"
              onChange={savePassword}
              value={passwordValue}
              onKeyDown={(e) => onPressEnter(e)}
            />
          </ConfigProvider>
          <ConfigProvider
            theme={{
              token: {
                colorBorder: Color.primary500,
                colorBgContainer: Color.primary500,
                fontSize: 16,
                controlHeight: 48,
                borderRadius: 8,
                colorText: 'white',
                colorPrimary: Color.primary500,
              },
              components: {
                Button: {
                  fontWeight: 500,
                  borderColorDisabled: Color.gray100,
                  colorBgContainerDisabled: Color.gray100,
                  colorTextDisabled: Color.gray400,
                },
              },
            }}
          >
            <Button onClick={login} disabled={idValue.length === 0 || passwordValue.length === 0}>
              로그인
            </Button>
          </ConfigProvider>
        </InputContainer>

        <SignUp>
          <Find>
            <FindText>비밀번호를 잊으셨나요?</FindText>
          </Find>
          <ConfigProvider
            theme={{
              token: {
                colorBorder: Color.primary500,
                lineWidth: 2,
                fontSize: 16,
                controlHeight: 48,
                borderRadius: 8,
                colorText: Color.primary500,
                colorPrimary: Color.primary500,
              },
              components: {
                Button: {
                  fontWeight: 500,
                },
              },
            }}
          >
            <Button onClick={navigateToSignup} style={{ width: '100%' }}>
              회원가입 하러가기
            </Button>
          </ConfigProvider>
        </SignUp>
      </Content>
    </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  padding: 48px 16px;
  width: calc(100% - 32px);
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: min(400px, 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  font-size: 32px;
  color: ${Color.gray800};
  font-weight: 700;
  margin-bottom: 32px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
const Find = styled.div`
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: center;
`;
const FindText = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${Color.primary500};
  font-size: 16px;
  font-weight: 500;
`;
const SignUp = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
