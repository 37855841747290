import React from 'react';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import { useRecoilState } from 'recoil';
import { ConfigProvider, Input } from 'antd';
import { courseNameState, maxCapacityState } from '../../state/CourseSignInState';

const Name = () => {
  const [name, setName] = useRecoilState<string>(courseNameState);
  const handleChange = (value: any) => {
    setName(value.target.value);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Color.primary500,
          colorBorder: Color.gray500,
          fontSize: 16,
          borderRadius: 8,
          colorTextPlaceholder: Color.gray500,
        },
      }}
    >
      <Input
        placeholder="수업의 이름을 입력해주세요. (최대 공백포함 35자)"
        style={{ width: '100%', height: 48 }}
        maxLength={35}
        status=""
        value={name}
        onInput={handleChange}
        // prefix={<ExclamationCircleOutlined />}
      />
    </ConfigProvider>
  );
};

export default Name;
