import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import Slider from 'react-slick';
import Banner from '../../../banner/view/Banner';
import Card from '../../../_common/view/component/Card';
import {
  mainClosestListState,
  mainNewListState,
  mainRecommendListState,
} from '../../state/MainState';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/Main.css';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import { findCoursePage } from '../../../course/repository/course-repository';
import { bannerState } from '../../../banner/state/BannerState';
import { findActiveBanners } from '../../../admin/repository/banners-repository';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const MainBody = () => {
  const [banners, setBanners] = useRecoilState(bannerState);
  const fetchBanners = async () => {
    const banners = (await findActiveBanners()) ?? [];
    setBanners(banners);
  };

  const [mainRecommendList, setMainRecommendList] = useRecoilState(mainRecommendListState);
  const [mainNewList, setMainNewList] = useRecoilState(mainNewListState);
  const [mainClosestList, setMainClosestList] = useRecoilState(mainClosestListState);

  useEffect(() => {
    fetchBanners();
    findCoursePage({
      page: 0,
      size: 8,
      sort: 'RECOMMEND',
    }).then((res) => {
      const data = res?.contents ?? [];
      setMainRecommendList(data);
    });
    findCoursePage({
      page: 0,
      size: 8,
      sort: 'CREATED_AT_DESC',
    }).then((res) => {
      const data = res?.contents ?? [];
      setMainNewList(data);
    });
    findCoursePage({
      page: 0,
      size: 8,
      sort: 'RECRUITMENT_PERIOD_CLOSEST',
    }).then((res) => {
      const data = res?.contents ?? [];
      setMainClosestList(data);
    });
  }, []);

  return (
    <Container>
      <BannerDiv>
        <Banner banners={banners.filter((it) => it.type === 'MAIN_1')} />
      </BannerDiv>

      <Content>
        <TextContainer>
          <Title>지금 나에게 어울리는 배움</Title>
          <SubTitle>내 관심사를 분석해서 추천해 드리는 배움이에요.</SubTitle>
        </TextContainer>
        <Slider {...settings}>
          {mainRecommendList.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </Slider>
      </Content>

      <Content>
        <TextContainer>
          <Title>새롭게 열린 배움</Title>
          <SubTitle>최근 개설된 따끈따끈한 배움들입니다.</SubTitle>
        </TextContainer>
        <Slider {...settings}>
          {mainNewList.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </Slider>
      </Content>

      <BannerDiv>
        <Banner banners={banners.filter((it) => it.type === 'MAIN_2')} />
      </BannerDiv>

      <Content>
        <TextContainer>
          <Title>마감 임박 배움</Title>
          <SubTitle>신청이 얼마 남지 않은 배움들이에요.</SubTitle>
        </TextContainer>
        <Slider {...settings}>
          {mainClosestList.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </Slider>
      </Content>
    </Container>
  );
};
export default MainBody;

const Container = styled.div`
  //margin: 0 16px;
  width: calc(100% - 32px);
`;
const Content = styled.div`
  width: 100%;
  padding: 56px 0;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-left: 16px;
  color: ${Color.gray800};
`;
const SubTitle = styled.div`
  font-size: 20px;
  margin-left: 16px;
  font-weight: 700;
  color: ${Color.gray500};
`;

const BannerDiv = styled.div`
  margin-left: 30px;
`;
