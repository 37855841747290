import React, { useEffect, useState } from 'react';
import AdminLayout from '../AdminLayout';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  Modal,
  Pagination,
  Select,
  Space,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Color from '../../../../_common/view/style/Color';
import { SearchOutlined, SmallDashOutlined } from '@ant-design/icons';
import { SearchProps } from 'antd/es/input';
import { useRecoilState } from 'recoil';
import { adminReportListState } from '../../../state/AdminState';
import { findReportPage } from '../../../repository/report-repository';
import { AdminReport } from '../../../domain/admin-report';

const { Search } = Input;
const AdminReportPage = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [visible, setVisible] = useState(true);

  const [report, setReport] = useRecoilState(adminReportListState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getPaymentPages = async () => {
    const paymentPages = await findReportPage(pageNumber - 1, 12);
    setReport(paymentPages?.contents as Array<AdminReport>);
    setTotalElements(paymentPages?.totalElements as number);
  };

  useEffect(() => {
    getPaymentPages();
    console.log(report?.map((el) => el.status));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal2 = () => {
    setIsModal2Open(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const isVisible = () => {
    setVisible(!visible);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const items = [
    {
      key: '1',
      label: (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                textHoverBg: 'transparent',
              },
            },
          }}
        >
          <Button type="text" onClick={isVisible}>
            {visible ? '비공개하기' : '공개하기'}
          </Button>
        </ConfigProvider>
      ),
    },
    {
      key: '2',
      label: (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                textHoverBg: 'transparent',
              },
            },
          }}
        >
          <Button type="text" onClick={showModal}>
            삭제하기
          </Button>
        </ConfigProvider>
      ),
    },
  ];

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    {
      // navigate('/search', {state: {value}});
      console.log(value);
    }
  };

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '3%',
                }}
              >
                신고 관리
              </div>
              <div style={{ display: 'flex', marginBottom: '3%' }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: Color.primary500,
                      controlHeight: 48,
                      colorBorder: Color.gray500,
                      colorTextPlaceholder: Color.gray500,
                      fontSize: 16,
                      colorBorderBg: Color.gray800,
                    },
                  }}
                >
                  <Search
                    placeholder="검색어를 입력하세요."
                    onSearch={onSearch}
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </Header>
          <Content>
            <div
              style={{
                padding: 16,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <div>
                <table
                  className="common-table"
                  style={{
                    borderCollapse: 'collapse',
                    borderSpacing: '0 10px',
                    width: '100%',
                    marginBottom: '30px',
                  }}
                >
                  <thead>
                    <tr>
                      <td className="common-table-header-column">글 유형</td>
                      <td className="common-table-header-column">내용</td>
                      <td className="common-table-header-column">작성자</td>
                      <td className="common-table-header-column">작성일</td>
                      <td className="common-table-header-column">상태</td>
                      <td className="common-table-header-column">신고 사유</td>
                      <td className="common-table-header-column"> </td>
                    </tr>
                  </thead>
                  <tbody>
                    {report ? (
                      report.length > 1 ? (
                        report.map((item, index) => (
                          <tr
                            className="common-table-row"
                            style={{
                              marginBottom: '5%',
                              borderBottom: `1px solid ${Color.gray200}`,
                            }}
                            key={index}
                          >
                            <td className="common-table-column">{item.targetType}</td>
                            <td className="common-table-column">{item.contents}</td>
                            <td className="common-table-column">{item.reporter.basicInfo.name}</td>
                            <td className="common-table-column">{item.createdAt} </td>
                            <td className="common-table-column"> {item.status}</td>
                            <td className="common-table-column"> {item.reportReason}</td>
                            <td className="common-table-column">
                              <Dropdown menu={{ items }} trigger={['click']}>
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Space>
                                    <SmallDashOutlined />
                                  </Space>
                                </a>
                              </Dropdown>

                              <Modal
                                open={isModalOpen}
                                mask={false}
                                closable={false}
                                width={400}
                                footer={
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Button
                                      type={'text'}
                                      style={{ color: Color.primary500, fontWeight: '700' }}
                                      onClick={handleCancel}
                                    >
                                      돌아가기
                                    </Button>
                                    <Button
                                      type={'primary'}
                                      style={{
                                        color: 'white',
                                        backgroundColor: Color.primary500,
                                      }}
                                    >
                                      강좌 삭제
                                    </Button>
                                  </div>
                                }
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p style={{ fontWeight: '700' }}>정말로 삭제하나요?</p>
                                  <span>탈퇴 처리 후에는 되돌릴 수 없습니다.</span>
                                  <span style={{ marginBottom: '5%' }}>
                                    정산,환불 등 남은 이슈가 없는지 꼭 확인해주세요.
                                  </span>
                                </div>
                              </Modal>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={15}>접수된 신고가 없습니다.</td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan={15}>접수된 신고가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {data.length > 1 ? (
                  <div
                    className={'page'}
                    style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                  >
                    <Pagination
                      defaultCurrent={1}
                      onChange={setPageNumber}
                      total={totalElements}
                      pageSize={12}
                      showSizeChanger={false}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default AdminReportPage;

const data = [
  // {
  //     category: '자유게시판',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '강의 개설 요청',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '모임자랑',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '자유게시판',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '강의 개설 요청',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '모임자랑',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '모임자랑',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '자유게시판',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '강의 개설 요청',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  // {
  //     category: '모임자랑',
  //     title: '강blablabla',
  //     name: '홍길동',
  //     date: '2021.08.30',
  //     recommand: '10',
  //     comment: '5',
  // },
  {
    category: '',
    title: '',
    name: '',
    date: '',
    recommand: '',
    comment: '',
  },
];
