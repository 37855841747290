import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Form, Input, Select } from 'antd';
import Color from '../../_common/view/style/Color';
import styled from 'styled-components';
import Chip from '../../signup/view/component/Chip';
import { useNavigate } from 'react-router-dom';
import silverClient from '../../_common/client/silver-client';
import SideBar from './Component/SideBar';
import bcrypt from 'bcryptjs-react';
import { AxiosError } from 'axios';
import { validatePassword } from '../util/UserUtil';
import { updateCategories, updateRegion } from '../repository/mypage-repository';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userCategoryState } from '../../user/state/user-state';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { findRegionByCode } from '../../signup/repository/signupRepository';
import { SignupAddress } from '../../signup/domain/signup';
import { signUpAddressState } from '../../signup/state/SignupState';

const { Search } = Input;

const Mypage: React.FC = () => {
  const navigate = useNavigate();
  const MoveToEditInfo = () => {
    navigate('/mypage');
  };

  const MoveToManage = () => {
    navigate('/managepayment');
  };

  const MoveToApplyStudy = () => {
    navigate('/applystudy');
  };

  const user = silverClient.getUser();

  const [isOpened, setIsOpened] = useState(false);
  const toggleIsOpened = () => {
    setIsOpened(!isOpened);
  };

  const [pw, setPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [newPw2, setNewPw2] = useState('');

  const savePw = (e: any) => {
    setPw(e.target.value);
  };
  const saveNewPw = (e: any) => {
    setNewPw(e.target.value);
  };
  const saveNewPw2 = (e: any) => {
    setNewPw2(e.target.value);
  };
  const validateChangePw = () => {
    if (pw.length === 0 || newPw.length === 0 || newPw2.length === 0) return false;
    if (newPw != newPw2) return false;

    return true;
  };
  const cancelChangePw = () => {
    setIsOpened(false);
    setPw('');
    setNewPw('');
    setNewPw2('');
  };
  const changePW = () => {
    if (pw == newPw) {
      window.alert('기존 비밀번호와 새 비밀번호가 같습니다.');
      return;
    }
    if (!validatePassword(newPw)) {
      window.alert('비밀번호는 영문, 숫자, 특수문자를 포함한 8자리 이상 15자리 이하이어야 합니다.');
      return;
    }
    const securedPassword = bcrypt.hashSync(newPw, bcrypt.genSaltSync(10));
    const data = {
      currentRawPassword: pw,
      securedPassword: securedPassword,
    };
    silverClient
      .put('/v1/users/password', data)
      .then((res) => {
        window.alert('비밀번호가 성공적으로 변경되었습니다.');
        setIsOpened(false);
      })
      .catch((err: AxiosError) => {
        if (err?.response?.status === 400) {
          window.alert('기존 비밀번호가 다릅니다.');
          return;
        }
        window.alert('비밀번호 변경에 실패했습니다.');
        console.error(err);
      });
  };

  const getPhoneNumber = () => {
    const regex = /^(\d{3})(\d{4})(\d{4})$/;
    return user?.basicInfo.phone.number.replace(regex, '$1-$2-$3');
  };

  const [address, setAddress] = useRecoilState(signUpAddressState);
  const categories = useRecoilValue(userCategoryState);
  const update = async () => {
    let selectedCategoryIdxes = categories.filter((c) => c.selected).map((c) => c.idx);
    await updateCategories(selectedCategoryIdxes);
    if (address && address.regionIdx && address.detailAddress) {
      await updateRegion(address.regionIdx, address.detailAddress);
    }
    await silverClient.updateMy();
    window.alert('회원정보가 수정되었습니다.');
  };

  const [form] = Form.useForm();
  const open = useDaumPostcodePopup(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  );
  const searchAddress = () => {
    open({
      onComplete: handleComplete,
      popupTitle: '주소 검색',
      popupKey: 'popupKey',
      showMoreHName: true,
    });
  };
  const handleComplete = (data: any) => {
    findRegionByCode(data?.bcode).then((res) => {
      const regionIdx = res?.idx;
      if (regionIdx) {
        setAddress({
          regionIdx: regionIdx.toString(),
          detailAddress: data?.roadAddress,
        } as SignupAddress);
      } else {
        alert('해당 지역은 수정이 불가능합니다.');
      }
    });
  };

  useEffect(() => {
    setAddress(null);
  }, []);

  return (
    <Container>
      <SideBar activeindex={0} />
      <ContentContainer>
        <MainTitle>회원정보 수정</MainTitle>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
            },
            components: {
              Form: {
                itemMarginBottom: 0,
              },
            },
          }}
        >
          <Content>
            <Title>아이디</Title>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  fontSize: 16,
                  colorTextDisabled: Color.gray800,
                  colorBgContainerDisabled: Color.gray,
                  colorPrimary: Color.primary500,
                  colorBorder: Color.gray500,
                },
                components: {
                  Input: {
                    paddingInline: 12,
                    paddingBlock: 12,
                  },
                },
              }}
            >
              <Input disabled={true} value={user?.email} />
            </ConfigProvider>
          </Content>

          <Content>
            <Title>비밀번호</Title>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  colorText: Color.primary500,
                  colorBorder: Color.primary500,
                  colorPrimary: Color.primary500,
                  lineWidth: 2,
                  fontSize: 16,
                  controlHeight: 44,
                },
                components: {
                  Button: {
                    fontWeight: 700,
                  },
                },
              }}
            >
              <Button onClick={toggleIsOpened}>비밀번호 변경</Button>
            </ConfigProvider>
          </Content>

          {isOpened ? (
            <Content>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    fontSize: 16,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    colorText: Color.gray800,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Input: {
                      paddingInline: 12,
                      paddingBlock: 12,
                    },
                  },
                }}
              >
                <Title>기존 비밀번호</Title>
                <Input.Password
                  placeholder="비밀번호를 입력해주세요"
                  onChange={savePw}
                  value={pw}
                  lang="en"
                />
                <Title>
                  새 비밀번호
                  <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                    영문, 숫자, 특수문자를 포함한 8자리 이상 15자리 이하
                  </span>
                </Title>
                <Input.Password
                  placeholder="새 비밀번호를 입력해주세요"
                  onChange={saveNewPw}
                  value={newPw}
                  lang="en"
                />
                <Input.Password
                  placeholder="새 비밀번호를 다시 한 번 입력해주세요"
                  onChange={saveNewPw2}
                  value={newPw2}
                  lang="en"
                />
              </ConfigProvider>
              <ChangePW>
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 8,
                      colorText: Color.primary500,
                      colorBorder: Color.primary500,
                      colorPrimary: Color.primary500,
                      lineWidth: 2,
                      fontSize: 16,
                      controlHeight: 44,
                    },
                    components: {
                      Button: {
                        fontWeight: 700,
                      },
                    },
                  }}
                >
                  <Button onClick={cancelChangePw} style={{ width: '100%' }}>
                    취소
                  </Button>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 8,
                      colorText: 'white',
                      colorBgContainer: Color.primary500,
                      colorBorder: `${validateChangePw() ? Color.primary500 : Color.gray100}`,
                      colorPrimary: Color.primary500,
                      lineWidth: 2,
                      fontSize: 16,
                      controlHeight: 44,
                      colorTextDisabled: Color.gray400,
                      colorBgContainerDisabled: Color.gray100,
                    },
                    components: {
                      Button: {
                        fontWeight: 700,
                      },
                    },
                  }}
                >
                  <Button
                    style={{ width: '100%' }}
                    disabled={!validateChangePw()}
                    onClick={changePW}
                  >
                    비밀번호 변경
                  </Button>
                </ConfigProvider>
              </ChangePW>
            </Content>
          ) : null}

          <Content>
            <Title>생년월일</Title>
            <BirthdayContainer>
              <ConfigProvider
                theme={{
                  token: {
                    controlHeight: 48,
                    colorBorder: Color.gray500,
                    colorText: Color.gray800,
                    colorTextPlaceholder: Color.gray500,
                    fontSize: 16,
                    colorPrimary: Color.primary500,
                    colorTextDisabled: Color.gray800,
                  },
                }}
              >
                {user?.basicInfo?.birth
                  ?.split('-')
                  ?.map((date) => <Select value={date} disabled={true} />)}
              </ConfigProvider>
            </BirthdayContainer>
          </Content>

          <Content>
            <Title>성함</Title>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  fontSize: 16,
                  colorBorder: Color.gray500,
                  colorTextPlaceholder: Color.gray500,
                  colorText: Color.gray800,
                  colorPrimary: Color.primary500,
                  colorTextDisabled: Color.gray800,
                },
                components: {
                  Input: {
                    paddingInline: 12,
                    paddingBlock: 12,
                  },
                },
              }}
            >
              <Input disabled={true} value={user?.basicInfo.name} />
            </ConfigProvider>
          </Content>

          <Content>
            <Title>번호</Title>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  fontSize: 16,
                  colorBorder: Color.gray500,
                  colorTextPlaceholder: Color.gray500,
                  colorText: Color.gray800,
                  colorPrimary: Color.primary500,
                  colorTextDisabled: Color.gray800,
                },
                components: {
                  Input: {
                    paddingInline: 12,
                    paddingBlock: 12,
                  },
                },
              }}
            >
              <Input disabled={true} value={getPhoneNumber()} />
            </ConfigProvider>
          </Content>

          <Content>
            <Title>성별</Title>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  fontSize: 16,
                  colorBorder: Color.gray500,
                  colorTextPlaceholder: Color.gray500,
                  colorText: Color.gray800,
                  colorPrimary: Color.primary500,
                  controlHeight: 48,
                  colorTextDisabled: Color.gray800,
                },
              }}
            >
              <Select disabled={true} value={user?.basicInfo.gender === 'MALE' ? '남성' : '여성'} />
            </ConfigProvider>
          </Content>

          <Content>
            <Title>주소</Title>
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  fontSize: 16,
                  colorBorder: Color.gray500,
                  colorTextPlaceholder: Color.gray500,
                  colorText: Color.gray800,
                  colorPrimary: Color.primary500,
                  controlHeight: 48,
                },
              }}
            >
              <Search
                placeholder={user?.basicInfo?.address ?? ''}
                value={address?.detailAddress ?? ''}
                readOnly
                onClick={searchAddress}
                onSearch={searchAddress}
              />
            </ConfigProvider>
          </Content>

          <Content>
            <Title>관심사 (최대 3개)</Title>
            <Chip init={true} />
          </Content>

          <ConfigProvider
            theme={{
              token: {
                borderRadius: 8,
                colorBgContainer: Color.primary500,
                colorText: 'white',
                fontSize: 16,
                controlHeight: 44,
              },
              components: {
                Button: {
                  fontWeight: 500,
                },
              },
            }}
          >
            <Button onClick={update}>저장</Button>
          </ConfigProvider>
        </ConfigProvider>
      </ContentContainer>
    </Container>
  );
};

export default Mypage;

const Container = styled.div`
  padding: 48px 16px;
  display: flex;
  gap: 120px;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
`;
const MainTitle = styled.div`
  font-weight: 700;
  color: ${Color.gray800};
  font-size: 24px;
  margin-bottom: 8px;

  @media screen and (max-width: 1023px) {
    align-self: center;
  }
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.gray800};
`;
const ContentContainer = styled.div`
  width: min(400px, 98%);
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ChangePW = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
const BirthdayContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;
