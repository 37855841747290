import { atom } from 'recoil';
import { Birthdate, SignupAddress, SignupRequestState } from '../domain/signup';

export const birthDateState = atom<Birthdate | null>({
  key: 'birthDateState',
  default: null,
});

export const selectedChipsState = atom<string[]>({
  key: 'selectedChipsState',
  default: [],
});

export const userSelectedChipsState = atom<number[]>({
  key: 'userSelectedChipsState',
  default: [],
});

export const checkItemsState = atom<boolean[]>({
  key: 'checkItemsState',
  default: [false, false, false, false],
});

export const signUpAddressState = atom<SignupAddress | null>({
  key: 'signUpAddressState',
  default: null,
});

export const signUpRequestState = atom<SignupRequestState | null>({
  key: 'signUpRequestState',
  default: null,
});
