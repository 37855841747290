import SilverClient from '../client/silver-client';
import { ListResponse, SingleResponse } from '../domain/response';

export const upload = async (file: File, path: StoragePath): Promise<string | null> => {
  const form = new FormData();
  form.append('file', file);
  form.append(
    'request',
    new Blob([JSON.stringify(path)], {
      type: 'application/json',
    }),
  );
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return await SilverClient.getResourceInstance()
    .post<SingleResponse<UploadUrlResponse>>('/v1/storages', form, config)
    .then((response: any) => {
      const singleResponse = response?.data as SingleResponse<UploadUrlResponse>;
      return singleResponse?.content?.url;
    })
    .catch((error: any) => {
      console.error('upload error', error);
      return null;
    });
};

export const uploads = async (files: File[], path: StoragePath): Promise<string[] | null> => {
  const form = new FormData();
  files.forEach((file) => {
    form.append('files', file);
  });
  form.append(
    'request',
    new Blob([JSON.stringify(path)], {
      type: 'application/json',
    }),
  );
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return await SilverClient.getResourceInstance()
    .post<ListResponse<UploadUrlResponse>>('/v1/storages/list', form, config)
    .then((response: any) => {
      const listResponse = response?.data as ListResponse<UploadUrlResponse>;
      return listResponse?.contents?.map((content) => content.url);
    })
    .catch((error: any) => {
      console.error('uploads error', error);
      return null;
    });
};

export interface UploadUrlResponse {
  url: string;
}

export interface StoragePath {
  bucket: string;
  domain: string;
  category: string;
}
