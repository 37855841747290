import { atom } from 'recoil';
import { Program } from '../../program/domain/program';
import { Course } from '../../course/domain/course';
import { Post } from '../../community/domain/community';

export const searchKeyword = atom<string>({
  key: 'searchKeyword',
  default: '', // Assuming data is your initial state
});

export const searchCourseListState = atom<Array<Course> | null>({
  key: 'searchCourseListState',
  default: null, // Assuming data is your initial state
});
export const searchProgramListState = atom<Array<Program> | null>({
  key: 'searchProgramListState',
  default: null, // Assuming data is your initial state
});
export const searchGatheringState = atom<Array<Course> | null>({
  key: 'searchGatheringState',
  default: null, // Assuming data is your initial state
});
export const searchCommunityState = atom<Array<Post> | null>({
  key: 'searchCommunityState',
  default: null, // Assuming data is your initial state
});
