import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';

const baseUrl = 'https://assets.bae-f.com/';

const CourseCard = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToDetail = () => {
    if (location.pathname === '/courses') navigate('/courses/' + props.idx);
    else if (location.pathname === '/gathering') navigate('/gathering/' + props.idx);
  };

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  return (
    <Container onClick={navigateToDetail}>
      <ImageWrapper>
        <img
          style={{ objectFit: 'contain', borderRadius: 4 }}
          src={baseUrl + props.thumbnailImage.pcUrl}
          className="product_img"
        />
      </ImageWrapper>
      <Detail>
        <div className="product_title">
          {props?.price > 0 ? '유료' : '무료'} | {props?.region?.region1Name}
        </div>
        <div className="product_des">{props?.title}</div>
        <div className="product_mon">
          {convertDateFormat(props?.operatingPeriod.start)} ~{' '}
          {convertDateFormat(props?.operatingPeriod.end)}
        </div>
      </Detail>
    </Container>
  );
};

export default CourseCard;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const ImageWrapper = styled.div`
  width: min(263px, 100%);
  aspect-ratio: 16/9;
`;
const Detail = styled.div`
  width: min(263px, 100%);
`;
