import React, { useEffect, useState } from 'react';
import { Button, Input, Layout, theme } from 'antd';
import Styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Color from '../../../_common/view/style/Color';
import { useMediaQuery } from 'react-responsive';
import SideBar from './SideBar';
import { findSettleInfo, updateTeacher } from '../../repository/coach-repository';
import silverClient from '../../../_common/client/silver-client';
import { SettleInfo } from '../../domain/coach';

const { Content, Sider } = Layout;

const CoachMypage: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const user = silverClient.getUser();

  //버튼 클릭하면 component open control
  const [pwdopen, setpwdOpen] = useState(false);
  const [carreropen, setCarrerOpen] = useState(false);
  const [phoneopen, setphoneOpen] = useState(false);
  const [expropen, setexprOpen] = useState(false);

  const [career, setCareer] = useState<string>('');
  const [educationHistory, setEducationHistory] = useState<string>('');

  useEffect(() => {
    silverClient.updateMy().then((user) => {
      setCareer(user?.career ?? '');
      setEducationHistory(user?.educationHistory ?? '');
    });
  }, []);

  const isChanged = () => {
    return user?.career !== career || user?.educationHistory !== educationHistory;
  };

  const update = () => {
    updateTeacher(career, educationHistory)
      .then(() => {
        alert('저장되었습니다.');
      })
      .catch((err) => {
        alert('저장에 실패하였습니다.');
      });
  };

  // const [carrers, setCarrers] = useRecoilState(CarrerState);
  // const[experiences, setExperiences] = useRecoilState(ExperienceState);

  // //경력사항 삭제 Event Controller
  // const onRemoveCarrer = (id: number) => {
  //     setCarrers(carrers? carrers.filter(carrer => carrer.idx !== id) : null);
  // }
  // const onRemoveExpr = (id: number) => {
  //     setExperiences(experiences? experiences.filter((expr) => expr.idx !== id) : null);
  // }

  // const fetchCareer = () => {
  //     const userIdx = user?.idx
  //     if (userIdx) {
  //         findCareer(userIdx).then(setCarrers)
  //     }
  // }
  //
  // const fetchHistory = () => {
  //     const userIdx = user?.idx
  //     if (userIdx) {
  //         findHistory(userIdx).then(setExperiences)
  //     }
  // }

  // useEffect(() => {
  //     fetchCareer();
  //     fetchHistory();
  // }, []);

  const navigate = useNavigate();
  const MoveToEditInfo = () => {
    navigate('/coachmypage');
  };

  const MoveToCourseManage = () => {
    navigate('/parking');
  };

  const MoveToPayManage = () => {
    navigate('/parking');
  };

  const MoveToCourseControl = () => {
    navigate('/coursecontrol');
  };

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTabletMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  const pcInput: React.CSSProperties = {
    width: '30%',
    height: '40px',
  };

  const mobileInput: React.CSSProperties = {
    width: '100%',
    height: '40px',
  };

  return (
    <>
      {isPc && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <Sider style={{ backgroundColor: 'white' }} width={150}>
                  <SideBar />
                </Sider>
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <h3>경력정보 수정</h3>
                  <div className={'mypage-container'}>
                    <h3>경력 사항</h3>
                    <Input
                      style={pcInput}
                      placeholder="경력사항을 입력해주세요."
                      value={career}
                      onChange={(e) => setCareer(e.target.value)}
                    />
                    {/*{carrers? carrers.map((carrer) => (*/}
                    {/*    <div key={carrer.idx} style={{display:"flex",width:"80%"}}>*/}
                    {/*        <div style={{width:"30%",display:"flex"}}>*/}
                    {/*            <div style={{marginRight: "5%"}}>*/}
                    {/*                <span style={{fontSize:"16px",color:Color.gray800}}>{carrer.startDate}</span>*/}
                    {/*                <p style={{marginTop:"5%",marginBottom:"5px",fontSize:"16px",color:Color.gray800,fontWeight:"700"}}>{carrer.companyName}</p>*/}
                    {/*                <p style={{color:Color.gray500,marginTop:"0"}}>{carrer.position}</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div >*/}
                    {/*            <Button type={"text"} onClick={()=>onRemoveCarrer(carrer.idx)}><CloseOutlined/></Button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*))*/}
                    {/*: null*/}
                    {/*}*/}
                    {/*<Button style = {{fontWeight:"700",width:"30%",height:"40px",color:Color.primary500, border:`2px solid ${Color.primary500}`}} onClick={ () => setCarrerOpen(carreropen ? false : true) }>+ 추가</Button>*/}
                    {/*{*/}
                    {/*    carreropen ?*/}
                    {/*        <CoachEditCarrer/>*/}
                    {/*        : null*/}
                    {/*}*/}
                    <h3>강의 이력</h3>
                    <Input
                      style={pcInput}
                      placeholder="강의 이력을 입력해주세요."
                      value={educationHistory}
                      onChange={(e) => setEducationHistory(e.target.value)}
                    />
                    {/*{*/}
                    {/*    experiences?.map((experience) => (*/}
                    {/*        <div style={{width:"90%"}}>*/}
                    {/*            <div style={{marginLeft: "1%", display: "flex"}}>*/}
                    {/*                <div key={experience.idx} style={{width: "30%",wordBreak:"break-all"}}>*/}
                    {/*                    <h4>{experience.description}</h4>*/}
                    {/*                </div>*/}
                    {/*                <div style={{marginTop: "2%"}}>*/}
                    {/*                    <Button type={"text"}*/}
                    {/*                            onClick={() => onRemoveExpr(experience.idx)}><CloseOutlined/></Button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}

                    {/*<Button style = {{fontWeight:"700",width:"30%",height:"40px",color:Color.primary500, border:`2px solid ${Color.primary500}`}} onClick={ () => setexprOpen(expropen ? false : true) }>+ 추가</Button>*/}
                    {/*{*/}
                    {/*    expropen ?*/}
                    {/*        <CoachEditExpr/>*/}
                    {/*        : null*/}
                    {/*}*/}
                    <div style={{ marginTop: '3%' }}>
                      <Button
                        disabled={!isChanged()}
                        style={{
                          backgroundColor: Color.primary500,
                          color: 'white',
                          marginTop: '1%',
                          width: '30%',
                          height: '40px',
                        }}
                        onClick={update}
                      >
                        저장
                      </Button>
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isTabletMobile && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <CenterText>경력정보 수정</CenterText>
                  <div className={'mypage-container'}>
                    <h3>경력 사항</h3>
                    <Input
                      style={mobileInput}
                      placeholder="경력사항을 입력해주세요."
                      value={career}
                      onChange={(e) => setCareer(e.target.value)}
                    />
                    {/*{carrers?*/}
                    {/*    carrers.map((carrer) => (*/}
                    {/*    <EvenDiv key={carrer.idx} >*/}
                    {/*        <div style={{width:"100%",display:"flex"}}>*/}
                    {/*            <div style={{marginRight: "5%"}}>*/}
                    {/*                <span style={{fontSize:"16px",color:Color.gray800}}>{carrer.startDate}</span>*/}
                    {/*                <p style={{marginTop:"5%",marginBottom:"5px",fontSize:"16px",color:Color.gray800,fontWeight:"700"}}>{carrer.companyName}</p>*/}
                    {/*                <p style={{color:Color.gray500,marginTop:"0"}}>{carrer.position}</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div >*/}
                    {/*            <Button type={"text"} onClick={()=>onRemoveCarrer(carrer.idx)}><CloseOutlined/></Button>*/}
                    {/*        </div>*/}
                    {/*    </EvenDiv>*/}
                    {/*))*/}
                    {/*: null*/}
                    {/*}*/}
                    {/*<Button style = {{fontWeight:"700",width:"100%",height:"40px",color:Color.primary500, border:`2px solid ${Color.primary500}`}} onClick={ () => setCarrerOpen(carreropen ? false : true) }>+ 추가</Button>*/}
                    {/*{*/}
                    {/*    carreropen ?*/}
                    {/*        <CoachEditCarrer/>*/}
                    {/*        : null*/}
                    {/*}*/}
                    <h3>강의 이력</h3>
                    <Input
                      style={mobileInput}
                      placeholder="강의 이력을 입력해주세요."
                      value={educationHistory}
                      onChange={(e) => setEducationHistory(e.target.value)}
                    />
                    {/*{*/}
                    {/*    experiences?.map((experience) => (*/}
                    {/*        <EvenDiv>*/}
                    {/*            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>*/}
                    {/*                <div key={experience.idx} style={{width: "100%",wordBreak:"break-all"}}>*/}
                    {/*                    <h4>{experience.description}</h4>*/}
                    {/*                </div>*/}
                    {/*                <div style={{justifyContent:"end"}}>*/}
                    {/*                    <Button type={"text"}*/}
                    {/*                            onClick={() => onRemoveExpr(experience.idx)}><CloseOutlined/></Button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </EvenDiv>*/}
                    {/*    ))}*/}
                    {/*<div style={{display:"flex"}}>*/}
                    {/*    <Button*/}
                    {/*        style={{*/}
                    {/*            fontWeight: "700",*/}
                    {/*            width: "100%",*/}
                    {/*            height: "40px",*/}
                    {/*            color: Color.primary500,*/}
                    {/*            justifyContent:"center",*/}
                    {/*            border: `2px solid ${Color.primary500}`*/}
                    {/*        }}*/}
                    {/*        onClick={() => setexprOpen(expropen ? false : true)}>*/}
                    {/*        + 추가*/}
                    {/*    </Button></div>*/}
                    {/*{*/}
                    {/*    expropen ?*/}
                    {/*        <CoachEditExpr/>*/}
                    {/*        : null*/}
                    {/*}*/}
                    <div style={{ marginTop: '3%' }}>
                      <Button
                        disabled={!isChanged()}
                        style={{
                          backgroundColor: Color.primary500,
                          color: 'white',
                          marginTop: '1%',
                          width: '100%',
                          height: '40px',
                        }}
                        onClick={update}
                      >
                        저장
                      </Button>
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
    </>
  );
};

export default CoachMypage;

const Infotext = Styled.div`
    font-weight: 700;
    font-size: 14px;
    color: gray;
    margin-bottom: 5px;
`;

const CenterText = Styled.h3`

    @media screen and (max-width: 1023px){
    text-align: center;
    margin-top: 5%;
    }
`;

const EvenDiv = Styled.div`
    display:flex;
    width:100%;
`;
