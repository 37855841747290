import React from 'react';
import styled from 'styled-components';
import Color from '../../../_common/view/style/Color';
import { useRecoilState } from 'recoil';
import { priceState, priceValueState } from '../../state/CourseSignInState';
import { ConfigProvider, Input, Radio, Space } from 'antd';

const Price = () => {
  // 배움가격 무료 or 유료 설정
  const [priceOption, setPriceOption] = useRecoilState(priceState);
  const handlePriceChange = (value: any) => {
    setPriceOption(value.target.value);
  };

  const [priceValueOption, setPriceValueOption] = useRecoilState(priceValueState);
  const onInputPrice = (e: any) => {
    setPriceValueOption(e.target.value);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: Color.primary500,
          },
        }}
      >
        <Radio.Group onChange={handlePriceChange} value={priceOption}>
          <Space direction="vertical" style={{ rowGap: 16 }}>
            <Radio value={1}>
              <Name>무료</Name>
            </Radio>
            <Radio value={2}>
              <Name>유료</Name>
            </Radio>
          </Space>
        </Radio.Group>
      </ConfigProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: Color.primary500,
            colorBorder: Color.gray500,
            fontSize: 16,
            borderRadius: 8,
            colorTextPlaceholder: Color.gray500,
          },
        }}
      >
        <Input
          type="number"
          min="0"
          placeholder="수업 가격을 입력해주세요."
          style={{ width: '100%', height: 48, marginTop: 8 }}
          disabled={priceOption == 1 ? true : false}
          onInput={onInputPrice}
        />
      </ConfigProvider>
    </>
  );
};

export default Price;

const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Color.gray800};
`;
