import { atom } from 'recoil';
import {
  Bank,
  Careers,
  CoachBirthdate,
  Experience,
  Settle,
  SettleInfo,
  Student,
} from '../domain/coach';
import { Coach, Coach2 } from '../domain/CoachSignup';

export const countListState = atom({
  key: 'countListState',
  default: [0],
});

export const countList2State = atom({
  key: 'countList2State',
  default: [0],
});

export const CoachBirthDateState = atom<CoachBirthdate | null>({
  key: 'CoachBirthDateState',
  default: {
    year: '년',
    month: '월',
    day: '일',
  },
});

export const CoachAgreeState = atom<number[]>({
  key: 'CoachAgreeState',
  default: [],
});

export const CarrerState = atom<Careers[] | null>({
  key: 'CarrerState',
  default: null,
});

export const ExperienceState = atom<Experience[] | null>({
  key: 'ExperienceState',
  default: null,
});

export const BankState = atom<Bank[] | null>({
  key: 'BankState',
  default: null,
});

export const SettleInfoState = atom<SettleInfo | null>({
  key: 'SettleInfoState',
  default: null,
});

export const CoachSignUpState = atom<Coach | null>({
  key: 'CoachSignUpState',
  default: null,
});

export const CoachSignUpState2 = atom<Coach2 | null>({
  key: 'CoachSignUpState2',
  default: null,
});

export const StudentState = atom<Student[] | null>({
  key: 'StudentState',
  default: null,
});

export const SettleCoachState = atom<Array<Settle> | null>({
  key: 'SettleCoachState',
  default: null,
});
