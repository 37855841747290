import React from 'react';
import { Button, Checkbox, ConfigProvider, Divider, Form, Input, Select } from 'antd';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import '../style/CoachSignUp.css';
import Color from '../../../_common/view/style/Color';
import {
  birthDateState,
  checkItemsState,
  signUpRequestState,
} from '../../../signup/state/SignupState';
import { userCategoryState } from '../../../user/state/user-state';
import bcrypt from 'bcryptjs-react';
import { SignupRequestState } from '../../../signup/domain/signup';
import { findRegionByCode } from '../../../signup/repository/signupRepository';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { validatePassword } from '../../../mypage/util/UserUtil';
import BirthDropdown from '../../../signup/view/component/BirthDropDown';
import Chip from '../../../signup/view/component/Chip';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../../user/repository/user-repository';

const { Search } = Input;

const CoachSignUpV2Page = () => {
  const emailRegEx =
    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;

  const navigate = useNavigate();
  const data = [
    { id: 0, title: '[필수] 만14세 이상입니다.', code: 'OVER_14_AGREEMENT' },
    { id: 1, title: '[필수] 배프 서비스 이용약관', code: 'TERM_OF_SERVICE_AGREEMENT' },
    { id: 2, title: '[필수] 개인정보 처리방침', code: 'PRIVACY_POLICY_AGREEMENT' },
    {
      id: 3,
      title: '[선택] 마케팅 활용 및 앱 푸시 알림 수신',
      code: 'USE_FOR_MARKETING_AGREEMENT',
    },
  ];

  const isBlank = (str: string) => {
    return !str || str.length === 0;
  };

  const birth = useRecoilValue(birthDateState);
  const categories = useRecoilValue(userCategoryState);
  const onFinish = async (values: any) => {
    const securedPassword = bcrypt.hashSync(values.password, bcrypt.genSaltSync(10));
    const selectedIdx = categories?.filter((c) => c?.selected).map((c) => c?.idx);

    if (await validateEmail(values.id)) {
      alert('이미 사용중인 이메일입니다.');
      return;
    }

    if (!emailRegEx.test(values.id)) {
      alert('이메일 형식이 올바르지 않습니다.');
      return;
    }
    if (!birth || isBlank(birth.year) || isBlank(birth.month) || isBlank(birth.day)) {
      alert('생년월일을 입력해주세요.');
      return;
    }
    if (!request?.user?.regionIdx) {
      alert('주소를 입력해주세요.');
      return;
    }
    if (!selectedIdx || selectedIdx?.length === 0) {
      alert('관심사를 선택해주세요.');
      return;
    }
    if (!checkItems[0] || !checkItems[1] || !checkItems[2]) {
      alert('필수 약관에 동의해주세요.');
      return;
    }
    console.log('Received values of form: ', values, categories, selectedIdx, checkItems);
    const newRequest = {
      ...request,
      user: {
        ...request?.user,
        email: values.id,
        passwordValue: securedPassword,
        basicInfo: {
          ...request?.user?.basicInfo,
          name: values.nickname,
          gender: values.gender === '남성' ? 'MALE' : 'FEMALE',
          birth: `${birth?.year}-${birth?.month}-${birth?.day}`,
          phoneNumber: values.phone,
        },
      },
      categoryIdxes: selectedIdx,
      agreements: [
        { type: data[0].code, isAgreed: checkItems[0] },
        { type: data[1].code, isAgreed: checkItems[1] },
        { type: data[2].code, isAgreed: checkItems[2] },
        { type: data[3].code, isAgreed: checkItems[3] },
      ],
    } as SignupRequestState;
    setRequest(newRequest);
    navigate('/coachinfo');
  };

  const [checkItems, setCheckItems] = useRecoilState<boolean[]>(checkItemsState);
  const toggleCheckBox = (i: number, e: CheckboxChangeEvent) => {
    const temp = [...checkItems];
    temp[i] = e.target.checked;
    setCheckItems(temp);
  };
  const toggleCheckBoxAll = (e: CheckboxChangeEvent) => {
    const temp = Array.from({ length: 4 }, () => e.target.checked);
    setCheckItems(temp);
  };
  const isAllChecked = () => {
    if (checkItems.length !== 4) return false;

    for (let i = 0; i < checkItems.length; i++) {
      if (!checkItems[i]) return false;
    }
    return true;
  };

  const [form] = Form.useForm();
  const open = useDaumPostcodePopup(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  );
  const searchAddress = () => {
    open({
      onComplete: handleComplete,
      popupTitle: '주소 검색',
      popupKey: 'popupKey',
      showMoreHName: true,
    });
  };
  const [request, setRequest] = useRecoilState(signUpRequestState);
  const handleComplete = (data: any) => {
    findRegionByCode(data?.bcode).then((res) => {
      const regionIdx = res?.idx;
      if (regionIdx) {
        const newRequest = {
          ...request,
          user: {
            ...request?.user,
            regionIdx: regionIdx,
            basicInfo: {
              ...request?.user?.basicInfo,
              address: data?.roadAddress,
            },
          },
        } as SignupRequestState;
        setRequest(newRequest);
      } else {
        alert('해당 지역은 가입이 불가능합니다.');
      }
    });
  };

  return (
    <OutContainer>
      <Container>
        <MainTitle>배코치 회원가입</MainTitle>
        {/*<Content>*/}
        {/*    <Title>본인인증</Title>*/}
        {/*    <Description>본인 명의의 휴대폰으로 본인인증을 진행 합니다.</Description>*/}
        {/*    <ConfigProvider*/}
        {/*        theme={{*/}
        {/*            token:{*/}
        {/*                colorPrimary: Color.primary500,*/}
        {/*                borderRadius: 8,*/}
        {/*                colorText: 'white',*/}
        {/*                colorBgContainer: Color.primary500,*/}
        {/*                controlHeight: 42,*/}
        {/*                fontSize: 16*/}
        {/*            }*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <Button onClick={showModal}>휴대폰 본인 인증</Button>*/}
        {/*    </ConfigProvider>*/}
        {/*</Content>*/}
        <ConfigProvider
          theme={{
            token: {
              marginLG: 48,
            },
          }}
        >
          <Divider style={{ borderColor: Color.gray200 }} />
        </ConfigProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
            },
            components: {
              Form: {
                itemMarginBottom: 0,
              },
            },
          }}
        >
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
            scrollToFirstError
          >
            <Content>
              <Title>아이디</Title>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    fontSize: 16,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    colorText: Color.gray800,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Input: {
                      paddingInline: 12,
                      paddingBlock: 12,
                    },
                  },
                }}
              >
                <Form.Item
                  name="id"
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: '',
                      type: 'email',
                    },
                  ]}
                >
                  <Input placeholder="아이디를 입력해주세요" />
                </Form.Item>
              </ConfigProvider>
            </Content>

            <Content>
              <Title>
                비밀번호
                <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '10px' }}>
                  영문, 숫자, 특수문자를 포함한 8자리 이상 15자리 이하
                </span>
              </Title>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    fontSize: 16,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    colorText: Color.gray800,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Input: {
                      paddingInline: 12,
                      paddingBlock: 12,
                    },
                  },
                }}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      min: 8,
                      message: '',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (validatePassword(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject();
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="비밀번호를 입력해주세요" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('비밀번호가 일치하지 않습니다'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="비밀번호를 다시 한 번 입력해주세요" />
                </Form.Item>
              </ConfigProvider>
            </Content>

            <Content>
              <Title>생년월일</Title>
              <BirthDropdown />
            </Content>

            <Content>
              <Title>성함</Title>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    fontSize: 16,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    colorText: Color.gray800,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Input: {
                      paddingInline: 12,
                      paddingBlock: 12,
                    },
                  },
                }}
              >
                <Form.Item
                  name="nickname"
                  rules={[{ required: true, message: '', whitespace: true }]}
                >
                  <Input placeholder="이름을 입력해 주세요" />
                </Form.Item>
              </ConfigProvider>
            </Content>

            <Content>
              <Title>번호</Title>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    fontSize: 16,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    colorText: Color.gray800,
                    colorPrimary: Color.primary500,
                  },
                  components: {
                    Input: {
                      paddingInline: 12,
                      paddingBlock: 12,
                    },
                  },
                }}
              >
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: '' },
                    {
                      pattern: /^(010)-?\d{4}-?\d{4}$/,
                      message: '올바른 휴대폰 번호를 입력해 주세요',
                    },
                  ]}
                >
                  <Input placeholder="휴대폰 번호를 입력해 주세요" />
                </Form.Item>
              </ConfigProvider>
            </Content>

            <Content>
              <Title>성별</Title>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    fontSize: 16,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    colorText: Color.gray800,
                    colorPrimary: Color.primary500,
                    controlHeight: 48,
                  },
                }}
              >
                <Form.Item name="gender" rules={[{ required: true, message: '' }]}>
                  <Select
                    options={[
                      { value: '남성', label: '남성' },
                      { value: '여성', label: '여성' },
                    ]}
                    placeholder="성별을 선택해주세요"
                  />
                </Form.Item>
              </ConfigProvider>
            </Content>

            <Content>
              <Title>주소</Title>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    fontSize: 16,
                    colorBorder: Color.gray500,
                    colorTextPlaceholder: Color.gray500,
                    colorText: Color.gray800,
                    colorPrimary: Color.primary500,
                    controlHeight: 48,
                  },
                }}
              >
                <Form.Item name="address" rules={[{ required: false, message: '' }]}>
                  <Search
                    placeholder={request?.user?.basicInfo?.address ?? '주소를 검색해 보세요.'}
                    value={request?.user?.basicInfo?.address}
                    readOnly
                    onClick={searchAddress}
                    onSearch={searchAddress}
                  />
                </Form.Item>
              </ConfigProvider>
            </Content>

            <Content>
              <Title>관심사 (최대 3개)</Title>
              <Chip init={true} />
            </Content>

            <Content>
              <Title>이용약관동의</Title>
              <Checkbox
                indeterminate={isAllChecked()}
                onChange={toggleCheckBoxAll}
                checked={isAllChecked()}
              >
                전체 동의
              </Checkbox>
              {data.map((data, i) => (
                <Checkbox onChange={(e) => toggleCheckBox(i, e)} checked={checkItems[i]}>
                  {data.title}
                </Checkbox>
              ))}
            </Content>

            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 8,
                  colorBgContainer: Color.primary500,
                  colorText: 'white',
                  fontSize: 16,
                  controlHeight: 44,
                },
              }}
            >
              <Form.Item>
                <Button htmlType="submit" style={{ width: '100%' }}>
                  다음
                </Button>
              </Form.Item>
            </ConfigProvider>
          </Form>
        </ConfigProvider>
      </Container>
    </OutContainer>
  );
};

export default CoachSignUpV2Page;

const OutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px 0;

  @media screen and (max-width: 767px) {
    padding: 48px 16px;
    width: calc(100% - 32px);
  }
`;
const Container = styled.div`
  width: min(400px, 100%);
  display: flex;
  flex-direction: column;
`;
const MainTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: ${Color.gray800};
  margin-bottom: 32px;
  align-self: center;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.gray800};
`;
const Description = styled.div`
  font-size: 16px;
  color: ${Color.gray800};
  font-weight: 500;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
