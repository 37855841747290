import { Course, Region } from '../domain/course';
import silverClient from '../../_common/client/silver-client';
import { PageResponse, SingleResponse } from '../../_common/domain/response';
import qs from 'qs';
import { Category } from '../../coachsignin/domain/category';

export interface FindCoursePageOptions {
  page?: number;
  type?: string;
  categoryIdxes?: number[];
  size?: number;
  regionIdx?: number;
  sort?: string;
  priceFilter?: string;
}

export interface SearchCoursePageOptions {
  page?: number;
  type?: string;
  size?: number;
  keyword?: string;
}

export const findCoursePage = async (
  options: FindCoursePageOptions,
): Promise<PageResponse<Course> | null> => {
  const params = qs.stringify({
    page: options.page,
    type: options.type,
    size: options.size,
    regionIdx: options.regionIdx,
    priceFilter: options.priceFilter,
    sort: options.sort,
  });
  const categories = options.categoryIdxes ?? [];
  const categoryParams = qs.stringify({ categoryIdxes: categories }, { arrayFormat: 'repeat' });

  let path = '';
  if (categories.length === 0) path = params;
  else path = params + '&' + categoryParams;

  return silverClient.get(`/v1/lectures?${path}`).then(
    (response) => {
      const pageResponse = response as PageResponse<Course>;
      return pageResponse;
    },
    () => {
      console.log('course findCoursePage error');
      return null;
    },
  );
};

export const findSingleCourse = async (lectureIdx: number): Promise<Course | null> => {
  try {
    const response = await silverClient.get('/v1/lectures/' + lectureIdx);
    const SingleResponse = response as SingleResponse<Course>;
    return SingleResponse?.content;
  } catch (error) {
    console.log('course findSingleCourse error');
    return null;
  }
};

export const findCoachCoursePage = async (
  page: number,
  size: number,
): Promise<PageResponse<Course> | null> => {
  const params = qs.stringify({ page: page, size: size });
  return silverClient.get('/v1/lectures/teacher?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<Course>;
      return pageResponse;
    },
    () => {
      console.log('course findCoursePage error');
      return null;
    },
  );
};

export const findCategory = async (): Promise<Array<Category> | null> => {
  return silverClient.get('/v1/categories').then(
    (response) => {
      const pageResponse = response as PageResponse<Category>;
      return pageResponse?.contents;
    },
    () => {
      console.log('course findCoursePage error');
      return null;
    },
  );
};

export const findRegion = async (): Promise<Array<Region> | null> => {
  return silverClient.get('/v1/regions/depth1').then(
    (response) => {
      const pageResponse = response as PageResponse<Region>;
      return pageResponse?.contents;
    },
    () => {
      console.log('course findRegion error');
      return null;
    },
  );
};

export const searchAdminCoursePage = async (
  options: SearchCoursePageOptions,
): Promise<PageResponse<Course> | null> => {
  const params = qs.stringify({
    page: options.page,
    type: options.type,
    size: options.size,
    keyword: options.keyword,
  });

  return silverClient.get(`/v1/admin/lectures/search?${params}`).then(
    (response) => {
      const pageResponse = response as PageResponse<Course>;
      return pageResponse;
    },
    () => {
      console.log('course searchAdminCoursePage error');
      return null;
    },
  );
};

export const deleteCourseByAdmin = async (lectureIdx: number): Promise<boolean> => {
  return silverClient.delete('/v1/admin/lectures/' + lectureIdx).then(
    () => {
      return true;
    },
    () => {
      console.log('course deleteCourseByAdmin error');
      return false;
    },
  );
};
