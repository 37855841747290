import React from 'react';
import img from '../img/parking.jpeg';
import '../style/parking.css';
import Color from '../style/Color';

const Parking = () => {
  return (
    <div className="image-container">
      <img className="img" src={img} />
      <div className={'text'}>
        <p style={{ fontWeight: '700', fontSize: '25px' }}>NOTICE</p>
        <p style={{ fontWeight: '700', fontSize: '45px' }}>
          홈페이지 오픈 <span style={{ color: Color.primary500 }}>준비중</span> 입니다
        </p>
        <br />
        <p style={{ fontWeight: '500', fontSize: '21px' }}>현재 서비스 최종 작업을 진행중입니다.</p>
        <p style={{ fontWeight: '500', fontSize: '21px' }}>홈페이지는 12월 중 오픈할 예정입니다.</p>
        <p style={{ fontWeight: '500', fontSize: '21px' }}>조금만 더 기다려주세요.</p>
        <p style={{ fontWeight: '500', fontSize: '21px' }}>감사합니다.</p>
      </div>
    </div>
  );
};

export default Parking;
