import silverClient from '../../_common/client/silver-client';
import { Course } from '../domain/course';
import { PageResponse, SingleResponse } from '../../_common/domain/response';
import qs from 'qs';
import { CourseRequest } from '../domain/course-request';

export const findCourse = async (lectureIdx: number): Promise<Course | null> => {
  return silverClient.get('/v1/lectures/' + lectureIdx).then(
    (response) => {
      const singleResponse = response as SingleResponse<Course>;
      return singleResponse?.content;
    },
    () => {
      console.log('course findOne error');
      return null;
    },
  );
};

export const findCoursePage = async (page: number, size: number): Promise<Array<Course> | null> => {
  const params = qs.stringify({ page: page, size: size });
  return silverClient.get('/v1/lectures?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<Course>;
      return pageResponse?.contents;
    },
    () => {
      console.log('course findCoursePage error');
      return null;
    },
  );
};

export const createCourse = async (request: CourseRequest): Promise<Course | null> => {
  return silverClient.post('/v1/lectures', JSON.stringify(request)).then(
    (response) => {
      const singleResponse = response as SingleResponse<Course>;
      return singleResponse?.content;
    },
    () => {
      alert('강좌 생성에 실패하였습니다.');
      return null;
    },
  );
};

export const updateCourse = async (lectureIdx: number, request: CourseRequest): Promise<any> => {
  return silverClient.put('/v1/lectures/' + lectureIdx, JSON.stringify(request));
};
