const Color = {
  primary: '#E6F6F4',
  primary100: '#B0E4DD',
  primary200: '#8AD7CC',
  primary300: '#54C5B5',
  primary400: '#33BAA7',
  primary500: '#00A991',
  primary600: '#009A84',
  primary700: '#007867',
  primary800: '#005D50',
  primary900: '#00473D',

  //GRAY SCALE
  gray: '#F2F2F7',
  gray100: '#E5E5EA',
  gray200: '#D1D1D6',
  gray300: '#C7C7CC',
  gray400: '#AEAEB2',
  gray500: '#8E8E93',
  gray600: '#636366',
  gray700: '#48484A',
  gray800: '#3A3A3C',
  gray900: '#2C2C2E',
  gray1000: '#1C1C1E',

  // BlueScale
  blue: '#D2E7F8',
  blue50: '#B5D8F4',
  blue100: '#98C9EF',
  blue200: '#81BFEF',
  blue300: '#6AB6F0',
  blue400: '#469FE2',
  blue500: '#2087D6',
  blue600: '#0073CB',
  blue700: '#0166B3',
  blue800: '#01599D',
  blue900: '#014D88',
  blue1000: '#014274',

  red1000: '#BC1A1A',
};

export default Color;
