import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { communityListState } from '../../state/CommunityState';
import '../style/CommomTable.css';
import { CommentOutlined, LikeOutlined } from '@ant-design/icons';
import { ConfigProvider, Pagination } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Color from '../../../_common/view/style/Color';
import { findPostPage } from '../../repository/community-repository';
import { Post } from '../../domain/community';

const Board = (props: any) => {
  const navigate = useNavigate();

  const navigateToBoard = (idx: any) => {
    navigate('/community/' + idx, { state: { ...post } });
  };

  const isPcTablet = useMediaQuery({
    query: '(min-width:768px)',
  });

  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const [post, setPost] = useRecoilState(communityListState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getCommunityPages = async () => {
    const communityPages = await findPostPage(false, pageNumber - 1, props.activeIdx, 10);

    setPost(communityPages?.contents as Array<Post>);
    setTotalElements(communityPages?.totalElements as number);
  };

  useEffect(() => {
    getCommunityPages();
  }, [pageNumber, props.activeIdx]);

  useEffect(() => {
    setPageNumber(1);
  }, [props.activeIdx]);

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  return (
    <>
      {isPcTablet && (
        <div style={{ width: '100%' }}>
          <table
            className="common-table"
            style={{
              borderCollapse: 'separate',
              borderSpacing: '0 10px',
              width: '100%',
              borderTop: `1px solid ${Color.gray800}`,
            }}
          >
            <thead>
              <tr>
                <td className="common-table-header-column">제목</td>
                <td className="common-table-header-column">작성자</td>
                <td className="common-table-header-column">작성일시</td>
                <td className="common-table-header-column">댓글</td>
                <td className="common-table-header-column">좋아요</td>
              </tr>
            </thead>
            <tbody>
              {post?.map((item, index) => (
                <tr
                  className="common-table-row"
                  style={{ marginBottom: '5%' }}
                  onClick={(e) => {
                    navigateToBoard(item.idx);
                  }}
                  key={index}
                >
                  <td className="common-table-column">{item.title}</td>
                  <td className="common-table-column">{item.writer.name}</td>
                  <td className="common-table-column">{convertDateFormat(item.createdAt)}</td>
                  <td className="common-table-column">
                    <CommentOutlined /> {item.commentCount}
                  </td>
                  <td className="common-table-column">
                    {' '}
                    <LikeOutlined /> {item.likeCount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className={'page'}
            style={{ textAlign: 'center', bottom: '0', display: 'fixed', marginTop: '5%' }}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: Color.primary500,
                },
              }}
            >
              <Pagination
                defaultCurrent={1}
                pageSize={10}
                onChange={setPageNumber}
                total={totalElements}
                showSizeChanger={false}
                current={pageNumber}
              />
            </ConfigProvider>
          </div>
        </div>
      )}
      {isMobile && (
        <div style={{ width: '100%' }}>
          <hr />
          {post?.map((item, index) => (
            <div
              onClick={(e) => {
                navigateToBoard(item.idx);
              }}
              style={{
                marginBottom: '10px',
                cursor: 'pointer',
                borderBottom: `1px solid ${Color.gray200}`,
                marginLeft: '20px',
                marginRight: '20px',
                textAlign: 'left',
              }}
            >
              <p>{item.title}</p>
              <p>
                {item.writer.name} <span>{convertDateFormat(item.createdAt)}</span>
              </p>
            </div>
          ))}
          <div
            className={'page'}
            style={{ textAlign: 'center', bottom: '0', display: 'fixed', marginTop: '5%' }}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: Color.primary500,
                },
              }}
            >
              <Pagination
                defaultCurrent={1}
                pageSize={10}
                onChange={setPageNumber}
                total={totalElements}
                showSizeChanger={false}
                current={pageNumber}
              />
            </ConfigProvider>
          </div>
        </div>
      )}
    </>
  );
};

export default Board;
