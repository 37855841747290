import React, { useEffect, useState } from 'react';
import {
  CloseOutlined,
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Flex, Input, Layout, Pagination, theme } from 'antd';
import Styled, { styled } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Color from '../../../../_common/view/style/Color';
import SideBar from '../SideBar';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SettleCoachState, SettleInfoState } from '../../../state/CoachState';
import { findCoachSettle, findSettleInfo } from '../../../repository/coach-repository';
import { Settle, SettleInfo } from '../../../domain/coach';
import CoachSettleInfoModal from './CoachSettleInfoModal';

const { Content, Sider } = Layout;

const CoachPayment: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [SettleData, setSettleData] = useRecoilState(SettleCoachState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [settleInfo, setSettleInfo] = useState<SettleInfo | null>();

  const getSettlePages = async () => {
    const settlePages = await findCoachSettle(pageNumber - 1, 12);
    setSettleData(settlePages?.contents as Array<Settle>);
    setTotalElements(settlePages?.totalElements as number);
  };

  useEffect(() => {
    getSettlePages();
    findSettleInfo().then(setSettleInfo);
  }, []);

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  return (
    <>
      {isPc && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <Sider style={{ backgroundColor: 'white' }} width={150}>
                  <SideBar />
                </Sider>
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>정산 관리</span>
                    <CoachSettleInfoModal />
                    {/*<Button onClick={getSettlePages} style={HeaderButton}>정산 신청</Button>*/}
                  </div>
                  <div>
                    <div
                      style={{
                        border: `1px solid ${Color.gray200}`,
                        padding: '4px 16px',
                        marginTop: '10px',
                      }}
                    >
                      <table
                        className="common-table"
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '0 10px',
                          width: '100%',
                          marginBottom: '30px',
                        }}
                      >
                        <thead>
                          <tr>
                            <td className="common-table-header-column">배움 명</td>
                            <td className="common-table-header-column">정산금액</td>
                            <td className="common-table-header-column">플랫폼수수료</td>
                            <td className="common-table-header-column">상태</td>
                          </tr>
                        </thead>
                        <tbody>
                          {SettleData ? (
                            SettleData?.map((item, index: number) => (
                              <tr
                                className="common-table-row"
                                style={{
                                  marginBottom: '5%',
                                  borderBottom: `1px solid ${Color.gray200}`,
                                }}
                                key={item.idx}
                              >
                                <td className="common-table-column">{item.lecture}</td>
                                <td className="common-table-column">{item.settleAmount}</td>
                                <td className="common-table-column">
                                  {item.commissionAmount}({item.commissionRate * 100}%)
                                </td>
                                <td
                                  className={'common-table-column'}
                                  style={{
                                    color: Color.primary500,
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {chipfun(item.status)}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>Loading...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div
                        className={'page'}
                        style={{
                          textAlign: 'center',
                          bottom: '0',
                          display: 'fixed',
                          marginBottom: '10px',
                        }}
                      >
                        <Pagination
                          defaultCurrent={1}
                          onChange={setPageNumber}
                          pageSize={12}
                          total={totalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isTablet && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>정산 관리</span>
                  </div>
                  <div>
                    <div
                      style={{
                        border: `1px solid ${Color.gray200}`,
                        padding: '4px 16px',
                        marginTop: '10px',
                      }}
                    >
                      <table
                        className="common-table"
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '0 10px',
                          width: '100%',
                          marginBottom: '30px',
                        }}
                      >
                        <thead>
                          <tr>
                            <td className="common-table-header-column">배움 명</td>
                            <td className="common-table-header-column">정산금액</td>
                            <td className="common-table-header-column">플랫폼수수료</td>
                            <td className="common-table-header-column">상태</td>
                          </tr>
                        </thead>
                        <tbody>
                          {SettleData ? (
                            SettleData.map((item, index) => (
                              <tr
                                className="common-table-row"
                                style={{
                                  marginBottom: '5%',
                                  borderBottom: `1px solid ${Color.gray200}`,
                                }}
                                key={index}
                              >
                                <td className="common-table-column">{item.lecture}</td>
                                <td className="common-table-column">{item.settleAmount}</td>
                                <td className="common-table-column">
                                  {item.commissionAmount}({item.commissionRate * 100}%)
                                </td>
                                <td
                                  className={'common-table-column'}
                                  style={{
                                    color: Color.primary500,
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {chipfun(item.status)}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>Loading...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div
                        className={'page'}
                        style={{
                          textAlign: 'center',
                          bottom: '0',
                          display: 'fixed',
                          marginBottom: '10px',
                        }}
                      >
                        <Pagination
                          defaultCurrent={1}
                          onChange={setPageNumber}
                          pageSize={12}
                          total={totalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isMobile && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ fontWeight: '700', fontSize: '24px', marginBottom: '10px' }}>
                      정산 관리
                    </span>
                  </div>
                  <div>
                    <div>
                      {SettleData ? (
                        SettleData.map((item: any, index: number) => (
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  flexDirection: 'row',
                                  width: '100%',
                                }}
                              >
                                <div style={{ color: Color.gray600, fontWeight: '700' }}>
                                  <p>배움명</p>
                                  <p>정산 금액</p>
                                  <p>플랫폼수수료</p>
                                  <p>상태</p>
                                </div>
                                <div style={{ color: Color.gray600, fontWeight: '500' }}>
                                  <p>{item.lecture}</p>
                                  <p>{item.settleAmount}</p>
                                  <p>
                                    {item.commissionAmount}({item.commissionRate * 100}%)
                                  </p>
                                  <p>{chipfun(item.status)}</p>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))
                      ) : (
                        <div>수강생이 없습니다.</div>
                      )}
                    </div>
                  </div>
                  <div className={'page'} style={{ textAlign: 'center', bottom: '0' }}>
                    <Pagination
                      defaultCurrent={1}
                      onChange={setPageNumber}
                      pageSize={12}
                      total={totalElements}
                      showSizeChanger={false}
                    />
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
    </>
  );
};

export default CoachPayment;

const HeaderButton: React.CSSProperties = {
  height: '40px',
  backgroundColor: Color.primary500,
  color: 'white',
  borderRadius: '8px',
  fontSize: '16px',
  marginBottom: '10px',
};

const ing = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.primary500,
      borderRadius: '16px',
      padding: '6px 16px',
      width: 'auto',
      backgroundColor: Color.primary,
    }}
  >
    정산 예정
  </div>
);

const cancel = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '6px 16px',
      width: 'auto',
      backgroundColor: 'red',
    }}
  >
    정산 취소
  </div>
);

const gather = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.blue500,
      borderRadius: '16px',
      padding: '6px 16px',
      width: 'auto',
      backgroundColor: Color.blue,
    }}
  >
    정산 완료
  </div>
);
const chipfun = (props: any) => {
  switch (props) {
    case 'READY':
      return ing;
    case 'CANCEL':
      return cancel;
    case 'SUCCESS':
      return gather;
    default:
      return <h4>엥?.?</h4>;
  }
};

const data = [
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산완료',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산완료',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산예정',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산완료',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산취소',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산취소',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산취소',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산취소',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산취소',
  },
  {
    title: '기초 배우기',
    name: '김코치',
    kind: '원데이 클래스',
    date: '2021.08.30',
    total: '800',
    apply: '100',
    state: '정산취소',
  },
];
