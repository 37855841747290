import { Program, STATUS } from '../domain/program';
import silverClient from '../../_common/client/silver-client';
import { PageResponse, SingleResponse } from '../../_common/domain/response';
import qs from 'qs';
import {
  Adminprogram,
  MEETING_TYPE,
  OperatingPeriod,
  RecuritmentPeriod,
} from '../../admin/domain/admin-contents';

export interface SearchProgramPageOptions {
  page?: number;
  size?: number;
  keyword?: string;
}

export interface AdminProgramUpdateRequest {
  title?: string;
  thumbnailUrl?: string;
  maxCapacity?: number;
  meetingType?: MEETING_TYPE;
  chatUrl?: string;
  regionIdx?: number;
  addressDetail?: string;
  operatingDayOfWeekInfo?: string;
  operatingTimeInfo?: string;
  operatingPeriod?: OperatingPeriod;
  recruitmentPeriod?: RecuritmentPeriod;
  contents?: string;
  price?: number;
  organization?: string;
  categoryIdxes?: number[];
  status?: STATUS;
}

export const findProgramPage = async (
  page: number,
  size: number,
  regionIdx: number | undefined,
  categoryIdxes: Array<number>,
  priceFilter: string,
  sort: string,
): Promise<PageResponse<Program> | null> => {
  const params = qs.stringify({
    page: page,
    size: size,
    regionIdx: regionIdx,
    priceFilter: priceFilter,
    sort: sort,
  });
  const categoryParams = qs.stringify({ categoryIdxes }, { arrayFormat: 'repeat' });

  let path = '';
  if (categoryIdxes.length === 0) path = params;
  else path = params + '&' + categoryParams;

  return silverClient.get('/v1/programs?' + path).then(
    (response) => {
      const pageResponse = response as PageResponse<Program>;
      return pageResponse;
    },
    () => {
      console.log('course findProgramPage error');
      return null;
    },
  );
};

export const findSingleProgram = async (lectureIdx: number): Promise<Program | null> => {
  return silverClient.get('/v1/programs/' + lectureIdx).then(
    (response) => {
      const SingleResponse = response as SingleResponse<Program>;
      return SingleResponse?.content;
    },
    () => {
      console.log('course findSingleProgram error');
      return null;
    },
  );
};

export const searchAdminProgramPage = async (
  options: SearchProgramPageOptions,
): Promise<PageResponse<Program> | null> => {
  const params = qs.stringify({ page: options.page, size: options.size, keyword: options.keyword });

  return silverClient.get(`/v1/admin/programs/search?${params}`).then(
    (response) => {
      const pageResponse = response as PageResponse<Program>;
      return pageResponse;
    },
    () => {
      console.log('course searchAdminProgramPage error');
      return null;
    },
  );
};

export const updateAdminProgram = async (
  programIdx: number,
  request: AdminProgramUpdateRequest,
): Promise<boolean> => {
  return silverClient.put(`/v1/admin/programs/${programIdx}`, JSON.stringify(request)).then(
    () => {
      return true;
    },
    () => {
      console.log('updateAdminProgram error');
      return false;
    },
  );
};
