import React, { useState } from 'react';
import AdminLayout from '../AdminLayout';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  Modal,
  Pagination,
  Select,
  Space,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Color from '../../../../_common/view/style/Color';
import { SearchOutlined, SmallDashOutlined } from '@ant-design/icons';
import { SearchProps } from 'antd/es/input';

const { Search } = Input;
const AdminComment = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [visible, setVisible] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal2 = () => {
    setIsModal2Open(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const isVisible = () => {
    setVisible(!visible);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const items = [
    {
      key: '1',
      label: (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                textHoverBg: 'transparent',
              },
            },
          }}
        >
          <Button type="text" onClick={isVisible}>
            {visible ? '비공개하기' : '공개하기'}
          </Button>
        </ConfigProvider>
      ),
    },
    {
      key: '2',
      label: (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                textHoverBg: 'transparent',
              },
            },
          }}
        >
          <Button type="text" onClick={showModal}>
            삭제하기
          </Button>
        </ConfigProvider>
      ),
    },
  ];

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    {
      // navigate('/search', {state: {value}});
      console.log(value);
    }
  };

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '3%',
                }}
              >
                강좌 관리
              </div>
              <div style={{ display: 'flex', marginBottom: '3%' }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: Color.primary500,
                      controlHeight: 48,
                      colorBorder: Color.gray500,
                      colorTextPlaceholder: Color.gray500,
                      fontSize: 16,
                      colorBorderBg: Color.gray800,
                    },
                  }}
                >
                  <Search
                    placeholder="검색어를 입력하세요."
                    onSearch={onSearch}
                    style={{
                      width: '70%',
                      borderRadius: '8px',
                    }}
                  />
                </ConfigProvider>
                <Select
                  defaultValue="카테고리"
                  style={{
                    width: '28%',
                    marginLeft: '2%',
                    height: '48px',
                    borderRadius: '8px',
                    border: `1px solid ${Color.gray500}`,
                  }}
                  onChange={handleChange}
                  options={[
                    { label: '전체', value: 'total' },
                    { label: '자유게시판', value: 'board' },
                    { label: '강좌 개설 요청', value: 'request' },
                    { label: '모임 자랑', value: 'brag' },
                  ]}
                />
              </div>
            </div>
          </Header>
          <Content>
            <div
              style={{
                padding: 16,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              <div>
                <table
                  className="common-table"
                  style={{
                    borderCollapse: 'collapse',
                    borderSpacing: '0 10px',
                    width: '100%',
                    marginBottom: '30px',
                  }}
                >
                  <thead>
                    <tr>
                      <td className="common-table-header-column">카테고리</td>
                      <td className="common-table-header-column">댓글 내용</td>
                      <td className="common-table-header-column">작성자</td>
                      <td className="common-table-header-column">작성일</td>
                      <td className="common-table-header-column"> </td>
                    </tr>
                  </thead>
                  <tbody>
                    {data ? (
                      data.map((item, index) => (
                        <tr
                          className="common-table-row"
                          style={{ marginBottom: '5%', borderBottom: `1px solid ${Color.gray200}` }}
                          key={index}
                        >
                          <td className="common-table-column">{item.category}</td>
                          <td className="common-table-column">{item.title}</td>
                          <td className="common-table-column">{item.name}</td>
                          <td className="common-table-column"> {item.date}</td>
                          <td className="common-table-column">
                            <Dropdown menu={{ items }} trigger={['click']}>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Space>
                                  <SmallDashOutlined />
                                </Space>
                              </a>
                            </Dropdown>

                            <Modal
                              open={isModalOpen}
                              mask={false}
                              closable={false}
                              width={400}
                              footer={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Button
                                    type={'text'}
                                    style={{ color: Color.primary500, fontWeight: '700' }}
                                    onClick={handleCancel}
                                  >
                                    돌아가기
                                  </Button>
                                  <Button
                                    type={'primary'}
                                    style={{
                                      color: 'white',
                                      backgroundColor: Color.primary500,
                                    }}
                                  >
                                    강좌 삭제
                                  </Button>
                                </div>
                              }
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <p style={{ fontWeight: '700' }}>정말로 삭제하나요?</p>
                                <span>탈퇴 처리 후에는 되돌릴 수 없습니다.</span>
                                <span style={{ marginBottom: '5%' }}>
                                  정산,환불 등 남은 이슈가 없는지 꼭 확인해주세요.
                                </span>
                              </div>
                            </Modal>

                            <Modal
                              title="배퍼 정보"
                              mask={false}
                              open={isModal2Open}
                              closable={false}
                              maskClosable={true}
                              onCancel={handleCancel2}
                              cancelText={'닫기'}
                              cancelButtonProps={{
                                style: { color: Color.primary500, backgroundColor: 'white' },
                              }}
                              footer={
                                <Button
                                  onClick={handleCancel2}
                                  style={{ color: Color.primary500, backgroundColor: 'white' }}
                                >
                                  닫기
                                </Button>
                              }
                            >
                              <div style={{ marginBottom: '10px' }}>
                                <table
                                  style={{
                                    borderCollapse: 'collapse',
                                    borderSpacing: '0 10px',
                                    width: '100%',
                                    marginTop: '10px',
                                  }}
                                >
                                  <thead>
                                    <tr style={{ color: Color.primary500, fontWeight: '700' }}>
                                      <td>성함</td>
                                      <td>아이디</td>
                                      <td>생년월일</td>
                                      <td>성별</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr style={{ marginBottom: '5%' }} key={index}>
                                      <td>홍길동</td>
                                      <td>bevaf123</td>
                                      <td>19950830</td>
                                      <td> 남성</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <span style={{ color: Color.primary500, fontWeight: '700' }}>
                                  주소
                                </span>
                                <br />
                                <span>서울특별시 강남구 역삼로 123길 45 명우아파트 6층 649호</span>
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <span style={{ color: Color.primary500, fontWeight: '700' }}>
                                  관심사
                                </span>
                                <br />
                                <span>
                                  컴퓨터/모바일/디지털, 비즈니스/창업/마케팅/홍보,제테크/경제/투자
                                </span>
                              </div>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div
                  className={'page'}
                  style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                >
                  <Pagination defaultCurrent={1} total={50} />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default AdminComment;

const data = [
  {
    category: '자유게시판',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '강의 개설 요청',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '모임자랑',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '자유게시판',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '강의 개설 요청',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '모임자랑',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '모임자랑',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '자유게시판',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '강의 개설 요청',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
  {
    category: '모임자랑',
    title: '강blablabla',
    name: '홍길동',
    date: '2021.08.30',
    recommand: '10',
    comment: '5',
  },
];
