import { ConfigProvider, Radio, Space } from 'antd';
import * as React from 'react';
import Color from '../../../_common/view/style/Color';
import { useRecoilState } from 'recoil';
import { programPriceState, programSortState } from '../../state/ProgramState';
import styled from 'styled-components';
import Dropdownchip from './Dropdownchip';
import FilterDropDown from './FilterDropDown';

const SideBar = () => {
  const [sortOption, setSortOption] = useRecoilState(programSortState);
  const [priceOption, setPriceOption] = useRecoilState(programPriceState);
  const handleSortChange = (value: any) => {
    setSortOption(value.target.value);
  };

  const handlePriceChange = (value: any) => {
    setPriceOption(value.target.value);
  };

  return (
    <Container>
      <Content>
        <Title>정렬</Title>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              colorBgContainer: '#f6ffed',
              fontSize: 16,
            },
          }}
        >
          <Radio.Group onChange={handleSortChange} value={sortOption}>
            <Space direction="vertical" style={{ gap: 12, fontWeight: 700 }}>
              <Radio value={'RECOMMEND'}>추천순</Radio>
              <Radio value={'CREATED_AT_DESC'}>최신순</Radio>
              <Radio value={'RECRUITMENT_PERIOD_CLOSEST'}>마감 임박순</Radio>
            </Space>
          </Radio.Group>
        </ConfigProvider>
      </Content>

      <Content>
        <Title>배움 지역</Title>
        <FilterDropDown name="지역 선택" wid="100%" />
      </Content>

      <Content>
        <Title>가격</Title>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: Color.primary500,
              colorBgContainer: '#f6ffed',
              fontSize: 16,
            },
          }}
        >
          <Radio.Group onChange={handlePriceChange} value={priceOption}>
            <Space direction="vertical" style={{ gap: 12, fontWeight: 700 }}>
              <Radio value={'ALL'}>전체</Radio>
              <Radio value={'PAID'}>유료</Radio>
              <Radio value={'FREE'}>무료</Radio>
            </Space>
          </Radio.Group>
        </ConfigProvider>
      </Content>

      <Content>
        <Title>관심사</Title>
        <Dropdownchip />
      </Content>
    </Container>
  );
};

export default SideBar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-right: 2%;
`;
const Content = styled.div``;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin-bottom: 12px;
`;
