import React, { useEffect, useRef, useState } from 'react';
import Color from '../../../_common/view/style/Color';
import styled from 'styled-components';
import { StoragePath, upload } from '../../../_common/repository/storage-repository';
import { useRecoilState } from 'recoil';
import { thumbnailUrlState } from '../../state/CourseSignInState';
const baseUrl = 'https://assets.bae-f.com/';
const Thumbnail = () => {
  useEffect(() => {
    setThumbnailUrl(null);
  }, []);

  const [thumbnailUrl, setThumbnailUrl] = useRecoilState(thumbnailUrlState);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onClickUploadBtn = (): void => {
    inputRef.current?.click();
  };
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = e.currentTarget;
    const selectedFile = (target.files as FileList)[0];
    if (selectedFile) {
      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width === 945 && height === 520) {
          setFileName(selectedFile.name);
          // 추가로 필요한 작업 수행
          const path: StoragePath = {
            bucket: 'DOMAIN_IMAGE',
            domain: 'lecture',
            category: 'thumbnail',
          };
          upload(selectedFile, path).then((url) => {
            setThumbnailUrl(url);
          });
        } else {
          alert('파일의 크기가 945px*520px이 아닙니다.');
        }
      };
    }
  };

  const [fileName, setFileName] = useState('945*520 px (png, jpg)');

  return (
    <Container onClick={onClickUploadBtn}>
      <img
        style={
          thumbnailUrl
            ? { width: '100%', height: '100%', objectFit: 'contain' }
            : { width: '24px', height: '24px' }
        }
        src={
          thumbnailUrl
            ? baseUrl + thumbnailUrl
            : require('../../../_common/view/img/attachment.png')
        }
      />
      <Description fileName={fileName}>{fileName}</Description>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept=".png, .jpg"
        onChange={onSelectFile}
      />
    </Container>
  );
};

export default Thumbnail;

const Container = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${Color.gray800};
  padding: 28px 12px;
  background-color: white;
`;
const Description = styled.div<{ fileName: string }>`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.fileName === '945*520 px (png, jpg)' ? Color.gray500 : Color.gray800)};
`;
