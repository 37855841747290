import { Bank, Careers, Experience, Settle, SettleInfo, Student } from '../domain/coach';
import silverClient from '../../_common/client/silver-client';
import { ListResponse, PageResponse, SingleResponse } from '../../_common/domain/response';
import qs from 'qs';
import { CoachSignUpRequest } from '../domain/coach-request';

// export const coachSignUp = async (url:string,postData: object): Promise<CoachSignUp | null> => {
//     return silverClient.post(url,postData).then((response) => {
//         return response as CoachSignUp
//     }, () => {
//         console.log('findTodos error')
//         return null
//     })
// }

export const findCareer = async (teacherIdx: number): Promise<Careers[] | null> => {
  return silverClient.get('/v1/teachers/' + teacherIdx + '/careers').then(
    (response) => {
      const CareerResponse = response as ListResponse<Careers>;
      return CareerResponse?.contents;
    },
    () => {
      console.log('Careers error');
      return null;
    },
  );
};

export const findHistory = async (teacherIdx: number): Promise<Experience[] | null> => {
  return silverClient.get('/v1/teachers/' + teacherIdx + '/education-histories').then(
    (response) => {
      const ExperienceResponse = response as ListResponse<Experience>;
      return ExperienceResponse?.contents;
    },
    () => {
      console.log('Settle error');
      return null;
    },
  );
};

export const findSettleInfo = async (): Promise<SettleInfo | null> => {
  return silverClient.get('/v1/teachers/settle-info').then(
    (response) => {
      const SettleResponse = response as SingleResponse<SettleInfo>;
      return SettleResponse?.content;
    },
    () => {
      console.log('Settle error');
      return null;
    },
  );
};

export const findBanks = async (): Promise<Bank[] | null> => {
  return silverClient.get('/v1/banks').then(
    (response) => {
      const BankResponse = response as ListResponse<Bank>;
      return BankResponse?.contents;
    },
    () => {
      console.log('Settle error');
      return null;
    },
  );
};

export const createCoach = async (request: CoachSignUpRequest): Promise<any> => {
  return silverClient.post('/v1/teachers/without-imp', JSON.stringify(request));
};

export const findCourseStudent = async (
  lectureIdx: number,
  page: number,
  size: number,
): Promise<PageResponse<Student> | null> => {
  const params = qs.stringify({ page: page, size: size });
  return silverClient.get('/v1/lectures/' + lectureIdx + '/learners?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<Student>;
      console.log('course findCourseStudent success', pageResponse);
      return pageResponse;
    },
    () => {
      console.log('course findCourseStudent error');
      return null;
    },
  );
};

export const deleteCourse = async (lectureIdx: number): Promise<string | null> => {
  return silverClient.delete('/v1/lectures/' + lectureIdx).then(
    (response) => {
      const singleResponse = response as SingleResponse<string>;
      return singleResponse?.message;
    },
    () => {
      alert('강좌 생성에 실패하였습니다.');
      return null;
    },
  );
};

export const findCoachSettle = async (
  page: number,
  size: number,
): Promise<PageResponse<Settle> | null> => {
  const params = qs.stringify({ page: page, size: size });
  return silverClient.get('/v1/settles/teacher?' + params).then(
    (response) => {
      const SettleResponse = response as PageResponse<Settle>;
      return SettleResponse;
    },
    () => {
      console.log('Settle error');
      return null;
    },
  );
};

export const updateTeacher = async (career: string, educationHistory: string): Promise<any> => {
  return silverClient.put(
    '/v1/teachers',
    JSON.stringify({ career: career, educationHistory: educationHistory }),
  );
};
