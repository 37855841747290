import React, { useEffect, useState } from 'react';
import { LeftOutlined, SmallDashOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Dropdown, Layout, Modal, Pagination, Space, theme } from 'antd';
import Styled, { styled } from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Color from '../../../../_common/view/style/Color';
import SideBar from '../SideBar';
import { findCourseStudent } from '../../../repository/coach-repository';
import { useRecoilState } from 'recoil';
import { StudentState } from '../../../state/CoachState';
import { Student } from '../../../domain/coach';

const { Content, Sider } = Layout;

const StudentDetail: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();
  const onClickBtn = () => {
    navigate(-1);
  };

  const title = location.state;
  const idx = id;

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [student, setStudent] = useRecoilState(StudentState);
  const fetchStudent = async () => {
    const students = await findCourseStudent(Number(idx), pageNumber - 1, 12);
    setStudent(students?.contents as Array<Student>);
    setTotalElements(students?.totalElements as number);
  };

  useEffect(() => {
    fetchStudent();
  }, [pageNumber]);

  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal2 = () => {
    setIsModal2Open(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModal2Open(false);
  };

  const items = [
    {
      key: '1',
      label: (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                textHoverBg: 'transparent',
              },
            },
          }}
        >
          <Button style={{ width: '100%', textAlign: 'left' }} type="text" onClick={showModal2}>
            자세히보기
          </Button>
        </ConfigProvider>
      ),
    },
    // {
    //     key: '2',
    //     label: (
    //         <ConfigProvider
    //             theme={{
    //                 components: {
    //                     Button: {
    //                         textHoverBg:"transparent"
    //                     },
    //                 },
    //             }}
    //         >
    //             <Button style={{width:"100%"}} type = "text" onClick={showModal}>
    //                 탈퇴시키기
    //             </Button></ConfigProvider>
    //     ),
    // }
  ];

  return (
    <>
      {isPc && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <Sider style={{ backgroundColor: 'white' }} width={150}>
                  <SideBar />
                </Sider>
                <Content style={{ padding: '0 24px', minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
                    <Button type={'text'} onClick={onClickBtn}>
                      {' '}
                      <LeftOutlined />{' '}
                    </Button>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>{title}</span>
                  </div>
                  <div>
                    <div>
                      <table
                        className="common-table"
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '0 10px',
                          width: '100%',
                          marginBottom: '30px',
                        }}
                      >
                        <thead>
                          <tr>
                            <td className="common-table-header-column">No</td>
                            <td className="common-table-header-column">성함</td>
                            <td className="common-table-header-column">연락처</td>
                            <td className="common-table-header-column">생년월일</td>
                            <td className="common-table-header-column">성별</td>
                            <td className="common-table-header-column"> </td>
                          </tr>
                        </thead>
                        <tbody>
                          {student ? (
                            student.map((item, index) => (
                              <tr
                                className="common-table-row"
                                style={{
                                  marginBottom: '5%',
                                  borderBottom: `1px solid ${Color.gray200}`,
                                }}
                                key={index}
                              >
                                <td className="common-table-column">{item.idx}</td>
                                <td className="common-table-column">{item.name}</td>
                                <td className="common-table-column"> {item.phoneNumber}</td>
                                <td className="common-table-column">{item.birth}</td>
                                <td className="common-table-column">
                                  {item.gender === 'MALE' ? '남성' : '여성'}
                                </td>
                                <td className="common-table-column">
                                  {/*<Button type={"text"}><SmallDashOutlined /></Button>*/}
                                  {/*<Dropdown menu={{ items }} trigger={['click']}>*/}
                                  {/*  <a*/}
                                  {/*    onClick={(e) => {*/}
                                  {/*      e.preventDefault();*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    <Space>*/}
                                  {/*      <SmallDashOutlined />*/}
                                  {/*    </Space>*/}
                                  {/*  </a>*/}
                                  {/*</Dropdown>*/}
                                  <Modal
                                    open={isModalOpen}
                                    mask={false}
                                    closable={false}
                                    width={350}
                                    footer={
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                          type={'text'}
                                          style={{
                                            color: Color.primary500,
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          }}
                                          onClick={handleCancel}
                                        >
                                          돌아 가기
                                        </Button>
                                        {/*<Button type={"primary"} style={{*/}
                                        {/*    color: "white",*/}
                                        {/*    backgroundColor: Color.primary500,*/}
                                        {/*    marginTop:"10px",*/}
                                        {/*    fontWeight:"700",*/}
                                        {/*    marginInlineStart:"0"*/}
                                        {/*}}>탈퇴시키기</Button>*/}
                                      </div>
                                    }
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p style={{ fontWeight: '700' }}>정말로 탈퇴시키나요??</p>
                                      <span>탈퇴 처리 후에는 되돌릴 수 없습니다.</span>
                                      <span>정산,환불 등 남은 이슈가 없는지 꼭 확인해주세요.</span>
                                    </div>
                                  </Modal>
                                  <Modal
                                    title="배퍼 정보"
                                    mask={false}
                                    open={isModal2Open}
                                    closable={false}
                                    maskClosable={true}
                                    onCancel={handleCancel2}
                                    cancelText={'닫기'}
                                    cancelButtonProps={{
                                      style: { color: Color.primary500, backgroundColor: 'white' },
                                    }}
                                    footer={
                                      <Button
                                        onClick={handleCancel2}
                                        style={{
                                          color: Color.primary500,
                                          backgroundColor: 'white',
                                        }}
                                      >
                                        닫기
                                      </Button>
                                    }
                                  >
                                    <div style={{ marginBottom: '10px' }}>
                                      <table
                                        style={{
                                          borderCollapse: 'collapse',
                                          borderSpacing: '0 10px',
                                          width: '100%',
                                          marginTop: '10px',
                                        }}
                                      >
                                        <thead>
                                          <tr
                                            style={{ color: Color.primary500, fontWeight: '700' }}
                                          >
                                            <td>성함</td>
                                            <td>아이디</td>
                                            <td>생년월일</td>
                                            <td>성별</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr style={{ marginBottom: '5%' }} key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.birth}</td>
                                            <td>{item.gender === 'MALE' ? '남성' : '여성'}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <span style={{ color: Color.primary500, fontWeight: '700' }}>
                                        주소
                                      </span>
                                      <br />
                                      <span>
                                        서울특별시 강남구 역삼로 123길 45 명우아파트 6층 649호
                                      </span>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <span style={{ color: Color.primary500, fontWeight: '700' }}>
                                        관심사
                                      </span>
                                      <br />
                                      <span>
                                        컴퓨터/모바일/디지털,
                                        비즈니스/창업/마케팅/홍보,제테크/경제/투자
                                      </span>
                                    </div>
                                  </Modal>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>Loading...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className={'page'}
                      style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                    >
                      <Pagination
                        defaultCurrent={1}
                        onChange={setPageNumber}
                        pageSize={12}
                        total={totalElements}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isTablet && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
                    <Button type={'text'} onClick={onClickBtn}>
                      {' '}
                      <LeftOutlined />{' '}
                    </Button>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>{title}</span>
                  </div>
                  <div>
                    <div>
                      <table
                        className="common-table"
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '0 10px',
                          width: '100%',
                          marginBottom: '30px',
                        }}
                      >
                        <thead>
                          <tr>
                            <td className="common-table-header-column">No</td>
                            <td className="common-table-header-column">성함</td>
                            <td className="common-table-header-column">연락처</td>
                            <td className="common-table-header-column">생년월일</td>
                            <td className="common-table-header-column">성별</td>
                            <td className="common-table-header-column"> </td>
                          </tr>
                        </thead>
                        <tbody>
                          {student ? (
                            student.map((item, index) => (
                              <tr
                                className="common-table-row"
                                style={{
                                  marginBottom: '5%',
                                  borderBottom: `1px solid ${Color.gray200}`,
                                }}
                                key={index}
                              >
                                <td className="common-table-column">{item.idx}</td>
                                <td className="common-table-column">{item.name}</td>
                                <td className="common-table-column"> {item.phoneNumber}</td>
                                <td className="common-table-column">{item.birth}</td>
                                <td className="common-table-column">
                                  {item.gender === 'MALE' ? '남성' : '여성'}
                                </td>
                                <td className="common-table-column">
                                  {/*<Button type={"text"}><SmallDashOutlined /></Button>*/}
                                  {/*<Dropdown menu={{ items }} trigger={['click']}>*/}
                                  {/*  <a*/}
                                  {/*    onClick={(e) => {*/}
                                  {/*      e.preventDefault();*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    <Space>*/}
                                  {/*      <SmallDashOutlined />*/}
                                  {/*    </Space>*/}
                                  {/*  </a>*/}
                                  {/*</Dropdown>*/}
                                  <Modal
                                    open={isModalOpen}
                                    mask={false}
                                    closable={false}
                                    width={350}
                                    footer={
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                          type={'text'}
                                          style={{
                                            color: Color.primary500,
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          }}
                                          onClick={handleCancel}
                                        >
                                          돌아 가기
                                        </Button>
                                        {/*<Button type={"primary"} style={{*/}
                                        {/*    color: "white",*/}
                                        {/*    backgroundColor: Color.primary500,*/}
                                        {/*    marginTop:"10px",*/}
                                        {/*    fontWeight:"700",*/}
                                        {/*    marginInlineStart:"0"*/}
                                        {/*}}>탈퇴시키기</Button>*/}
                                      </div>
                                    }
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p style={{ fontWeight: '700' }}>정말로 탈퇴시키나요??</p>
                                      <span>탈퇴 처리 후에는 되돌릴 수 없습니다.</span>
                                      <span>정산,환불 등 남은 이슈가 없는지 꼭 확인해주세요.</span>
                                    </div>
                                  </Modal>
                                  <Modal
                                    title="배퍼 정보"
                                    mask={false}
                                    open={isModal2Open}
                                    closable={false}
                                    maskClosable={true}
                                    onCancel={handleCancel2}
                                    cancelText={'닫기'}
                                    cancelButtonProps={{
                                      style: { color: Color.primary500, backgroundColor: 'white' },
                                    }}
                                    footer={
                                      <Button
                                        onClick={handleCancel2}
                                        style={{
                                          color: Color.primary500,
                                          backgroundColor: 'white',
                                        }}
                                      >
                                        닫기
                                      </Button>
                                    }
                                  >
                                    <div style={{ marginBottom: '10px' }}>
                                      <table
                                        style={{
                                          borderCollapse: 'collapse',
                                          borderSpacing: '0 10px',
                                          width: '100%',
                                          marginTop: '10px',
                                        }}
                                      >
                                        <thead>
                                          <tr
                                            style={{ color: Color.primary500, fontWeight: '700' }}
                                          >
                                            <td>성함</td>
                                            <td>아이디</td>
                                            <td>생년월일</td>
                                            <td>성별</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr style={{ marginBottom: '5%' }} key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.birth}</td>
                                            <td>{item.gender === 'MALE' ? '남성' : '여성'}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <span style={{ color: Color.primary500, fontWeight: '700' }}>
                                        주소
                                      </span>
                                      <br />
                                      <span>
                                        서울특별시 강남구 역삼로 123길 45 명우아파트 6층 649호
                                      </span>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <span style={{ color: Color.primary500, fontWeight: '700' }}>
                                        관심사
                                      </span>
                                      <br />
                                      <span>
                                        컴퓨터/모바일/디지털,
                                        비즈니스/창업/마케팅/홍보,제테크/경제/투자
                                      </span>
                                    </div>
                                  </Modal>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>Loading...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className={'page'}
                      style={{ textAlign: 'center', bottom: '0', display: 'fixed' }}
                    >
                      <Pagination
                        defaultCurrent={1}
                        onChange={setPageNumber}
                        pageSize={12}
                        total={totalElements}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
      {isMobile && (
        <>
          <Layout>
            <Content style={{ padding: '0 8px', backgroundColor: 'white' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  backgroundColor: 'white',
                  borderRadius: borderRadiusLG,
                }}
              >
                <SideBar />
                <Content style={{ minHeight: 280, marginBottom: '3%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
                    <Button type={'text'} onClick={onClickBtn}>
                      <LeftOutlined />
                    </Button>
                    <span style={{ fontWeight: '700', fontSize: '24px' }}>{title}</span>
                  </div>
                  <div>
                    <div>
                      {student ? (
                        student.slice(0, 5).map((item: any, index: number) => (
                          <div>
                            <div
                              // onClick={() => setIsModal2Open(true)}
                              style={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-evenly',
                                  flexDirection: 'row',
                                  width: '100%',
                                }}
                              >
                                <div style={{ color: Color.gray600, fontWeight: '700' }}>
                                  <p>성함</p>
                                  <p>연락처</p>
                                  <p>생년월일</p>
                                  <p>성별</p>
                                </div>
                                <div style={{ color: Color.gray600, fontWeight: '500' }}>
                                  <p> {item.name}</p>
                                  <p>{item.phoneNumber}</p>
                                  <p>{item.birth}</p>
                                  <p>{item.gender === 'MALE' ? '남성' : '여성'}</p>
                                </div>
                              </div>
                            </div>
                            <hr style={{ background: Color.gray200, height: '1px', border: '0' }} />
                          </div>
                        ))
                      ) : (
                        <div>수강생이 없습니다.</div>
                      )}
                    </div>
                  </div>

                  <div className={'page'} style={{ textAlign: 'center', bottom: '0' }}>
                    <Pagination
                      defaultCurrent={1}
                      onChange={setPageNumber}
                      pageSize={12}
                      total={totalElements}
                      showSizeChanger={false}
                    />
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      )}
    </>
  );
};

export default StudentDetail;

const Infotext = Styled.div`
    font-weight: 700;
    font-size: 14px;
    color: gray;
    margin-bottom: 5px;
`;

const HeaderButton: React.CSSProperties = {
  height: '40px',
  backgroundColor: Color.primary500,
  color: 'white',
  borderRadius: '8px',
  fontSize: '16px',
  marginBottom: '10px',
};

const Spantitle = styled.span`
  color: ${Color.primary500};
`;

const Colorspan = styled.span`
  color: ${Color.gray600};
  font-weight: 500;
`;

const MainButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  fontSize: '16px',
  color: Color.primary500,
  fontWeight: '700',
  border: `2px solid ${Color.primary500}`,
  marginBottom: '10px',
};

const RedButton: React.CSSProperties = {
  padding: ' 6px 24px 30px 24px',
  fontSize: '16px',
  color: 'red',
  fontWeight: '700',
  border: `2px solid red`,
  marginBottom: '10px',
};

const finish = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      width: '60px',
      backgroundColor: Color.gray600,
    }}
  >
    종 료
  </div>
);

const ing = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.primary500,
      borderRadius: '16px',
      padding: '4px 16px',
      width: '70px',
      backgroundColor: Color.primary,
    }}
  >
    진행중
  </div>
);

const cancel = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: 'white',
      borderRadius: '16px',
      padding: '4px 16px',
      width: '60px',
      backgroundColor: 'red',
    }}
  >
    취 소
  </div>
);

const gather = (
  <div
    style={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      color: Color.blue500,
      borderRadius: '16px',
      padding: '4px 16px',
      width: '70px',
      backgroundColor: Color.blue,
    }}
  >
    모집중
  </div>
);
const chipfun = (props: any) => {
  switch (props) {
    case '종료':
      return finish;
    case '진행중':
      return ing;
    case '취소':
      return cancel;
    case '모집중':
      return gather;
    default:
      return <h4>엥?.?</h4>;
  }
};

const dataList = [
  {
    no: '1',
    name: '김배퍼',
    id: 'baefer1',
    createDate: '2021-09-01',
    birth: '19950830',
    phone: '010-1234-5678',
    article: '0',
    comment: '200',
    sex: '남성',
  },
  {
    no: '2',
    name: '김배퍼',
    id: 'baefer1',
    sex: '남성',
    birth: '19950830',
    createDate: '2021-09-01',
    phone: '010-1234-5678',
    article: '100',
    comment: '0',
  },
  {
    no: '3',
    name: '김배퍼',
    id: 'baefer1',
    birth: '19950830',
    sex: '남성',
    createDate: '2021-09-01',
    phone: '010-1234-5678',
    article: '100',
    comment: '200',
  },
  {
    no: '4',
    name: '김배퍼',
    id: 'baefer1',
    birth: '19950830',
    createDate: '2021-09-01',
    phone: '010-1234-5678',
    sex: '남성',
    article: '100',
    comment: '200',
  },
  {
    no: '5',
    name: '김배퍼',
    id: 'baefer1',
    sex: '남성',
    createDate: '2021-09-01',
    phone: '010-1234-5678',
    birth: '19950830',
    article: '100',
    comment: '200',
  },
  {
    no: '6',
    name: '김배퍼',
    id: 'baefer1',
    createDate: '2021-09-01',
    sex: '남성',
    phone: '010-1234-5678',
    birth: '19950830',
    article: '100',
    comment: '200',
  },
  {
    no: '7',
    name: '김배퍼',
    id: 'baefer1',
    createDate: '2021-09-01',
    birth: '19950830',
    sex: '남성',
    phone: '010-1234-5678',
    article: '100',
    comment: '200',
  },
  {
    no: '8',
    name: '김배퍼',
    id: 'baefer1',
    createDate: '2021-09-01',
    birth: '19950830',
    sex: '남성',
    phone: '010-1234-5678',
    article: '100',
    comment: '200',
  },
  {
    no: '9',
    name: '김배퍼',
    id: 'baefer1',
    createDate: '2021-09-01',
    birth: '19950830',
    phone: '010-1234-5678',
    sex: '남성',
    article: '100',
    comment: '200',
  },
  {
    no: '10',
    name: '김배퍼',
    id: 'baefer1',
    createDate: '2021-09-01',
    birth: '19950830',
    phone: '010-1234-5678',
    article: '100',
    sex: '남성',
    comment: '200',
  },
];
