import silverClient from '../../_common/client/silver-client';
import { User } from '../domain/user';
import { ListResponse, SingleResponse } from '../../_common/domain/response';
import { Category } from '../../coachsignin/domain/category';

export const findMy = async (): Promise<User | null> => {
  return silverClient.get('/v1/users/my').then(
    (response) => {
      const singleResponse = response as SingleResponse<User>;
      return singleResponse?.content;
    },
    () => {
      console.log('findMy error');
      return null;
    },
  );
};

export const findMyCategories = async (): Promise<Category[]> => {
  return silverClient.get('/v1/categories/my').then(
    (response) => {
      const listResponse = response as ListResponse<Category>;
      return listResponse?.contents ?? [];
    },
    () => {
      console.log('findMyCategories error');
      return [];
    },
  );
};

export const validateEmail = async (email: string): Promise<boolean> => {
  return silverClient.get('/v1/users/validate-email?email=' + email).then(
    (response) => {
      const singleResponse = response as SingleResponse<boolean>;
      return singleResponse?.content;
    },
    () => {
      console.log('validateEmail error');
      return false;
    },
  );
};
