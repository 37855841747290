import React from 'react';
import Color from '../../../_common/view/style/Color';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ConfigProvider, Input } from 'antd';
import { categoryState, maxCapacityState } from '../../state/CourseSignInState';

const Number = () => {
  const MAX = 10;

  const [categoryOption, setCategoryOption] = useRecoilState(categoryState);
  const [capacity, setCapacity] = useRecoilState<number>(maxCapacityState);
  const handleCapacityChange = (value: any) => {
    if (categoryOption === 1) {
      setCapacity(value.target.value);
    } else {
      if (value.target.value > MAX) {
        setCapacity(MAX);
      } else {
        setCapacity(value.target.value);
      }
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Color.primary500,
          colorBorder: Color.gray500,
          fontSize: 16,
          borderRadius: 8,
          colorTextPlaceholder: Color.gray500,
        },
      }}
    >
      <Input
        type={'number'}
        min={1}
        max={categoryOption === 1 ? '' : '10'}
        placeholder="수업의 최대 인원을 숫자로 입력해주세요."
        style={{ width: '100%', height: 48 }}
        value={capacity}
        status=""
        onInput={handleCapacityChange}
        // prefix={<ExclamationCircleOutlined />}
      />
    </ConfigProvider>
  );
};

export default Number;
