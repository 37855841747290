import React from 'react';
import AdminLayout from '../AdminLayout';
import { ConfigProvider, Input, Layout, Tabs, TabsProps, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Color from '../../../../_common/view/style/Color';
import PaymentControl from './PaymentControl';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  adminCurrentSettleStatusState,
  adminSettleState,
  adminSettleTotalState,
} from '../../../state/AdminState';

const { Search } = Input;
const AdminPayment = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [selectedTab, setSelectedTab] = React.useState('1');
  const [currentStatus, setCurrentStatus] = useRecoilState(adminCurrentSettleStatusState);
  const settle = useRecoilValue(adminSettleState);
  const totalElements = useRecoilValue(adminSettleTotalState);

  const onChange = (key: string) => {
    setSelectedTab(key);
    switch (key) {
      case '1':
        setCurrentStatus(['READY', 'PROCESSING', 'HOLD', 'CARRYOVER']);
        break;
      case '2':
        setCurrentStatus(['CANCEL']);
        break;
      case '3':
        setCurrentStatus(['SUCCESS']);
        break;
      default:
        break;
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `정산 예정 ${selectedTab === '1' && totalElements > 0 ? totalElements ?? '' : ''}`,
      children: <PaymentControl />,
    },
    {
      key: '2',
      label: `정산 취소 ${selectedTab === '2' && totalElements > 0 ? totalElements ?? '' : ''}`,
      children: <PaymentControl />,
    },
    {
      key: '3',
      label: `정산 완료 ${selectedTab === '3' && totalElements > 0 ? totalElements ?? '' : ''}`,
      children: <PaymentControl />,
    },
  ];

  return (
    <div>
      <AdminLayout>
        <Layout>
          <Header style={{ background: 'transparent', padding: '0', height: 'auto' }}>
            <div>
              <div
                style={{
                  lineHeight: '0px',
                  fontSize: '20px',
                  fontWeight: '700',
                  marginBottom: '3%',
                }}
              >
                정산 관리
              </div>
              <div>
                <ConfigProvider
                  theme={{
                    components: {
                      Tabs: {
                        itemActiveColor: Color.primary500,
                        itemHoverColor: Color.primary500,
                        itemSelectedColor: Color.primary500,
                        inkBarColor: Color.primary500,
                        titleFontSize: 16,
                      },
                    },
                  }}
                >
                  <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                </ConfigProvider>
              </div>
            </div>
          </Header>
          <Content></Content>
        </Layout>
      </AdminLayout>
    </div>
  );
};

export default AdminPayment;
