import qs from 'qs';
import silverClient from '../../_common/client/silver-client';
import { PageResponse } from '../../_common/domain/response';
import { AdminReport } from '../domain/admin-report';

export const findReportPage = async (
  page: number,
  size: number,
): Promise<PageResponse<AdminReport> | null> => {
  const params = qs.stringify({ page: page, size: size });
  return silverClient.get('/v1/admin/settles?' + params).then(
    (response) => {
      const pageResponse = response as PageResponse<AdminReport>;
      return pageResponse;
    },
    () => {
      console.log('payment findPaymentPage error');
      return null;
    },
  );
};
