import React, { useEffect, useState } from 'react';
import { Pagination, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Color from '../../../_common/view/style/Color';
import { useRecoilState } from 'recoil';
import SideBar from './SideBar';
import { useMediaQuery } from 'react-responsive';
import { PaymentState } from '../../../payment/state/PaymentState';
import { findPayment } from '../../../payment/repository/payment-repository';
import { Payment } from '../../../payment/domain/payment';

const ManagePayment: React.FC = () => {
  const isPcTablet = useMediaQuery({
    query: '(min-width:768px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width:767px)',
  });

  const [payment, setPayment] = useRecoilState(PaymentState);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const getPaymentPages = async () => {
    const paymentPages = await findPayment(pageNumber - 1, 12);
    setPayment(paymentPages?.contents as Array<Payment>);
    setTotalElements(paymentPages?.totalElements as number);
  };
  const returnStatusText = (status: String) => {
    switch (status) {
      case 'PROCESSING':
        return '결제 진행 중';
      case 'SUCCESS':
        return '결제 완료';
      case 'CANCEL':
        return '취소 완료';
      case 'FAIL':
        return '결제 실패';
    }
  };

  useEffect(() => {
    getPaymentPages();
  }, [pageNumber]);

  const convertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const convertedDate = `${year}.${month}.${day}`;
    return convertedDate;
  };

  return (
    <>
      {isPcTablet && (
        <Container>
          <SideBar activeindex={2} />
          <ContentContainer>
            <MainTitle>결제 관리</MainTitle>
            <GridContainer>
              <GridWrapper style={{ backgroundColor: Color.gray }}>
                <GridLabel key={'noKey'}>결제 번호</GridLabel>
                <GridLabel key={'titleKey'}>배움명</GridLabel>
                <GridLabel key={'timeKey'}>결제 및 환불 요청 시간</GridLabel>
                <GridLabel key={'priceKey'}>금액</GridLabel>
                <GridLabel key={'statusKey'}>상태</GridLabel>
              </GridWrapper>
              {payment?.map((info) => (
                <GridWrapper>
                  <GridValue>{info.idx}</GridValue>
                  <GridValue>{info.lecture.title}</GridValue>
                  <GridValue>{convertDateFormat(info.succeedAt)}</GridValue>
                  <GridValue>{info.totalAmount.toLocaleString()}</GridValue>
                  <GridValue>
                    <Status status={info.status}>{returnStatusText(info.status)}</Status>
                  </GridValue>
                </GridWrapper>
              ))}
            </GridContainer>
            <PaginationWrapper>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                  },
                }}
              >
                <Pagination
                  defaultCurrent={1}
                  onChange={setPageNumber}
                  pageSize={4}
                  total={totalElements}
                  showSizeChanger={false}
                />
              </ConfigProvider>
            </PaginationWrapper>
          </ContentContainer>
        </Container>
      )}
      {isMobile && (
        <Container>
          <SideBar activeindex={2} />
          <ContentContainer>
            {payment?.map((info) => (
              <Content>
                <MobileLabel>결제 번호</MobileLabel>
                <MobileValue>{info.idx}</MobileValue>
                <MobileLabel>배움명</MobileLabel>
                <MobileValue>{info.lecture.title}</MobileValue>
                <MobileLabel>결제 및 환불 요청 시간</MobileLabel>
                <MobileValue>{convertDateFormat(info.canceledAt)}</MobileValue>
                <MobileLabel>금액</MobileLabel>
                <MobileValue>{info.totalAmount.toLocaleString()}</MobileValue>
                <MobileLabel>상태</MobileLabel>
                <MobileValue>
                  <Status status={info.status}>{returnStatusText(info.status)}</Status>
                </MobileValue>
              </Content>
            ))}
            <PaginationWrapper>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Color.primary500,
                  },
                }}
              >
                <Pagination
                  defaultCurrent={1}
                  onChange={setPageNumber}
                  pageSize={10}
                  total={totalElements}
                  showSizeChanger={false}
                />
              </ConfigProvider>
            </PaginationWrapper>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default ManagePayment;

const Container = styled.div`
  padding: 48px 16px;
  display: flex;
  gap: 120px;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
`;
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 767px) {
    gap: 16px;
  }
`;
const MainTitle = styled.div`
  font-weight: 700;
  color: ${Color.gray800};
  font-size: 24px;
  margin-bottom: 8px;

  @media screen and (max-width: 1023px) {
    align-self: center;
  }
`;
const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
`;
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(140px, 200px)
    minmax(200px, 385px)
    minmax(140px, 200px)
    minmax(76px, 100px)
    minmax(100px, 100px);
  border-bottom: 1px solid ${Color.gray100};
`;
const GridLabel = styled.div`
  background-color: ${Color.gray};
  padding: 16px 0;
  color: ${Color.gray800};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const GridValue = styled.div`
  padding: 16px 0;
  font-size: 14px;
  font-weight: 500;
  color: ${Color.gray800};
  white-space: nowrap;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Status = styled.div<{ status: string }>`
  /* 결제완료, 환불완료 - 1, 환불예정 - 2, 결제실패 - 3 */
  border-radius: 16px;
  font-size: 13px;
  padding: 4px 12px;
  display: inline-flex;
  width: fit-content;
  color: ${(props) => {
    if (props.status === 'SUCCESS' || props.status === 'CANCEL') return Color.blue500;
    else if (props.status === 'PROCESSING') return Color.primary500;
    else if (props.status === 'FAIL') return 'white';
  }};
  background-color: ${(props) => {
    if (props.status === 'SUCCESS' || props.status === 'CANCEL') return Color.blue;
    else if (props.status === 'PROCESSING') return Color.primary;
    else if (props.status === 'FAIL') return '#f05c2e';
  }};
`;

const Content = styled.div`
  border-radius: 8px;
  border: 1px solid ${Color.gray100};
  padding: 24px;
  display: grid;
  grid-template-columns: 130px auto;
  row-gap: 12px;
  column-gap: 16px;
`;
const MobileLabel = styled.div`
  font-size: 14px;
  color: ${Color.gray800};
  font-weight: 700;
`;
const MobileValue = styled.div`
  font-size: 14px;
  color: ${Color.gray800};
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
